import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import SelectProducerModal from '../../components/SelectProducerModal/SelectProducerModal'
import SelectProducerCard from 'features/contracts/add/components/SelectProducerCard/SelectProducerCard'
import { AddContractStepProps, AddContractSteps } from '../../types'
import contractStyles from '../../../Contracts.module.scss'
import styles from './ProducerStep.module.scss'
import classNames from 'classnames'
import { Producer } from 'types/types'
import { Button } from '@boltenergy-be/design-system'

import { useGetPersonalizedProducersQuery } from 'store/queries/bolt-api'
import Card from 'components/Card/Card.tsx'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { useStoreSelector } from 'hooks/store.ts'

const ProducerStep = ({ setCurrentStep, setNextStep, addContractData, setAddContractData }: AddContractStepProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Redux queries
  const {
    data: personalizedProducers,
    isLoading,
    isError
  } = useGetPersonalizedProducersQuery({ address: addContractData.address.deliveryAddress })

  // Local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('contracts')

  /**
   * Update producer in registration data state object
   * @param {Producer} producer
   */
  const handleSelectProducer = useCallback(
    (producer: Producer) => {
      if (typeof setAddContractData !== 'undefined') {
        setAddContractData({
          ...addContractData,
          producer
        })
      }
    },
    [addContractData, setAddContractData]
  )

  // Set producer from personalized producers if available
  useEffect(() => {
    if (
      !addContractData?.[AddContractSteps.PRODUCER] &&
      personalizedProducers?.[language] &&
      Object.values(personalizedProducers[language]).some((p) => !!p)
    ) {
      if (personalizedProducers[language].popular) handleSelectProducer(personalizedProducers[language].popular)
      else if (personalizedProducers[language].nearest) handleSelectProducer(personalizedProducers[language].nearest)
      else if (personalizedProducers[language].recent) handleSelectProducer(personalizedProducers[language].recent)
    }
  }, [personalizedProducers, addContractData, language, handleSelectProducer])

  return (
    <div className={classNames(contractStyles['form-card'], styles['producer-step'])}>
      <div className={contractStyles['form-content']}>
        <div>
          <Card.Title>{t('add.steps.producer.title')}</Card.Title>
          <p className={styles['step-description']}>{t('add.steps.producer.description')}</p>
        </div>

        {isLoading ? (
          <LoadingDots />
        ) : (
          <>
            {!isError && personalizedProducers ? (
              <>
                <ul className={styles['producers-container']}>
                  {personalizedProducers[language].popular && (
                    <li className={styles['producer-card-container']}>
                      <SelectProducerCard
                        badge={t('add.steps.producer.categories.popular')}
                        key={personalizedProducers[language].popular?.id}
                        producer={personalizedProducers[language].popular}
                        activeProducerId={addContractData.producer?.id || ''}
                        handleSelectProducer={() => handleSelectProducer(personalizedProducers[language].popular)}
                      />
                    </li>
                  )}
                  {personalizedProducers[language].nearest && (
                    <li className={styles['producer-card-container']}>
                      <SelectProducerCard
                        badge={t('add.steps.producer.categories.nearest')}
                        key={personalizedProducers[language].nearest?.id}
                        producer={personalizedProducers[language].nearest}
                        activeProducerId={addContractData.producer?.id || ''}
                        handleSelectProducer={() => handleSelectProducer(personalizedProducers[language].nearest)}
                      />
                    </li>
                  )}
                  {personalizedProducers[language].recent && (
                    <li className={styles['producer-card-container']}>
                      <SelectProducerCard
                        badge={t('add.steps.producer.categories.recent')}
                        key={personalizedProducers[language].recent?.id}
                        producer={personalizedProducers[language].recent}
                        activeProducerId={addContractData.producer?.id || ''}
                        handleSelectProducer={() => handleSelectProducer(personalizedProducers[language].recent)}
                      />
                    </li>
                  )}
                </ul>

                <Button className={styles['button-trigger-modal']} representation="link" onClick={() => setIsModalOpen(true)}>
                  {t('add.steps.producer.buttonViewAll')}
                </Button>
              </>
            ) : (
              <div className={contractStyles['error-description-container']}>{t('errorTryLater', { ns: 'common' })}</div>
            )}
          </>
        )}
      </div>

      {!isLoading && (
        <FormButtons
          currentStep={AddContractSteps.PRODUCER}
          setCurrentStep={(step) => setCurrentStep(step as AddContractSteps)}
          submitDisabled={isError || !addContractData.producer}
          trackingId={ContractFlowTrackingTypes.ADD}
          onSubmit={() => {
            if (addContractData.producer) setNextStep(addContractData.producer, AddContractSteps.PRODUCT)
          }}
        />
      )}

      {/* EDIT PRODUCER MODAL */}
      <SelectProducerModal
        isOpen={isModalOpen}
        setClose={() => setIsModalOpen(false)}
        addContractData={addContractData}
        setAddContractData={setAddContractData}
      />
    </div>
  )
}

export default ProducerStep
