import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { getInvoiceTypeTranslationKey } from 'pages/App/Billing/Invoices/hooks/utils.tsx'
import { useTranslation } from 'react-i18next'
import { DescriptionProps } from 'pages/App/Billing/Invoices/InvoicesTable/Description/types.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { formatAddress } from 'utils/user.ts'
import { getContractByContractNumber } from 'utils/contracts.ts'
import { DocumentType } from 'types/types.ts'

const Description = ({ document }: DescriptionProps) => {
  // Redux
  const { customers, selectedCustomer } = useStoreSelector((store) => store.user)

  // i18n
  const { t } = useTranslation('invoices')

  // Constants
  const invoiceTypeTranslationKey = getInvoiceTypeTranslationKey(document)
  const isCustomerInvoice = document?.documentType === DocumentType.INVOICE
  const address =
    formatAddress(getContractByContractNumber(customers[selectedCustomer]?.contracts, document.electricityContractNumber)?.address) ??
    undefined

  return (
    <Cell variant={CellVariant.DESCRIPTION} title={address}>
      <span>
        {invoiceTypeTranslationKey
          ? t(`invoiceTypes.${invoiceTypeTranslationKey}`, {
              ns: 'billing'
            })
          : t('cells.bankTransaction')}
      </span>
      {isCustomerInvoice && <span>{address}</span>}
    </Cell>
  )
}

export default Description
