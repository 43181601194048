// ENUMS

export enum Region {
  FLA = 'Flanders',
  WAL = 'Wallonia',
  BRU = 'Brussels'
}

export enum Province {
  BRU = 'Brussels',
  WVL = 'West-Flanders',
  OVL = 'East-Flanders',
  ANT = 'Antwerp',
  LIM = 'Limburg',
  VLB = 'Flemish Brabant',
  WLB = 'Walloon Brabant',
  HAI = 'Hainaut',
  NMR = 'Namur',
  LIE = 'Liege',
  LUX = 'Luxembourg'
}

// TYPES

export type EnergyOverviewMetrics = {
  community: EnergyOverviewCommunity
  personal: EnergyOverviewPersonal
}

export type EnergyOverviewCommunity = {
  households: {
    total: number
    people: number
    cats: number
    dogs: number
    bicycleMechanics: number
    bakers: number
    schools: number
    barbers: number
  }
  carbonDioxide: {
    reduction: number
    trees: number
    flightsBrusselsNyc: number
    hummers: number
  }
  producers: {
    total: number
    freeHouseholds: number
    production: {
      total: number
      solar: number
      wind: number
      water: number
      bio: number
      households: number
    }
    usage: number
  }
  miniProducers?: MiniProducers
  referrals: {
    total: number
  }
}

export type MiniProducers = {
  amount: number
  totalInjection: number
  households: number
}

export type EnergyOverviewPersonal = {
  referrals: {
    amount: number
    code: string
  }
  electricity: {
    consumed: Volume & {
      laundryMachines: number
      microwaveHours: number
      amountOfPhoneCharges: number
      vacuumHours: number
      tvHours: number
    }
    carbonDioxide: {
      reduction: number
      trees: number
      dieselKilometers: number
      avocados: number
      steaks: number
      veggieDays: number
    }
    produced?: Volume
  }
  gas?: {
    consumed: Volume
  }
}

export type Volume = {
  total: number
  from: string // ISO 8601 format
  to: string // ISO 8601 format
}

export type ProvinceWithCities = {
  province: Province
  region: Region
  cities: any
}

export type BoltAmbitionConstant = {
  amount: number
  [Region.FLA]: string
  [Region.WAL]: string
  [Region.BRU]: { nl: string; fr: string }
}

export type BoltAmbition = {
  level: number
  list: string[]
  nextGoal: number
}
