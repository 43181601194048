import dayjs from 'dayjs'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from './Events.module.scss'
import SalesCard from 'components/SalesCard/SalesCard'
import { WEBSITE_URL } from 'constants/envs'
import { Store } from 'store/types'
import { SalesEvent } from 'types/sales'
import { useGetSalesEventsQuery } from 'store/queries/bolt-api'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { stringify } from 'qs'
import { Heading } from '@boltenergy-be/design-system'

const Events = () => {
  const user = useSelector((store: Store) => store.user)
  const { data, isLoading, isError } = useGetSalesEventsQuery({ filterSignatures: false })

  // i18n
  const { t } = useTranslation(['home', 'common'])

  /**
   * Opens the event link of the given event
   *
   * @param {SalesEvent} event
   */
  const openEventLink = (event: SalesEvent) => {
    const query = stringify(
      {
        ...event.parameters,
        agent_id: user?.sales?.agentId || '',
        event_id: event.eventId,
        event_name: event.eventName,
        partner_id: user?.sales?.partner.id || ''
      },
      { skipNulls: true, addQueryPrefix: true }
    )

    const url = `${WEBSITE_URL}/${user.info.language}${query}`
    window.open(url, '_blank')?.focus()
  }

  /**
   * Formats the arrays into a string if applicable
   *
   * @param {string[] | string} array
   */
  const formatArrays = (array: string[] | string) => {
    if (Array.isArray(array) && array.every((item) => typeof item === 'string')) {
      return array.join(' ')
    }
    return array
  }

  return (
    <div className="content">
      <div className={classNames('container', styles.wrapper)}>
        <section className={styles.events}>
          <div className={styles['section-head']}>
            <Heading as="h1" variant="h3">
              {t('app.welcome')}
            </Heading>
          </div>

          {isError && <p>{t('common:error')}</p>}

          {!!data && (
            <>
              {data?.events && data.events?.length > 0 ? (
                data.events
                  .filter((event: SalesEvent) => !event.hidden)
                  .map((event: SalesEvent) => (
                    <SalesCard key={event.eventId} extraClasses={styles['event-card']} onClick={() => openEventLink(event)}>
                      <strong className={styles['event-title']}>
                        {event.eventId} | {event.eventName}
                      </strong>
                      <div className={styles.parameters}>
                        <span>{formatArrays(event.parameters.simulation_so)}</span>
                        <span>{formatArrays(event.parameters.sign_channel)}</span>
                        <span>{formatArrays(event.parameters.simulation_type)}</span>
                      </div>
                      {(event.eventStartDate || event.eventEndDate) && (
                        <small className={styles['event-date']}>
                          {event.eventStartDate && dayjs(event.eventStartDate).format('DD/MM/YYYY')}
                          {event.eventStartDate && event.eventEndDate && ' - '}
                          {event.eventEndDate && dayjs(event.eventEndDate).format('DD/MM/YYYY')}
                        </small>
                      )}
                    </SalesCard>
                  ))
              ) : (
                <p>{t('sales.home.noEvents')}</p>
              )}
            </>
          )}

          {!data && isLoading && <LoadingDots />}
        </section>
      </div>
    </div>
  )
}

export default Events
