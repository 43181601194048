import styles from 'pages/App/Friends/Friends.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FriendStatusProps } from 'pages/App/Friends/components/FriendStatus/types'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import ReferralIcon from 'components/Icon/ReferralIcon/ReferralIcon'
import { ReferralIconType } from 'components/Icon/ReferralIcon/types'
import { Heading } from '@boltenergy-be/design-system'
import { DEFAULT_REFERRAL_AMOUNT } from 'constants/referral.ts'

const FriendStatus = ({ referral }: FriendStatusProps) => {
  // Redux
  const { language } = useSelector((store: Store) => store.app)

  // i18n
  const { t } = useTranslation('referral')

  // constants
  const isClient = !referral.pending
  const isOlderReferral = dayjs().subtract(1, 'week').isAfter(dayjs(referral.createdAt))

  /**
   * Get the correct translation key depending on whether or not referral comes from move
   * @returns {string}
   */
  const getTranslationKey = () => {
    if (isClient) {
      return referral.simulationSalesOffice === 'Move Process' ? 'referrals.list.clientFromMove' : 'referrals.list.isClient'
    }
    return isOlderReferral ? 'referrals.list.notClient' : 'referrals.list.notClientAlt'
  }

  const formatDate = dayjs(referral?.closeDate).locale(language).format('DD MMMM YYYY')

  return (
    <li className={styles['friend-status']}>
      <div className={styles.friendStatusIcon}>
        {isClient ? <ReferralIcon type={ReferralIconType.SMILEY} /> : <ReferralIcon type={ReferralIconType.OPEN_MOUNTH} />}
      </div>
      <div className={styles['friend-status-overview']}>
        <Heading as="h3" variant="h6" className={classNames(styles['friend-name'], styles['extra-bold'])}>
          {referral.firstName} {referral.lastName}
        </Heading>
        <p className={classNames(styles['friend-status-description'])}>{t(getTranslationKey(), '', { date: formatDate })}</p>
        {isClient && (
          <p className={classNames(styles['friend-status-result'])}>
            {t('friends.blocks.friendsList.list.reward', '', { euro: referral?.reward ?? DEFAULT_REFERRAL_AMOUNT })}
          </p>
        )}
      </div>
    </li>
  )
}

export default FriendStatus
