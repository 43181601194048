import App from 'pages/App/App'
import { store } from 'store'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import Bugsnag from '@bugsnag/js'
import invariant from 'tiny-invariant'
import { deleteRedirect } from 'store/app/slice'
import { UrlSearchParamsKeys } from 'store/app/types'
import { routes } from 'types/routes'
import { getUserTypeLoginPage, setSelectedUserDataInStore } from 'utils/user'
import { countTotalContracts, findFirstActiveElectricityContract, hasMultipleActiveElectricityContracts } from 'utils/contracts'
import { UserTypes } from 'store/auth/types'
import { logout } from 'utils/app'
import { setUserType } from 'utils/userType'
import { isAuthenticated } from 'utils/localStorageService'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { getUserData } from 'store/user/thunks/customer'
import { unwrapResult } from '@reduxjs/toolkit'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import { ContractType } from 'types/types.ts'

const AppContainer = () => {
  // REDUX STORE
  const { redirect, urlSearchParams } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { userError, customers, info } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Local State
  const [isLoading, setIsLoading] = useState(true)
  const [userSuccess, setUserSuccess] = useState<boolean>(false)

  // React Router
  const navigate = useNavigate()

  // Auth constants
  const authenticatedUserTypes = isAuthenticated()

  /**
   * Redirect to the sales portal or logout if the user is not a sales user
   */
  useEffect(() => {
    if (!authenticatedUserTypes[UserTypes.CUSTOMER] && !authenticatedUserTypes[UserTypes.SUPER_USER]) {
      logout(() => navigate(getUserTypeLoginPage(userType)))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fire the getUserData thunk and set the userSuccess state to true if the promise resolves
   */
  const fetchUserData = async () => {
    const thunkRes = await dispatch(getUserData({ email: undefined }))
    const unwrappedThunkRes = unwrapResult(thunkRes)
    if (unwrappedThunkRes) setUserSuccess(true)
  }

  /**
   * Fetches the user data for the contact id in the urlSearchParams store entry
   */
  const fetchUserDataForContactId = async () => {
    const contactId = urlSearchParams[UrlSearchParamsKeys.CONTACT_ID]

    if (contactId) {
      const { success, data } = await request(getAxiosRequestConfig(Methods.GET, `/contact/${contactId}/email`))

      if (success) {
        const { email } = data
        const thunkRes = await dispatch(getUserData({ email }))
        const unwrappedThunkRes = unwrapResult(thunkRes)
        if (unwrappedThunkRes) setUserSuccess(true)
      }
    }
  }

  /**
   * 1. Fetch required data:
   * - User data
   * - FAQ
   * - Feedback widgets
   */
  useEffect(() => {
    const { urlSearchParams } = store.getState().app

    if (!userType && (authenticatedUserTypes[UserTypes.CUSTOMER] || authenticatedUserTypes[UserTypes.SUPER_USER])) {
      // Fetch user data
      setUserType(authenticatedUserTypes[UserTypes.SUPER_USER] ? UserTypes.SUPER_USER : UserTypes.CUSTOMER)
    }

    if (userType && userType !== UserTypes.SALES) {
      if (userType === UserTypes.SUPER_USER) {
        // Fetch user data by contact id if necessary
        if (urlSearchParams[UrlSearchParamsKeys.CONTACT_ID]) fetchUserDataForContactId()

        setIsLoading(false)
        navigate(routes.CUSTOMER_EMAIL)
      } else {
        // Fetch 'normal' user data
        fetchUserData()
      }
    }
  }, [userType])

  /**
   * 2. Check contracts of user
   * If only 1 contract is found, redirect to overview and load contract data in store
   * If more contracts are found, redirect to welcome/home page
   */
  useEffect(() => {
    if (userError) {
      setIsLoading(false)
    }

    if (userSuccess) {
      const customersArray = Object.values(customers)
      const totalContracts = countTotalContracts(customersArray)

      if (totalContracts === 1 || !hasMultipleActiveElectricityContracts(customers)) {
        const firstActiveItem = findFirstActiveElectricityContract(customers)
        const firstContract = {
          contract: customersArray[0].contracts[0].find((c) => c.type === ContractType.BILLING),
          customer: customersArray[0]?.id
        }
        const itemToSelect = firstActiveItem || firstContract

        const billingId = itemToSelect?.contract?.id
        invariant(billingId)
        setSelectedUserDataInStore(customers, itemToSelect?.customer, billingId)

        // Define where to redirect: if redirect string in AppStore present, use that. Else redirect to overview
        if (redirect) {
          navigate(redirect)
          // Clear redirect from AppStore
          dispatch(deleteRedirect())
        } else {
          navigate(routes.OVERVIEW_GENERAL)
        }
      } else {
        // Multiple active contracts: redirect to choose address page
        navigate(routes.WELCOME)
      }

      if (info.email) {
        // Add user data to Bugsnag
        Bugsnag.addMetadata('user', {
          email: info.email
        })
      }

      setIsLoading(false)
    }
  }, [userSuccess, userError])

  /**
   * Handles the logout with correct redirect
   */
  const handleLogout = () =>
    logout(() => {
      setUserType(null)
      navigate(getUserTypeLoginPage(userType))
    })

  return (
    <App {...{ isLoading, logout: handleLogout }}>
      <Outlet />
    </App>
  )
}

export default AppContainer
