import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { MoveFlows, UserStore } from 'store/user/types.ts'
import { SetMoveFlowStatePayload } from 'store/user/reducers/types.ts'
import { initialUserState } from 'store/user/initial.ts'

const setMoveFlow: CaseReducer<UserStore, PayloadAction<MoveFlows>> = (state, action) => {
  return {
    ...state,
    move: {
      ...state.move,
      flow: action.payload
    }
  }
}

const setMoveFlowState: CaseReducer<UserStore, PayloadAction<SetMoveFlowStatePayload>> = (state, action) => {
  return {
    ...state,
    move: {
      ...state.move,
      [action.payload.key]: action.payload.state
    }
  }
}

const resetMoveFlow: CaseReducer<UserStore, PayloadAction<void>> = (state) => {
  return {
    ...state,
    move: initialUserState.move
  }
}

export const commonUserReducers = {
  resetMoveFlow,
  setMoveFlow,
  setMoveFlowState
}
