import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import BCStyles from '../../BillingCycles.module.scss'
import styles from './PreviousCycles.module.scss'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import { PeriodTypes } from '../../types'
import OverviewCard from '../OverviewCard/OverviewCard'
import { formatAmount } from 'utils/format'
import { getTitleSection } from '../TitleSection/TitleSection'
import PreviousBillingCycleChart from 'components/Charts/BillingCycle/Previous/PreviousBillingCycleChart'
import { PreviousCyclesProps } from './types'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import UsageBadges from '../UsageBadges/UsageBadges'
import { Button, Heading } from '@boltenergy-be/design-system'

import { GET_HELP_FAQ_PREVIOUS_BILLING_CYCLES } from '../../constants'
import HelpModal from 'components/HelpModal/HelpModal'
import { WidgetSlug } from 'types/feedbackWidget'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget'
import { getPreviousBillingCycle, getPreviousBillingCyclePeriods } from 'store/contracts/thunks'
import { useGetFeedbackWidgetBySlugQuery } from 'store/queries/bolt-api'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import Card from 'components/Card/Card.tsx'

const PreviousCycles = ({ billingContractId, openId }: PreviousCyclesProps) => {
  // Redux
  const { previousBillingCycles } = useStoreSelector((store) => store.contracts)
  const { periods, loadingPeriods, loadingDataIds, data, error, erroredIds } = previousBillingCycles
  const dispatch = useStoreDispatch()

  // Redux queries
  const { data: feedbackWidgetData } = useGetFeedbackWidgetBySlugQuery({ slug: WidgetSlug.PREVIOUS_BILLING_CYCLES })

  // Local state
  const [isPreviousBillingCyclesHelpModalOpen, setIsPreviousBillingCyclesHelpModalOpen] = useState<boolean>(false)
  const [canOpenFeedbackWidget, setCanOpenFeedbackWidget] = useState<boolean>(true)
  const [openFeedbackWidget, setOpenFeedbackWidget] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('billing')

  // Window size
  const { isTablet } = useWindowSize()

  // Constants
  const HELP_FAQ_PREVIOUS_BILLING_CYCLES = GET_HELP_FAQ_PREVIOUS_BILLING_CYCLES()

  /**
   * Fetch billing cycles periods if none are available
   */
  useEffect(() => {
    if (!previousBillingCycles.periods?.length) {
      dispatch(getPreviousBillingCyclePeriods({ billingContractId }))
    }
  }, [billingContractId, previousBillingCycles.periods?.length])

  return (
    <>
      <section className="container">
        <div className={classNames(BCStyles['title-container'], styles['title-container'])}>
          <Heading as="h2" variant="h5" className="mb-100 tablet:mb-none">
            {t('billingCycles.previous.title')}
          </Heading>
          <Button representation="link" onClick={() => setIsPreviousBillingCyclesHelpModalOpen(true)} leadingIcon="info">
            {t('billingCycles.help')}
          </Button>
        </div>

        <ul className={styles['cycle-cards-list']}>
          {!loadingPeriods && !!periods?.length ? (
            periods.map((period) => (
              <li key={period.id} id={period.id}>
                <CollapsibleCard
                  startCollapsed={openId ? period.id !== openId : true}
                  disabled={!!loadingDataIds.length && !loadingDataIds.includes(period.id) && !data[period.id]}
                  titleSection={getTitleSection(PeriodTypes.PREVIOUS, period)}
                  onOpen={() => {
                    // Pop feedback widget after 10 seconds and disable another pop
                    if (canOpenFeedbackWidget) {
                      setTimeout(() => {
                        setOpenFeedbackWidget(true)
                      }, 10000)
                      setCanOpenFeedbackWidget(false)
                    }

                    // Fetch data if not available, hasn't errored and isn't loading
                    if (!data[period.id] && !erroredIds?.includes(period.id) && !loadingDataIds.includes(period.id)) {
                      dispatch(getPreviousBillingCycle({ billingContractId, billingPeriodId: period.id }))
                    }
                  }}
                >
                  {erroredIds?.includes(period.id) ? (
                    <div className={styles['info-content']}>{t('errorTryLater', { ns: 'common' })}</div>
                  ) : typeof data[period.id] === 'undefined' ? (
                    <div className={styles['period-loading-dots']}>
                      <LoadingDots />
                    </div>
                  ) : (
                    <div className={BCStyles['bill-shock-data']}>
                      <div className={styles['usage-container']}>
                        <strong className={styles['usage-title']}>{t('billingCycles.previous.usage.title')}:</strong>
                        <UsageBadges electricity={data[period.id].usage.electricity} gas={data[period.id].usage.gas} />
                      </div>

                      {/* CHART */}
                      <PreviousBillingCycleChart data={data[period.id]} isTablet={isTablet} />

                      {/* OVERVIEW */}
                      <OverviewCard
                        isDanger={data[period.id].settlement > 0}
                        blocks={[
                          {
                            label: t('previousBillingCycle.overview.labels.totalBilled', { ns: 'charts' }),
                            value: `€
                          ${formatAmount(
                            data[period.id].months.reduce((sum, instalment) => {
                              return sum + (instalment.billedAmount || 0)
                            }, 0)
                          )}
                        `
                          },
                          {
                            label: t('previousBillingCycle.overview.labels.totalActual', { ns: 'charts' }),
                            value: (
                              <>
                                €{' '}
                                {formatAmount(
                                  data[period.id].months.reduce((sum, instalment) => {
                                    return sum + (instalment.actualPrice || 0)
                                  }, 0)
                                )}
                              </>
                            )
                          },
                          {
                            label: t('previousBillingCycle.overview.labels.settlement', { ns: 'charts' }),
                            valueClass: classNames(BCStyles['instalment-value'], { [BCStyles.danger]: data[period.id].settlement > 0 }),
                            value: `€ ${formatAmount(Math.abs(data[period.id].settlement))} ${t(
                              `previousBillingCycle.overview.labels.${
                                data[period.id].settlement > 0 ? 'settlementPayExtra' : 'settlementRecover'
                              }`,
                              { ns: 'charts' }
                            )}`
                          }
                        ]}
                        // button={
                        //   <Button
                        //     className={styles['consumption-button']}
                        //     onClick={() =>
                        //       navigate({
                        //         pathname: routes.CONSUMPTION_YOUR_CONSUMPTION,
                        //         search: `cycle=${period.id}&type=${getPeriodType(data[period.id])}`
                        //       })
                        //     }
                        //   >
                        //     {t('previousBillingCycle.overview.button', { ns: 'charts' })}
                        //   </Button>
                        // }
                      />
                    </div>
                  )}
                </CollapsibleCard>
              </li>
            ))
          ) : (!loadingPeriods && !periods?.length) || error ? (
            <div className={classNames(styles['info-content'], { [styles['under-construction']]: !error })}>
              {error ? t('errorTryLater', { ns: 'common' }) : t('billingCycles.previous.noneFound')}
            </div>
          ) : (
            <Card className={styles['cycles-loading-dots']}>
              <LoadingDots />
            </Card>
          )}
        </ul>
      </section>

      <HelpModal
        title={t('billingCycles.previous.title')}
        isOpen={isPreviousBillingCyclesHelpModalOpen}
        setClose={() => setIsPreviousBillingCyclesHelpModalOpen(false)}
        faq={HELP_FAQ_PREVIOUS_BILLING_CYCLES}
      />

      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default PreviousCycles
