import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Phone: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M6.25 4.5A2.25 2.25 0 0 1 8.5 2.25h7a2.25 2.25 0 0 1 2.25 2.25v15a2.25 2.25 0 0 1-2.25 2.25h-7a2.25 2.25 0 0 1-2.25-2.25v-15Zm2.25-.75a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75h-7Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M9.75 5.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
      <circle cx="12" cy="18" r="1" fill={iconColor} />
    </svg>
  )
}

Phone.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Phone
