import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import type { Response } from 'types/request'
import { Methods } from 'types/request'
import { createCookie } from 'utils/cookies'
import { Language } from 'store/app/types'
import { switchLanguage } from 'store/app/thunks'
import type {
  GetUserDataPayload,
  GetUserDataSuccessPayload,
  RejectValue,
  SelectContractsPayload,
  SelectContractsSuccessPayload,
  UpdateBillingPayload,
  UpdateBillingSuccessPayload,
  UpdateCustomerPayload,
  UpdateCustomerSuccessPayload,
  UpdateInstalmentsPayload
} from './types'
import type { RootState } from 'store'
import { ContractType } from 'types/types'
import Geocode from 'react-geocode'
import { GMAP_KEY } from 'constants/envs'
import { setSelectedContracts } from 'store/user/slice'
import { CustomerLibrary } from '../types'
import { GetUserDataResponseData } from 'types/user'

export const getUserData = createAsyncThunk<GetUserDataSuccessPayload, GetUserDataPayload, RejectValue>(
  'users/customer/getUserData',
  async ({ email }, thunkAPI) => {
    try {
      const encodedEmail = email ? encodeURIComponent(email) : null
      const { success, data, message, error }: Response<GetUserDataResponseData> = await newRequest(
        getAxiosRequestConfig(Methods.GET, `/user${encodedEmail ? `?email=${encodedEmail}` : ''}`)
      )

      if (success && data) {
        const { user, customers, producers } = data

        // Set language cookie
        createCookie('language', user.language, 365)

        // Fetch current language and change it if it doesn't match the user language
        const lang: Language = (thunkAPI.getState() as RootState).app.language
        if (lang !== user.language) thunkAPI.dispatch(switchLanguage({ language: user.language }))

        const customersWithFilteredContracts = Object.values(customers)
          .map((customer) => ({
            ...customer,
            contracts: customer.contracts.filter((contracts) => contracts.some((contract) => contract.type === ContractType.ELECTRICITY))
          }))
          .reduce((acc, customer) => {
            acc[customer.id] = customer
            return acc
          }, {} as CustomerLibrary)

        return { user, customers: customersWithFilteredContracts, producers } as GetUserDataSuccessPayload
      }

      return thunkAPI.rejectWithValue(message ?? error ?? 'getUserData')
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const selectContracts = createAsyncThunk<SelectContractsSuccessPayload, SelectContractsPayload, RejectValue>(
  'users/customer/selectContracts',
  async ({ contracts, address }, thunkAPI) => {
    const addressParam = address.streetName + ' ' + address.streetNumber + ' ' + address.postalCode + ' ' + address.townName
    thunkAPI.dispatch(setSelectedContracts(contracts))

    try {
      Geocode.setApiKey(GMAP_KEY as string)
      const { results } = await Geocode.fromAddress(addressParam)

      if (results) {
        return {
          location: results[0].geometry.location
        }
      } else {
        return thunkAPI.rejectWithValue('selectContract Geocode error')
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateBilling = createAsyncThunk<UpdateBillingSuccessPayload, UpdateBillingPayload, RejectValue>(
  'users/customer/updateBilling',
  async ({ billingContractId, updateBillingContractData }, thunkAPI) => {
    try {
      const { success, data, message }: Response<UpdateBillingSuccessPayload> = await newRequest(
        getAxiosRequestConfig(Methods.PATCH, `/contracts/${billingContractId}`, { contract: updateBillingContractData })
      )

      if (success && data) {
        return data
      } else {
        return thunkAPI.rejectWithValue(message || 'updateBilling')
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateCustomer = createAsyncThunk<UpdateCustomerSuccessPayload, UpdateCustomerPayload, RejectValue>(
  'users/customer/updateCustomer',
  async ({ customerId, customer }, thunkAPI) => {
    try {
      const { success, data, message } = await newRequest<UpdateCustomerSuccessPayload>(
        getAxiosRequestConfig(Methods.PATCH, `/customers/${customerId}`, { customer })
      )

      if (success && data) {
        return data
      } else {
        return thunkAPI.rejectWithValue(message || 'updateCustomer')
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateInstalment = createAsyncThunk<UpdateInstalmentsPayload, UpdateInstalmentsPayload, RejectValue>(
  'users/customer/updateInstalment',
  async ({ billingContractId, electricity, gas }, thunkAPI) => {
    try {
      const { success, message }: Response = await newRequest(
        getAxiosRequestConfig(Methods.PATCH, `/contracts/${billingContractId}/instalment`, {
          electricityInstalment: electricity.detail.instalment,
          gasInstalment: gas?.detail.instalment
        })
      )

      if (success) {
        return { billingContractId, electricity, gas }
      } else {
        return thunkAPI.rejectWithValue(message || 'updateInstalment')
      }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
