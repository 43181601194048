import { ConsumptionPlannerWrapperProps } from 'components/Charts/ConsumptionPlanner/types.ts'
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, PointElement } from 'chart.js'
import { yScaleTitle } from 'components/Charts/plugins.ts'
import { useStoreSelector } from 'hooks/store.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import dayjs from 'dayjs'
import { Language } from 'store/app/types.ts'
import { useMemo } from 'react'
import { getConsumptionPlannerChartDatasets } from 'components/Charts/ConsumptionPlanner/chartjs.config.ts'
import ConsumptionPlannerChartMobile from 'components/Charts/ConsumptionPlanner/ChartMobile.tsx'
import ConsumptionPlannerChartDesktop from 'components/Charts/ConsumptionPlanner/ChartDesktop.tsx'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, yScaleTitle)

const ConsumptionPlannerChart = ({ data, lowestPriceIndexes }: ConsumptionPlannerWrapperProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Custom hooks
  const { isTablet } = useWindowSize()

  // Constants
  const labels = data.map((entry) => `${dayjs(entry.date).format('HH')}${language === Language.NL ? 'u' : 'h'}`)

  // Chart data
  const chartData = useMemo<any>(
    () => ({
      labels,
      datasets: getConsumptionPlannerChartDatasets({
        labels,
        data,
        lowestIndexes: lowestPriceIndexes
      })
    }),
    [data, labels, lowestPriceIndexes]
  )

  return isTablet ? (
    <ConsumptionPlannerChartMobile {...{ chartData, labels }} />
  ) : (
    <ConsumptionPlannerChartDesktop {...{ chartData, labels }} />
  )
}

export default ConsumptionPlannerChart
