import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Thermostat: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M12.25 2.75a2.583 2.583 0 0 0-2.583 2.583v7.778c0 .042 0 .083.003.124l.016.356-.266.238a4.25 4.25 0 1 0 5.66 0l-.266-.238.016-.356a2.53 2.53 0 0 0 .003-.124V5.333A2.583 2.583 0 0 0 12.25 2.75ZM8.167 5.333a4.083 4.083 0 0 1 8.166 0v7.619a5.75 5.75 0 1 1-8.167 0V5.333Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M13.06 7a.81.81 0 0 0-1.62 0v7.017a2.851 2.851 0 1 0 1.62 0V7Zm-2.16 9.75a1.35 1.35 0 1 1 2.7 0 1.35 1.35 0 0 1-2.7 0Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Thermostat.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Thermostat
