const CloseHouse = ({ className }: Partial<JSX.IntrinsicElements['svg']>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className={className}>
      <path
        d="M27 12.5542C27 11.5664 26.5063 10.644 25.6845 10.0961L17.721 4.78713C16.6788 4.09236 15.3212 4.09236 14.279 4.78713L6.31552 10.0961C5.49365 10.644 5 11.5664 5 12.5542V23.8362C5 25.5497 6.38907 26.9388 8.10256 26.9388H23.8974C25.6109 26.9388 27 25.5497 27 23.8362L27 12.5542Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path d="M11 17H16M16 17H21M16 17V12M16 17V22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CloseHouse
