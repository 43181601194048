import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { createCookie, readCookie } from 'utils/cookies'
import { Language } from 'store/app/types'
import { NODE_ENV } from 'constants/envs'
import dutch from './dutch'
import french from './french'

export const defaultNS = 'common'
export const nameSpaces = [
  'auth',
  'billing',
  'charts',
  'common',
  'consumption',
  'contracts',
  'energyOverview',
  'feedbackWidget',
  'home',
  'invoices',
  'overview',
  'producer',
  'referral',
  'support',
  'user',
  'validation'
]

export const resources = {
  nl: dutch,
  fr: french
} as const

/**
 * Define the default language
 */
const defaultLanguage = () => {
  const cookieLanguage = readCookie('language')

  if (cookieLanguage === null) {
    const searchParams = new URLSearchParams(window.location.search)
    const urlLanguage = searchParams.get('lang')

    if (urlLanguage === null) {
      return Language.NL
    }

    createCookie('language', urlLanguage, 365)
    return urlLanguage
  }

  return cookieLanguage
}

i18n.use(initReactI18next).init({
  returnNull: false,
  debug: NODE_ENV === 'development',
  lng: defaultLanguage(),
  fallbackLng: Language.NL,
  ns: nameSpaces,
  defaultNS: defaultNS,
  resources
})

export default i18n
