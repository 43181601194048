import { FC } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router'
import Subnav from 'components/Subnav/Subnav'
import { Tab } from 'components/Subnav/types'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'
import SeoTags from 'components/SeoTags/SeoTags'

const Support: FC = () => {
  // i18n
  const { t } = useTranslation('support')

  // React Router
  const navigate = useNavigate()
  const location = useLocation()
  const handleTabClick = (idx: number) => navigate(tabs[idx].path)

  // Tabs
  const tabs: Tab[] = [
    { label: t('faqTab'), path: routes.SUPPORT_FAQ },
    { label: t('contactTab'), path: routes.SUPPORT_CONTACT }
  ]

  /**
   * get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (location.pathname) {
      case routes.SUPPORT_FAQ:
        return `${t('nav.support', { ns: 'common' })} - ${t('faqTab')}`
      case routes.SUPPORT_CONTACT:
        return `${t('nav.support', { ns: 'common' })} - ${t('contactTab')}`
      default:
        return t('nav.support', { ns: 'common' })
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <Subnav tabs={tabs} activeTab={handleTabClick} />

      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default Support
