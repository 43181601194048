import { BillShock } from 'types/billShock'
import { CHART_COLORS } from '../constants'

/**
 * Function to update proposed instalment to 20 if it's a negative amount
 *
 * @param {BillShock} billShockData
 * @returns {BillShock}
 */
export const updateProposedInstalmentBillShockData = (billShockData: BillShock): BillShock => {
  const futureMonths = billShockData.future.map((month) => {
    return {
      ...month,
      proposedInstalment: month.proposedInstalment < 20 ? 20 : month.proposedInstalment
    }
  })

  return {
    ...billShockData,
    future: futureMonths,
    instalment: {
      ...billShockData.instalment,
      minimum: billShockData.instalment.minimum < 20 ? 20 : billShockData.instalment.minimum,
      proposed: billShockData.instalment.proposed < 20 ? 20 : billShockData.instalment.proposed
    }
  }
}

/**
 * Get settlement color based on settlement total amount
 * @param {number} settlementTotal
 * @returns {string}
 */
export const getSettlementColor = (settlementTotal: number): string => (settlementTotal <= 0 ? CHART_COLORS.greenDark : CHART_COLORS.orange)
