import classNames from 'classnames'
import Icon from 'components/Icon/Icon'
import { ClickableCardProps } from './types'
import styles from './ClickableCard.module.scss'
import LoadingDots from '../LoadingDots/LoadingDots'
import Card from 'components/Card/Card.tsx'
import { memo } from 'react'

const ClickableCard = memo(({ onClick, icons = null, isDisabled = false, isLoading = false, children }: ClickableCardProps) => {
  return (
    <Card as="button" className={styles['clickable-card']} disabled={isDisabled || isLoading} onClick={onClick}>
      {children}
      <div className={styles['icons-container']}>
        {icons}
        <div
          className={classNames(styles['arrow-icon-container'], {
            [styles['has-icons']]: !!icons
          })}
        >
          {isLoading ? <LoadingDots /> : !isDisabled ? <Icon name="arrowRight" color="currentColor" /> : null}
        </div>
      </div>
    </Card>
  )
})

ClickableCard.displayName = 'ClickableCard'

export default ClickableCard
