import { FC } from 'react'
import styles from './ComparisonCard.module.scss'
import { ComparisonCardProps as Props } from 'components/EnergyOverview/ComparisonCard/types'

const ComparisonCard: FC<Props> = ({ data, description, icon }) => {
  return (
    <div className={styles['comparison-wrapper']}>
      <div className={styles['energy-comparison-card']}>
        <div className={styles.icon}>{icon}</div>
        <h3 className={styles.data}>{data}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}

export default ComparisonCard
