import { ReferralIconProps } from './types'
import { iconVariants } from './constants'

const ReferralIcon = ({ type }: ReferralIconProps) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill={iconVariants[type].rectFill} />
      <path fillRule="evenodd" clipRule="evenodd" d={iconVariants[type].d} fill={iconVariants[type].pathFill} />
    </svg>
  )
}

export default ReferralIcon
