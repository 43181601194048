import { normalizeText } from './format'

/**
 * Returns the keys of a given enum object
 *
 * @template O
 * @param {O} obj
 * @returns K[]
 */
export const enumKeys = <O extends Record<string, unknown>, K extends keyof O = keyof O>(obj: O): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]
}

export const getPropertyByDottedPath = (path: string, object?: Record<string, any>) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], object)
}

/**
 * Checks for matching strings in given keys
 *
 * @param {string[]} keys
 * @param {string} query
 * @returns {boolean}
 */
export const searchMultipleKeys = (keys: string[], query: string): boolean => {
  return keys.some((key) => normalizeText(key).includes(normalizeText(query)))
}

/**
 * Shuffles any given array with the Fisher-Yates algorithm
 *
 * @template T
 * @param {T[]} array
 * @returns T[]
 */
export const shuffleArray = <T>(array: T[]): T[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]

    array[i] = array[j]
    array[j] = temp
  }

  return array
}
