import { FC, useState } from 'react'
import parse from 'html-react-parser'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Icon from 'components/Icon/Icon'
import { HelpModalContentProps, HelpModalFAQ, HelpModalProps } from './types'
import styles from './HelpModal.module.scss'
import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import { Language } from 'store/app/types'
import Modal from '../Modal/Modal'
import { checkHasFixedProduct } from 'utils/products'
import { Heading } from '@boltenergy-be/design-system'

/**
 * Returns the correct image based on language & responsiveness
 * @param {HelpModalFAQ} faq
 * @param {boolean} isTablet
 * @param {Language} language
 * @returns {string | undefined}
 */
const getFaqImage = (faq: HelpModalFAQ, isTablet: boolean, language: Language): string | undefined => {
  let img = null

  if (isTablet) {
    img = language === Language.FR ? faq.altImgFrMobile : faq.altImgNlMobile
  } else {
    img = language === Language.FR ? faq.altImgFrDesktop : faq.image
  }

  return img || faq.image
}

const HelpModalContent: FC<HelpModalContentProps> = ({ setClose, title, faq }) => {
  // Redux
  const { language } = useSelector((store: Store) => store.app)

  // Local State
  const [subViewIndex, setSubViewIndex] = useState<number | null>(null)

  // Window size
  const { isTablet } = useWindowSize()

  // i18n
  const { t } = useTranslation()

  // constants
  const hasFixedProduct = checkHasFixedProduct()

  return (
    <>
      <div className={styles.header}>
        {subViewIndex !== null && (
          <div className={styles['back-container']}>
            <button className={styles['button-back']} onClick={() => setSubViewIndex(null)}>
              &laquo; {t('back')}
            </button>
          </div>
        )}
        <div className={classNames(styles['title-container'], { [styles['on-detail']]: subViewIndex !== null })}>
          <span className={styles['icon-container']}>
            <Icon name="info" color="currentColor" />
          </span>
          <h3>{title}</h3>
        </div>
        <button className={styles['button-close']} onClick={() => setClose()}>
          <Icon name="close" color="currentColor" />
        </button>
      </div>
      <div className={styles.body}>
        {subViewIndex === null ? (
          <ul>
            {faq.map((question, index) => {
              return (
                <li key={`q-${index}`}>
                  <button className={styles['button-question']} onClick={() => setSubViewIndex(index)}>
                    <span>{question.question}</span>
                    <div className={styles['icon-container']}>
                      <Icon name="menuRight" color="currentColor" size={16} />
                    </div>
                  </button>
                </li>
              )
            })}
          </ul>
        ) : (
          <>
            <Heading as="h4" variant="h6" weight={600} className="mb-400">
              {faq[subViewIndex].question}
            </Heading>

            <div className={styles.answer}>
              {hasFixedProduct && !!faq[subViewIndex].answerFix
                ? parse(faq[subViewIndex]!.answerFix as string)
                : parse(faq[subViewIndex].answer)}
            </div>

            {!!getFaqImage(faq[subViewIndex], isTablet, language) && (
              <div className={styles['image-container']}>
                <div className={styles['image-wrapper']}>
                  <img src={getFaqImage(faq[subViewIndex], isTablet, language)} alt={faq[subViewIndex].question} />
                </div>
              </div>
            )}

            <div className={styles['back-container-mobile']}>
              <button className={styles['button-back']} onClick={() => setSubViewIndex(null)}>
                &laquo; {t('back')}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const HelpModal: FC<HelpModalProps> = ({ isOpen, setClose, title, faq }) => {
  return (
    <Modal isOpen={isOpen} setClose={setClose} withCloseButton={false} className={styles.container}>
      <HelpModalContent setClose={setClose} title={title} faq={faq} />
    </Modal>
  )
}

export default HelpModal
