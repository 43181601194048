import { FC, useMemo } from 'react'
import { PreviousBillingCycleWrapperProps as Props } from './types'
import dayjs from 'dayjs'
import { MinMaxData } from '../../types'
import { getAlternatingBackground } from 'utils/chartjs'
import { CHART_COLORS } from '../../constants'
import annotationPlugin, { CoreAnnotationOptions } from 'chartjs-plugin-annotation'
import { BarElement, CategoryScale, Chart as ChartJS, ChartData, LinearScale, Tooltip } from 'chart.js'
import { getBillingCycleChartDatasets } from './chartjs.config'
import PreviousBillingCycleChartMobile from './ChartMobile'
import PreviousBillingCycleChartDesktop from './ChartDesktop'
import { useTranslation } from 'react-i18next'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, annotationPlugin)

const PreviousBillingCycleChart: FC<Props> = ({ data, isTablet }) => {
  // i18n
  const { t } = useTranslation('billing')

  // Constants
  const months = data.months.map((bill) => dayjs(bill.datetime).toISOString())
  const extendedMonths = [...months, t('billingCycles.chart.legend.settlement')]
  const settlementColor = data.settlement <= 0 ? CHART_COLORS.greenDark : CHART_COLORS.orange

  // Memo's
  const alternatingBackground = useMemo<CoreAnnotationOptions>(() => getAlternatingBackground(months), [months])
  const minMaxData = useMemo<MinMaxData>(() => {
    let max = Math.max(data.settlement, 0)
    let min = Math.min(data.settlement, 0, -50)

    data.months.forEach(({ billedAmount, actualPrice }) => {
      max = Math.max(billedAmount, actualPrice, max)
      min = Math.min(billedAmount, actualPrice, min)
    })

    // add extra margin on top
    max += 50

    return {
      maxDataValue: Math.ceil(max / 100) * 100,
      minDataValue: Math.floor(min / 100) * 100
    }
  }, [data])

  // Table data
  const chartData = useMemo<ChartData<'bar', (number | null)[], unknown>>(
    () => ({
      labels: extendedMonths,
      datasets: getBillingCycleChartDatasets(extendedMonths, data, isTablet)
    }),
    [data, months, isTablet]
  )

  const chartProps = { data, minMaxData, chartData, labels: extendedMonths, settlementColor, alternatingBackground }

  return isTablet ? <PreviousBillingCycleChartMobile {...chartProps} /> : <PreviousBillingCycleChartDesktop {...chartProps} />
}

export default PreviousBillingCycleChart
