import classNames from 'classnames'
import Badge from 'components/Badge/Badge'
import { BadgeColors } from 'components/Badge/types'
import LoadingCard from 'components/LoadingCard/LoadingCard'
import { determineAccessRights, getMemoizedSelectedContracts } from 'utils/contracts'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import { routes } from 'types/routes'
import styles from './General.module.scss'
import ReferralDefaultImage from 'assets/images/referral/referral-default.jpg'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds'
import Card from 'components/Card/Card.tsx'
import { DEFAULT_REFERRAL_AMOUNT } from 'constants/referral.ts'
import InvoicesStatusBanner from 'pages/App/Billing/Invoices/InvoicesStatusBanner/InvoicesStatusBanner.tsx'
import PlanConsumptionContent from 'pages/App/Consumption/YourConsumption/PlanConsumptionSection/PlanConsumptionContent.tsx'
import Link from 'components/Link/Link.tsx'
import { useGetActiveReferralActionQuery } from 'store/queries/cms-api'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'

const General = () => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)
  const { producer, selectedContracts } = useSelector((store: Store) => store.user)

  // Redux query
  const { data: activeAction } = useGetActiveReferralActionQuery({ language })

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)

  // i18n
  const { t } = useTranslation(['overview', 'common'])

  // Constants
  const amount = activeAction?.attributes?.reduction || DEFAULT_REFERRAL_AMOUNT

  return (
    <section className={classNames('container', styles.container)}>
      <div>
        <InvoicesStatusBanner />

        {producer?.id ? (
          <Card
            image={{
              src: `${producer?.images?.profile?.url}`,
              alt: `Producer: ${producer?.name[language]}`
            }}
          >
            <Card.Title>{producer?.name?.[language]}</Card.Title>
            <p>{producer?.description?.[language]}</p>
            <Link href={routes.PRODUCER} variant="secondary" className="mt-200">
              {t('general.seeProducer')}
            </Link>
          </Card>
        ) : (
          <LoadingCard />
        )}

        <Card>
          <Card.Title>{t('general.actions.title')}</Card.Title>
          <ul className={styles['actions-menu']}>
            {accessRights.instalment.canAccess && (
              <li>
                <Link variant="secondary" href={routes.BILLING_INSTALMENT} leadingIcon="chevronRight">
                  {t('general.actions.instalment')}
                </Link>
              </li>
            )}
            {accessRights.billingCycles.canAccess && (
              <li>
                <Link variant="secondary" href={routes.BILLING_CYCLES} leadingIcon="chevronRight">
                  {t('general.actions.billingCycles')}
                </Link>
              </li>
            )}
            <li>
              <Link variant="secondary" href={routes.SUPPORT_FAQ} leadingIcon="chevronRight">
                {t('general.actions.faq')}
              </Link>
            </li>
            <li>
              <Link variant="secondary" href={routes.CONSUMPTION_ENERGY_OVERVIEW} leadingIcon="chevronRight">
                {t('general.actions.energyOverview')}
              </Link>
            </li>
            <li>
              <Link variant="secondary" href={routes.CONSUMPTION_YOUR_CONSUMPTION} leadingIcon="chevronRight">
                {t('general.actions.consumption')}
              </Link>
            </li>
            <li>
              <Link
                variant="secondary"
                href={routes.MOVE_INITIATION}
                leadingIcon="chevronRight"
                onClick={() =>
                  mixpanel.track(MX_CONTRACT_FLOW.overview, {
                    from: MX_CONTRACT_FLOW.from.QUICK_ACTIONS
                  })
                }
              >
                {t('general.actions.move')}
              </Link>
              <Badge text={t('common:new')} color={BadgeColors.GREEN} className={styles.badge} />
            </li>
            <li>
              <Link
                variant="secondary"
                href={routes.CONTRACTS_ADD}
                leadingIcon="chevronRight"
                onClick={() =>
                  mixpanel.track(MX_CONTRACT_FLOW.start, {
                    from: MX_CONTRACT_FLOW.from.HOME_PAGE
                  })
                }
              >
                {t('general.actions.addAddress')}
              </Link>
            </li>
          </ul>
        </Card>
      </div>

      <div>
        {/* Referral banner */}
        <Card
          image={{
            className: activeAction ? styles['referral-image'] : styles['referral-image-default'],
            src: activeAction ? activeAction.attributes.image.data.attributes.url : ReferralDefaultImage,
            alt: ''
          }}
        >
          <Card.Title>{activeAction?.attributes.shortContent.title || t('general.referral.actions.default.title', { amount })}</Card.Title>
          {activeAction ? (
            <BlocksRenderer content={activeAction?.attributes?.shortContent?.description} />
          ) : (
            <p>{t('general.referral.actions.default.description', { amount })}</p>
          )}
          <Link href={routes.FRIENDS} variant="secondary" className="mt-200">
            {t('general.referral.button', { ns: 'overview' })}
          </Link>
        </Card>

        {accessRights.planConsumptionDynamic.canAccess && (
          <Card className={styles.dynamic}>
            <header className={styles['dynamic-header']}>
              <Card.Title>{t('general.dynamicTariff.title')}</Card.Title>
              <Link variant="secondary" href={routes.CONSUMPTION_YOUR_CONSUMPTION}>
                {t('general.dynamicTariff.cta')}
              </Link>
            </header>
            <PlanConsumptionContent compact />
          </Card>
        )}

        {accessRights.billingCycles.canAccess && (
          <Card className={styles['welcome-card']}>
            <Card.Title>{t('general.billingCycles.title')}</Card.Title>
            <p>{t('general.billingCycles.description')}</p>
            <Link representation="button" href={routes.BILLING_CYCLES}>
              {t('general.billingCycles.button')}
            </Link>
          </Card>
        )}
      </div>
    </section>
  )
}

export default General
