import { FC } from 'react'
import styles from './ArrowButton.module.scss'
import Icon from 'components/Icon/Icon'
import { ArrowButtonProps as Props } from 'components/Slider/ArrowButton/types'
import classNames from 'classnames'

const SliderArrow: FC<Props> = ({ action, onClick, disabled }) => {
  return (
    <button
      className={classNames(styles.arrow, { [styles.previous]: action === 'previous' }, { [styles.next]: action === 'next' })}
      onClick={onClick}
      disabled={disabled}
    >
      {action === 'previous' ? <Icon name="arrowLeft" /> : <Icon name="arrowRight" />}
    </button>
  )
}

export default SliderArrow
