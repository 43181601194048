/**
 * Rounds the given number to two decimals and returns it
 *
 * @param {number} number
 * @return {number}
 */
export const roundToTwoDecimals = (number: number): number => {
  // EPSILON is being used here for extraordinary cases
  // e.g. makes sure that 1.005 is correctly rounded to 1.01 instead of 1.00 otherwise
  return Math.round((number + Number.EPSILON) * 100) / 100
}

/**
 * Rounds the given number to closest 50 and returns it
 *
 * @param {number} number
 * @param {number} roundTo
 * @return {number}
 */
export const roundTo = (roundTo: number, number: number): number => {
  return Math.round(number / roundTo) * roundTo
}
