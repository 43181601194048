import { FC, PropsWithChildren, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Logo from 'assets/logo.svg'
import { routes } from 'types/routes'
import { AuthProducer, AuthProps as Props } from './types'
import styles from './Auth.module.scss'
import SeoTags from 'components/SeoTags/SeoTags'
import { Language } from 'store/app/types'
import AuthSlider from 'components/Slider/AuthSlider/AuthSlider'
import SmallProducerCard from 'components/SmallProducerCard/SmallProducerCard'
import { authProducers } from './constants'
import { shuffleArray } from 'utils/objects'
import useWindowSize from 'hooks/useWindowSize'

const Auth: FC<PropsWithChildren<Props>> = ({ children, language, switchLanguage }) => {
  // i18n
  const { t } = useTranslation('auth')

  // React Router
  const { pathname } = useLocation()

  // Window size
  const { isMobile } = useWindowSize()

  // Constants
  const shuffledProducers = useMemo<AuthProducer[]>(() => shuffleArray(authProducers), [])

  /**
   * Get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (pathname) {
      case routes.RESET_PASSWORD:
      case routes.REQUEST_NEW_PASSWORD:
      case routes.RESET_PASSWORD_SALES:
      case routes.REQUEST_NEW_PASSWORD_SALES:
        return t('seo.reset')
      case routes.REGISTER:
        return t('seo.register')
      default:
        return t('seo.login')
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <main className={styles['auth-wrapper']}>
        <section className={styles.auth}>
          <div className={styles['auth-content']}>
            <nav className={styles['auth-nav']}>
              <Link to={routes.LOGIN}>
                <img className={styles.logo} src={Logo} alt="Bolt Logo" />
              </Link>
              <button className={styles['lang-btn']} onClick={switchLanguage}>
                {Object.values(Language).map((lang) => (
                  <span className={classNames(styles.lang, { [styles.active]: lang === language })} key={lang}>
                    {lang.toUpperCase()}
                  </span>
                ))}
              </button>
            </nav>

            {children}
          </div>
        </section>

        {!isMobile && (
          <aside className={styles.producers}>
            <AuthSlider producers={shuffledProducers}>
              {shuffledProducers.map((producer) => {
                return (
                  <div key={producer[language].name} className={styles.slide}>
                    <SmallProducerCard
                      inverted
                      className={styles['producer-info']}
                      title={producer[language].name}
                      energyType={producer.energyType}
                      location={producer[language].location}
                      badge={t('badge.producer', { ns: 'producer' })}
                    />
                    <img
                      className={classNames(styles['slide-img'], 'subtle-zoom-animation')}
                      style={{ objectPosition: producer.objectPosition }}
                      src={producer.publicImgUrl}
                      alt={producer[language].imgAlt}
                    />
                  </div>
                )
              })}
            </AuthSlider>
          </aside>
        )}
      </main>
    </>
  )
}

export default Auth
