import { useState, useEffect, useRef } from 'react'

/**
 * Custom hook to check if a component is visible & handle click outside/escape key press
 *
 * @param {boolean} initVisible
 */
const useIsComponentVisible = (initVisible: boolean) => {
  // Local state
  const [isComponentVisible, setIsComponentVisible] = useState(initVisible)

  // Ref
  const ref = useRef<HTMLDivElement>(null)

  /**
   * Handles the escape key press
   *
   * @param {KeyboardEvent} e
   */
  const handleEscapeKeyPress = (e: KeyboardEvent): void => {
    if (e.key === 'Escape' && isComponentVisible) {
      setIsComponentVisible(false)
    }
  }

  /**
   * Handles the click outside the ref
   *
   * @param {Event} e
   */
  const handleClickOutside = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target as HTMLDivElement)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress, true)
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

export default useIsComponentVisible
