import contractsStyles from 'features/contracts/Contracts.module.scss'
import classNames from 'classnames'
import { Language } from 'store/app/types.ts'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { DocumentUploadInputs, DocumentUploadProps } from './types.ts'
import { useStoreSelector } from 'hooks/store.ts'
import parse from 'html-react-parser'

const DocumentUpload = ({ bigLabel }: DocumentUploadProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const { register, watch, formState, setValue } = useFormContext<DocumentUploadInputs>()
  const { errors } = formState
  const watchDocUpload = watch('docUpload')

  return (
    <div className="row">
      <fieldset className="column form-group">
        <label htmlFor="docUpload" className={bigLabel ? contractsStyles['label-large'] : undefined}>
          {t('add.steps.meterDetails.form.docUpload')}
        </label>
        <p className="mb-400">
          {parse(
            t('common.documentAlert', {
              url: 'https://www.boltenergie.be/nl/encyclopedie/energie-thuis/regel-energiecontract-voor-verhuis/energieovernamedocument-nodig'
            })
          )}
        </p>
        <div className="upload">
          <input
            hidden
            type="file"
            accept="application/pdf, image/jpeg, image/png, image/heic, image/heif"
            multiple={false}
            {...register('docUpload', {
              validate: (value) => {
                if (value && value[0]) return value[0]?.size < 10_000_000

                return true
              }
            })}
            id="docUpload"
            className={classNames('form-control', { error: errors?.docUpload })}
          />
          <div className="file">
            <label htmlFor="docUpload" className="label-as-button">
              {t('chooseFile', { ns: 'common' })}
            </label>
            {watchDocUpload && watchDocUpload[0] && (
              <>
                <span className="filename">{watchDocUpload[0].name}</span>
                <button className="button-delete" onClick={() => setValue('docUpload', null)}>
                  {t('delete', { ns: 'common' })}
                </button>
              </>
            )}
          </div>
        </div>

        {errors && errors.docUpload && (
          <span className="help-block text-negative">
            {errors.docUpload.type === 'validate' &&
              t('invalid.fileSize', { size: language === Language.NL ? '10mb' : '10mo', ns: 'validation' })}
          </span>
        )}
      </fieldset>
    </div>
  )
}

export default DocumentUpload
