import { ScriptableContext } from 'chart.js'
import { createChartDataArray } from 'utils/chartjs.ts'
import { GetConsumptionPlannerChartDatasets } from 'components/Charts/ConsumptionPlanner/types.ts'
import { CHART_COLORS, getBarChartDefaults } from 'components/Charts/constants.ts'
import { externalTooltipHandler } from 'components/Charts/chartjs.config.ts'
import { formatAmount } from 'utils/format.ts'
import i18n from 'i18next'
import { ChartLabels } from 'components/Charts/types.ts'

/**
 * Get consumption planner chart options
 */
export const getConsumptionPlannerChartOptions = (labels: ChartLabels, isMobile?: boolean) => {
  const defaults = getBarChartDefaults({ isMobile })
  const {
    scales: { y, x }
  } = defaults

  return {
    ...defaults,
    aspectRatio: isMobile ? 1.8 : 2.5,
    barPercentage: 1,
    scales: {
      y: {
        ...y,
        ticks: {
          ...y.ticks,
          callback: (value: number) => formatAmount(value, 2)
        }
      },
      x: {
        grid: {
          lineWidth: 0,
          drawTicks: true,
          tickWidth: 1,
          tickLength: 5,
          tickColor: CHART_COLORS.gridBaseline
        },
        ticks: {
          display: false,
          autoSkipPadding: 0
        }
      },
      x1: {
        grid: {
          lineWidth: 0,
          drawBorder: false,
          drawOnChartArea: false
        },
        ticks: {
          ...x.ticks,
          align: 'end',
          labelOffset: isMobile ? 0 : -5
        }
      }
    },
    plugins: {
      yScaleTitle: {
        labels: {
          y: '€/kWh'
        }
      },
      tooltip: {
        enabled: false,
        external: (context: any) => externalTooltipHandler({ context, totalColumns: 24, isCombined: false }),
        mode: 'index',
        callbacks: {
          label: (context: any) => `${formatAmount(context.raw, 3)} €/kWh`,
          title: (context: any) => {
            const from = context?.[0]?.label
            const to = labels?.[context?.[0]?.dataIndex + 1] || labels[0]
            return i18n.t('consumption:planConsumption.tooltip.title', { from, to })
          }
        }
      }
    }
  }
}

export const getConsumptionPlannerChartDatasets = ({ labels, data, lowestIndexes }: GetConsumptionPlannerChartDatasets) => {
  const borderRadius = 4
  const maxBarThickness = 28

  return [
    {
      type: 'bar' as const,
      backgroundColor: (ctx: ScriptableContext<'bar'>) =>
        lowestIndexes.includes(ctx.dataIndex) ? CHART_COLORS.greenDark : `${CHART_COLORS.blueDark}1A`, // 1A = 10% opacity in hex
      data: createChartDataArray(
        labels?.length,
        data.map(({ price }) => price || 0)
      ),
      skipNull: true,
      hideNullTooltip: true,
      borderSkipped: 'start',
      maxBarThickness,
      borderRadius
    }
  ]
}
