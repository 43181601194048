import { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { PersonalInputs } from './types'
import { LegalFormSelectOptionKeys } from 'types/types'
import { Store } from 'store/types'
import { isUnderaged, isValidDate } from './utils'
import { MIN_AGE } from './constants'
import styles from './Personal.module.scss'
import LoadingCard from 'components/LoadingCard/LoadingCard'
import { OLD_LEGAL_FORM_TRANSLATIONS } from 'features/contracts/add/constants'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { updateCustomer } from 'store/user/thunks/customer'
import PhoneFormField from 'components/FormFields/PhoneFormField/PhoneFormField'
import Card from 'components/Card/Card.tsx'
import { Button, Heading } from '@boltenergy-be/design-system'
import parse from 'html-react-parser'
import { formatAddress } from 'utils/user.ts'

const Personal = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { error, loading, selectedCustomer, customers, selectedContracts } = useStoreSelector((store: Store) => store.user)
  const customer = customers[selectedCustomer]
  const dispatch = useStoreDispatch()

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // i18n
  const { t } = useTranslation(['user', 'validation'])

  // Local state
  const [customerSaved, setCustomerSaved] = useState(false)

  // Constants
  const contractAddress = electricityContract.address
  const birthDate = customer.person?.birthDate

  // React Hook Form
  const hookForm = useForm<PersonalInputs>({
    mode: 'onChange',
    defaultValues: {
      birthDate: {
        day: birthDate ? birthDate.split('-')[2] : '',
        month: birthDate ? birthDate.split('-')[1] : '',
        year: birthDate ? birthDate.split('-')[0] : ''
      },
      phone: customer.contact.phoneMobile || '+32'
    }
  })

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors }
  } = hookForm

  /**
   * Handles the form submit after validation by React Hook Form
   *
   * @param {PersonalInputs} data
   */
  const onSubmit = (data: PersonalInputs) => {
    const { day, month, year } = data.birthDate

    // Update the customer (only map the fields that have changed since it's a PATCH request)
    dispatch(
      updateCustomer({
        customerId: customer.id,
        customer: {
          contact: {
            phoneMobile: data.phone
          },
          person: {
            birthDate: day !== '' && month !== '' && year !== '' ? `${year}-${month}-${day}` : ''
          }
        }
      })
    )

    setCustomerSaved(true)
  }

  /**
   * Validates the date of birth
   */
  const validateDateOfBirth = () => {
    // Clear previous errors
    clearErrors('birthDate')

    const { day, month, year } = getValues().birthDate

    if (day !== '' && month !== '' && year !== '') {
      const birthDateIsValid = isValidDate(day, month, year)

      // Check if date if birth is valid
      if (birthDateIsValid) {
        // Check if person is old enough
        if (isUnderaged(day, month, year)) {
          setError('birthDate', {
            type: 'notOldEnough',
            message: t('invalid.date.underaged', { ns: 'validation' })
          })
        }
      } else {
        setError('birthDate', {
          type: 'invalid',
          message: t('invalid.date.default', { ns: 'validation' })
        })
      }
    }
  }

  return (
    <div className="container">
      {customer ? (
        <Card>
          <Heading as="h1" variant="h3">
            {t('personal.title')}
          </Heading>
          <p className={styles.intro}>{parse(t('personal.description'))}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading as="h2" variant="h5" className="mt-500">
              {t('personal.form.contactAddress')}
            </Heading>
            <div className="row">
              {/* FIRST NAME */}
              <div className="column form-group">
                <label htmlFor="firstName">{t('personal.form.firstName')}</label>
                <input value={customer.person.firstName} className="form-control" disabled placeholder={t('personal.form.firstName')} />
              </div>

              {/* LAST NAME */}
              <div className="column form-group">
                <label htmlFor="lastName">{t('personal.form.lastName')}</label>
                <input value={customer.person.lastName} className="form-control" disabled placeholder={t('personal.form.lastName')} />
              </div>
            </div>

            <div className="row">
              {/* EMAIL */}
              <div className="column form-group">
                <label htmlFor="email">{t('personal.form.email')}</label>
                <input name="email" className="form-control" value={customer.contact?.email} disabled />
              </div>

              {/* CLIENT NUMBER */}
              <div className="column form-group">
                <label htmlFor="clientNbr">{t('personal.form.clientNbr')}</label>
                <input name="clientNbr" className="form-control" value={customer.id} disabled />
              </div>
            </div>

            <div className="row">
              {/* PHONE */}
              <div className="column form-group">
                <PhoneFormField errors={errors} isRequired label={t('personal.form.phone')} hookFormSelector="phone" {...hookForm} />
              </div>

              {/* BIRTHDATE */}
              <div className="column form-group">
                <label htmlFor="birthDate">{t('personal.form.birthDate.title')}</label>
                <div className={styles['birthdate-inputs']}>
                  <select
                    className={classNames('form-control', {
                      error: errors?.birthDate?.day
                    })}
                    {...register('birthDate.day', { required: true })}
                    onChange={(e) => {
                      setValue('birthDate.day', e.currentTarget.value)
                      validateDateOfBirth()
                    }}
                  >
                    <option value="">{t('personal.form.birthDate.day')}</option>
                    {Array.from({ length: 31 }).map((_, index) => {
                      const day = (index + 1).toString().padStart(2, '0')

                      return (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      )
                    })}
                  </select>

                  <select
                    className={classNames('form-control', {
                      error: errors?.birthDate?.month
                    })}
                    {...register('birthDate.month', { required: true })}
                    onChange={(e) => {
                      setValue('birthDate.month', e.currentTarget.value)
                      validateDateOfBirth()
                    }}
                  >
                    <option value="">{t('personal.form.birthDate.month')}</option>
                    {Array.from({ length: 12 }).map((_, index) => {
                      const month = (index + 1).toString().padStart(2, '0')

                      return (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      )
                    })}
                  </select>

                  <select
                    className={classNames('form-control', {
                      error: errors?.birthDate?.year
                    })}
                    {...register('birthDate.year', { required: true })}
                    onChange={(e) => {
                      setValue('birthDate.year', e.currentTarget.value)
                      validateDateOfBirth()
                    }}
                  >
                    <option value="">{t('personal.form.birthDate.year')}</option>
                    {Array.from({ length: 100 - MIN_AGE })
                      .reverse()
                      .map((_, index) => {
                        const year = new Date().getFullYear() - MIN_AGE - index

                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      })}
                  </select>
                </div>

                {errors && errors.birthDate && (
                  <span className="help-block text-negative">
                    {[errors.birthDate.day?.type, errors.birthDate.month?.type, errors.birthDate.year?.type].includes('required') &&
                      t('validation:required')}
                  </span>
                )}
              </div>
            </div>

            <Heading as="h2" variant="h5" className="mt-400">
              {t('personal.form.deliveryAddress')}
            </Heading>

            <div className="row">
              {/* ADDRESS */}
              <div className={classNames('column form-group', styles.streetName)}>
                <label htmlFor="streetName">{t('contact.fields.billing.streetName')}</label>
                <input value={formatAddress(contractAddress) ?? contractAddress?.streetName ?? ''} className="form-control" disabled />
              </div>
            </div>

            {customer.company && (
              <>
                <hr className="my-400" />
                <div className="row">
                  {/* COMPANY NAME */}
                  <div className="column form-group">
                    <label htmlFor="companyName">{t('personal.form.company.name')}</label>
                    <input
                      defaultValue={customer.company.name}
                      className="form-control"
                      disabled
                      placeholder={t('personal.form.company.name')}
                    />
                  </div>

                  {/* Enterprise number */}
                  <div className="column form-group">
                    <label htmlFor="enterpriseNumber">{t('personal.form.company.enterpriseNumber')}</label>
                    <input
                      value={customer.company.enterpriseNumber}
                      className="form-control"
                      disabled
                      placeholder={t('personal.form.company.enterpriseNumber')}
                    />

                    <label className={styles['vat-application']}>
                      <input type="checkbox" defaultChecked={customer.company.vatApplication} disabled />
                      {t('personal.form.company.vatApplication')}
                    </label>
                  </div>
                </div>

                <div className="row">
                  {/* LEGAL FORM */}
                  <div className="column form-group half">
                    <label htmlFor="legalForm">{t('personal.form.company.legalForm')}</label>
                    <select defaultValue={customer.company.legalForm} className="form-control" disabled>
                      <option value="" disabled>
                        {t('defaultPlaceholders.select', { ns: 'common' })}
                      </option>
                      {Object.keys(OLD_LEGAL_FORM_TRANSLATIONS)
                        .sort((a, b) => a.localeCompare(b))
                        .map((legalForm) => {
                          const translatedLegalForm = OLD_LEGAL_FORM_TRANSLATIONS[legalForm as LegalFormSelectOptionKeys][language]

                          return (
                            <option key={legalForm} value={translatedLegalForm}>
                              {translatedLegalForm}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
              </>
            )}

            <Button type="submit" disabled={Object.keys(errors).length > 0} loading={loading}>
              {t('save', { ns: 'common' })}
            </Button>

            {!loading && customerSaved && (
              <span className="help-block">
                {(error === null || error !== 'updateCustomer') && (
                  <span className="text-positive">{t('formFeedback.saved', { ns: 'common' })}</span>
                )}
                {error && error === 'updateCustomer' && <span className="text-negative">{t('error', { ns: 'common' })}</span>}
              </span>
            )}
          </form>
        </Card>
      ) : (
        <LoadingCard />
      )}
    </div>
  )
}

export default Personal
