/**
 * Convertes a file to base64
 *
 * @param {File} file
 * @returns {Promise<string|ArrayBuffer>}
 */
export const fileToBase64 = (file: File): Promise<string | ArrayBuffer> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string | ArrayBuffer)
  })
}
