import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Chart } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js'
import { ConsumptionChartProps } from './types'
import { ChartLabels } from 'components/Charts/types'
import { calculateChartDimensions } from 'utils/chartjs'
import { getConsumptionChartOptions } from './chartjs.config'
import SettlementPeriodIndicator from '../SettlementPeriodIndicator/SettlementPeriodIndicator'
import { useStoreSelector } from 'hooks/store.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'

const ConsumptionChartDesktop = ({
  activeProductType,
  alternatingBackground,
  chartData,
  children,
  isFetchingVolumes,
  labels,
  minMaxData,
  priceHistoryModifier,
  showEstimatedUsage,
  granularity
}: PropsWithChildren<ConsumptionChartProps>) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // Local state
  const [columnWidth, setColumnWidth] = useState<number>(0)
  const [chartYAxisWidth, setChartYAxisWidth] = useState<number>(0)

  // Reference
  const chartRef = useRef<never>(null)

  /**
   * sets the local state sizes for custom styling
   * @param {ChartJS} chart
   * @param {ChartLabels} labels
   */
  const handleResizeChart = useCallback((chart: ChartJS, labels: ChartLabels) => {
    if (chart) {
      const { chartWidth, chartYAxisWidth: YAxisWidth } = calculateChartDimensions(chart, 0)

      setColumnWidth(chartWidth / labels.length)
      setChartYAxisWidth(YAxisWidth)
    }
  }, [])

  const options = useMemo<any>(
    () =>
      getConsumptionChartOptions({
        labels,
        alternatingBackground,
        handleResize: handleResizeChart,
        historyModifier: priceHistoryModifier,
        granularity,
        language,
        minMax: minMaxData,
        fuelType: activeProductType
      }),
    [activeProductType, alternatingBackground, granularity, handleResizeChart, labels, language, minMaxData, priceHistoryModifier]
  )

  // Set the state dimensions
  useEffect(() => {
    const ref = chartRef?.current
    if (ref) handleResizeChart(ref, labels)

    // Clean up subscription
    return () => {
      if (ref) handleResizeChart(ref, labels)
    }
  }, [chartRef, handleResizeChart, labels])

  return (
    <>
      {!isFetchingVolumes ? (
        <>
          <div className={styles['chart-wrapper']}>
            <Chart type="bar" options={options} data={chartData} className={styles.chart} ref={chartRef} />
          </div>

          {children}
        </>
      ) : (
        <LoadingSkeleton>
          <LoadingSkeleton.Rectangle aspectRatio="3 / 1" />
          <LoadingSkeleton.Rectangle height={30} />
        </LoadingSkeleton>
      )}

      {!showEstimatedUsage && (
        // CURRENT PERIOD INDICATOR
        <SettlementPeriodIndicator columns={labels.length} negativeMarginLeftValue={20} {...{ columnWidth, chartYAxisWidth }} />
      )}
    </>
  )
}

export default ConsumptionChartDesktop
