import { FC, useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router'
import { isAuthenticated } from 'utils/localStorageService'
import { routes } from 'types/routes'
import { checkIsSales } from 'utils/sales'
import { ProtectedRouteProps } from 'components/ProtectedRoute/types'

const ProtectedRoute: FC<ProtectedRouteProps> = ({ userTypes }) => {
  // Router
  const navigate = useNavigate()

  /**
   * Checks if the user is authenticated, if not, redirects to the login page
   */
  useEffect(() => {
    const authenticatedUserTypes = isAuthenticated()
    if (userTypes.every((userType) => !authenticatedUserTypes[userType])) {
      navigate(checkIsSales() ? routes.LOGIN_SALES : routes.LOGIN)
    }
  }, [])

  return <Outlet />
}

export default ProtectedRoute
