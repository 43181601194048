import { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { Banner, Button, Heading } from '@boltenergy-be/design-system'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import AddMeterReadingsModal from 'features/MeterReadings/AddMeterReadingsModal/AddMeterReadingsModal'
import HelpModal from 'components/HelpModal/HelpModal'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import styles from './BillingCycles.module.scss'
import { CycleViews, PeriodTypes } from './types'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { formatAmount } from 'utils/format'
import classNames from 'classnames'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { routes } from 'types/routes'
import { GET_HELP_FAQ_CURRENT_BILLING_CYCLE, NEVER_SHOW_INSTALMENT_INFO_MODAL } from './constants'
import FooterFaq from 'components/Faq/FooterFaq/FooterFaq'
import { UserTypes } from 'store/auth/types'
import { logBillingCycleViewedEvent } from 'api/events'
import { BillShock, BillShockFutureInstalment, GetBillShockResponseData, TransfoErrorCodes } from 'types/billShock'
import { MeterReadingSource } from 'types/contracts'
import { determineAccessRights, getMemoizedSelectedContracts, isCloseToTermination, triggerGetMeterReadings } from 'utils/contracts'
import { DATE_FORMAT } from 'constants/constants'
import PreviousCycles from './components/PreviousCycles/PreviousCycles'
import { getTitleSection } from './components/TitleSection/TitleSection'

import CurrentBillingCycleChart from 'components/Charts/BillingCycle/Current/CurrentBillingCycleChart'
import { generateBillShockAxiosRequestConfig } from 'api/contracts'
import { newRequest } from 'utils/request'
import OverviewCard from './components/OverviewCard/OverviewCard'
import { getBillShock } from 'store/contracts/thunks'
import UsageBadges from './components/UsageBadges/UsageBadges'
import ButtonMeterReadings from 'features/MeterReadings/ButtonMeterReadings/ButtonMeterReadings'
import { popSuccessToast } from 'utils/toast'
import { AddMeterReadingsModalEntryPoint } from 'features/MeterReadings/AddMeterReadingsModal/types'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget'
import { WidgetSlug } from 'types/feedbackWidget'
import InstalmentInfoModal from './components/InstalmentInfoModal/InstalmentInfoModal'
import { ContractStatus } from 'types/types'
import Bugsnag from '@bugsnag/js'
import { Response } from 'types/request'
import { useGetFeedbackWidgetBySlugQuery } from 'store/queries/bolt-api'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { Language } from 'store/app/types'
import { FaqSectionSlugs } from 'types/faq'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import Toggle from 'components/Toggle/Toggle.tsx'
import parse from 'html-react-parser'
import { ReturnLater } from 'components/ReturnLater/ReturnLater'

const BillingCycles: FC = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const {
    billShock: { loading: billShockLoading, data: billShockData, error: billShockError },
    meterReadings: { loading: meterReadingsLoading, data: meterReadingsData }
  } = useStoreSelector((store) => store.contracts)
  const { info, selectedContracts } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Contracts
  const { billingContract, electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)
  const shouldShowMeterReadings = accessRights.meterReadings.canAccess && accessRights.meterReadings.showContent

  // Redux queries
  const { data: feedbackWidgetData } = useGetFeedbackWidgetBySlugQuery({ slug: WidgetSlug.ADDED_METER_READINGS })
  const { data: faq, isLoading: faqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.BILLING_CYCLES_FR : FaqSectionSlugs.BILLING_CYCLES_NL
  })

  // Local state
  const [isAddMeterReadingsModalOpen, setIsAddMeterReadingsModalOpen] = useState<boolean>(false)
  const [isCurrentBillingCycleHelpModalOpen, setIsCurrentBillingCycleHelpModalOpen] = useState<boolean>(false)
  const [selectedCycleView, setSelectedCycleView] = useState<CycleViews>(CycleViews.CURRENT)
  const [triggerSuccessCallback, setTriggerSuccessCallback] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentInstalment, setCurrentInstalment] = useState<number>(0)
  const [billShock, setBillShock] = useState<BillShock>()
  const [adjustedBillShockAmount, setAdjustedBillShockAmount] = useState<number>()
  const [openFeedbackWidget, setOpenFeedbackWidget] = useState<boolean>(false)
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
  const [hasOpenedInfoModal, setHasOpenedInfoModal] = useState<boolean>(!!Cookies.get(NEVER_SHOW_INSTALMENT_INFO_MODAL))

  // React router
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = useMemo<URLSearchParams>(() => new URLSearchParams(search), [search])

  // i18n
  const { t } = useTranslation('billing')

  // Window size
  const { isTablet } = useWindowSize()

  // Constants
  const billShockDate = dayjs(billShock?.computeDate).format(DATE_FORMAT)
  const electricityLastMeterReadingDate =
    meterReadingsData?.electricity?.source === MeterReadingSource.CLIENT_PROVIDED ? meterReadingsData.electricity.date : undefined
  const gasLastMeterReadingDate =
    meterReadingsData?.gas?.source === MeterReadingSource.CLIENT_PROVIDED ? meterReadingsData.gas.date : undefined
  const closeToTermination = isCloseToTermination(electricityContract)
  const HELP_FAQ_CURRENT_BILLING_CYCLE = GET_HELP_FAQ_CURRENT_BILLING_CYCLE()
  const proposedInstalment = billShock && billShock?.instalment.proposed < 20 ? 20 : (billShock?.instalment.proposed ?? 0)

  /**
   * Fetch meter readings if none available
   */
  useEffect(() => {
    if (billShockData && shouldShowMeterReadings && typeof meterReadingsData === 'undefined') {
      triggerGetMeterReadings(electricityContract, gasContract)
    }
  }, [billShockData, meterReadingsData, dispatch, electricityContract, gasContract, shouldShowMeterReadings])

  /**
   * Scroll to selected cycle when navigating back
   */
  useEffect(() => {
    const cycleId = query.get('cycle')
    if (cycleId && billShock && !isLoading && !billShockError) {
      const elPos = document.getElementById(cycleId)?.getBoundingClientRect().top
      if (elPos) {
        window.scroll({
          top: elPos + window.scrollY - (isTablet ? 120 : 160)
        })
      }
    }
  }, [billShock, billShockError, isLoading, isTablet, query])

  /**
   * Set billshock data to state
   * If proposed instalment is negative, refetch billshock with €20 instalment
   */
  useEffect(() => {
    if (billShockError) {
      setIsLoading(false)
      return
    }

    if (!billShockLoading && billShockData) {
      setBillShock(billShockData)
      setCurrentInstalment(billShockData.instalment.current)

      if (billShockData.instalment.proposed < 20) {
        refetchBillShockWithAdjustedInstalment(billShockData)
      } else {
        setIsLoading(false)
      }
    }
  }, [billShockLoading, billShockData, billShockError])

  /**
   * Logs the billingCycleViewed event through the API if the authenticated user is a regular user
   */
  useEffect(() => {
    if (accessRights.billingCycles.canAccess && accessRights.billingCycles.showContent && userType === UserTypes.CUSTOMER) {
      logBillingCycleViewedEvent(billingContract.id)
    }
  }, [accessRights, billingContract, userType])

  /**
   * Meter readings successfully added: show success toast + show feedback widget after 10s
   */
  useEffect(() => {
    if (!billShockLoading && triggerSuccessCallback) {
      popSuccessToast(t('addMeterReadingsModal.form.successToast', { ns: 'common' }))
      setTimeout(() => {
        setOpenFeedbackWidget(true)
      }, 10000)
      setTriggerSuccessCallback(false)
    }
  }, [billShockLoading, t, triggerSuccessCallback])

  /**
   * Returns the correct bill shock error for the given code
   *
   * @param {TransfoErrorCodes} code
   * @returns {ReactElement}
   */
  const getBillShockError = (code: TransfoErrorCodes): ReactElement => {
    if (
      [
        TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG,
        TransfoErrorCodes.E431_NO_INVOICE_FOUND,
        TransfoErrorCodes.E442_NEAR_METERREADING_MONTH,
        TransfoErrorCodes.E443_NO_FUTURE_INSTALMENTS,
        TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES
      ].includes(code)
    ) {
      return (
        <Banner type="informative">
          {t(
            `transfoErrors.${
              code as
                | TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG
                | TransfoErrorCodes.E431_NO_INVOICE_FOUND
                | TransfoErrorCodes.E442_NEAR_METERREADING_MONTH
                | TransfoErrorCodes.E443_NO_FUTURE_INSTALMENTS
                | TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES
            }`,
            { ns: 'common' }
          )}
        </Banner>
      )
    }

    return (
      <div className={styles['info-content']}>
        <div className={styles.text}>{t('errorTryLater', { ns: 'common' })}</div>
      </div>
    )
  }

  /**
   * Refetches the bill shock with adjusted instalment
   *
   * @param {BillShock} originalBillShock
   */
  const refetchBillShockWithAdjustedInstalment = async (originalBillShock: BillShock) => {
    const hasEffectiveGasContract = typeof gasContract !== 'undefined' && gasContract.detail.status === ContractStatus.EFFECTIVE

    // Adjust instalment to 10 if gas contract is present, otherwise 20
    const electricityContractWithAdjustedInstalment = {
      ...electricityContract,
      detail: {
        ...electricityContract.detail,
        instalment: hasEffectiveGasContract ? 10 : 20
      }
    }

    // Adjust instalment to 10 if gas contract is present, otherwise undefined
    const gasContractWithAdjustedInstalment = hasEffectiveGasContract
      ? {
          ...gasContract,
          detail: {
            ...gasContract.detail,
            instalment: 10
          }
        }
      : undefined

    // Get bill shock with adjusted instalment
    const options = generateBillShockAxiosRequestConfig(
      billingContract.id,
      info.email,
      electricityContractWithAdjustedInstalment,
      gasContractWithAdjustedInstalment
    )

    const { success, data }: Response<GetBillShockResponseData> = await newRequest(options)

    if (success && data) {
      if (data.billShock) {
        const { billShock } = data
        const futureWithOriginalInstalment = billShock.future.map((month: BillShockFutureInstalment) => ({
          ...month,
          plannedInstalment: originalBillShock.instalment.current
        }))
        const modifiedBillShockData = {
          ...billShock,
          future: futureWithOriginalInstalment,
          settlement: {
            ...billShock.settlement,
            billShockAmount: originalBillShock.settlement.billShockAmount
          }
        }

        setAdjustedBillShockAmount(billShock.settlement.billShockAmount)
        setBillShock(modifiedBillShockData)
        setIsLoading(false)
      }
    } else {
      Bugsnag.notify('Error while fetching bill shock data or empty response')
    }
  }

  return !accessRights.billingCycles.canAccess ? (
    <Navigate to={routes.BILLING} replace />
  ) : !accessRights.billingCycles.showContent ? (
    <ReturnLater description={t('billingCycles.returnLater')} />
  ) : (
    <>
      <section className="container">
        {/* CURRENT BILLING CYCLE */}
        <div className={styles['title-container']}>
          <Heading as="h1" variant="h5" className="mb-100 tablet:mb-none">
            {t('billingCycles.current.title')}
          </Heading>
          <Button
            className={styles.button}
            representation="link"
            onClick={() => setIsCurrentBillingCycleHelpModalOpen(true)}
            leadingIcon="info"
          >
            {t('billingCycles.help')}
          </Button>
        </div>

        <CollapsibleCard titleSection={getTitleSection(PeriodTypes.CURRENT, billShockData?.settlement || undefined)}>
          <div className={closeToTermination || (!billShock && billShockError) ? styles['card-with-banner'] : undefined}>
            {closeToTermination ? (
              <Banner type="informative">{t('closeToTermination', { ns: 'common' })}</Banner>
            ) : (
              <>
                {isLoading && (
                  <div className={styles.loading}>
                    <LoadingDots />
                  </div>
                )}

                {billShock && !isLoading && !billShockError && (
                  <div className={styles['bill-shock-data']}>
                    <div className={styles['usage-container']}>
                      <div className={styles.usage}>
                        <strong className={styles['usage-title']}>{t('billingCycles.current.usage.title')}:</strong>
                        <UsageBadges
                          isEstimation
                          electricity={{
                            consumption: billShock.meters.electricity?.totalConsumption,
                            injection: billShock.meters.electricity?.totalInjection
                          }}
                          gas={billShock.meters?.gas?.totalConsumption}
                        />
                      </div>

                      {shouldShowMeterReadings && (
                        <>
                          {meterReadingsLoading ? (
                            <div className={styles['loading-container']}>
                              <LoadingDots />
                            </div>
                          ) : (
                            <ButtonMeterReadings
                              setModalOpen={() => setIsAddMeterReadingsModalOpen(true)}
                              electricityLastMeterReadingDate={electricityLastMeterReadingDate}
                              gasLastMeterReadingDate={gasLastMeterReadingDate}
                            />
                          )}
                        </>
                      )}
                    </div>

                    <div className={styles['comparison-toggle-container']}>
                      <Toggle
                        active={selectedCycleView}
                        onClick={(view) => {
                          if (!hasOpenedInfoModal) {
                            setOpenInfoModal(true)
                          }
                          setSelectedCycleView(view)
                        }}
                        options={[
                          {
                            value: CycleViews.CURRENT,
                            label: parse(t('billingCycles.chart.toggle.current', { instalment: formatAmount(currentInstalment) }))
                          },
                          {
                            value: CycleViews.PROPOSED,
                            label: parse(t('billingCycles.chart.toggle.proposed', { instalment: formatAmount(proposedInstalment) }))
                          }
                        ]}
                      />
                    </div>

                    <div className={styles['chart-container']}>
                      <CurrentBillingCycleChart
                        data={billShock}
                        isTablet={isTablet}
                        activePeriod={selectedCycleView}
                        adjustedBillShockAmount={adjustedBillShockAmount && adjustedBillShockAmount}
                      />
                    </div>

                    <OverviewCard
                      isDanger={billShock.settlement.billShockAmount > 0 && selectedCycleView === CycleViews.CURRENT}
                      badge={t('currentBillingCycle.overview.labels.badge', {
                        date: billShockDate,
                        ns: 'charts',
                        interpolation: { escapeValue: false }
                      })}
                      blocks={[
                        {
                          label: t('currentBillingCycle.overview.labels.totalBilledInstalments', { ns: 'charts' }),
                          value: `€
                        ${formatAmount(
                          billShock.past.reduce((sum, instalment) => {
                            return sum + instalment.billedInstalment
                          }, 0)
                        )}
                      `
                        },
                        {
                          label: t('currentBillingCycle.overview.labels.totalCost', { ns: 'charts' }),
                          value: `€ ${formatAmount(billShock.settlement.estimatedTotalAmount)}`
                        },
                        {
                          label: t('currentBillingCycle.overview.labels.settlement', { ns: 'charts' }),
                          valueClass: classNames(styles['instalment-value'], {
                            [styles.danger]: billShock.settlement.billShockAmount > 0 && selectedCycleView === CycleViews.CURRENT
                          }),
                          value:
                            selectedCycleView === CycleViews.CURRENT ? (
                              <>
                                &euro;&nbsp;
                                {formatAmount(Math.abs(billShock.settlement.billShockAmount))}
                                <br />
                                {billShock.settlement.billShockAmount > 0
                                  ? ` ${t('currentBillingCycle.overview.labels.settlementPayExtra', { ns: 'charts' })}`
                                  : ` ${t('currentBillingCycle.overview.labels.settlementRecover', { ns: 'charts' })}`}
                              </>
                            ) : (
                              <>
                                &euro;&nbsp;
                                {adjustedBillShockAmount
                                  ? `${formatAmount(Math.abs(adjustedBillShockAmount))} ${t(
                                      'currentBillingCycle.overview.labels.settlementRecover',
                                      { ns: 'charts' }
                                    )}`
                                  : 0}
                              </>
                            )
                        },
                        {
                          label:
                            selectedCycleView === CycleViews.CURRENT
                              ? t('currentBillingCycle.overview.labels.instalment.current', { ns: 'charts' })
                              : t('currentBillingCycle.overview.labels.instalment.proposed', { ns: 'charts' }),
                          value:
                            selectedCycleView === CycleViews.CURRENT
                              ? `€ ${formatAmount(currentInstalment)}`
                              : `€ ${formatAmount(proposedInstalment)}`
                        }
                      ]}
                      button={
                        <Button className={styles.button} onClick={() => navigate(routes.BILLING_INSTALMENT)}>
                          {t(`billingCycles.current.changeInstalment.${isTablet ? 'mobile' : 'desktop'}`)}
                        </Button>
                      }
                    />
                  </div>
                )}

                {!billShock && billShockError && getBillShockError(billShockError.code as TransfoErrorCodes)}
              </>
            )}
          </div>
        </CollapsibleCard>
      </section>

      <PreviousCycles billingContractId={billingContract.id} openId={query.get('cycle') || undefined} />

      <FooterFaq isLoading={faqLoading} category={faq?.category} subtitle={t('faq.subtitles.billingCycles', { ns: 'common' })} />

      <AddMeterReadingsModal
        entryPoint={AddMeterReadingsModalEntryPoint.BillingCyclesPage}
        isOpen={isAddMeterReadingsModalOpen}
        setClose={() => setIsAddMeterReadingsModalOpen(false)}
        onSuccess={() => {
          // Refetch billShock
          setIsLoading(true)
          dispatch(
            getBillShock({
              billingContractId: billingContract.id,
              email: info.email,
              electricityContract,
              gasContract
            })
          )
          setTriggerSuccessCallback(true)
        }}
      />

      <HelpModal
        title={t('billingCycles.current.title')}
        isOpen={isCurrentBillingCycleHelpModalOpen}
        setClose={() => setIsCurrentBillingCycleHelpModalOpen(false)}
        faq={HELP_FAQ_CURRENT_BILLING_CYCLE}
      />

      <InstalmentInfoModal
        isOpen={openInfoModal}
        setClose={() => {
          setOpenInfoModal(false)
          setHasOpenedInfoModal(true)
        }}
      />

      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default BillingCycles
