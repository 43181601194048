import React, { PropsWithChildren } from 'react'
import styles from './GeneralErrorHandler.module.scss'

const GeneralErrorHandler = class GeneralErrorHandler extends React.Component<PropsWithChildren> {
  state = { hasError: false }

  static getDerivedStateFromError(error: any) {
    console.log(error)

    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles['general-error']}>
          <h3>Oei, er ging iets mis. Hernieuw deze pagina of contacteer onze helpdesk.</h3>
          <h3>
            Oups ! Il semblerait que quelque chose n&apos;ait pas fonctionné. Pour résoudre le problème, veuillez rafraîchir cette page ou
            contacter notre service client pour obtenir de l&apos;aide.
          </h3>
        </div>
      )
    }

    return this.props.children
  }
}

export default GeneralErrorHandler
