import { FC } from 'react'
import { MarkerProps as Props } from '../types'
import Icon from 'components/Icon/Icon'
import styles from './Marker.module.scss'

const Marker: FC<Props> = ({ icon, text }) => (
  <div className={styles.marker}>
    <Icon name={icon} />
    {text}
  </div>
)

export default Marker
