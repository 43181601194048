import { Table } from 'components/Table/Table.tsx'
import { CellType, InvoicesTableProps } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'
import styles from './InvoiceTable.module.scss'
import { useEffect, useState } from 'react'
import { paginate, sortRowsByKey } from 'pages/App/Billing/Invoices/utils.ts'
import StackedInvoicesView from 'pages/App/Billing/Invoices/InvoicesTable/StackedInvoicesView/StackedInvoicesView.tsx'
import { SortingDirection } from 'components/Table/types.ts'
import { Pagination } from '@boltenergy-be/design-system'

const InvoicesTable = ({ headers, rows, isSmaller, activeTab }: InvoicesTableProps) => {
  // state
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ id: CellType.DATE as string, direction: SortingDirection.DESC })

  // constants
  const pages = paginate(rows.sort(sortRowsByKey(sorting?.id, sorting?.direction)), 10)

  // Reset the navigation when switching tab
  useEffect(() => {
    if (activeTab && currentPage !== 1) setCurrentPage(1)
  }, [activeTab])

  return (
    <div>
      {isSmaller ? (
        <StackedInvoicesView rows={pages[currentPage - 1]} />
      ) : (
        <Table {...{ headers, sorting }} rows={pages[currentPage - 1]} className={styles.table} onSort={setSorting} />
      )}
      {pages.length > 1 && (
        <Pagination
          pagesLength={pages.length}
          activePageNumber={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          className={styles.pagination}
          maxWidth={320}
        />
      )}
    </div>
  )
}

export default InvoicesTable
