import { Row } from 'components/Table/types.ts'
import { InvoiceTableTabs } from 'pages/App/Billing/Invoices/hooks/types.ts'

export enum CellType {
  ADDRESS = 'address',
  DESCRIPTION = 'description',
  DATE = 'date',
  AMOUNT = 'amount',
  RUNNING_BALANCE = 'runningBalance',
  STATUS = 'status',
  MATCHING_NUMBER = 'matchingNumber',
  PAYMENT_METHOD = 'paymentMethod'
}

export type TableHeaderValueType = {
  value: string
  id?: CellType
}
export type TableHeaderValuesType = {
  values: TableHeaderValueType[]
}
export type TableHeader = TableHeaderValueType | TableHeaderValuesType
export type TableHeaders = TableHeader[]

export type InvoicesTableProps = {
  headers: TableHeaders
  rows: Row[]
  isSmaller: boolean
  activeTab: InvoiceTableTabs
}
