import { NODE_ENV } from 'constants/envs.ts'

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_LONG = 'D MMMM YYYY'
export const DATE_FORMAT_REACT_DATEPICKER = 'dd/MM/yyyy'

export const ISO_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const IS_DEVELOPMENT_OR_PREVIEW = NODE_ENV === 'preview' || NODE_ENV === 'development' || NODE_ENV === 'staging'

export const PARTNER_IDS = {
  PRODUCTION_BOLT_SUPER_USER: '0010900001mIpTAAA0',
  STAGING_BOLT_SUPER_USER: '0011q00001CH6FEAA1'
}
