import { Language } from 'store/app/types'
import { CustomerLibrary } from 'store/user/types.ts'
import { Contract, Producer } from 'types/types.ts'

export type GetUserDataResponseData = {
  user: User
  customers: CustomerLibrary
  contracts: Contract[][]
  producers: Producer[]
  locations: Location[]
}

export type User = {
  _id?: string
  firstName: string
  lastName: string
  email: string
  language: Language
  phone: string
}

export type UserObjectForApiCalls = {
  firstName: string
  email: string
  language: Language
  customerNumber: string
}

export type UpdateUserPayload = {
  language?: Language
}

export enum SourceType {
  PUSH = 'Push',
  PULL = 'Pull'
}
