import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { switchLanguage } from 'utils/app.ts'
import { useTranslation } from 'react-i18next'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop.tsx'
import { Language } from 'store/app/types.ts'
import { Outlet } from 'react-router'

const RootLayout = () => {
  // i18n
  const { t } = useTranslation()

  // Constants
  const langFromQuery = new URLSearchParams(window.location.search).get('lang')

  // Use correct lang (from URL queryparams)
  useEffect(() => {
    if (langFromQuery && (langFromQuery === Language.NL || langFromQuery === Language.FR)) {
      switchLanguage(langFromQuery)
    }
  }, [langFromQuery])

  return (
    <>
      <Helmet>
        <title>My Bolt</title>
        <meta property="og:image" content="https://my.boltenergie.be/images/social.jpg" />
        <meta name="description" content={t('meta.description')} />
      </Helmet>

      <ScrollToTop />

      <Outlet />
    </>
  )
}

export default RootLayout
