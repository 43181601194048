const InfoOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M9.08 7.23h1.84V5.38H9.08m.92 12a7.4 7.4 0 0 1 0-14.76 7.4 7.4 0 0 1 0 14.76M10 .77a9.23 9.23 0 1 0 0 18.46A9.23 9.23 0 0 0 10 .77m-.92 13.85h1.84V9.08H9.08v5.54Z"
      />
    </svg>
  )
}

export default InfoOutlined
