import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { ChartData } from 'chart.js'
import { CoreAnnotationOptions } from 'chartjs-plugin-annotation'
import CurrentBillingCycleChartMobile from './ChartMobile'
import CurrentBillingCycleChartDesktop from './ChartDesktop'
import { updateProposedInstalmentBillShockData } from '../utils'
import { CurrentBillingCycleChartWrapperProps } from './types'
import { MinMaxData } from '../../types'
import { getActiveMonthIndex, getAlternatingBackground, getBillingCycleMinMaxRange } from 'utils/chartjs'
import { CycleViews } from 'pages/App/Billing/BillingCycles/types'
import { CHART_COLORS } from '../../constants'
import { getInstalmentChartDatasets } from './chartjs.config'
import { BillShock } from 'types/billShock'
import { useTranslation } from 'react-i18next'

const CurrentBillingCycleChart = ({ data, isTablet, activePeriod, adjustedBillShockAmount }: CurrentBillingCycleChartWrapperProps) => {
  // i18n
  const { t } = useTranslation('charts')

  // Local state
  const [months, setMonths] = useState<string[]>([])

  /**
   * Sets bill shock data
   */
  useEffect(() => {
    if (data) {
      const combinedMonths = [
        ...data.past.map((instalment) => dayjs(instalment.datetime).toISOString()),
        ...data.future.map((instalment) => dayjs(instalment.datetime).toISOString())
      ]

      setMonths([...combinedMonths, t('currentBillingCycle.legend.settlement')])
    }
  }, [data])

  // Memo's
  const transformedData = useMemo<BillShock>(() => updateProposedInstalmentBillShockData(data), [data])
  const minMaxData = useMemo<MinMaxData>(() => getBillingCycleMinMaxRange(transformedData), [transformedData])
  const settlementColor = useMemo<string>(
    () =>
      (activePeriod === CycleViews.CURRENT ? transformedData.settlement.billShockAmount : 0) <= 0
        ? CHART_COLORS.greenDark
        : CHART_COLORS.orange,
    [activePeriod, transformedData.settlement.billShockAmount]
  )
  const alternatingBackground = useMemo<CoreAnnotationOptions>(() => getAlternatingBackground(months), [months])
  const activeMonthIndex = useMemo<number>(() => getActiveMonthIndex(months), [months])

  // Table data
  const chartData = useMemo<ChartData<'bar', (number | null)[], unknown>>(
    () => ({
      labels: months,
      datasets: getInstalmentChartDatasets(months, transformedData, activePeriod, isTablet, adjustedBillShockAmount)
    }),
    [months, transformedData, activePeriod, isTablet, adjustedBillShockAmount]
  )

  return isTablet ? (
    <CurrentBillingCycleChartMobile
      data={transformedData}
      adjustedBillShockAmount={adjustedBillShockAmount}
      {...{ activePeriod, minMaxData, activeMonthIndex, settlementColor, alternatingBackground, chartData, labels: months }}
    />
  ) : (
    <CurrentBillingCycleChartDesktop
      data={transformedData}
      adjustedBillShockAmount={adjustedBillShockAmount}
      {...{ activePeriod, minMaxData, activeMonthIndex, settlementColor, alternatingBackground, chartData, labels: months }}
    />
  )
}

export default CurrentBillingCycleChart
