import Drawer from 'components/Drawer/Drawer'
import { ProductDrawerProps } from './types'
import parse from 'html-react-parser'
import { PRODUCTS_CONFIG } from 'constants/products'
import { formatAmount } from 'utils/format'
import styles from './ProductDrawer.module.scss'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { useTranslation } from 'react-i18next'
import { ELProduct, NGProduct, Product, ProductBillingOptions, ProductConfigOption } from 'types/products'
import { useGetProductsQuery } from 'store/queries/bolt-api'
import { useStoreSelector } from 'hooks/store'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { Heading } from '@boltenergy-be/design-system'

const ProductDrawer = ({ product, conditions, isOpen, onRequestClose, userType, productName, needsGas }: ProductDrawerProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation('contracts')

  // Redux queries
  const { data: productsData, isLoading: loadingProductsData } = useGetProductsQuery()

  // constants
  const priceListEl = productsData?.products.electricity?.[ELProduct[product]]?.pricelists?.[userType]?.[language]
  const priceListGas = needsGas ? productsData?.products.gas?.[NGProduct[product]]?.pricelists?.[userType]?.[language] : undefined
  const billingOptions = PRODUCTS_CONFIG[product][ProductConfigOption.BILLING_OPTIONS]
    .map((option) => t(`contact.${option === ProductBillingOptions.DIGITAL ? 'email' : option}`, '', { ns: 'common' }))
    .join(` ${t('andOr', 'en/of', { ns: 'common' })} `)
    .toLowerCase()

  return (
    <Drawer contentClassName={styles.drawer} {...{ isOpen, onRequestClose }}>
      {product ? (
        <>
          <header className={styles.intro}>
            <Heading as="h1" variant="h4">
              Bolt {productName}
            </Heading>
          </header>

          <section>
            <ul>
              <li>
                {parse(t('drawer.intro.connections', '<strong>Aansluiting(en):</strong> Enkel elektriciteit of elektriciteit én gas'))}
              </li>
              <li>{parse(t('drawer.intro.origins.el', '<strong>Herkomst elektriciteit:</strong> Duurzaam'))}</li>
              <li>{parse(t('drawer.intro.origins.gas', '<strong>Herkomst gas:</strong> Niet duurzaam'))}</li>
              <li>
                {parse(
                  t('drawer.intro.type', '<strong>Type tarief:</strong> {{type}}', {
                    type: t(
                      `tariffType.${PRODUCTS_CONFIG[product][ProductConfigOption.TYPE]}`,
                      PRODUCTS_CONFIG[product][ProductConfigOption.TYPE],
                      {
                        ns: 'common'
                      }
                    )
                  })
                )}
              </li>
              <li>
                {parse(
                  product === Product.FIX
                    ? t('drawer.intro.duration.yearly', '<strong>Looptijd:</strong> 1 jaar')
                    : t('drawer.intro.duration.unlimited', '<strong>Looptijd:</strong> Onbeperkt')
                )}
              </li>
            </ul>
          </section>

          <section>
            <ul>
              {loadingProductsData ? (
                <>
                  <LoadingDots />
                </>
              ) : (
                <>
                  {!!priceListEl && (
                    <li>
                      <a href={`https://${priceListEl}`} target="_blank" rel="noreferrer">
                        {t('priceList.el', 'Tariefkaart Elektriciteit', { ns: 'common' })}
                      </a>
                    </li>
                  )}
                  {!!priceListGas && (
                    <li>
                      <a href={`https://${priceListGas}`} target="_blank" rel="noreferrer">
                        {t('priceList.gas', 'Tariefkaart Gas', { ns: 'common' })}
                      </a>
                    </li>
                  )}
                </>
              )}
            </ul>
          </section>

          <section>
            <Heading as="h2" variant="h6">
              {t('drawer.structure.title', 'Opbouw tarief')}
            </Heading>
            <p>
              {t(
                'drawer.structure.description',
                'In tegenstelling tot veel andere leveranciers willen we jou bij Bolt inzicht geven in waar je geld naartoe gaat. Je zal de kostenverhouding voor jouw persoonlijke aansluiting steeds op je facturen terug vinden.'
              )}
            </p>
            <table>
              <thead>
                <tr>
                  <th scope="col">{t('drawer.structure.table.payment.head', 'Waarvoor je betaalt')}</th>
                  <th scope="col">{t('drawer.structure.table.receiver.head', 'Naar wie je geld gaat')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('drawer.structure.table.payment.el', 'Stroom van een lokale energie-opwekker')}</td>
                  <td>{t('drawer.structure.table.receiver.el', 'De lokale energie-opwekker')}</td>
                </tr>
                <tr>
                  <td>{t('drawer.structure.table.payment.gas', 'Gas')}</td>
                  <td>{t('drawer.structure.table.receiver.gas', 'Groothandelsmarkt')}</td>
                </tr>
                <tr>
                  <td>{t('drawer.structure.table.payment.net', 'Nettarieven en bijdragen')}</td>
                  <td>{t('drawer.structure.table.receiver.net', 'Overheid en netbeheerder')}</td>
                </tr>
                <tr>
                  <td>{t('drawer.structure.table.payment.platform', 'Platformkost')}</td>
                  <td>{t('drawer.structure.table.receiver.platform', 'Bolt')}</td>
                </tr>
                <tr>
                  <td>{t('drawer.structure.table.payment.taxes', 'Btw')}</td>
                  <td>{t('drawer.structure.table.receiver.taxes', 'Overheid')}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section>
            <Heading as="h2" variant="h6">
              {t('drawer.platformCost.title', 'Platformkost per aansluiting van €{{cost}}/maand', {
                cost: formatAmount(PRODUCTS_CONFIG[product][ProductConfigOption.PLATFORM_COST][userType])
              })}
            </Heading>
            <p>
              {t(
                `drawer.platformCost.description.${product}`,
                'Het bedrag dat naar Bolt zelf gaat voor onze diensten en het optimaliseren van ons platform, noemen we de platformkost.'
              )}
            </p>
            <table>
              <tbody>
                <tr>
                  <th scope="row">{t(`drawer.platformCost.table.${ProductConfigOption.INSTALMENT_PERIODICITY}`, 'Voorschot')}</th>
                  <td>{t(`periodicity.${PRODUCTS_CONFIG[product][ProductConfigOption.INSTALMENT_PERIODICITY]}`, '', { ns: 'common' })}</td>
                </tr>
                <tr>
                  <th scope="row">{t(`drawer.platformCost.table.${ProductConfigOption.DIRECT_DEBIT_MANDATORY}`, 'Domiciliëring')}</th>
                  <td>
                    {PRODUCTS_CONFIG[product][ProductConfigOption.DIRECT_DEBIT_MANDATORY]
                      ? t('directDebit.mandatory', 'Verplicht', { ns: 'common' })
                      : t('directDebit.optional', 'Optioneel', { ns: 'common' })}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t(`drawer.platformCost.table.${ProductConfigOption.BILLING_OPTIONS}`, 'Facturen')}</th>
                  <td>
                    {t('drawer.platformCost.table.billingOptionsValue', 'Via')} {billingOptions}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t(`drawer.platformCost.table.${ProductConfigOption.CONTACT_OPTIONS}`, 'Klantendienst')}</th>
                  <td>
                    {PRODUCTS_CONFIG[product][ProductConfigOption.CONTACT_OPTIONS]
                      .map((option, idx) =>
                        idx === 0
                          ? t(`contact.${option}`, { ns: 'common' })
                          : (t(`contact.${option}`, { ns: 'common' }) as string).toLowerCase()
                      )
                      .join(' & ')}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {conditions && (
            <section className={styles.conditions}>
              <Heading as="h2" variant="h6">
                {t('drawer.conditions.title', 'Voorwaarden voor Bolt {{ productName }}', { productName })}
              </Heading>
              <BlocksRenderer content={conditions} />
            </section>
          )}
        </>
      ) : (
        <></>
      )}
    </Drawer>
  )
}

export default ProductDrawer
