import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Icon from 'components/Icon/Icon'
import styles from './Dropdown.module.scss'
import { DropdownItem, DropdownProps } from './types'

const getFirstSelected = (items: { [key: string]: DropdownItem }) => {
  return Object.keys(items).find((key) => items[key].checked) || Object.keys(items)[0]
}

const Dropdown: FC<DropdownProps> = ({ disabled, header, icon, isMultiple, items, label, onSelect }) => {
  // Local state
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [singleSelected, setSingleSelected] = useState<string>(getFirstSelected(items))

  // Refs
  const dropdownRef = useRef<HTMLDivElement>(null)

  /**
   * Hides dropdown on click outside
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as HTMLDivElement)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  /**
   * Hides dropdown on ESC button press when dropdown is open
   */
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && isOpen) {
        setIsOpen(false)
      }
    }

    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [isOpen])

  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <button aria-expanded={isOpen} className={styles.button} onClick={() => setIsOpen(!isOpen)} disabled={disabled}>
        {isMultiple ? (
          <>
            {!!icon && <Icon name={icon} className={styles.icon} color="currentColor" size={16} viewBox="0 0 16 16" />}
            {label}
            <Icon name="arrowDown" className={styles.arrow} color="currentColor" size={16} />
          </>
        ) : (
          <>{items[singleSelected]?.text || label}</>
        )}
      </button>

      <ul role="menu" style={{ display: isOpen ? 'flex' : 'none' }} className={styles.list} aria-hidden={!isOpen}>
        {/* Dropdown header */}
        {header && <li className={styles.header}>{header}</li>}

        {/* Dropdown items */}
        {Object.entries(items).map(([key, item]) => (
          <li
            key={key}
            className={classNames(styles.item, { [styles.hidden]: item.hidden })}
            aria-disabled={item.hidden}
            role={isMultiple ? 'menuitemcheckbox' : 'menuitem'}
          >
            {isMultiple ? (
              <label htmlFor={key}>
                <input
                  type="checkbox"
                  id={key}
                  name={label}
                  value={key}
                  checked={item.checked}
                  disabled={item.hidden}
                  onChange={() => {
                    const newItem: DropdownItem = { ...item, checked: !item.checked }
                    onSelect(key, newItem)
                  }}
                />
                {item.text}
              </label>
            ) : (
              <button
                onClick={() => {
                  setSingleSelected(key)
                  onSelect(key, item)
                }}
              >
                {item.text}
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown
