import { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import styles from './FooterFaq.module.scss'
import { FaqQuestion } from 'types/types'
import SearchBar from 'components/Search/SearchBar'
import { normalizeText } from 'utils/format'
import { Link } from 'react-router-dom'
import { routes } from 'types/routes'
import { FooterFaqProps as Props } from '../types'
import useWindowSize from 'hooks/useWindowSize'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { Accordion, Heading } from '@boltenergy-be/design-system'

const FooterFaq: FC<Props> = ({ subtitle, category, isLoading }) => {
  // i18n
  const { t } = useTranslation()

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [toShow, setToShow] = useState<number>(5)

  // Window size
  const { isMobile } = useWindowSize()

  // Filtered questions based on the search query
  const questionsToShow = useMemo<{
    questions: FaqQuestion[]
    total: number
  }>(() => {
    const allQuestionsToShow = !searchQuery
      ? category?.questions || []
      : category?.questions.filter(({ question }) => normalizeText(question).includes(normalizeText(searchQuery))) || []

    return {
      questions: allQuestionsToShow.slice(0, toShow),
      total: allQuestionsToShow.length
    }
  }, [category, searchQuery, toShow])

  /**
   * Reset to show to 5 if searching
   */
  useEffect(() => {
    if (searchQuery) {
      setToShow(5)
    }
  }, [searchQuery])

  return isLoading || (!isLoading && category?.questions?.length) ? (
    <>
      <hr className={styles['container-divider']} />
      <div className={classNames('container', styles.container)}>
        <Heading as="h2" variant="h5" className={styles.title}>
          {t('faq.title')}
        </Heading>
        <div>
          <span className={styles.subtitle}>{subtitle}</span>
          <SearchBar
            className={styles.search}
            size="small"
            onInput={(value) => setSearchQuery(value)}
            placeholder={t('faq.searchPlaceholder')}
            disabled={isLoading}
          />
          {!isMobile && (
            <Link to={routes.SUPPORT_FAQ} className={styles.link}>
              « {t('faq.viewFaq')}
            </Link>
          )}
        </div>

        <ul>
          {isLoading ? (
            <li>
              <LoadingDots />
            </li>
          ) : (
            <>
              {questionsToShow.questions.map((question) => (
                <li key={question.id}>
                  <Accordion items={[{ title: question.question, children: parse(question.answer) }]} />
                </li>
              ))}
              <li className={styles['show-more-wrapper']}>
                {questionsToShow.total > questionsToShow.questions.length && (
                  <button onClick={() => setToShow(toShow + 5)}>{t('showMore')}</button>
                )}
              </li>
            </>
          )}
        </ul>

        {isMobile && (
          <Link to={routes.SUPPORT_FAQ} className={styles.link}>
            « {t('faq.viewFaq')}
          </Link>
        )}
      </div>
    </>
  ) : (
    // Hide section if no questions are found
    <></>
  )
}

export default FooterFaq
