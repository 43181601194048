import { CellType } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import classNames from 'classnames'
import styles from './Cell.module.scss'

const Cell = ({ variant, title, children }: CellType) => {
  return (
    <div title={title} className={classNames(styles.cell, styles[variant])}>
      {children}
    </div>
  )
}

export default Cell
