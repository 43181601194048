import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Sun: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor, isFilled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="10" r="9" fill={backdropColor} />}
      {isFilled ? (
        <>
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M5.25 12a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0ZM12 1.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75ZM12 19.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM1.25 12a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75ZM19.25 12a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM4.399 19.601a.75.75 0 0 1 0-1.06l1.414-1.415a.75.75 0 1 1 1.06 1.061L5.46 19.601a.75.75 0 0 1-1.06 0ZM17.126 6.873a.75.75 0 0 1 0-1.06l1.415-1.414a.75.75 0 0 1 1.06 1.06l-1.414 1.414a.75.75 0 0 1-1.06 0Z"
            clipRule="evenodd"
          />
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M19.601 19.601a.75.75 0 0 1-1.06 0l-1.415-1.414a.75.75 0 0 1 1.061-1.06l1.414 1.414a.75.75 0 0 1 0 1.06ZM6.874 6.874a.75.75 0 0 1-1.061 0L4.399 5.459a.75.75 0 1 1 1.06-1.06l1.415 1.414a.75.75 0 0 1 0 1.06Z"
            clipRule="evenodd"
          />
        </>
      ) : (
        <>
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M12 6.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM5.25 12a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0ZM12 1.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75ZM12 19.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM1.25 12a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75ZM19.25 12a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM4.399 19.601a.75.75 0 0 1 0-1.06l1.414-1.415a.75.75 0 1 1 1.06 1.061L5.46 19.601a.75.75 0 0 1-1.06 0ZM17.126 6.873a.75.75 0 0 1 0-1.06l1.415-1.414a.75.75 0 0 1 1.06 1.06l-1.414 1.414a.75.75 0 0 1-1.06 0Z"
            clipRule="evenodd"
          />
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M19.601 19.601a.75.75 0 0 1-1.06 0l-1.415-1.414a.75.75 0 0 1 1.061-1.06l1.414 1.414a.75.75 0 0 1 0 1.06ZM6.874 6.874a.75.75 0 0 1-1.061 0L4.399 5.459a.75.75 0 1 1 1.06-1.06l1.415 1.414a.75.75 0 0 1 0 1.06Z"
            clipRule="evenodd"
          />
        </>
      )}
    </svg>
  )
}

Sun.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Sun
