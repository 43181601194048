import { Heading } from '@boltenergy-be/design-system'
import styles from './ContractsLayout.module.scss'
import Icon from 'components/Icon/Icon.tsx'
import classNames from 'classnames'
import { routes } from 'types/routes.ts'
import SeoTags from 'components/SeoTags/SeoTags.tsx'
import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import { ContractsLayoutProps, FlowSteps } from 'features/contracts/layout/types.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { UserTypes } from 'store/auth/types.ts'
import { clearSalesCustomerData } from 'store/user/slice.ts'
import { useNavigate } from 'react-router'
import { Button } from '@boltenergy-be/design-system'

import useWindowSize from 'hooks/useWindowSize.tsx'

const ContractsLayout = <S extends FlowSteps | undefined>({
  flow,
  steps,
  currentStep,
  children,
  label
}: PropsWithChildren<ContractsLayoutProps<S>>) => {
  // Redux
  const { userType } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation(['contracts', 'common'])

  // Router
  const navigate = useNavigate()

  // Window size
  const { isTablet } = useWindowSize()

  // Constants
  const showNav = !!steps && currentStep !== steps[steps.length - 1]
  const stepsWithoutLast = steps?.slice(0, -1) || []

  /**
   * Handles back button click
   */
  const handleExit = () => {
    if (userType === UserTypes.SALES) {
      dispatch(clearSalesCustomerData())
      navigate(routes.SALES_HOME)
    } else {
      navigate(routes.OVERVIEW_GENERAL)
    }
  }

  return (
    <>
      <SeoTags title={t(`seo.${flow || 'index'}`)} />

      <header className={styles.header}>
        <Heading as="h1" variant="h6" className={classNames(styles.title, { [styles.solo]: !showNav })}>
          {label}
        </Heading>

        {showNav && (
          <nav className={styles.indicator}>
            {isTablet ? (
              <strong className={styles['mobile-indicator']}>
                {t('header.indicator', {
                  current: stepsWithoutLast.findIndex((step) => step === currentStep) + 1,
                  total: stepsWithoutLast.length
                })}
              </strong>
            ) : (
              <ul className={styles.steps}>
                {stepsWithoutLast.map((step, index) => {
                  const activeIndex = stepsWithoutLast.findIndex((step) => step === currentStep)

                  return (
                    <li
                      key={index}
                      className={classNames(styles.step, {
                        [styles.active]: currentStep === step,
                        [styles.done]: index < activeIndex
                      })}
                    >
                      {index < activeIndex ? <Icon name="checkMarkOutline" color="currentColor" size={16} /> : index + 1}
                    </li>
                  )
                })}
              </ul>
            )}
          </nav>
        )}

        <Button variant="tertiary" leadingIcon="cross" className={styles.exit} onClick={handleExit}>
          {t('common:close')}
        </Button>
      </header>

      <main className={classNames(styles.main, { [styles.sales]: userType === UserTypes.SALES })}>
        <div className={styles.wrapper}>{children}</div>
      </main>
    </>
  )
}

export default ContractsLayout
