import { useState } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { dateToString } from 'utils/addContract'
import { Language } from 'store/app/types'
import { AddContractStepProps, AddContractSteps, CounterTypes, InvoiceMedium, MeterType, PaymentMethod, Situation } from '../../types'
import contractsStyles from '../../../Contracts.module.scss'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { mapCreateContractData } from './utils'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds'
import { createContract } from 'api/contracts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { getUserData, updateCustomer } from 'store/user/thunks/customer'
import { formatAddress } from 'utils/user'
import { log } from 'utils/logging.ts'
import Bugsnag from '@bugsnag/js'
import { Flow } from 'types/logging.ts'
import Card from 'components/Card/Card.tsx'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock.tsx'
import dayjs from 'dayjs'
import { checkHasMeterAndNumberInputs } from 'utils/contracts.ts'
import { setMoveFlowState } from 'store/user/slice.ts'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { ApiResponseCodes } from 'types/errorCodes.ts'
import { Banner } from '@boltenergy-be/design-system'

const OverviewStep = ({ addContractData, isSales, isMove, setCurrentStep }: AddContractStepProps) => {
  // Redux
  const { customers, info, move } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [agreesWithTermsAndConditions, setAgreesWithTermsAndConditions] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation(['contracts', 'common'])

  // Constants
  const { customerData, meterDetails, address } = addContractData
  const { deliveryAddress } = address
  const { correspondenceAddress } = customerData
  const showMeterReadAndNumbers = checkHasMeterAndNumberInputs({
    situation: meterDetails.situation,
    counterType: meterDetails.counterType,
    contractStartDate: meterDetails.contractStartDate
  })

  /**
   * Handles the submit after validation by React Hook Form
   */
  const onSubmit = async () => {
    setError(null)
    setLoading(true)

    // Add user data to Bugsnag
    Bugsnag.addMetadata('user', info)

    if (agreesWithTermsAndConditions) {
      try {
        // Add contractData to bugsnag for debugging
        Bugsnag.addMetadata('contractData', addContractData)

        // Map the request data & create the contract
        const data = await mapCreateContractData(addContractData)

        // create contract
        const { success, message } = await createContract(data)

        if (success) {
          // Update the customer in the store if a customerNumber is present in the created contract data
          if (data.customerNumber) {
            const customer = customers[data.customerNumber]

            await dispatch(
              updateCustomer({
                customerId: customer.id,
                customer: {
                  address: customerData.correspondenceAddress ?? address.deliveryAddress,
                  contact: data.contact
                }
              })
            )
          }

          // If not sales, re-fetch user data to update store with new address
          // (sales cannot see the user data)
          if (!isSales) {
            dispatch(getUserData({ email: undefined }))
          }

          // Track the event in Mixpanel
          mixpanel.track(MX_CONTRACT_FLOW.confirm, { flow: move.flow })

          // Add the move flow state to the store
          dispatch(
            setMoveFlowState({
              key: 'started',
              state: {
                address: deliveryAddress,
                date: meterDetails.contractStartDate
              }
            })
          )

          // Go to the "done" step
          setCurrentStep(AddContractSteps.DONE)
        } else {
          log({
            error: message || 'unknown error with createContract',
            identifier: `[${Flow.CONTRACT}:createContract]`
          })

          setError(message ?? error)
        }
      } catch (err) {
        const error = err as Error

        log({
          error,
          identifier: `[${Flow.CONTRACT}:createContractCatch]`
        })

        setError(error.message ?? 'error')
      }
    }

    setLoading(false)
  }

  /**
   * Returns the error text based on the error code
   *
   * @param {string} err
   * @returns {string}
   */
  const getErrorText = (err: string): string => {
    switch (err) {
      case ApiResponseCodes.NOT_A_CUSTOMER:
        return t('common:errors.notACustomer')

      case ApiResponseCodes.REQUESTED_PRODUCER_FULL:
        return t('common:errors.requestedProducerFull')

      default:
        return t('common:error')
    }
  }

  return (
    <form className={contractsStyles['form-card']} onSubmit={onSubmit}>
      <div className={contractsStyles['form-content']}>
        <Card.Title>{t('add.steps.confirmation.title')}</Card.Title>

        <div className="form-group">
          <h3 className={contractsStyles['label-large']}>{t('add.steps.confirmation.subTitle')}</h3>
          <div className={contractsStyles.description}>{t('add.steps.confirmation.description')}</div>
        </div>

        {/* PERSONAL DATA */}
        <section className={contractsStyles['overview-section']}>
          <h3 className={contractsStyles['section-title']}>{t('add.steps.confirmation.personalData.title')}</h3>
          <DataBlock label={t('add.steps.confirmation.personalData.fields.fuelType')}>
            {t(`add.steps.address.form.fuelType.labels.${address.needsGas ? 'elecAndGas' : 'onlyElec'}`)}
          </DataBlock>

          <DataBlock label={t('add.steps.confirmation.personalData.fields.deliveryAddress')}>
            {parse(formatAddress(deliveryAddress, true) || '')}
          </DataBlock>

          {customerData.isCompany && (
            <DataBlock label={t('add.steps.confirmation.personalData.fields.company')}>
              <span>
                {customerData.companyName} {customerData.legalForm}
              </span>
              <span>
                {t('add.steps.customerData.fields.companyNumber')}: {customerData.companyNumber}
              </span>
              {customerData.subjectToVat && <span>{t('add.steps.customerData.fields.subjectToVat')}</span>}
            </DataBlock>
          )}

          <DataBlock label={t('add.steps.confirmation.contactDetails.title', 'Contactgegevens')}>
            <span>
              {customerData.firstName} {customerData.lastName}
            </span>
            <span>{customerData.email}</span>
            <span>{customerData.mobilePhone}</span>
          </DataBlock>

          {customerData.dateOfBirth?.day && customerData.dateOfBirth?.month && customerData.dateOfBirth?.year && (
            <DataBlock label={t('add.steps.customerData.fields.birthDate.label')}>
              {customerData.dateOfBirth.day.padStart(2, '0')}/{customerData.dateOfBirth.month.padStart(2, '0')}/
              {customerData.dateOfBirth.year}
            </DataBlock>
          )}

          <DataBlock label={t('add.steps.customerData.fields.language.label')}>
            {customerData.language === Language.NL
              ? t('add.steps.customerData.fields.language.dutch')
              : t('add.steps.customerData.fields.language.french')}
          </DataBlock>
        </section>

        {/* METER DETAILS */}
        <section className={contractsStyles['overview-section']}>
          <h3 className={contractsStyles['section-title']}>{t('add.steps.confirmation.meterDetails.title')}</h3>

          {!isMove && (
            <DataBlock label={t('add.steps.confirmation.meterDetails.fields.mySituation')}>
              {t(`add.steps.meterDetails.form.situation.options.${meterDetails.situation}`)}
            </DataBlock>
          )}

          {meterDetails.docUpload && (
            <DataBlock label={t('add.steps.meterDetails.form.docUpload')}>{meterDetails.docUpload[0].name}</DataBlock>
          )}

          <DataBlock label={t('add.steps.confirmation.meterDetails.fields.solarPanels')}>
            {meterDetails.hasSolarPanels ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' })}
          </DataBlock>

          <DataBlock label={t('add.steps.confirmation.meterDetails.fields.counterType')}>
            {meterDetails.counterType === CounterTypes.DIGITAL ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' })}
          </DataBlock>

          {/* - Meter reading month */}
          {meterDetails.situation === Situation.SWITCH && meterDetails.meterReadingMonth && meterDetails.hasSolarPanels && (
            <DataBlock label={t('meteringMonth', 'Maand van meter opname')}>
              {dayjs(meterDetails.meterReadingMonth).locale(customerData.language).format('MMMM')}
            </DataBlock>
          )}

          {/* - Electricity */}
          <>
            <DataBlock label={t('electricity', { ns: 'common' })}>
              <span>EAN: {meterDetails.electricity.ean}</span>
              {showMeterReadAndNumbers && (
                <>
                  {meterDetails.electricity.meterNumber && (
                    <span>
                      {t('add.steps.meterDetails.form.electricity.meterNumber')}: {meterDetails.electricity.meterNumber}
                    </span>
                  )}

                  {meterDetails.meterType === MeterType.SINGLE_RATE && meterDetails.electricity.total && (
                    <span>
                      {t('add.steps.meterDetails.form.electricity.total')}: {meterDetails.electricity.total}
                    </span>
                  )}

                  {meterDetails.meterType === MeterType.DOUBLE_RATE && (
                    <>
                      {meterDetails.electricity.day && (
                        <span>
                          {t('add.steps.meterDetails.form.electricity.day')}: {meterDetails.electricity.day}
                        </span>
                      )}

                      {meterDetails.electricity.night && (
                        <span>
                          {t('add.steps.meterDetails.form.electricity.night')}: {meterDetails.electricity.night}
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </DataBlock>

            {meterDetails.hasExclNight && (
              <>
                <DataBlock label={t('add.steps.confirmation.meterDetails.fields.meterType.titleExclNight')}>
                  <span>
                    {t('add.steps.confirmation.meterDetails.fields.meterReading')}: {meterDetails.electricity.exclNight}
                  </span>
                  {meterDetails.situation === Situation.MOVE && meterDetails.hasExclNightEan && (
                    <span>EAN: {meterDetails.electricity.eanExclNight}</span>
                  )}
                </DataBlock>
              </>
            )}
          </>

          {/* - Gas */}
          {address.needsGas && meterDetails.gas && (
            <>
              <DataBlock label={t('gas', { ns: 'common' })}>
                <span>EAN: {meterDetails.gas.ean}</span>
                {showMeterReadAndNumbers && (
                  <>
                    {meterDetails.gas.meterNumber && (
                      <span>
                        {t('add.steps.confirmation.meterDetails.fields.meterNumber')}: {meterDetails.gas.meterNumber}
                      </span>
                    )}

                    {meterDetails.gas.total && (
                      <span>
                        {t('add.steps.confirmation.meterDetails.fields.meterReading')}: {meterDetails.gas.total}
                      </span>
                    )}
                  </>
                )}
              </DataBlock>
            </>
          )}

          {/* - Contract start date */}
          {meterDetails.situation !== Situation.NEW_CONNECTION && meterDetails.contractStartDate && (
            <DataBlock label={t('add.steps.confirmation.meterDetails.fields.confirmationStartDateContract', 'Startdatum contract')}>
              {dateToString(meterDetails.contractStartDate)}
            </DataBlock>
          )}
        </section>

        {/* BILLING DATA */}
        <section className={contractsStyles['overview-section']}>
          <h3 className={contractsStyles['section-title']}>{t('add.steps.confirmation.billingData.title')}</h3>

          <DataBlock label={t('add.steps.confirmation.billingData.fields.invoiceMedium')}>
            {customerData.invoiceMedium === InvoiceMedium.DIGITAL
              ? t('add.steps.customerData.fields.invoiceMedium.digital')
              : t('add.steps.customerData.fields.invoiceMedium.paper')}
          </DataBlock>

          <DataBlock label={t('add.steps.confirmation.sections.correspondenceAddress')}>
            {parse(formatAddress(correspondenceAddress ?? deliveryAddress, true) ?? '')}
          </DataBlock>

          <DataBlock label={t('add.steps.confirmation.billingData.fields.paymentMethod')}>
            <span>
              {customerData.paymentMethod === PaymentMethod.BANK_TRANSFER
                ? t('add.steps.customerData.fields.paymentMethod.bankTransfer')
                : t('add.steps.customerData.fields.paymentMethod.directDebit')}
            </span>
            {!!customerData.iban && (
              <span>
                {t('add.steps.customerData.fields.iban')}&nbsp;{customerData.iban}
              </span>
            )}
          </DataBlock>
        </section>

        {/* TERMS & CONDITIONS AGREEMENT */}
        <div className="row">
          <div className="column form-group">
            <div className={contractsStyles['checkbox-container']}>
              <input
                type="checkbox"
                id="agreesWithTermsAndConditions"
                required
                onChange={() => setAgreesWithTermsAndConditions(!agreesWithTermsAndConditions)}
              />
              <label htmlFor="agreesWithTermsAndConditions">{parse(t('add.steps.confirmation.terms'))}</label>
            </div>
            <div className={classNames(contractsStyles.description, contractsStyles['is-small'], 'mt-400')}>
              {t('add.steps.confirmation.billingLegalInfo')}
            </div>
          </div>
        </div>

        {error && (
          <div className="row">
            <div className="column form-group">
              <Banner type="blocking" title={getErrorText(error)} />
            </div>
          </div>
        )}
      </div>

      <FormButtons
        trackingId={ContractFlowTrackingTypes.ADD}
        currentStep={AddContractSteps.CONFIRMATION}
        loading={loading}
        onSubmit={onSubmit}
        setCurrentStep={(step) => setCurrentStep(step as AddContractSteps)}
        submitDisabled={!agreesWithTermsAndConditions || loading || !!error}
      />
    </form>
  )
}

export default OverviewStep
