import { IndexesFormType } from 'components/IndexesFormInputs/types'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {
  AccessRights,
  CheckHasMeterAndReadingInputs,
  FormatContractStatus,
  GetMemoizedContractsLibrary,
  MeterIndexType,
  SelectedContracts
} from 'types/contracts'
import { ContractDataWithIndexes } from 'pages/App/Billing/Settlement/types'
import invariant from 'tiny-invariant'
import {
  AddressTile,
  ClientType,
  Contract,
  ContractStatus,
  ContractType,
  Customer,
  Direction,
  SimplifiedContractStatus,
  TimeframeCode
} from 'types/types'
import { CustomerLibrary, UserStore } from 'store/user/types.ts'
import { memoize } from 'proxy-memoize'
import { CounterTypes, Situation } from 'features/contracts/add/types.ts'
import { AvailableContracts } from 'components/AddressPicker/types.ts'
import { BadgeColors } from 'components/Badge/types.ts'
import { getMeterReadings } from 'store/contracts/thunks'
import { store } from 'store/index.ts'
import { ELProduct, NGProduct } from 'types/products.ts'

dayjs.extend(isBetween)

/**
 * Determines access rights based on the given contract
 *
 * @param {Contract} contract
 * @returns {AccessRights}
 */
export const determineAccessRights = (contract: Contract): AccessRights => {
  const activeContract = isActiveContract(contract)
  const atLeastAMonthOld = contractIsAtLeastAMonthOld(contract)
  const isBoltGo = [ELProduct.Go, NGProduct.Go].includes(contract.detail?.productCode)

  return {
    billingCycles: {
      canAccess: !isBoltGo && !contract.monthlyBilling,
      showContent: activeContract && atLeastAMonthOld
    },
    consumption: {
      showContent: activeContract && atLeastAMonthOld
    },
    instalment: {
      canAccess: !isBoltGo && !contract.monthlyBilling,
      showContent: activeContract
    },
    meterReadings: {
      canAccess: !contract.dynamicTariff,
      showContent: activeContract && atLeastAMonthOld
    },
    planConsumptionDynamic: {
      canAccess: !!contract.dynamicTariff
    }
  }
}

/**
 * Checks if the given contract is at least a month old (both start & subscription date)
 *
 * @param {Contract} contract
 * @returns {boolean}
 */
export const contractIsAtLeastAMonthOld = (contract: Contract): boolean => {
  return (
    dayjs().diff(dayjs(contract.detail.contractualStartDate), 'month') >= 1 &&
    dayjs().diff(dayjs(contract.detail.subscriptionDate), 'month') >= 1
  )
}

/**
 * Checks if the meter and number inputs are visible
 * @param {CheckHasMeterAndReadingInputs} params
 * @returns {boolean}
 */
export const checkHasMeterAndNumberInputs = ({ situation, counterType, contractStartDate }: CheckHasMeterAndReadingInputs): boolean => {
  return situation === Situation.MOVE && counterType === CounterTypes.ANALOG && !dayjs(contractStartDate).isAfter(dayjs())
}

/**
 * Counts total contracts
 *
 * @param {Customer[]} customers
 * @returns {number}
 */
export const countTotalContracts = (customers: Customer[]): number => {
  return customers.reduce((result, customer) => {
    return result + customer.contracts.length
  }, 0)
}

/**
 * (Memoized) Defines addresses to show, and sorts them
 *
 * @param {Customer[]} customers
 * @returns {AddressTile[]}
 */
export const getMemoizedAddressTiles = memoize<CustomerLibrary, AddressTile[]>((customers) =>
  Object.values(customers)
    .map((customer) => {
      return customer.contracts.map((customerContracts) => {
        const { electricityContract, billingContract, gasContract } = getMemoizedSelectedContracts(customerContracts)

        const electricityContractTerminated = electricityContract.detail.status === ContractStatus.TERMINATED
        const gasContractTerminated = gasContract ? gasContract.detail.status === ContractStatus.TERMINATED : null

        return {
          billingNumber: billingContract.id ?? '',
          address: electricityContract.address,
          terminated: !!(gasContract ? electricityContractTerminated || gasContractTerminated : electricityContractTerminated),
          customerId: customerContracts[0].customerId,
          isProfessional: customer.clientType === ClientType.PROFESSIONAL,
          contracts: {
            electricity: isContractStatusActive(electricityContract.detail.status as ContractStatus),
            gas: gasContract ? isContractStatusActive(gasContract.detail.status as ContractStatus) : null
          },
          terminatedContracts: {
            electricity: electricityContractTerminated,
            gas: gasContractTerminated
          },
          subscriptionDate: billingContract?.detail.subscriptionDate ?? ''
        }
      })
    })
    // Flatten the array (creates a one-dimensional array)
    .flat()
    // Sort by subscriptionDate (newest first)
    .sort((a, b) => (dayjs(a.subscriptionDate).isAfter(dayjs(b.subscriptionDate)) ? -1 : 1))
    // Move addresses with terminated contracts to the end of the array
    .sort((a, b) => Number(hasOnlyTerminatedContracts(a.terminatedContracts)) - Number(hasOnlyTerminatedContracts(b.terminatedContracts)))
)

/**
 * Generates the contracts data with indexes based on the given parameters
 *
 * @param {IndexesFormType} indexes
 * @param {Contract} electricityContract
 * @param {Contract=} gasContract
 * @param {boolean=} isInFuture
 * @returns {ContractDataWithIndexes[]}
 */
export const generateContractsDataWithIndexes = (
  indexes: IndexesFormType,
  electricityContract: Contract,
  gasContract?: Contract,
  isInFuture?: boolean
): ContractDataWithIndexes[] => {
  const contractsDataWithIndexes: ContractDataWithIndexes[] = []

  if (!electricityContract.digitalMeter && electricityContract?.previousIndex) {
    // Generate electricity indexes
    if (indexes.electricity) {
      contractsDataWithIndexes.push({
        contractNumber: electricityContract.id,
        ean: electricityContract.ean,
        indexes: isInFuture
          ? undefined
          : [
              ...electricityContract.previousIndex?.registers
                .filter((register) => register.timeframeCode !== TimeframeCode.NOT_USED)
                .map((register, i) => ({
                  meterNumber: electricityContract.previousIndex?.meterNumber,
                  registerName: register.registerName,
                  timeframeCode: register.timeframeCode,
                  value: indexes.electricity![i],
                  direction: register.direction
                }))
            ]
      })
    }

    // Generate gas indexes if necessary
    if (gasContract && indexes.gas) {
      contractsDataWithIndexes.push({
        contractNumber: gasContract.id,
        ean: gasContract.ean,
        indexes: isInFuture
          ? undefined
          : [
              ...gasContract?.previousIndex?.registers
                .filter((register) => register.timeframeCode !== TimeframeCode.NOT_USED)
                .map((register, i) => ({
                  meterNumber: gasContract?.previousIndex?.meterNumber,
                  registerName: register.registerName,
                  timeframeCode: register.timeframeCode,
                  value: indexes.gas![i],
                  direction: register.direction
                }))
            ]
      })
    }
  } else {
    // Generate electricity indexes (always present)
    contractsDataWithIndexes.push({
      contractNumber: electricityContract.id,
      ean: electricityContract.ean
    })

    // Generate gas indexes if necessary
    if (gasContract) {
      contractsDataWithIndexes.push({
        contractNumber: gasContract.id,
        ean: gasContract.ean
      })
    }
  }

  return contractsDataWithIndexes
}

/**
 * Returns the contract from the multidimensional contract array based on the given contract number
 *
 * @param {Contract[][]} contracts
 * @param {string} contractNumber
 */
export const getContractByContractNumber = (contracts: Contract[][], contractNumber: string): Contract | undefined => {
  return contracts.flat().find((contract) => contract.id === contractNumber)
}

/**
 * Returns the digital meter register name based on the given direction & meterIndexType
 *
 * @param {Direction} direction
 * @param {MeterIndexType} meterIndexType
 * @returns {string}
 */
export const getDigitalMeterRegisterName = (direction: Direction, meterIndexType: MeterIndexType): string => {
  if ([MeterIndexType.DAY, MeterIndexType.NIGHT].includes(meterIndexType)) {
    return ` (${direction === Direction.CONSUMPTION ? '1' : '2'}.8.${meterIndexType === MeterIndexType.DAY ? '1' : '2'})`
  }

  return ''
}

/**
 * (Memoized) Helper function to get the selected contract
 *
 * @param {Contract[]} [selectedContracts]
 * @returns {SelectedContracts}
 */
export const getMemoizedSelectedContracts = memoize<Contract[], SelectedContracts>((selectedContracts) => {
  const billingContract = selectedContracts?.find((ctr) => ctr.type === ContractType.BILLING)
  const electricityContract = selectedContracts?.find((ctr) => ctr.type === ContractType.ELECTRICITY)

  invariant(billingContract, 'Customer should always have a billing contract')
  invariant(electricityContract, 'Customer should always have an electricity contract')

  return {
    billingContract,
    electricityContract,
    gasContract: selectedContracts?.find((ctr) => ctr.type === ContractType.GAS)
  }
})

/**
 * Defines if no (meaning zero) contracts of address is active
 *
 * @param {AddressTile['contracts']} contracts
 * @returns {boolean}
 */
export const hasNoActiveContracts = (contracts: AddressTile['contracts']): boolean => {
  return Object.values(contracts).every((value) => value === false || value === null)
}

/**
 * Defines if contract only has terminated contracts
 *
 * @param {AddressTile['contracts']} terminatedContracts
 * @returns {boolean}
 */
export const hasOnlyTerminatedContracts = (terminatedContracts: AddressTile['contracts']): boolean => {
  return Object.values(terminatedContracts).every((value) => value === true && value !== null)
}

/**
 * Checks if the given contract is active
 *
 * @param {Contract} contract
 * @returns {boolean}
 */
export const isActiveContract = (contract: Contract): boolean => {
  return (
    // Contract start date has passed AND
    dayjs().isSameOrAfter(contract.detail.contractualStartDate) &&
    // Contract has 'Active' or 'Effective' status OR Contract has 'Terminated' status but end date has not passed yet
    (isContractStatusActive(contract.detail.status) ||
      (contract.detail.status === ContractStatus.TERMINATED && dayjs().isSameOrBefore(contract.detail.effectiveEndDate)))
  )
}

/**
 * Checks if the given contract is set to be terminated within a month
 *
 * @param {Contract} contract
 * @returns {boolean}
 */
export const isCloseToTermination = (contract: Contract): boolean => {
  return (
    contract.detail.status === ContractStatus.TERMINATED &&
    dayjs(contract.detail.effectiveEndDate).isBetween(dayjs(), dayjs().add(1, 'month'), 'day', '[]')
  )
}

/**
 * Defines if contract status is active
 *
 * @param {ContractStatus} contractStatus
 * @returns {boolean}
 */
export const isContractStatusActive = (contractStatus: ContractStatus): boolean => {
  return contractStatus === ContractStatus.ACTIVE || contractStatus === ContractStatus.EFFECTIVE
}

/**
 * Checks if the given contract is terminated or cancelled
 *
 * @param {Contract} contract
 * @returns {boolean}
 */
export const isInactiveContract = (contract: Contract) => {
  return contract.detail.status === ContractStatus.TERMINATED || contract.detail.status === ContractStatus.CANCELLED
}

/**
 * Returns if a CustomerLibrary has more than one active electricity contract
 *
 * @param {UserStore['customers']} customers
 * @returns {boolean}
 */
export const hasMultipleActiveElectricityContracts = (customers: CustomerLibrary): boolean => {
  const activeContracts = Object.values(customers)
    .map((customer) => customer.contracts)
    .flat(2)
    .filter((contract) => contract.type === ContractType.ELECTRICITY && isActiveContract(contract))

  return activeContracts.length > 1
}

/**
 * Returns the first active electricity contract
 *
 * @param {UserStore["customers"]} customers
 * @returns {Contract | undefined}
 */
export const findFirstActiveElectricityContract = (
  customers: UserStore['customers']
):
  | {
      contract: Contract
      customer: string
    }
  | undefined => {
  let activeElectricityContract: Contract | undefined
  let customerId: string = ''

  for (const customer in customers) {
    const outerContractsArray = customers[customer].contracts
    outerContractsArray.forEach((contracts) => {
      // assign values
      activeElectricityContract = contracts.find((contract) => contract.type === ContractType.ELECTRICITY && isActiveContract(contract))
      customerId = customer
    })

    // skip loop if the first contract has been found
    if (activeElectricityContract) {
      return {
        contract: activeElectricityContract,
        customer: customerId
      }
    }
  }

  return activeElectricityContract
    ? {
        contract: activeElectricityContract,
        customer: customerId
      }
    : undefined
}

/**
 * (memoized) Returns the available contracts
 * @returns {AvailableContracts}
 */
export const getMemoizedContractsLibrary = memoize<GetMemoizedContractsLibrary, AvailableContracts>(({ customers, availableOnly }) => {
  const availableContracts: AvailableContracts = {}

  Object.values(customers).forEach((customer) => {
    customer.contracts.forEach((contracts) => {
      const { billingContract, ...serviceContracts } = getMemoizedSelectedContracts(contracts)

      const availableContract = {
        ...billingContract,
        serviceContracts
      }

      if (availableOnly) {
        if (billingContract && isActiveContract(billingContract)) {
          availableContracts[billingContract.id] = availableContract
        }
      } else {
        availableContracts[billingContract.id] = availableContract
      }
    })
  })

  return availableContracts
})

/**
 * Determines the contracts simplified status
 * @param {Contract["detail"]["status"]} status
 * @returns {SimplifiedContractStatus}
 */
const determineContractStatusType = (status: Contract['detail']['status']): SimplifiedContractStatus => {
  switch (status) {
    case ContractStatus.EFFECTIVE:
      return SimplifiedContractStatus.ACTIVE
    case ContractStatus.TERMINATED:
      return SimplifiedContractStatus.TERMINATED
    case ContractStatus.CANCELLED:
      return SimplifiedContractStatus.CANCELLED
    case ContractStatus.ACTIVE:
    case ContractStatus.ILC_ONGOING:
    case ContractStatus.INACTIVE:
      return SimplifiedContractStatus.IN_PROGRESS
    default:
      return SimplifiedContractStatus.INACTIVE
  }
}

/**
 * Calculates the contract status based on the given contract
 * @param {Contract} contract
 * @returns {FormatContractStatus}
 */
export const formatContractStatus = (contract: Contract): FormatContractStatus => {
  const simplifiedStatus = determineContractStatusType(contract.detail.status)
  const {
    detail: { effectiveEndDate, contractualStartDate }
  } = contract

  switch (simplifiedStatus) {
    case SimplifiedContractStatus.ACTIVE: {
      return {
        key: dayjs(contractualStartDate).isAfter(dayjs()) ? 'startingOn' : 'activeSince',
        color: BadgeColors.GREEN,
        date: dayjs(contractualStartDate).toISOString()
      }
    }
    case SimplifiedContractStatus.TERMINATED: {
      const date = dayjs(effectiveEndDate).toISOString()
      return dayjs(effectiveEndDate).isAfter(dayjs())
        ? { date, key: 'terminatingOn', color: BadgeColors.GREY }
        : { date, key: 'terminatedSince', color: BadgeColors.ORANGE }
    }
    default:
      return { key: simplifiedStatus, color: BadgeColors.GREY }
  }
}

/**
 * Triggers the getMeterReadings action
 *
 * @param {Contract} electricityContract
 * @param {Contract=} gasContract
 */
export const triggerGetMeterReadings = (electricityContract: Contract, gasContract?: Contract) => {
  store.dispatch(
    getMeterReadings({
      electricityContractNumber: electricityContract.id,
      ...(gasContract &&
        isActiveContract(gasContract) && {
          gasContractNumber: gasContract.id
        })
    })
  )
}
