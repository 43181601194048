const Contract = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 4.5C4.25 3.25736 5.25736 2.25 6.5 2.25H17.5C18.7426 2.25 19.75 3.25736 19.75 4.5V19.5C19.75 20.7426 18.7426 21.75 17.5 21.75H6.5C5.25736 21.75 4.25 20.7426 4.25 19.5V4.5ZM11.25 17C11.25 16.5858 11.5858 16.25 12 16.25H13.2308C13.403 16.25 13.57 16.3093 13.7037 16.4179L14.6178 17.1606L15.7682 16.7867C16.1621 16.6587 16.5852 16.8743 16.7133 17.2682C16.8413 17.6621 16.6257 18.0852 16.2318 18.2133L14.6934 18.7133C14.4514 18.7919 14.1861 18.7425 13.9886 18.5821L12.9645 17.75H12C11.5858 17.75 11.25 17.4142 11.25 17ZM8 13.25C7.58579 13.25 7.25 13.5858 7.25 14C7.25 14.4142 7.58579 14.75 8 14.75H16C16.4142 14.75 16.75 14.4142 16.75 14C16.75 13.5858 16.4142 13.25 16 13.25H8ZM7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H16C16.4142 10.25 16.75 10.5858 16.75 11C16.75 11.4142 16.4142 11.75 16 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11ZM8 7.25C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75H11C11.4142 8.75 11.75 8.41421 11.75 8C11.75 7.58579 11.4142 7.25 11 7.25H8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Contract
