import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from './PreviousInhabitantForm.module.scss'
import { useState } from 'react'
import { getTown } from 'api/addContract'
import { Town } from 'api/types'
import { ClientType } from 'types/types'
import { isValidPostalCode } from 'utils/addContract'
import { MeterDataFormInputs } from '../../types'
import { Heading } from '@boltenergy-be/design-system'

const PreviousInhabitantForm = () => {
  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const { register, watch, setValue } = useFormContext<MeterDataFormInputs>()
  const watchTownName = watch('previousInhabitant.address.townName')

  // Local state
  const [selectableTowns, setSelectableTowns] = useState<Town[]>([])

  /**
   * Handles the town select change & sets the town code
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = selectableTowns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('previousInhabitant.address.townCode', townCode as never, { shouldDirty: true, shouldValidate: true })
    setValue('previousInhabitant.address.townName', townName as never, { shouldDirty: true, shouldValidate: true })
  }

  /**
   * Fetches the town names based on the given postalCode
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns } = response
        setSelectableTowns(towns)

        if (!towns.some((town) => town.townName === watchTownName)) {
          setValue('previousInhabitant.address.townName', towns[0].townName as never, {
            shouldDirty: true,
            shouldValidate: true
          })
          setValue('previousInhabitant.address.townCode', towns[0].townCode as never, {
            shouldDirty: true,
            shouldValidate: true
          })
        }

        return true
      }
    }

    return t('invalid.postalCode', { ns: 'validation' })
  }

  return (
    <section className={styles['previous-inhabitant-form']}>
      <header>
        <Heading as="h2" variant="h3" className={classNames('mb-400')}>
          {t('add.steps.meterDetails.form.previousInhabitant.title')}
        </Heading>
        <p>{t('add.steps.meterDetails.form.previousInhabitant.description')}</p>
      </header>

      <form className={styles.form}>
        <div className={styles['form-field']}>
          <label>{t('add.steps.customerData.fields.firstName')}</label>
          <input {...register('previousInhabitant.firstName')} />
        </div>

        <div className={styles['form-field']}>
          <label>{t('add.steps.customerData.fields.lastName')}</label>
          <input {...register('previousInhabitant.lastName')} />
        </div>

        <div className={styles['form-field']}>
          <label>{t('add.steps.customerData.fields.correspondenceAddress.streetName')}</label>
          <input {...register('previousInhabitant.address.streetName')} />
        </div>

        <div className={styles['form-field-collection']}>
          <div className={styles['form-field']}>
            <label>{t('add.steps.customerData.fields.correspondenceAddress.streetNumber')}</label>
            <input {...register('previousInhabitant.address.streetNumber')} />
          </div>

          <div className={styles['form-field']}>
            <label>{t('add.steps.customerData.fields.correspondenceAddress.streetBox')}</label>
            <input {...register('previousInhabitant.address.streetBox')} />
          </div>
        </div>

        <div className={styles['form-field']}>
          <label>{t('add.steps.customerData.fields.correspondenceAddress.postalCode')}</label>
          <input
            {...register('previousInhabitant.address.postalCode', {
              validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
            })}
            onChange={(e) => {
              const value = e.target.value
              setValue('previousInhabitant.address.postalCode', value as never, { shouldDirty: true, shouldValidate: true })
              validatePostalCodeAndFetchTowns(parseInt(value))
            }}
          />
        </div>

        <div className={styles['form-field']}>
          <label>{t('add.steps.customerData.fields.correspondenceAddress.townName')}</label>
          <select
            className={classNames('form-control')}
            {...register('previousInhabitant.address.townName')}
            onChange={(e) => handleTownSelectChange(e.target.value)}
            value={watchTownName}
          >
            <option disabled>
              {watch('previousInhabitant.address.postalCode') === '' ? 'text' : t('defaultPlaceholders.select', { ns: 'common' })}
            </option>
            {selectableTowns.map((town) => {
              return (
                <option key={town.townCode} value={town.townName}>
                  {town.townName}
                </option>
              )
            })}
          </select>
        </div>

        <div className={styles['form-field']}>
          <label>{t('supplier', { ns: 'common' })}</label>
          <input {...register('previousInhabitant.supplier')} />
        </div>

        <div className={styles['form-field']}>
          <label>{t('details.customer.customerType', { ns: 'sales' })}</label>
          <select {...register('previousInhabitant.clientType')}>
            <option value={ClientType.RESIDENTIAL}>{t('details.customer.residential', { ns: 'sales' })}</option>
            <option value={ClientType.PROFESSIONAL}>{t('details.customer.professional', { ns: 'sales' })}</option>
          </select>
        </div>
      </form>
    </section>
  )
}

export default PreviousInhabitantForm
