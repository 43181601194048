import { InlineMessage } from '@boltenergy-be/design-system'
import { EditableTextFieldProps } from './types'
import { useFormContext } from 'react-hook-form'
import { maskWithAsterisk } from 'utils/format.ts'
import { useTranslation } from 'react-i18next'
import { getPropertyByDottedPath } from 'utils/objects.ts'

const EditableTextField = ({ extraValidation, isEditing, masked, name, required = false, value }: EditableTextFieldProps) => {
  // i18n
  const { t } = useTranslation('validation')

  // React Hook Form
  const {
    formState: { errors },
    register
  } = useFormContext()

  // Constants
  const fieldError = getPropertyByDottedPath(name, errors)

  return (
    <div>
      {isEditing ? (
        <>
          <input defaultValue={value} {...register(name, { required, ...extraValidation })} />
          {fieldError && (
            <InlineMessage type="negative">{fieldError.type === 'required' ? t('required') : fieldError.message}</InlineMessage>
          )}
        </>
      ) : (
        <span>{masked ? maskWithAsterisk(value) : value}</span>
      )}
    </div>
  )
}

export default EditableTextField
