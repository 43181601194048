import { AdvancedVideo } from '@cloudinary/react'
import { cld, cld_core } from 'utils/cloudinary.ts'
import styles from './Video.module.scss'
import classNames from 'classnames'
import { VideoProps } from 'components/Video/types.ts'

const Video = ({ publicId, className, thumbnailOffset }: VideoProps) => {
  return (
    <AdvancedVideo
      className={classNames(styles.video, className)}
      cldVid={cld.video(publicId).quality('auto')}
      controls
      // @ts-expect-error - controlsList is not in the types but disables the download button for chrome
      controlsList="nodownload"
      poster={cld_core.video_url(publicId, { format: 'png', offset: thumbnailOffset })}
    />
  )
}

export default Video
