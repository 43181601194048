import { IconPaths } from './types'

const icon: IconPaths = {
  account: 'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z',
  accountSwitch:
    'M16 9C22 9 22 13 22 13V15H16V13C16 13 16 11.31 14.85 9.8C14.68 9.57 14.47 9.35 14.25 9.14C14.77 9.06 15.34 9 16 9M2 13C2 13 2 9 8 9S14 13 14 13V15H2V13M9 17V19H15V17L18 20L15 23V21H9V23L6 20L9 17M8 1C6.34 1 5 2.34 5 4S6.34 7 8 7 11 5.66 11 4 9.66 1 8 1M16 1C14.34 1 13 2.34 13 4S14.34 7 16 7 19 5.66 19 4 17.66 1 16 1Z',
  add: 'M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z',
  arrowCollapse: 'M10.08 2.08L18 10L10.08 17.92L8.67 16.5L14.17 11H0V9H14.17L8.67 3.5L10.08 2.08ZM18 10V20H20V0H18V10Z',
  arrowDown: 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z',
  arrowDownDropCircle: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M7,10L12,15L17,10H7Z',
  arrowLeft: 'M15.4 16.6 10.8 12l4.6-4.6L14 6l-6 6 6 6z',
  arrowOpen: 'm7.293 14 4.207 4.33L15.707 14 17 15.34 11.5 21 6 15.34 7.293 14Zm0-5L11.5 4.67 15.707 9 17 7.66 11.5 2 6 7.66 7.293 9Z',
  arrowRight: 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z',
  arrowUp: 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z',
  arrowUpDropCircle: 'M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M17,14L12,9L7,14H17Z',
  billing:
    'M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z',
  calendarRange:
    'M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V8H19V19Z',
  checkMark: 'M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
  checkMarkOutline: 'M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z',
  close: 'M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z',
  docSearch:
    'M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M7,15V17H9C9.14,18.55 9.8,19.94 10.81,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V13.03C19.85,11.21 17.82,10 15.5,10C14.23,10 13.04,10.37 12.04,11H7V13H10C9.64,13.6 9.34,14.28 9.17,15H7M17,9V7H7V9H17Z',
  dots: 'M16 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-6 0a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-6 0a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z',
  edit: 'M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z',
  energyOverview: 'M12 3L2 12H5V20H19V12H22L12 3M11.5 18V14H9L12.5 7V11H15L11.5 18Z',
  flash:
    'M13.21 2.28a.75.75 0 0 1 .54.72v6.25H18a.75.75 0 0 1 .633 1.153l-7 11A.75.75 0 0 1 10.25 21v-6.25H6a.75.75 0 0 1-.633-1.153l7-11a.75.75 0 0 1 .843-.317Z',
  gas: 'M12.172 3.11a.5.5 0 0 1 .666.035c1.026 1.018 2.073 1.71 3.094 2.382l.02.014c.894.588 1.785 1.175 2.558 1.949A8.475 8.475 0 0 1 21 13.5c0 2.175-.83 4.35-2.49 6.01a8.495 8.495 0 0 1-2.159 1.57.5.5 0 0 1-.632-.737c.185-.26.281-.524.281-.94 0-.467-.003-.828-.105-1.179-.096-.331-.29-.68-.727-1.068-.198-.176-.386-.257-.674-.377l-.006-.003a4.545 4.545 0 0 1-1.03-.557 5.984 5.984 0 0 1-1.041-1.01c-.43.398-.857.949-1.201 1.558-.446.788-.716 1.603-.716 2.233 0 .97.492 1.708.74 2.015a.5.5 0 0 1-.492.803A8.463 8.463 0 0 1 6.49 19.51 8.475 8.475 0 0 1 4 13.5c0-2.175.83-4.35 2.49-6.01a.5.5 0 0 1 .818.168l-.465.185.465-.185.003.007.018.041c.018.039.046.098.087.173.082.151.213.367.402.613.273.356.667.773 1.212 1.15.14-2.015.829-4.67 3.143-6.532Z',
  general: 'M5,3C3.89,3 3,3.89 3,5V19C3,20.11 3.89,21 5,21H11V3M13,3V11H21V5C21,3.89 20.11,3 19,3M13,13V21H19C20.11,21 21,20.11 21,19V13',
  gift: 'M16.6665 10.8336V16.6669C16.6665 16.8879 16.5787 17.0999 16.4224 17.2562C16.2661 17.4124 16.0542 17.5002 15.8332 17.5002H4.1665C3.94549 17.5002 3.73353 17.4124 3.57725 17.2562C3.42097 17.0999 3.33317 16.8879 3.33317 16.6669V10.8336H16.6665ZM12.0832 1.66691C12.5754 1.66675 13.0596 1.79114 13.4908 2.02852C13.922 2.2659 14.2861 2.60854 14.5492 3.02452C14.8123 3.44051 14.9658 3.91632 14.9955 4.40763C15.0252 4.89893 14.9301 5.38977 14.719 5.83441L17.4998 5.83358C17.7209 5.83358 17.9328 5.92138 18.0891 6.07766C18.2454 6.23394 18.3332 6.4459 18.3332 6.66691V9.16691C18.3332 9.38793 18.2454 9.59989 18.0891 9.75617C17.9328 9.91245 17.7209 10.0002 17.4998 10.0002H2.49984C2.27882 10.0002 2.06686 9.91245 1.91058 9.75617C1.7543 9.59989 1.6665 9.38793 1.6665 9.16691V6.66691C1.6665 6.4459 1.7543 6.23394 1.91058 6.07766C2.06686 5.92138 2.27882 5.83358 2.49984 5.83358L5.28067 5.83441C4.9838 5.21081 4.91786 4.50208 5.09459 3.83441C5.27132 3.16674 5.67924 2.58345 6.24575 2.18837C6.81226 1.7933 7.50059 1.61208 8.1882 1.67697C8.87581 1.74186 9.51808 2.04866 10.0007 2.54275C10.2715 2.26485 10.5954 2.04415 10.9531 1.8937C11.3108 1.74326 11.6951 1.66614 12.0832 1.66691ZM7.9165 3.33358C7.59549 3.33374 7.28684 3.45739 7.05452 3.67892C6.82219 3.90045 6.68399 4.20287 6.66857 4.52351C6.65314 4.84415 6.76166 5.15844 6.97165 5.40125C7.18164 5.64406 7.47699 5.79677 7.7965 5.82775L7.9165 5.83358H9.1665V4.58358C9.16649 4.27285 9.05074 3.97325 8.84182 3.74323C8.63291 3.51321 8.3458 3.36924 8.0365 3.33941L7.9165 3.33358ZM12.0832 3.33358L11.9632 3.33941C11.6743 3.36709 11.4041 3.4944 11.1988 3.69951C10.9936 3.90462 10.8661 4.17475 10.8382 4.46358L10.8332 4.58358V5.83358H12.0832L12.2032 5.82775C12.5124 5.79777 12.7993 5.65374 13.0081 5.42374C13.2169 5.19373 13.3326 4.89422 13.3326 4.58358C13.3326 4.27294 13.2169 3.97343 13.0081 3.74342C12.7993 3.51342 12.5124 3.36939 12.2032 3.33941L12.0832 3.33358Z',
  home: 'M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z',
  info: 'M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
  infoOutline:
    'M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z',
  logout:
    'M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z',
  menuDown: 'M7,10L12,15L17,10H7Z',
  menuUp: 'M7,15L12,10L17,15H7Z',
  menuRight: 'M10,17L15,12L10,7V17Z',
  openInNew:
    'M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z',
  preview:
    'M8 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 5.3333a3.3334 3.3334 0 1 1 0-6.6668 3.3334 3.3334 0 0 1 0 6.6668ZM8 3C4.6667 3 1.82 5.0733.6667 8c1.1533 2.9267 4 5 7.3333 5 3.3333 0 6.18-2.0733 7.3333-5-1.1533-2.9267-4-5-7.3333-5Z',
  support:
    'M3,15H1V3A2,2 0 0,1 3,1H19V3H3V15M12,23A1,1 0 0,1 11,22V19H7A2,2 0 0,1 5,17V7A2,2 0 0,1 7,5H21A2,2 0 0,1 23,7V17A2,2 0 0,1 21,19H16.9L13.2,22.71C13,22.89 12.76,23 12.5,23H12M9,9V11H19V9H9M9,13V15H17V13H9Z',
  share:
    'M18.8003 11.1897L12.5336 5.15292V8.60251C6.26678 9.46491 3.58102 13.7769 2.68576 18.0889C4.9239 15.0705 8.05729 13.6907 12.5336 13.6907V17.2265L18.8003 11.1897Z',
  x: 'M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z',
  email:
    'M2.25956 6.29124C2.36488 5.14647 3.32775 4.25 4.5 4.25H19.5C20.5259 4.25 21.3915 4.93663 21.6622 5.8754L11.9849 11.1539L2.25956 6.29124ZM2.25 7.96351V17.5C2.25 18.7426 3.25736 19.75 4.5 19.75H19.5C20.7426 19.75 21.75 18.7426 21.75 17.5V7.53613L12.3592 12.6584C12.1436 12.776 11.8842 12.7806 11.6646 12.6708L2.25 7.96351Z',
  messenger:
    'M12 2C17.634 2 22 6.127 22 11.7C22 17.273 17.634 21.4 12 21.4C11.0221 21.4025 10.0484 21.2733 9.105 21.016C8.9278 20.9673 8.73924 20.9811 8.571 21.055L6.587 21.931C6.46713 21.984 6.33608 22.0067 6.20537 21.9972C6.07467 21.9876 5.94831 21.9461 5.8374 21.8763C5.72649 21.8065 5.63444 21.7105 5.56933 21.5967C5.50422 21.483 5.46805 21.355 5.464 21.224L5.409 19.444C5.4054 19.3358 5.37977 19.2294 5.3337 19.1314C5.28762 19.0334 5.22205 18.9458 5.141 18.874C3.195 17.135 2 14.617 2 11.7C2 6.127 6.367 2 12 2ZM5.995 14.537C5.713 14.984 6.263 15.488 6.684 15.168L9.839 12.774C9.94313 12.6954 10.07 12.6529 10.2005 12.6529C10.331 12.6529 10.4579 12.6954 10.562 12.774L12.899 14.524C13.0648 14.6483 13.2545 14.737 13.4562 14.7844C13.658 14.8317 13.8673 14.8368 14.0711 14.7992C14.2749 14.7616 14.4687 14.6822 14.6403 14.566C14.8119 14.4498 14.9575 14.2993 15.068 14.124L18.005 9.464C18.287 9.016 17.737 8.512 17.316 8.831L14.161 11.227C14.0569 11.3056 13.93 11.3481 13.7995 11.3481C13.669 11.3481 13.5421 11.3056 13.438 11.227L11.101 9.477C10.9352 9.35265 10.7455 9.264 10.5438 9.21664C10.342 9.16927 10.1327 9.16422 9.92887 9.2018C9.72508 9.23938 9.53129 9.31878 9.35971 9.43499C9.18814 9.5512 9.04251 9.7017 8.932 9.877L5.995 14.537Z',
  whatsapp:
    'M2.00401 22L3.35601 17.032C2.46515 15.5049 1.99711 13.768 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.2328 22.0029 8.49667 21.5352 6.97001 20.645L2.00401 22ZM8.39101 7.308C8.26188 7.31602 8.13569 7.35003 8.02001 7.408C7.91153 7.46943 7.81251 7.54622 7.72601 7.636C7.60601 7.749 7.53801 7.847 7.46501 7.942C7.09542 8.423 6.89662 9.01342 6.90001 9.62C6.90201 10.11 7.03001 10.587 7.23001 11.033C7.63901 11.935 8.31201 12.89 9.20101 13.775C9.41501 13.988 9.62401 14.202 9.84901 14.401C10.9524 15.3725 12.2673 16.073 13.689 16.447L14.258 16.534C14.443 16.544 14.628 16.53 14.814 16.521C15.1053 16.506 15.3896 16.4271 15.647 16.29C15.813 16.202 15.891 16.158 16.03 16.07C16.03 16.07 16.073 16.042 16.155 15.98C16.29 15.88 16.373 15.809 16.485 15.692C16.568 15.606 16.64 15.505 16.695 15.39C16.773 15.227 16.851 14.916 16.883 14.657C16.907 14.459 16.9 14.351 16.897 14.284C16.893 14.177 16.804 14.066 16.707 14.019L16.125 13.758C16.125 13.758 15.255 13.379 14.724 13.137C14.668 13.1126 14.608 13.0987 14.547 13.096C14.4786 13.089 14.4095 13.0967 14.3443 13.1186C14.2791 13.1405 14.2193 13.1761 14.169 13.223V13.221C14.164 13.221 14.097 13.278 13.374 14.154C13.3325 14.2098 13.2754 14.2519 13.2098 14.2751C13.1443 14.2982 13.0733 14.3013 13.006 14.284C12.9409 14.2666 12.877 14.2445 12.815 14.218C12.691 14.166 12.648 14.146 12.563 14.109L12.558 14.107C11.9859 13.8572 11.4562 13.5198 10.988 13.107C10.862 12.997 10.745 12.877 10.625 12.761C10.2316 12.3842 9.88873 11.958 9.60501 11.493L9.54601 11.398C9.50364 11.3342 9.46937 11.2653 9.44401 11.193C9.40601 11.046 9.50501 10.928 9.50501 10.928C9.50501 10.928 9.74801 10.662 9.86101 10.518C9.9551 10.3983 10.0429 10.2738 10.124 10.145C10.242 9.955 10.279 9.76 10.217 9.609C9.93701 8.925 9.64701 8.244 9.34901 7.568C9.29001 7.434 9.11501 7.338 8.95601 7.319C8.90201 7.313 8.84801 7.307 8.79401 7.303C8.65972 7.29633 8.52515 7.29866 8.39101 7.308Z'
}

export default icon
