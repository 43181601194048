import Logo from 'assets/logo.svg'
import { AppbarProps as Props } from './types'
import styles from './Appbar.module.scss'
import { useLocation, useNavigate } from 'react-router'
import { routes } from 'types/routes'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import Icon from 'components/Icon/Icon'
import AddressSwitcher from 'components/AddressSwitcher/AdressSwitcher'
import useWindowSize from 'hooks/useWindowSize'
import classNames from 'classnames'
import { Language } from 'store/app/types'
import { UserTypes } from 'store/auth/types'
import { getUserTypeHomepage } from 'utils/user'
import { checkIsDown, clearUser, switchLanguage } from 'utils/app'
import { Link, To } from 'react-router-dom'
import Badge from 'components/Badge/Badge.tsx'
import { BadgeColors } from 'components/Badge/types.ts'
import { Banner } from '@boltenergy-be/design-system'

const Appbar = ({ logout, hideLogout = false, notFixed }: Props) => {
  // REDUX
  const { language } = useSelector((store: Store) => store.app)
  const { userType } = useSelector((store: Store) => store.auth)
  const { info, selectedCustomer } = useSelector((store: Store) => store.user)
  const isSuperUser = userType === UserTypes.SUPER_USER

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Window size
  const { isMobile } = useWindowSize()

  // Constants
  const { isDown, message } = checkIsDown()

  /**
   * Handles the otherClient button click
   */
  const handleOtherClientClick = () => {
    clearUser()
    navigate(routes.CUSTOMER_EMAIL)
  }

  /**
   * Defines route when clicking on logo
   */
  const getHomePage = (): To => {
    if (pathname === routes.WELCOME) {
      return pathname
    } else {
      return getUserTypeHomepage(userType)
    }
  }

  return (
    <header className={styles.header}>
      <nav
        className={classNames(styles.appbar, {
          [styles['not-fixed']]: notFixed,
          [styles['super-user-bg-pattern']]: isSuperUser
        })}
      >
        <Link to={getHomePage()}>
          <img src={Logo} className={styles.logo} alt="Bolt logo" aria-label="logo" />
        </Link>

        {isSuperUser && <Badge className="ml-300" text="Superuser" color={BadgeColors.GREEN} />}

        {isSuperUser && pathname !== routes.CUSTOMER_EMAIL && (
          <div className={styles.actions}>
            <button className={styles.button} onClick={handleOtherClientClick}>
              Other customer
            </button>

            <button
              className={classNames(styles.button, { [styles.active]: pathname === routes.EVENT_LOG })}
              onClick={() => navigate(routes.EVENT_LOG)}
            >
              Event log
            </button>
          </div>
        )}

        {isMobile && selectedCustomer && ![routes.WELCOME, routes.CONTRACTS].some((route) => pathname.includes(route)) && (
          <AddressSwitcher />
        )}

        <div className={styles.profile}>
          {![routes.CUSTOMER_EMAIL].includes(pathname as routes) && info && (
            <div className={styles.info}>
              <strong>{`${info.firstName} ${info.lastName}`}</strong>
              {isSuperUser && <span>{info._id ? 'Account created' : 'Account not created'}</span>}
            </div>
          )}

          <button
            className={classNames(styles.button, styles['button-language'])}
            onClick={() => switchLanguage(language === Language.NL ? Language.FR : Language.NL)}
          >
            {language.toUpperCase()}
          </button>

          <div className={styles['language-container']}>
            <button className={classNames({ [styles.active]: language === Language.NL })} onClick={() => switchLanguage(Language.NL)}>
              {Language.NL.toUpperCase()}
            </button>
            <button className={classNames({ [styles.active]: language === Language.FR })} onClick={() => switchLanguage(Language.FR)}>
              {Language.FR.toUpperCase()}
            </button>
          </div>

          {!hideLogout && (
            <button className={classNames(styles.button, styles['button-logout'])} onClick={logout}>
              <Icon name="logout" />
            </button>
          )}
        </div>
      </nav>

      <div className={classNames(styles.push, { [styles['not-fixed']]: notFixed })} />

      {isDown && (
        <Banner type="informative" className={styles.maintenance}>
          <p>{message}</p>
        </Banner>
      )}
    </header>
  )
}

export default Appbar
