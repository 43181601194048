import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Diesel: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M8 10.61c.18-.8.88-1.36 1.7-1.36H16.4c.67 0 1.28.38 1.58.98l1.49 3.02H20c.97 0 1.75.78 1.75 1.75v4c0 .41-.34.75-.75.75h-1.01a2.5 2.5 0 0 1-4.98 0H13a2.5 2.5 0 0 1-4.98 0H6a.75.75 0 0 1-.75-.75v-4c0-.97.78-1.75 1.75-1.75h.4l.6-2.64Zm7.33 7.64a2.5 2.5 0 0 1 4.34 0h.58V15a.25.25 0 0 0-.25-.25H7a.25.25 0 0 0-.25.25v3.25h1.58a2.5 2.5 0 0 1 4.34 0h2.66Zm-3.08-7.5H9.7a.25.25 0 0 0-.24.2l-.52 2.3h3.35a.75.75 0 0 1-.04-.25v-2.25Zm1.46 2.5a.75.75 0 0 0 .04-.25v-2.25h2.66c.1 0 .18.05.22.14l1.16 2.36h-4.08ZM10.5 20.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm8-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM11.7 2.17a3.24 3.24 0 0 1 4.7 3.8 3.25 3.25 0 0 1-5.24 1.5c-.27.04-.56.04-.84 0a2.25 2.25 0 1 1-2.06-3.9 2.75 2.75 0 0 1 3.44-1.4Zm2.1 1.17a1.75 1.75 0 0 0-1.58.28l-.4.3-.43-.25a1.25 1.25 0 0 0-1.85.76l-.14.54-.55.02a.75.75 0 1 0 .63 1.2l.33-.45.52.19a1.28 1.28 0 0 0 .82 0l.48-.16.33.38a1.75 1.75 0 1 0 1.84-2.82ZM5.5 8.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm-2.25.75a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
        clipRule="evenodd"
      />
      <path fill={iconColor} d="M5 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </svg>
  )
}

Diesel.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Diesel
