import i18next from 'i18next'
import { Chart as ChartJS, ScriptableLineSegmentContext } from 'chart.js'
import { CHART_COLORS, getBarChartDefaults } from 'components/Charts/constants'
import { createChartDataArray, formatChartDateLabel } from 'utils/chartjs'
import { capitalizeFirstLetter, formatAmount } from 'utils/format'
import { ProductType } from 'types/types'
import { ConsumptionChartOptions, GetConsumptionChartDatasets } from './types'
import { externalTooltipHandler } from 'components/Charts/chartjs.config.ts'
import dayjs from 'dayjs'
import { Granularity } from 'types/contracts.ts'

/**
 * Get consumption chart options
 * @param {ConsumptionChartOptions} params
 */
export const getConsumptionChartOptions = ({
  alternatingBackground,
  fuelType,
  granularity,
  handleResize,
  historyModifier,
  isMobile,
  labels,
  language,
  minMax
}: ConsumptionChartOptions) => {
  const defaults = getBarChartDefaults({ isMobile, alternatingBackground })
  const {
    scales: { y, x },
    plugins
  } = defaults

  return {
    ...defaults,
    aspectRatio: isMobile ? 1.5 : 3,
    onResize: (chart: ChartJS) => {
      if (chart?.boxes?.length) {
        handleResize(chart, labels)
      }
    },
    scales: {
      y: {
        ...y,
        suggestedMin: minMax?.minDataValue,
        suggestedMax: minMax?.maxDataValue,
        stacked: true,
        ticks: {
          ...y.ticks,
          callback: (value: number) => formatAmount(value, 0).toString()
        }
      },
      y1: {
        suggestedMin: minMax?.minDataValue,
        suggestedMax: minMax?.maxDataValue,
        type: 'linear',
        display: !isMobile,
        position: 'right',
        grace: '20%',
        ticks: {
          ...y.ticks,
          callback: (value: number) =>
            value / historyModifier === 0
              ? '0'
              : `${(value / historyModifier)?.toLocaleString('nl-BE', { maximumFractionDigits: 3, minimumFractionDigits: 3 })}`
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      },
      x: {
        ...x,
        stacked: true,
        ticks: {
          ...x.ticks,
          callback: (value: number) => {
            return formatChartDateLabel(labels[value], granularity, language)
          }
        }
      }
    },
    plugins: {
      ...plugins,
      yScaleTitle: {
        labels: {
          y1: '€/kWh',
          y: 'kWh'
        }
      },
      tooltip: {
        enabled: false,
        external: (context: any) => externalTooltipHandler({ context, totalColumns: labels.length, isCombined: true, isMobile }),
        callbacks: {
          label: (context: any) => {
            if (!context.raw && context.dataset.type !== 'line') return
            return context.dataset.type === 'line'
              ? `${context.dataset.label}: € ${formatAmount(context.raw / historyModifier, 3)} / kWh`
              : `${context.dataset.label}: ${formatAmount(context.raw, granularity === Granularity.HOUR ? 2 : granularity === Granularity.DAY ? 1 : 0)} kWh`
          },
          title: (context: any) =>
            `${capitalizeFirstLetter(formatChartDateLabel(context?.[0]?.label, granularity, language, true))} (${i18next.t(`common:${fuelType}`).toLowerCase()})`
        }
      }
    }
  }
}

export const getConsumptionChartDatasets = ({
  labels,
  data,
  type,
  showInjection,
  showEstimatedUsage,
  historyModifier,
  isMobile,
  hideHistory,
  hasSmartMeter
}: GetConsumptionChartDatasets) => {
  const borderRadius = isMobile ? 2 : 4
  const maxBarThickness = 28
  const isElek = type === ProductType.ELECTRICITY

  const lineChart = {
    id: 'history',
    type: 'line' as const,
    label: i18next.t('consumption:chart.legend.marketPrice'),
    fill: false,
    data: createChartDataArray(
      labels?.length,
      data.map(({ history }) => {
        const value = (type === ProductType.ELECTRICITY ? history?.electricity : history?.gas) || 0
        return typeof value === 'number' ? value * historyModifier : null
      })
    ),
    borderColor: CHART_COLORS.blueDark,
    tension: 0,
    pointBorderWidth: 0,
    pointRadius: 3.5,
    pointHoverRadius: 5,
    borderWidth: 1.5,
    borderCapStyle: 'round',
    pointBackgroundColor: CHART_COLORS.blueDark,
    pointStyle: 'rectRot',
    spanGaps: true,
    segment: {
      borderColor: ({ p0, p1 }: ScriptableLineSegmentContext) => (p0.skip || p1.skip ? 'transparent' : undefined),
      borderDash: ({ p1DataIndex }: ScriptableLineSegmentContext) => {
        const isInFuture = dayjs(labels[p1DataIndex]).isAfter(dayjs())
        return isInFuture ? [4, 8] : undefined
      }
    }
  }

  const barCharts = [
    {
      id: 'consumption-billed',
      type: 'bar' as const,
      label: i18next.t('consumption:chart.legend.usage'),
      backgroundColor: isElek ? CHART_COLORS.greenDark : CHART_COLORS.purpleDark,
      hoverBackgroundColor: isElek ? CHART_COLORS.greenHover : CHART_COLORS.purpleHover,
      data: createChartDataArray(
        labels?.length,
        data.map(({ consumption }) => consumption.billed || 0)
      ),
      skipNull: true,
      hideNullTooltip: true,
      borderSkipped: 'middle',
      maxBarThickness,
      borderRadius,
      yAxisID: 'y'
    },
    ...(showEstimatedUsage.all && showEstimatedUsage.consumption
      ? [
          {
            id: 'consumption-unbilled',
            type: 'bar' as const,
            label: i18next.t(`consumption:chart.legend.${!hasSmartMeter ? 'estimated' : 'unbilled'}`),
            backgroundColor: isElek ? CHART_COLORS.greenLight : CHART_COLORS.purpleLight,
            hoverBackgroundColor: isElek ? CHART_COLORS.greenHover : CHART_COLORS.purpleHover,
            data: createChartDataArray(
              labels?.length,
              data.map(({ consumption }) => consumption.unbilled || 0)
            ),
            skipNull: true,
            hideNullTooltip: true,
            borderSkipped: 'middle',
            maxBarThickness,
            borderRadius,
            yAxisID: 'y'
          }
        ]
      : []),
    ...(isElek && showInjection
      ? [
          {
            id: 'injection-billed',
            type: 'bar' as const,
            label: i18next.t('consumption:chart.legend.injection'),
            backgroundColor: CHART_COLORS.yellowDark,
            hoverBackgroundColor: CHART_COLORS.yellowHover,
            data: createChartDataArray(
              labels?.length,
              data.map(({ injection }) => injection?.billed || 0)
            ),
            skipNull: true,
            hideNullTooltip: true,
            borderSkipped: 'middle',
            maxBarThickness,
            borderRadius,
            yAxisID: 'y'
          }
        ]
      : []),
    ...(isElek && showEstimatedUsage.all && showEstimatedUsage.injection && showInjection
      ? [
          {
            id: 'injection-unbilled',
            type: 'bar' as const,
            label: i18next.t(`consumption:chart.legend.${!hasSmartMeter ? 'estimated' : 'unbilled'}`),
            backgroundColor: CHART_COLORS.yellowLight,
            hoverBackgroundColor: CHART_COLORS.yellowHover,
            data: createChartDataArray(
              labels?.length,
              data.map(({ injection }) => injection?.unbilled || 0)
            ),
            skipNull: true,
            hideNullTooltip: true,
            borderSkipped: 'middle',
            maxBarThickness,
            borderRadius,
            yAxisID: 'y'
          }
        ]
      : [])
  ]
  return !hideHistory ? [lineChart, ...barCharts] : barCharts
}
