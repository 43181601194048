import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { initialUserState } from 'store/user/initial'
import { Contract, Producer } from 'types/types'
import { initialProducer, UserStore } from '../types'
import { SelectCustomerPayload, SetProducerPayload } from './types'

const clearUser: CaseReducer = () => {
  return {
    ...initialUserState
  }
}

const setSelectedContracts: CaseReducer<UserStore, PayloadAction<Contract[]>> = (state, action) => {
  return {
    ...state,
    selectedContracts: action.payload,
    working: true
  }
}

const selectCustomer: CaseReducer<UserStore, PayloadAction<SelectCustomerPayload>> = (state, action) => {
  return {
    ...state,
    selectedCustomer: action.payload?.customerId,
    selectedCustomerProduct: action.payload?.electricityProduct
  }
}

const setProducer: CaseReducer<UserStore, PayloadAction<SetProducerPayload>> = (state, action) => {
  const { id } = action.payload

  let producer: Producer = initialProducer
  state.producers.forEach((prod) => {
    if (prod.id === id) {
      producer = prod
    }
  })

  return {
    ...state,
    producer
  }
}

export const customerReducers = {
  clearUser,
  setSelectedContracts,
  selectCustomer,
  setProducer
}
