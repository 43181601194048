import { AccountingEntity, CustomerAccountingDocument, DocumentType, InvoiceTypeTranslationKey } from 'types/types.ts'
import { filteredInvoicesProps, FilterOutDirectDebitBounceProps, InvoiceTableTabs } from 'pages/App/Billing/Invoices/hooks/types.ts'
import { InvoiceTypeTranslationKeys } from 'pages/App/Billing/Invoices/constants.ts'
import dayjs from 'dayjs'
import { memoize } from 'proxy-memoize'

/**
 * Returns filtered documents for a given route.
 * @param {filteredInvoicesProps} props
 * @returns {CustomerAccountingDocument[]}
 */
export const filterDocumentsBasedOnRoute = ({ tab, documents }: filteredInvoicesProps): CustomerAccountingDocument[] => {
  if (!documents?.length) return []

  const isAlsoSupplier = documents?.some((doc) => doc.accountingEntity === AccountingEntity.SUPPLIER)

  switch (tab) {
    case InvoiceTableTabs.SUPER_USER_CUSTOMER:
      return isAlsoSupplier ? documents.filter((document) => document.accountingEntity === AccountingEntity.CUSTOMER) : documents
    case InvoiceTableTabs.SUPER_USER_SUPPLIER:
      return documents.filter((document) => document.accountingEntity === AccountingEntity.SUPPLIER)
    case InvoiceTableTabs.ALL_INVOICES:
      return documents.filter((document) => document.documentType === DocumentType.INVOICE)
    case InvoiceTableTabs.ALL_TRANSACTIONS:
      return documents.filter((document) => document.documentType === DocumentType.PAYMENT)
    case InvoiceTableTabs.OUTSTANDING_INVOICES:
      return documents.filter((document) => !document.paid && document.accountingEntity === AccountingEntity.CUSTOMER)

    default:
      return documents
  }
}

/**
 * Returns the translation key of the invoiceType when the documentType is DocumentType.INVOICE
 * @param {CustomerAccountingDocument} document
 * @returns {InvoiceTypeTranslationKey|null}
 */
export const getInvoiceTypeTranslationKey = (document: CustomerAccountingDocument): InvoiceTypeTranslationKey | null => {
  return document?.documentType === DocumentType.INVOICE ? InvoiceTypeTranslationKeys[document.invoiceType] : null
}

/**
 * Filter out the direct debit bounce documents
 * @param {FilterOutDirectDebitBounceProps} props
 * @returns {(CustomerAccountingDocument)[]} - The filtered list of accounting documents
 */
const filterOutDirectDebitBounce = ({ documents, usesDirectDebit, tab }: FilterOutDirectDebitBounceProps): CustomerAccountingDocument[] => {
  // Define the routes that require filtering
  const routesToFilter = [InvoiceTableTabs.ALL_INVOICES, InvoiceTableTabs.ALL_TRANSACTIONS, InvoiceTableTabs.OUTSTANDING_INVOICES]

  // If direct debit is not used or the route is not in the routesToFilter, return the original documents
  if (!usesDirectDebit || !routesToFilter.includes(tab)) return documents

  // Only keep the payment documents
  const paymentDocuments = documents.filter((document) => document.documentType === DocumentType.PAYMENT)

  // Group payment documents by their absolute amount
  const paymentDocumentsByAbsAmountAndDate = new Map<string, Map<string, CustomerAccountingDocument[]>>()
  for (const document of paymentDocuments) {
    const absoluteAmountKey = `${Math.abs(document.amount)}`
    if (!paymentDocumentsByAbsAmountAndDate.has(absoluteAmountKey)) {
      paymentDocumentsByAbsAmountAndDate.set(absoluteAmountKey, new Map())
    }

    const amountMap = paymentDocumentsByAbsAmountAndDate.get(absoluteAmountKey)!

    // If the document amount is negative, initialize an empty array for it
    if (document.amount < 0) {
      // Only add the document to the map if it is not already present
      if (!amountMap.has(document.id)) amountMap.set(document.id, [])
    } else {
      // Find a negative counterpart for the positive payment document
      const negativeCounterpart = paymentDocuments.find((doc) => {
        const dateDiff = dayjs(document.date).diff(dayjs(doc.date), 'day')
        return doc.amount < 0 && Math.abs(doc.amount) === Math.abs(document.amount) && Math.abs(dateDiff) <= 5
      })

      // If a negative counterpart is found, add the positive document to its array
      if (negativeCounterpart) {
        if (amountMap.has(negativeCounterpart.id)) {
          amountMap.get(negativeCounterpart.id)!.push(document)
        } else {
          amountMap.set(negativeCounterpart.id, [document])
        }
      }
    }
  }

  // Set to keep track of document ids to be filtered out
  const documentsToFilterOut = new Set<string>()

  // Add 2 entries (1 negative and first positive) to the documents to filter out if applicable
  for (const amountMap of paymentDocumentsByAbsAmountAndDate.values()) {
    for (const [negativePaymentId, positivePayments] of amountMap.entries()) {
      // Skip the loop iteration if noo matching positive payments are found
      if (positivePayments.length === 0) continue

      // Add the negative payment id to the documents to filter out
      documentsToFilterOut.add(negativePaymentId)

      // Add the only positive payment id to the documents to filter out
      if (positivePayments.length === 1) {
        documentsToFilterOut.add(positivePayments[0].id)
      } else {
        // sort the positive payments by date
        const sortedPositivePayments = positivePayments.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))

        // Add the first positive payment id to the documents to filter out
        documentsToFilterOut.add(sortedPositivePayments[0].id)
      }
    }
  }

  // If no documents to filter out, return the original documents
  if (documentsToFilterOut.size === 0) return documents

  // Return the filtered documents
  return documents.filter((document) => !documentsToFilterOut.has(document.id))
}

/**
 * Memoized version of filterOutDirectDebitBounce
 */
export const memoizedFilterOutDirectDebitBounce = memoize<FilterOutDirectDebitBounceProps, CustomerAccountingDocument[]>(
  filterOutDirectDebitBounce
)
