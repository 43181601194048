import styles from './OverviewCard.module.scss'
import classNames from 'classnames'
import { OverviewCardProps } from './types'
import Badge from 'components/Badge/Badge.tsx'
import { BadgeColors } from 'components/Badge/types.ts'

const OverviewCard = ({ badge, blocks, button, isDanger }: OverviewCardProps) => {
  return (
    <div className={classNames(styles['overview-card'], { [styles.danger]: isDanger })}>
      {badge && (
        <Badge text={badge} color={isDanger ? BadgeColors.ORANGE : BadgeColors.GREEN} className={styles['badge-update-container']} />
      )}

      <ul className={styles.blocks}>
        {blocks.map((block, idx) => (
          <li key={idx} className={styles.block}>
            <div className={styles.label}>{block.label}</div>
            <div className={classNames(styles.value, block.valueClass)}>{block.value}</div>
          </li>
        ))}
      </ul>

      {button && <div className={styles['button-container']}>{button}</div>}
    </div>
  )
}

export default OverviewCard
