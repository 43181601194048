import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialContractsState } from 'store/contracts/initial'
import { getBillShock, getMeterReadings, getPreviousBillingCycle, getPreviousBillingCyclePeriods } from 'store/contracts/thunks'
import { MeterReadings } from 'types/contracts'

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState: initialContractsState,
  reducers: {
    resetContractsStore: () => initialContractsState,
    resetBillShock: (state) => {
      return {
        ...state,
        billShock: initialContractsState.billShock
      }
    },
    updateMeterReadings: (state, { payload }: PayloadAction<MeterReadings>) => {
      return {
        ...state,
        meterReadings: {
          ...state.meterReadings,
          data: payload
        }
      }
    }
  },
  extraReducers: (builder) => {
    // BILL SHOCK
    builder.addCase(getBillShock.pending, (state) => {
      state.billShock.loading = true
      state.billShock.error = undefined
    })

    builder.addCase(getBillShock.fulfilled, (state, { payload }) => {
      state.billShock.loading = false
      state.billShock.data = payload.billShock
      state.billShock.initialLoaded = true
      state.billShock.error = undefined
    })

    builder.addCase(getBillShock.rejected, (state, { payload }) => {
      state.billShock.loading = false
      state.billShock.initialLoaded = true
      state.billShock.error = payload
    })

    // METER READINGS
    builder.addCase(getMeterReadings.pending, (state) => {
      state.meterReadings.loading = true
      state.meterReadings.error = undefined
    })

    builder.addCase(getMeterReadings.fulfilled, (state, { payload }) => {
      state.meterReadings.loading = false
      state.meterReadings.data = payload.meterReadings
      state.meterReadings.error = undefined
    })

    builder.addCase(getMeterReadings.rejected, (state, { payload }) => {
      state.meterReadings.loading = false
      state.meterReadings.error = payload
    })

    // GET PREVIOUS BILLING CYCLE PERIODS
    builder.addCase(getPreviousBillingCyclePeriods.pending, (state) => {
      state.previousBillingCycles.loadingPeriods = true
      state.previousBillingCycles.error = undefined
    })

    builder.addCase(getPreviousBillingCyclePeriods.fulfilled, (state, { payload }) => {
      state.previousBillingCycles.loadingPeriods = false
      state.previousBillingCycles.periods = payload.periods
      state.previousBillingCycles.error = undefined
    })

    builder.addCase(getPreviousBillingCyclePeriods.rejected, (state, { payload }) => {
      state.previousBillingCycles.loadingPeriods = false
      state.previousBillingCycles.error = payload
      state.previousBillingCycles.loadingDataIds = []
    })

    // GET PREVIOUS BILLING CYCLE
    builder.addCase(getPreviousBillingCycle.pending, (state, { meta }) => {
      const billingPeriodId = meta.arg.billingPeriodId

      const erroredIds = state.previousBillingCycles.erroredIds.filter((id) => id !== billingPeriodId)
      const loadingIds = state.previousBillingCycles.loadingDataIds

      state.previousBillingCycles.loadingDataIds = loadingIds?.includes(billingPeriodId) ? loadingIds : [...loadingIds, billingPeriodId]
      state.previousBillingCycles.erroredIds = erroredIds
      state.previousBillingCycles.error = undefined
    })

    builder.addCase(getPreviousBillingCycle.fulfilled, (state, { payload }) => {
      const loadingIds = state.previousBillingCycles.loadingDataIds.filter((id) => id !== payload.previousBillingCycle.id)

      state.previousBillingCycles.data = {
        ...state.previousBillingCycles.data,
        [payload.previousBillingCycle.id]: payload.previousBillingCycle
      }
      state.previousBillingCycles.loadingDataIds = loadingIds
      state.previousBillingCycles.error = undefined
    })

    builder.addCase(getPreviousBillingCycle.rejected, (state, { payload }) => {
      const loadingIds = state.previousBillingCycles.loadingDataIds.filter((loadingId) => loadingId !== payload?.id)

      state.previousBillingCycles.loadingPeriods = false
      state.previousBillingCycles.loadingDataIds = loadingIds
      state.previousBillingCycles.error = payload?.error

      if (payload?.id) {
        state.previousBillingCycles.erroredIds = !state.previousBillingCycles.erroredIds.includes(payload.id)
          ? [...state.previousBillingCycles.erroredIds, payload?.id]
          : state.previousBillingCycles.erroredIds
      }
    })
  }
})

export const { resetContractsStore, resetBillShock, updateMeterReadings } = contractsSlice.actions

export default contractsSlice.reducer
