import { initialProducer, MoveFlows, UserStore } from 'store/user/types'
import { readCookie } from 'utils/cookies'
import { Language } from 'store/app/types'

export const initialUserState: UserStore = {
  loading: false,
  error: null,
  success: false,
  userLoading: false,
  userError: null,
  info: {
    firstName: '',
    lastName: '',
    email: '',
    language: (readCookie('language') ?? Language.NL) as Language,
    phone: ''
  },
  customers: {},
  producers: [],
  location: { lat: 0, lng: 0 },
  selectedCustomer: '',
  selectedCustomerProduct: undefined,
  selectedContracts: [],
  producer: initialProducer,
  contractUpdatedStatus: '',
  move: {
    flow: MoveFlows.START
  }
}
