import { FC } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { MIN_AGE } from 'features/contracts/add/constants.ts'
import { isUnderaged, isValidDate } from 'utils/addContract.ts'
import { DateFieldProps as Props } from 'components/FormFields/DateFormField/types.ts'
import styles from 'components/FormFields/DateFormField/DateFormField.module.scss'
import { BirthDate } from 'features/contracts/add/types.ts'

const DateFormField: FC<Props> = ({ checkDateOfBirth, label, isFuture, isRequired, isDisabled }) => {
  // i18n
  const { t } = useTranslation()

  // React hook form context
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors }
  } = useFormContext<{ dateOfBirth: BirthDate }>()

  // Constants
  const watchDate = watch('dateOfBirth')

  /**
   * Validates the date
   */
  const validateDateOfBirth = () => {
    clearErrors('dateOfBirth')

    const { day, month, year } = watchDate

    if (day !== '' && month !== '' && year !== '') {
      const dateIsValid = isValidDate(day, month, year)

      // Check date if birth is valid
      if (dateIsValid) {
        // Check if person is old enough
        if (checkDateOfBirth && isUnderaged(day, month, year)) {
          setError('dateOfBirth', {
            type: 'notOldEnough',
            message: t('fulfillment:personalDataInvalidAge', 'Je moet min. 18 jaar zijn om een contract te kunnen afsluiten.')
          })
        }
      } else {
        setError('dateOfBirth', { type: 'invalid', message: t('invalidDate', 'Dit is geen geldige datum') })
      }
    }
  }

  return (
    <div className="form-input">
      <label htmlFor="dateOfBirth">{label}</label>
      <div className={styles['birthday-select']}>
        <select
          className={classNames('form-control', { error: !!errors && 'day' in errors })}
          {...register('dateOfBirth.day', { required: isRequired })}
          onChange={(e) => {
            setValue('dateOfBirth.day', e.target.value)
            validateDateOfBirth()
          }}
          disabled={isDisabled}
        >
          <option value="" disabled>
            {t('add.steps.customerData.fields.birthDate.day', { ns: 'contracts' })}
          </option>
          {Array.from({ length: 31 }).map((_, index) => (
            <option key={index} value={index + 1}>
              {(index + 1).toString().padStart(2, '0')}
            </option>
          ))}
        </select>

        <select
          className={classNames('form-control', { error: !!errors && 'month' in errors })}
          {...register('dateOfBirth.month', { required: isRequired })}
          onChange={(e) => {
            setValue('dateOfBirth.month', e.target.value)
            validateDateOfBirth()
          }}
          disabled={isDisabled}
        >
          <option value="" disabled>
            {t('add.steps.customerData.fields.birthDate.month', { ns: 'contracts' })}
          </option>
          {Array.from({ length: 12 }).map((_, index) => (
            <option key={index} value={index + 1}>
              {(index + 1).toString().padStart(2, '0')}
            </option>
          ))}
        </select>

        <select
          className={classNames('form-control', { error: !!errors && 'year' in errors })}
          {...register('dateOfBirth.year', { validate: (value) => (isRequired ? value !== '' : true) })}
          onChange={(e) => {
            setValue('dateOfBirth.year', e.target.value)
            validateDateOfBirth()
          }}
          disabled={isDisabled}
        >
          <option value="" disabled>
            {t('add.steps.customerData.fields.birthDate.year', { ns: 'contracts' })}
          </option>
          {!isFuture
            ? Array.from({ length: 100 - MIN_AGE })
                .reverse()
                .map((_, index) => {
                  const year = new Date().getFullYear() - MIN_AGE - index

                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })
            : Array.from({ length: 25 }).map((_, index) => {
                const year = new Date().getFullYear() + index

                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              })}
        </select>
      </div>

      {errors.dateOfBirth &&
        (errors.dateOfBirth.day?.type === 'required' ||
          errors.dateOfBirth.month?.type === 'required' ||
          errors.dateOfBirth.year?.type === 'required') && (
          <p className="help-block text-negative">{t('required', { ns: 'validation' })}</p>
        )}

      {errors && (errors.dateOfBirth?.type?.toString() === 'notOldEnough' || errors.dateOfBirth?.type?.toString() === 'invalid') && (
        <p className="help-block text-negative">{errors.dateOfBirth?.message}</p>
      )}
    </div>
  )
}

export default DateFormField
