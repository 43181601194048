import { Address, CustomerAccountingDocument, Producer, Referral } from 'types/types'
import type { Account, Opportunity, OpportunityCase, SalesEvent, SalesOpportunity, SalesParameter } from 'types/sales'
import type { EventLog } from 'types/eventLog'
import { FeedbackWidgetType, WidgetSlug } from 'types/feedbackWidget'
import { Granularity } from 'types/contracts'
import { VolumeEntry } from 'pages/App/Consumption/YourConsumption/ConsumptionSection/types'
import { PriceHistoryMetrics } from 'components/Charts/PriceHistory/types'
import { PersonalizedProducers } from 'types/producer'
import { AllProducts } from 'types/products'
import { EnergyOverviewMetrics } from 'pages/App/Consumption/EnergyOverview/types'
import { SalesCase } from 'components/CasesCard/types'
import { Contact } from 'types/sales'
import { SearchOpportunityTypes } from 'api/types'

// TAGS
export enum TagTypes {
  ACCOUNT = 'account',
  CONTACT = 'contact',
  OPPORTUNITY = 'opportunity',
  CASE = 'case',
  EVENT = 'event'
}

// GET COMMUNITY SALES REFERRALS

export type GetCommunitySalesReferralsPayload = {
  email: string
}

export type GetCommunitySalesReferralsResponse = {
  referrals: Referral[]
}

// GET CUSTOMER ACCOUNTING

export type GetCustomerAccountingPayload = {
  customerNumber: string
}

export type GetCustomerAccountingResponse = {
  balance: number
  documents: CustomerAccountingDocument[]
  hasBddubDocuments: boolean
}

// GET FEEDBACK WIDGETS

export type GetFeedbackWidgetsPayload = {
  slug: WidgetSlug
}

export type GetFeedbackWidgetsResponse = FeedbackWidgetType

// GET PERSONALIZED PRODUCERS

export type GetPersonalizedProducersPayload = {
  address: Address
}

export type GetPersonalizedProducersResponse = PersonalizedProducers

// GET PRICE HISTORY

export type GetPriceHistoryPayload = {
  granularity: Granularity
  from: string
  until: string
  unit?: 'kWh' | 'MWh'
}

export type GetPriceHistoryResponse = {
  priceHistory: PriceHistoryMetrics
}

// GET PRODUCERS

export type GetProducersResponse = {
  producers: Producer[]
}

// GET PRODUCTS

export type GetProductsResponse = {
  products: AllProducts
}

// GET REFERRAL CODE

export type GetReferralCodePayload = {
  email: string
}

export type GetReferralCodeResponse = {
  code: string
}

// GET SALES EVENTS

export type GetSalesEventsPayload = {
  filterSignatures: boolean
}

export type GetSalesEventsResponse = {
  events: SalesEvent[]
}

// GET SALES CASES

export type GetSalesCasesResponse = {
  cases: SalesCase[]
}

export type getSalesContactResponse = {
  contact: Contact
}

// GET USER EVENT LOGS

export type GetUserEventLogsPayload = {
  email: string
}

export type GetUserEventLogsResponse = {
  logs: EventLog[]
}

// GET VOLUMES

export type GetVolumesPayload = {
  serviceContractId: string
  granularity: Granularity
  from: string // ISO
  until: string // ISO
}

export type GetVolumesResponse = {
  nonSmr3VolumesAvailable: boolean
  totalConsumption: number
  totalInjection?: number
  volumes: VolumeEntry[]
}

// GET YEAR OVERVIEW METRICS

export type GetYearOverviewMetricsPayload = {
  email: string
  electricityContractNumber: string
  gasContractNumber?: string
}

export type GetYearOverviewMetricsResponse = {
  yearOverviewMetrics: EnergyOverviewMetrics
}

// GET SALES OPPORTUNITIES

export type SalesOpportunityPayload = {
  searchQuery?: string
  filter?: SearchOpportunityTypes
  from?: string
  until?: string
}

export type OpportunitiesResponse = {
  opportunities: SalesOpportunity[]
}

// GET SALES OPPORTUNITIES STATS

export type SalesOpportunityStatsPayload = {
  from?: string
  until?: string
}

export type SalesOpportunitiesStatsResponse = {
  toBeConfirmed: number
  effective: number
  cancelled: number
  terminated: number
}

// UPDATE SALES OPPORTUNITY

export type UpdateSalesOpportunityPayload = {
  contactId?: string
  accountId: string
  opportunity: Partial<Opportunity>
}

export type UpdateSalesOpportunityResponse = {
  opportunity: Opportunity
}

// UPDATE SALES CONTACT

export type UpdateSalesContactPayload = {
  contact: Partial<Contact>
}

// UPDATE ACCOUNT (SALES CONTACT)

export type UpdateAccountPayload = {
  contactId?: string
  accountId: string
  account: Partial<Account>
}

export type UpdateAccountResponse = {
  account: Account
}

// CREATE OPPORTUNITY CASE

export type CreateOpportunityCasePayload = {
  contactId?: string
  accountId: string
  opportunityId: string
  caseContent: OpportunityCase
}

// GET SALES PARAMETERS

export type GetSalesParametersPayload = {
  key: string
}

export type GetSalesParametersResponse = {
  parameters: SalesParameter[]
}

// RESEND OPPORTUNITY EMAIL

export type ResendOpportunityEmailPayload = {
  contactId?: string
  accountId: string
  opportunityId: string
}
