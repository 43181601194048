import { ToggleProps as Props } from './types'
import styles from './Toggle.module.scss'
import classNames from 'classnames'

const Toggle = <T,>({ active, isFullwidth, isFitContent, onClick, options, className, id }: Props<T>) => {
  return (
    <div
      id={id}
      className={classNames(
        styles.toggle,
        { [styles['full-width']]: !isFitContent && isFullwidth },
        { [styles['fit-content']]: isFitContent },
        className
      )}
    >
      {options.map((option, index) => (
        <button
          type="button"
          className={classNames(styles['toggle-button'], { [styles.active]: active === option.value })}
          key={index}
          onClick={(e) => {
            e.preventDefault()
            onClick(option.value)
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}

export default Toggle
