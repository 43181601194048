import { Contract, Direction, SimplifiedContractStatus, TimeframeCode } from './types'

import { VolumeEntry } from 'pages/App/Consumption/YourConsumption/ConsumptionSection/types.ts'
import { CounterTypes, Situation } from 'features/contracts/add/types.ts'
import { CustomerLibrary } from 'store/user/types.ts'
import { BadgeColors } from 'components/Badge/types.ts'

// REQUEST & RESPONSE TYPES

export type CreateMeterReadingsRequest = {
  date: string // ISO string
  contractNumber: string
  ean: string
  indexes?: Index[]
}

export type GetLastMeterReadingResponseData = {
  lastMeterReading: MeterReadingEntry | null
}

export type GetServiceContractVolumesResponseData = {
  volumes: VolumeEntry[]
}

// GENERAL CONTRACT TYPES

export type Index = {
  meterNumber: string
  registerName: string
  timeframeCode: TimeframeCode
  value: number
  direction: Direction
}

export type MeterReading = {
  singleRate?: number
  exclNight?: number
  doubleRate?: {
    day?: number
    night?: number
  }
}

export type MeterReadings = {
  electricity: MeterReadingEntry | null
  gas?: MeterReadingEntry | null
}

export type MeterReadingEntry = {
  ean: string
  date: string // ISO date string
  source: MeterReadingSource
  consumption: MeterReading
  injection?: MeterReading
}

export type MeterReadingsPayload = {
  date: string // ISO
  electricity: {
    ean: string
    consumption: MeterReading
    injection?: MeterReading
  }
  gas?: {
    ean: string
    consumption: MeterReading
  }
}

export type SelectedContracts = {
  billingContract: Contract
  electricityContract: Contract
  gasContract?: Contract
}

export type GetMemoizedContractsLibrary = {
  customers: CustomerLibrary
  availableOnly?: boolean
}

export type FormatContractStatus = {
  key:
    | SimplifiedContractStatus.INACTIVE
    | SimplifiedContractStatus.IN_PROGRESS
    | SimplifiedContractStatus.CANCELLED
    | 'activeSince'
    | 'startingOn'
    | 'terminatedSince'
    | 'terminatingOn'
  color: BadgeColors
  date?: string // ISO
}

export type CheckHasMeterAndReadingInputs = {
  situation: Situation
  counterType: CounterTypes
  contractStartDate: string | null
}

// FUNCTION TYPES

export type AccessRights = {
  [AccessRightsKeys.BILLING_CYCLES]: {
    canAccess: boolean
    showContent: boolean
  }
  [AccessRightsKeys.CONSUMPTION]: {
    showContent: boolean
  }
  [AccessRightsKeys.INSTALMENT]: {
    canAccess: boolean
    showContent: boolean
  }
  [AccessRightsKeys.METER_READINGS]: {
    canAccess: boolean
    showContent: boolean
  }
  [AccessRightsKeys.PLAN_CONSUMPTION_DYNAMIC]: {
    canAccess: boolean
  }
}

// ENUMS

export enum AccessRightsKeys {
  BILLING_CYCLES = 'billingCycles',
  CONSUMPTION = 'consumption',
  INSTALMENT = 'instalment',
  METER_READINGS = 'meterReadings',
  PLAN_CONSUMPTION_DYNAMIC = 'planConsumptionDynamic'
}

export enum Granularity {
  MONTH = 'MONTH',
  DAY = 'DAY',
  HOUR = 'HOUR'
  // QUARTER_HOUR = 'QUARTER_HOUR'
}

export enum MeterIndexType {
  TOTAL = 'total',
  DAY = 'day',
  NIGHT = 'night',
  EXCL_NIGHT = 'exclNight'
}

export enum MeterReadingSource {
  CLIENT_PROVIDED = 'CLIENT_PROVIDED',
  EOD_DGO = 'EOD_DGO'
}
