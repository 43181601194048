import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api'
import { useStoreSelector } from 'hooks/store'
import { CustomerInvoice, CustomerPayment, DocumentType } from 'types/types'
import dayjs from 'dayjs'
import Card from 'components/Card/Card'
import { formatAsCurrency } from 'utils/format'
import { useLocation } from 'react-router-dom'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from 'pages/App/Billing/Invoices/InvoicesStatusBanner/InvoicesStatusBanner.module.scss'
import { Button } from '@boltenergy-be/design-system'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { useState } from 'react'
import { getCustomerBalancePayUrl } from 'api/customer'
import { popErrorToast } from 'utils/toast'
import useWindowSize from 'hooks/useWindowSize'
import Link from 'components/Link/Link.tsx'
import { InvoiceTableTabs } from 'pages/App/Billing/Invoices/hooks/types.ts'

const InvoicesStatusBanner = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { selectedCustomer, customers } = useStoreSelector((store) => store.user)
  const { data, isLoading, isFetching, isError } = useGetCustomerAccountingQuery(
    {
      customerNumber: selectedCustomer
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  )

  // Local state
  const [isLoadingBalancePayUrl, setIsLoadingBalancePayUrl] = useState<boolean>(false)

  // Hooks
  const { isMobile } = useWindowSize()

  // i18n
  const { t } = useTranslation(['invoices', 'common'])

  // Router
  const { pathname } = useLocation()

  // Constants
  const today = dayjs()
  const hasBddubDocuments = data?.hasBddubDocuments
  const isGeneralOverviewPage = pathname === routes.OVERVIEW_GENERAL
  const isDomiciled = customers[selectedCustomer]?.paymentDetails?.directDebit
  const documents = data?.documents || []
  const invoices = documents.filter((document) => document.documentType === DocumentType.INVOICE) as CustomerInvoice[]
  const payments = documents.filter((document) => document.documentType === DocumentType.PAYMENT) as CustomerPayment[]
  const expiredInvoices = invoices.filter((invoice) => {
    const invoiceDueDate = dayjs(invoice.dueDate)
    return today.isAfter(invoiceDueDate.add(4, 'days'), 'day') && !invoice.paid
  })
  const hasExpiredInvoices = !!expiredInvoices.length
  const hasNegativeUnpaidDueInvoices = invoices.some(
    (invoice) => invoice.amount < 0 && dayjs().isAfter(invoice.dueDate, 'day') && !invoice.paid
  )
  const hasOpenPayments = payments.some((payment) => !payment.paid)
  const showGeneralPaymentButton = hasOpenPayments || hasNegativeUnpaidDueInvoices

  /**
   * Handles the click on the general payment button
   * Fetches the balance pay url and opens it
   */
  const onGeneralPaymentButtonClick = async () => {
    setIsLoadingBalancePayUrl(true)

    // Fetch the balance pay url
    const balancePayUrl = await getCustomerBalancePayUrl(selectedCustomer)

    if (balancePayUrl) {
      window.location.href = balancePayUrl
    } else {
      popErrorToast(t('common:error'), isMobile)
    }

    setIsLoadingBalancePayUrl(false)
  }

  // hasBddubDocuments or error
  if (hasBddubDocuments || isError) return null

  // Data loading
  if (!data && (isLoading || isFetching)) {
    return (
      <Card as="section" className={styles.card}>
        <LoadingSkeleton>
          <LoadingSkeleton.Paragraph lines={2} />
          <LoadingSkeleton.Button />
        </LoadingSkeleton>
      </Card>
    )
  }

  // You don't have to pay anything. That's nice!
  if (Number(data?.balance) === 0) {
    return (
      <Card as="section" className={classNames(styles.card, styles.green)}>
        <Card.Title as="h2">{t('invoicesStatusBanner.neutralBalance.title')}</Card.Title>
        <Link variant="secondary" href={`${routes.BILLING_INVOICES}?tab=${InvoiceTableTabs.ALL_INVOICES}`}>
          {t('invoicesStatusBanner.neutralBalance.linkText')}
        </Link>
      </Card>
    )
  }

  // You have an outstanding balance of €XX'
  if (Number(data?.balance) > 0 && !isDomiciled) {
    return (
      <Card as="section" className={classNames(styles.card, styles.yellow)}>
        <Card.Title as="h2">
          {t('invoicesStatusBanner.positiveBalance.title', {
            amount: formatAsCurrency({
              num: Number(data?.balance),
              lang: language
            })
          })}
        </Card.Title>
        {(showGeneralPaymentButton || isGeneralOverviewPage) && (
          <div className={styles['button-group']}>
            {showGeneralPaymentButton && (
              <Button variant="secondary-on-accent" onClick={onGeneralPaymentButtonClick} loading={isLoadingBalancePayUrl}>
                {t('invoicesStatusBanner.positiveBalance.buttonText')}
              </Button>
            )}
            {isGeneralOverviewPage && (
              <Link variant="secondary" href={routes.BILLING_INVOICES}>
                {t('invoicesStatusBanner.positiveBalance.linkText')}
              </Link>
            )}
          </div>
        )}
      </Card>
    )
  }

  // You have an outstanding balance of €-XX'
  if (Number(data?.balance) < 0) {
    return (
      <Card as="section" className={classNames(styles.card, styles.green)}>
        <Card.Title as="h2">
          {t('invoicesStatusBanner.negativeBalance.title', {
            amount: formatAsCurrency({
              num: Number(data?.balance),
              lang: language
            })
          })}
        </Card.Title>
        <Link variant="secondary" href={routes.BILLING_INVOICES}>
          {t('invoicesStatusBanner.negativeBalance.linkText')}
        </Link>
      </Card>
    )
  }

  // You pay by direct debit
  if (Number(data?.balance) > 0 && isDomiciled && !hasExpiredInvoices) {
    return (
      <Card as="section" className={classNames(styles.card, styles.green)}>
        <Card.Title as="h2">{t('invoicesStatusBanner.directDebit.title')}</Card.Title>
        <p>{t('invoicesStatusBanner.directDebit.text')}</p>
      </Card>
    )
  }

  // You have an outstanding balance of €XX'
  if (Number(data?.balance) > 0 && isDomiciled && hasExpiredInvoices) {
    return (
      <Card as="section" className={classNames(styles.card, styles.yellow)}>
        <Card.Title as="h2">
          {t('invoicesStatusBanner.positiveBalance.title', {
            amount: formatAsCurrency({
              num: Number(data?.balance),
              lang: language
            })
          })}
        </Card.Title>
        {(showGeneralPaymentButton || isGeneralOverviewPage) && (
          <div className={styles['button-group']}>
            {showGeneralPaymentButton && (
              <Button variant="secondary-on-accent" onClick={onGeneralPaymentButtonClick} loading={isLoadingBalancePayUrl}>
                {t('invoicesStatusBanner.positiveBalance.buttonText')}
              </Button>
            )}
            {isGeneralOverviewPage && (
              <Link variant="secondary" href={routes.BILLING_INVOICES}>
                {t('invoicesStatusBanner.positiveBalance.linkText')}
              </Link>
            )}
          </div>
        )}
      </Card>
    )
  }

  return null
}

export default InvoicesStatusBanner
