import classNames from 'classnames'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import styles from './Index.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { routes } from 'types/routes'
import { Store } from 'store/types'
import { UserTypes } from 'store/auth/types'
import { AddAddress, TerminateAddress } from 'assets/svg/contracts'
import { getMemoizedSelectedContracts, isInactiveContract } from 'utils/contracts'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds'
import Card from 'components/Card/Card.tsx'
import ContractsLayout from 'features/contracts/layout/ContractsLayout.tsx'
import { CardVariants } from 'components/Card/types.ts'
import { ContractsIconAccentColors } from 'assets/svg/contracts/types.ts'
import { formatAddress } from 'utils/user.ts'
import { MoveFlows } from 'store/user/types.ts'
import { useStoreDispatch } from 'hooks/store.ts'
import { setMoveFlow } from 'store/user/slice.ts'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'

const Index = () => {
  // Redux
  const { userType } = useSelector((store: Store) => store.auth)
  const { selectedContracts, move } = useSelector((store: Store) => store.user)
  const isSales = userType === UserTypes.SALES
  const dispatch = useStoreDispatch()

  // Contracts
  const contracts = !isSales ? getMemoizedSelectedContracts(selectedContracts) : undefined
  const inactiveContract = contracts?.electricityContract ? isInactiveContract(contracts.electricityContract) : false

  // i18n
  const { t } = useTranslation('contracts')

  // React router
  const navigate = useNavigate()

  /**
   * Tracks the contract flow from the contract overview with a custom flow
   * @param {string} flow
   * @param {(typeof MX_CONTRACT_FLOW)['type']} type
   */
  const trackByFlow = (flow: string, type: ContractFlowTrackingTypes) => {
    mixpanel.track(MX_CONTRACT_FLOW.start, {
      flow,
      type,
      from: MX_CONTRACT_FLOW.from.CONTRACT_OVERVIEW
    })
  }

  return (
    <ContractsLayout label={t('header.labels.move')} flow={undefined}>
      <Card border={false} as="section" variant={CardVariants.DEFAULT_MORE_PADDING} className={classNames('container', styles.container)}>
        <Card.Header>
          <Card.Title variant="h4">{t('index.title')}</Card.Title>
          <p>{t('index.description')}</p>
        </Card.Header>

        <Card border={false} variant={CardVariants.SUBDUED} className={styles['wrapper-card']}>
          <Card border={false} className={styles['action-card']}>
            <Card.Title as="h3" variant="h5">
              <AddAddress accent={ContractsIconAccentColors.PRIMARY} />
              {t('index.move.title')}
            </Card.Title>
            <p>{t('index.move.description')}</p>
            <Button
              className={styles.button}
              onClick={() => {
                const flow = MoveFlows.START_STOP
                trackByFlow(flow, ContractFlowTrackingTypes.ADD)
                dispatch(setMoveFlow(flow))
                navigate(isSales ? routes.SALES_CONTRACTS_ADD : routes.CONTRACTS_MOVE)
              }}
            >
              {t('index.move.action')}
            </Button>
          </Card>

          {!isSales && !inactiveContract && (
            <Card border={false} className={styles['action-card']}>
              <Card.Title as="h3" variant="h5">
                <TerminateAddress accent={ContractsIconAccentColors.PRIMARY} />
                {t('index.terminate.title')}
              </Card.Title>
              <p>{t('index.terminate.description')}</p>

              {!!contracts?.electricityContract?.address && (
                <address className={styles.address}>{parse(formatAddress(contracts.electricityContract!.address, true) || '')}</address>
              )}

              <Button
                className={styles.link}
                onClick={() => {
                  const flow = !move.started ? MoveFlows.STOP_START : MoveFlows.START_STOP
                  trackByFlow(flow, ContractFlowTrackingTypes.TERMINATE)
                  dispatch(setMoveFlow(flow))
                  navigate(routes.CONTRACTS_TERMINATE)
                }}
              >
                {t('index.terminate.action')}
              </Button>
            </Card>
          )}

          <p className={styles.support}>{parse(t('index.support'))}</p>
        </Card>
      </Card>
    </ContractsLayout>
  )
}

export default Index
