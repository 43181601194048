import { Heading } from '@boltenergy-be/design-system'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import { ContractDetailsProps } from '../types'
import styles from '../Details.module.scss'
import { useTranslation } from 'react-i18next'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import MappedContractStatus from 'pages/Sales/components/MappedContractStatus/MappedContractStatus.tsx'
import { ContractType } from 'types/types.ts'

const ContractDetails = ({ contract }: ContractDetailsProps) => {
  // i18n
  const { t } = useTranslation(['sales', 'common'])

  return (
    <CollapsibleCard
      titleSection={
        <Heading as="h2" variant="h4" className={styles.title}>
          {t('details.contract.title')} {t(`common:${contract.type as ContractType.ELECTRICITY | ContractType.GAS}`).toLowerCase()}
        </Heading>
      }
      contentClassName={styles['details-block']}
    >
      <DataBlock label={t('details.contract.status')}>
        <MappedContractStatus status={contract.detail.status} />
      </DataBlock>
      <DataBlock label={t('details.contract.registrationDate')}>{contract.detail.subscriptionDate}</DataBlock>
      <DataBlock label={t('details.contract.startDate')}>{contract.detail.contractualStartDate}</DataBlock>
      <DataBlock label={t('details.contract.endDate')}>{contract.detail.effectiveEndDate}</DataBlock>
    </CollapsibleCard>
  )
}

export default ContractDetails
