import { ReactNode } from 'react'

export enum CellVariant {
  DESCRIPTION = 'description',
  DATE = 'date',
  PAYMENT_AMOUNT = 'payment-amount',
  STATUS_PAYMENT_PAYED = 'status-payment-payed',
  STATUS_PAYMENT_PAYED_BANKTRANSACTION = 'status-payment-payed-banktransaction',
  STATUS_PAYMENT_OVERDUE = 'status-payment-overdue',
  STATUS_PAYMENT_OPEN = 'status-payment-open',
  STATUS_PAYMENT_OPEN_DOMICELED = 'status-payment-open-domiceled',
  PAYMENT_BUTTON = 'payment-button',
  INVOICE_DOWNLOAD = 'invoice-download',
  ACCOUNTING_INFO_NEGATIVE = 'accounting-info-negative',
  ACCOUNTING_INFO_POSITIVE = 'accounting-info-positive',
  PAYMENT_METHOD = 'payment-method',
  RUNNING_BALANCE = 'running-balance',
  OPTIONS = 'options'
}

export type CellType = {
  variant: CellVariant
  children: ReactNode
  title?: string
}
