export type FeedbackWidgetProps = {
  id: string
  openFeedbackWidget?: boolean
  showTrigger?: boolean
  data?: {
    [key: string]: unknown
  }
}

export enum FeedbackWidgetStep {
  RATING = 'rating',
  FEEDBACK = 'feedback',
  THANKS = 'thanks'
}
