import { toast } from 'react-toastify'

export const popSuccessToast = (text: string, isMobile?: boolean) => {
  toast.success(text, {
    position: isMobile ? 'top-center' : 'bottom-center',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  })
}

export const popErrorToast = (text: string, isMobile?: boolean) => {
  toast.error(text, {
    position: isMobile ? 'top-center' : 'bottom-center',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  })
}
