const Support = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.867 18.867a7.933 7.933 0 1 0-6.057-2.81L3 18.867h8.4l-.018-.015c.16.01.322.015.485.015Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.3 19.617a6.75 6.75 0 0 1 10.25-8.603v-.08a8.683 8.683 0 1 0-15.74 5.061l-2.34 2.341a.75.75 0 0 0 .53 1.28h7.3Zm1.889-.006a5.25 5.25 0 1 1 8.115-6.618 8.688 8.688 0 0 1-8.115 6.618Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.75 16a5.25 5.25 0 1 1 10.31 1.403c-.155.407-.36.791-.605 1.147l1.339 1.29a.662.662 0 0 1 .152.74.706.706 0 0 1-.651.42H16a.715.715 0 0 1-.076-.004 5.859 5.859 0 0 1-2.72-.551A5.246 5.246 0 0 1 10.75 16Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Support
