import dayjs from 'dayjs'
import { DATE_FORMAT, DAYS_TO_YEARS_COEFFICIENT, MIN_AGE } from 'features/contracts/add/constants'
import { BirthDate, Situation, StartDateRange } from 'features/contracts/add/types'

/**
 * Returns if underaged based on the given day, month & year
 *
 * @param day
 * @param month
 * @param year
 * @returns boolean
 */
export const isUnderaged = (day: string, month: string, year: string): boolean => {
  const date = dayjs()
    .year(parseInt(year, 10))
    .month(parseInt(month, 10) - 1)
    .date(parseInt(day, 10))
  const age = dayjs().diff(date, 'days') * DAYS_TO_YEARS_COEFFICIENT

  return age < MIN_AGE
}

/**
 * Returns if the given day, month & year can form a valid date
 *
 * @param day
 * @param month
 * @param year
 * @returns boolean
 */
export const isValidDate = (day: string, month: string, year: string): boolean => {
  if (day === '' || month === '' || year === '') return false

  const daysInMonth = dayjs()
    .year(parseInt(year, 10))
    .month(parseInt(month, 10) - 1)
    .daysInMonth()

  return parseInt(day, 10) <= daysInMonth
}

/**
 * Turns a date string (YYYY-MM-DD) to object with keys day, month and year
 *
 * @param date
 * @returns BirthDate
 */
export const dateStringToObject = (date: string): BirthDate => {
  return {
    day: dayjs(date, 'YYYY-MM-DD').get('date').toString(),
    month: (dayjs(date, 'YYYY-MM-DD').get('month') + 1).toString(),
    year: dayjs(date, 'YYYY-MM-DD').get('year').toString()
  }
}

/**
 * Returns if a given postal code is valid
 *
 * @param postalCode
 * @returns boolean
 */
export const isValidPostalCode = (postalCode?: number): boolean => {
  return postalCode?.toString()?.length === 4
}

/**
 * Turns VAT number to company number (removes 'BE')
 *
 * @param {string} vatNumber
 * @returns {string}
 */
export const vatNumberToCompanyNumber = (vatNumber: string): string => {
  return vatNumber.replace('BE', '')
}

/**
 * Returns the date range for the start date based on the given situation, hasForcedDate, isMoveFlow & isSuperUser parameters
 *
 * @param {Situation} situation
 * @returns {StartDateRange}
 */
export const getStartDateRange = (situation: Situation): StartDateRange => {
  const now = dayjs()

  switch (situation) {
    case Situation.NEW_CONNECTION: {
      return {
        min: now.toDate(),
        max: now.toDate(),
        initial: now.toDate()
      }
    }

    case Situation.SWITCH: {
      return {
        min: now.add(30, 'days').toDate(),
        max: now.add(12, 'months').toDate(),
        initial: now.add(30, 'days').toDate()
      }
    }

    case Situation.MOVE: {
      return {
        min: now.subtract(25, 'days').toDate(),
        max: now.add(10, 'months').toDate(),
        initial: now.toDate()
      }
    }

    default:
      return {
        min: now.toDate(),
        max: now.toDate(),
        initial: now.toDate()
      }
  }
}

/**
 * Checks if text has no numbers in it
 *
 * @param {string} text
 * @returns {boolean}
 */
export const hasNoNumber = (text: string): boolean => {
  return !/\d/.test(text)
}

/**
 * Formats a given date to a string in the dd/MM/yyyy format
 *
 * @param {Date} date
 * @returns {string}
 */
export const dateToString = (date: Date | string): string => {
  return dayjs(date).format(DATE_FORMAT)
}

/**
 * Returns if the given barcode string is a valid EAN number
 *
 * @param {string} barcode
 * @returns {boolean}
 */
export const isValidEAN = (barcode: string): boolean => {
  // Check if EAN is 18 characters long
  if (barcode.length !== 18) return false

  // Check if the EAN starts with 54
  if (!barcode.startsWith('54')) return false

  // Check if EAN is a number
  if (isNaN(parseInt(barcode, 10))) return false

  // Check if the last digit is a number
  const lastDigit = parseInt(barcode.substring(barcode.length - 1), 10)
  if (isNaN(lastDigit)) return false

  // Reverse the all the numbers except the last
  const arr = barcode
    .substring(0, barcode.length - 1)
    .split('')
    .reverse()

  // Calculate the sum of all the numbers
  // Numbers on even positions are multiplied by 3 and then added
  // Numbers on odd positions are added without being multiplied
  let sum = 0
  for (let i = 0; i < arr.length; i++) {
    if (i % 2 === 0) {
      sum += parseInt(arr[i], 10) * 3
    } else {
      sum += parseInt(arr[i], 10)
    }
  }

  // Calculate the checksum
  let checkSum = sum % 10

  // Check & return if the checksum equals the last digit if the checksum equals 0
  if (checkSum === 0) return checkSum === lastDigit

  // Recalculate the checksum & check if last digit matches if checksum didn't equal 0
  checkSum = 10 - (sum % 10)

  return checkSum === lastDigit
}
