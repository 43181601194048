import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styles from './Consumption.module.scss'
import { routes } from 'types/routes'
import Subnav from 'components/Subnav/Subnav'
import { Tab } from 'components/Subnav/types'
import { determineAccessRights, getMemoizedSelectedContracts, triggerGetMeterReadings } from 'utils/contracts'
import SeoTags from 'components/SeoTags/SeoTags'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'

const Consumption: FC = () => {
  // REDUX STORE
  const {
    meterReadings: { data, loading }
  } = useStoreSelector((store) => store.contracts)
  const { selectedContracts } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Contracts
  const { electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)

  // Local state
  const [attemptedMeterReadingsFetch, setAttemptedMeterReadingsFetch] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('consumption')

  // React Router
  const navigate = useNavigate()
  const location = useLocation()

  const handleTabClick = (idx: number) => navigate(getTabs()[idx].path)

  /**
   * Fetch meter readings if none available
   */
  useEffect(() => {
    if (
      accessRights.meterReadings.canAccess &&
      accessRights.meterReadings.showContent &&
      !data?.electricity &&
      !loading &&
      !attemptedMeterReadingsFetch
    ) {
      setAttemptedMeterReadingsFetch(true)
      triggerGetMeterReadings(electricityContract, gasContract)
    }
  }, [accessRights, attemptedMeterReadingsFetch, data, dispatch, electricityContract, gasContract, loading])

  const getTabs = (): Tab[] => {
    const tabs: Tab[] = []

    tabs.push({ label: t('tabs.yourConsumption'), path: routes.CONSUMPTION_YOUR_CONSUMPTION })

    if (accessRights.meterReadings.canAccess) {
      tabs.push({
        label: t('tabs.meterReadings'),
        path: routes.CONSUMPTION_METER_READINGS
      })
    }

    tabs.push({ label: t('tabs.energyOverview'), path: routes.CONSUMPTION_ENERGY_OVERVIEW })

    return tabs
  }

  /**
   * get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (location.pathname) {
      case routes.CONSUMPTION_ENERGY_OVERVIEW:
        return `${t('nav.usage', { ns: 'common' })} - ${t('tabs.energyOverview')}`
      case routes.CONSUMPTION_METER_READINGS:
        return `${t('nav.usage', { ns: 'common' })} - ${t('tabs.meterReadings')}`
      default:
        return t('nav.usage', { ns: 'common' })
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <Subnav tabs={getTabs()} activeTab={handleTabClick} />

      <div
        className={classNames('content', styles.content, {
          [styles['energy-overview-content']]: location.pathname === routes.CONSUMPTION_ENERGY_OVERVIEW
        })}
      >
        <Outlet />
      </div>
    </>
  )
}

export default Consumption
