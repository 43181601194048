import { FC } from 'react'
import Icon from 'components/Icon/Icon'
import { TabbarItemProps as Props } from './types'
import styles from './Tabbar.module.scss'
import classNames from 'classnames'
import { IconPath } from '../Icon/types'
import Badge from 'components/Badge/Badge'
import { BadgeColors } from 'components/Badge/types'

const TabbarItem: FC<Props> = ({ icon, text, iconSize, isSelected, onClick, badge }) => {
  return (
    <button
      className={classNames(styles['tabbar-item'], {
        [styles.selected]: isSelected
      })}
      onClick={onClick}
    >
      {typeof icon === 'string' ? <Icon name={icon as IconPath} size={iconSize} /> : icon}
      <p className={styles.textContent}>
        {text}
        {!!badge && <Badge text={badge} color={BadgeColors.YELLOW} className={styles.badge} />}
      </p>
    </button>
  )
}

export default TabbarItem
