import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Subnav from 'components/Subnav/Subnav'
import { Tab } from 'components/Subnav/types'
import { routes } from 'types/routes'
import SeoTags from 'components/SeoTags/SeoTags'
import { Outlet } from 'react-router'

const ContractPage = () => {
  // i18n
  const { t } = useTranslation('contract')

  // React Router
  const navigate = useNavigate()
  const location = useLocation()
  const handleTabClick = (idx: number) => navigate(tabs[idx].path)

  // The overview tabs
  const tabs: Tab[] = [{ label: t('myContract.tab'), path: routes.CONTRACT_MY_CONTRACT }]

  /**
   * get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (location.pathname) {
      case routes.CONTRACT_MY_CONTRACT:
        return `${t('nav.contract', { ns: 'common' })} - ${t('myContract.tab')}`
      default:
        return t('nav.contract', { ns: 'common' })
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <Subnav tabs={tabs} activeTab={handleTabClick} />

      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default ContractPage
