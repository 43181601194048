import { MeterIndexType } from 'types/contracts'
import { ProductType } from 'types/types'

export type AddMeterReadingsModalContentProps = {
  entryPoint: AddMeterReadingsModalEntryPoint
  setClose: () => void
  isConfirmationModalOpen: boolean
  setIsConfirmationModalOpen: (arg: boolean) => void
  onSuccess?: () => void
}

export type AddMeterReadingsModalProps = {
  entryPoint: AddMeterReadingsModalEntryPoint
  isOpen: boolean
  setClose: () => void
  onSuccess?: () => void
}

export type AddMeterReadingsModalFormInputs = {
  date: Date
  [ProductType.ELECTRICITY]: {
    consumption: {
      [key in MeterIndexType]?: number
    }
    injection?: {
      [key in MeterIndexType]?: number
    }
  }
  [ProductType.GAS]: {
    [MeterIndexType.TOTAL]?: number
  }
}

export type MeterReadingInputLayoutContract = {
  allElOptional: boolean
  electricity: {
    consumption: {
      [key in MeterIndexType]: boolean
    }
    injection?: {
      [MeterIndexType.TOTAL]: boolean
      [MeterIndexType.DAY]: boolean
      [MeterIndexType.NIGHT]: boolean
    }
  }
}

export enum AddMeterReadingsModalEntryPoint {
  MeterReadingsPage = 'meterReadingsPage',
  BillingCyclesPage = 'billingCyclesPage',
  ConsumptionPage = 'consumptionPage'
}
