import { PropsWithChildren } from 'react'
import styles from './DataBlock.module.scss'
import { DataBlockProps } from './types'
import { Heading } from '@boltenergy-be/design-system'
import classNames from 'classnames'

const DataBlock = ({ label, withMargin = true, children }: PropsWithChildren<DataBlockProps>) => (
  <div className={styles['data-block']}>
    {label && (
      <Heading as="h4" variant="h6" className={classNames({ ['mb-200']: withMargin })}>
        {label}
      </Heading>
    )}
    {children}
  </div>
)

export default DataBlock
