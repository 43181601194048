import { useTranslation } from 'react-i18next'
import { EditableSelectFieldProps } from './types'
import { InlineMessage } from '@boltenergy-be/design-system'
import { useFormContext } from 'react-hook-form'

const EditableSelectField = ({ isEditing, name, options, required, value }: EditableSelectFieldProps) => {
  // i18n
  const { t } = useTranslation('common')

  // React Hook Form
  const {
    formState: { errors },
    register
  } = useFormContext()

  return (
    <>
      {isEditing ? (
        <>
          <select defaultValue={value?.toString()} {...register(name, { required })}>
            <option value="" disabled>
              {t('selectOption')}
            </option>{' '}
            {options.map((option) => (
              <option key={option.label} value={option.value.toString()}>
                {option.label}
              </option>
            ))}
          </select>
          {errors?.target?.type === 'required' && <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>}
        </>
      ) : (
        <span>{options.find((option) => option.value === value)?.label}</span>
      )}
    </>
  )
}

export default EditableSelectField
