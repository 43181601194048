import { removeSpacesAndDots } from './format.ts'

/**
 * Validates a Belgian enterprise number
 *
 * @param {string} enterpriseNumber
 * @returns {boolean}
 */
export const validateBelgianEnterpriseNumber = (enterpriseNumber: string): boolean => {
  enterpriseNumber = removeSpacesAndDots(enterpriseNumber)

  const firstEightNumbers = parseInt(enterpriseNumber.substring(0, 8), 10)
  const rest = firstEightNumbers % 97
  const controlDigit = 97 - rest
  const lastTwoNumbers = parseInt(enterpriseNumber.substring(8), 10)

  return controlDigit === lastTwoNumbers
}
