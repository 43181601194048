import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Calendar: FC<SvgProps> = ({ iconColor = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6668 4C9.93046 4 9.3335 4.59696 9.3335 5.33333V6.66667H8.00016C6.5274 6.66667 5.3335 7.86057 5.3335 9.33333V24C5.3335 25.4728 6.5274 26.6667 8.00016 26.6667H24.0002C25.473 26.6667 26.6668 25.4728 26.6668 24V9.33333C26.6668 7.86057 25.473 6.66667 24.0002 6.66667H22.6668V5.33333C22.6668 4.59696 22.0699 4 21.3335 4C20.5971 4 20.0002 4.59696 20.0002 5.33333V6.66667H12.0002V5.33333C12.0002 4.59696 11.4032 4 10.6668 4ZM10.6668 10.6667C9.93046 10.6667 9.3335 11.2636 9.3335 12C9.3335 12.7364 9.93046 13.3333 10.6668 13.3333H21.3335C22.0699 13.3333 22.6668 12.7364 22.6668 12C22.6668 11.2636 22.0699 10.6667 21.3335 10.6667H10.6668Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default Calendar
