import Toggle from 'components/Toggle/Toggle.tsx'
import styles from 'pages/App/Consumption/YourConsumption/PlanConsumptionSection/PlanConsumptionSection.module.scss'
import { ConsumptionView, PlanConsumptionContentProps } from 'pages/App/Consumption/YourConsumption/PlanConsumptionSection/types.ts'
import parse from 'html-react-parser'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Response } from 'types/request.ts'
import { useTranslation } from 'react-i18next'
import { ReactElement, useMemo, useState } from 'react'
import { useGetPriceHistoryQuery } from 'store/queries/bolt-api'
import { Granularity } from 'types/contracts.ts'
import dayjs from 'dayjs'
import { getIndexesOfLowestPrices } from 'pages/App/Consumption/YourConsumption/PlanConsumptionSection/utils.ts'
import { TransfoErrorCodes } from 'types/billShock.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { formatAmount } from 'utils/format.ts'
import Totals from 'pages/App/Consumption/YourConsumption/TotalsBlock/TotalsBlock.tsx'
import mixpanel from 'mixpanel-browser'
import { CommonTrackingParams, ConsumptionEvents, ConsumptionTrackingParams } from 'types/tracking.ts'
import { useLocation } from 'react-router'
import ConsumptionPlannerChart from 'components/Charts/ConsumptionPlanner/ConsumptionPlannerChart.tsx'
import { Banner } from '@boltenergy-be/design-system'
import { formatHour } from 'utils/date'

const PlanConsumptionContent = ({ compact }: PlanConsumptionContentProps) => {
  // i18n
  const { t } = useTranslation('consumption')

  // Router
  const { pathname } = useLocation()

  // Local state
  const [activeView, setActiveView] = useState<ConsumptionView>(ConsumptionView.TODAY)

  // Redux Query
  const skipFetchTomorrow = activeView === ConsumptionView.TOMORROW && dayjs().isBefore(dayjs().set('hour', 15))
  const { data, isLoading, isFetching, isError, error } = useGetPriceHistoryQuery(
    {
      granularity: Granularity.HOUR,
      from:
        activeView === ConsumptionView.TODAY ? dayjs().startOf('day').toISOString() : dayjs().startOf('day').add(1, 'day').toISOString(),
      until:
        activeView === ConsumptionView.TODAY
          ? dayjs().endOf('day').add(1, 'day').toISOString()
          : dayjs().endOf('day').add(2, 'day').toISOString(),
      unit: 'kWh'
    },
    { skip: skipFetchTomorrow }
  )

  // Constants
  const lowestPriceIndexes = getIndexesOfLowestPrices(data?.priceHistory.electricity || [], 4)

  /**
   * Get error message based on error code
   * @param {TransfoErrorCodes | string} code
   * @returns {React.ReactElement}
   */
  const getErrorMessage = (code?: TransfoErrorCodes | string): ReactElement => {
    if (code === TransfoErrorCodes.E311_NO_DATA_AVAILABLE_FOR_FUTURE_DATES) {
      return <p>{parse(t('planConsumption.error.notYetKnown'))}</p>
    }

    return <Banner type="warning">{t('errorTryLater', { ns: 'common' })}</Banner>
  }

  /**
   * Set the hour to 00:00 if curr is 23, thus preventing the fetching of element 24 from the array of prices
   * @param {number} curr
   * @returns {string}
   */
  const correctLastHourLowestPrice = (curr: number) => {
    const time = curr === 23 ? '00:00' : formatHour(dayjs(data?.priceHistory.electricity[curr + 1].date))
    return time
  }

  // Memoized off-peak hours
  const offPeakHours = useMemo<{ from: string; until: string }[]>(() => {
    return lowestPriceIndexes
      .sort((a, b) => a - b)
      .reduce((rv: { from: string; until: string }[], curr, idx, original) => {
        if (!data?.priceHistory.electricity[curr].date) return rv

        // Always add first element with from & until hour
        if (!rv.length) {
          rv.push({
            from: formatHour(dayjs(data?.priceHistory.electricity[curr].date)),
            until: correctLastHourLowestPrice(curr)
          })
          return rv
        }

        // If the current index is a consecutive index compared to the previous one, update the until hour
        if (curr === original[idx - 1] + 1) {
          rv[rv.length - 1].until = correctLastHourLowestPrice(curr)
        } else {
          // Else, add a new entry
          rv.push({
            from: formatHour(dayjs(data?.priceHistory.electricity[curr].date)),
            until: correctLastHourLowestPrice(curr)
          })
        }

        return rv
      }, [])
  }, [data?.priceHistory.electricity, lowestPriceIndexes])

  return (
    <>
      <Toggle
        disabled={isLoading || isFetching}
        className={!compact ? styles.toggle : undefined}
        active={activeView}
        onClick={(view) => {
          mixpanel.track(ConsumptionEvents.PLAN_CONSUMPTION_TOGGLED, {
            [ConsumptionTrackingParams.VIEW]: view,
            [CommonTrackingParams.ROUTE]: pathname
          })
          setActiveView(view)
        }}
        options={[
          { value: ConsumptionView.TODAY, label: t('planConsumption.toggle.today') },
          { value: ConsumptionView.TOMORROW, label: t('planConsumption.toggle.tomorrow') }
        ]}
      />

      {!compact && !skipFetchTomorrow && !isError && (
        <Totals>
          <Totals.Block
            fullwidth="mobile"
            isLoading={isFetching || isLoading}
            label={t('planConsumption.totals.offPeakHours')}
            color="green"
          >
            <strong>
              {offPeakHours.map((range, idx) =>
                idx === offPeakHours.length - 1
                  ? `${range.from} - ${range.until}`
                  : `${range.from} - ${range.until} ${t('planConsumption.totals.and')} `
              )}
            </strong>
          </Totals.Block>

          <Totals.Block isLoading={isFetching || isLoading} label={t('planConsumption.totals.lowestPrice')} color="green">
            {data?.priceHistory.electricity && (
              <strong>&euro; {formatAmount(Math.min(...data.priceHistory.electricity.map((entry) => entry.price)), 3)}</strong>
            )}
          </Totals.Block>

          <Totals.Block isLoading={isFetching || isLoading} label={t('planConsumption.totals.averagePrice')}>
            {data?.priceHistory.electricity && (
              <strong>
                &euro;{' '}
                {formatAmount(
                  data.priceHistory.electricity.reduce((total: number, curr) => total + curr.price, 0) /
                    data.priceHistory.electricity.length,
                  3
                )}
              </strong>
            )}
          </Totals.Block>
        </Totals>
      )}

      {/* LOADING STATE */}
      {(isLoading || isFetching) && (
        <LoadingSkeleton>
          <LoadingSkeleton.Rectangle aspectRatio="2.5 / 1" />

          <LoadingSkeleton.Paragraph />
        </LoadingSkeleton>
      )}

      {/* CHART */}
      {!(isError || skipFetchTomorrow) && !(isLoading || isFetching) && (
        <ConsumptionPlannerChart data={data?.priceHistory.electricity || []} {...{ lowestPriceIndexes }} />
      )}

      {/* ERROR STATE */}
      {(isError || skipFetchTomorrow) &&
        getErrorMessage(
          skipFetchTomorrow
            ? TransfoErrorCodes.E311_NO_DATA_AVAILABLE_FOR_FUTURE_DATES
            : ((error as FetchBaseQueryError).data as Response).message
        )}
    </>
  )
}

export default PlanConsumptionContent
