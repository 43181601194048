import dayjs, { ManipulateType } from 'dayjs'
import { NavigationItem, NavigationItemType } from 'components/Charts/ConsumptionNavigation/types.ts'
import { PreviousBillingCyclePeriod } from 'types/billShock.ts'
import { Contract, ProductType } from 'types/types.ts'
import { t } from 'i18next'
import { Granularity } from 'types/contracts.ts'
import { getArrayOfFormattedDatesBetweenDates } from 'utils/date.ts'
import {
  GranularityOptions,
  ShowEstimatedUsage,
  VolumeEntry,
  VolumeSearchQueries
} from 'pages/App/Consumption/YourConsumption/ConsumptionSection/types.ts'

/**
 * Generates the from & to value for the given params
 *
 * @param {string} date
 * @param {dayjs.ManipulateType} dayjsUnit
 * @returns {Pick<VolumeSearchQueries, 'from' | 'to'>}
 */
export const generateFromAndToValue = (date: string, dayjsUnit: ManipulateType): Pick<VolumeSearchQueries, 'from' | 'until'> => {
  return {
    from: dayjs(date).startOf(dayjsUnit),
    until: dayjs(date).endOf(dayjsUnit)
  }
}

/**
 * Generates the granularity navigation items based on the given electricityContract
 *
 * @param {Contract} electricityContract
 * @returns {GranularityOptions}
 */
export const generateGranularityNavigationItems = (electricityContract: Contract): GranularityOptions => {
  const defaultGranularity = {
    [Granularity.MONTH]: {
      text: t('consumption:chart.header.pagination.year'),
      id: Granularity.MONTH,
      type: NavigationItemType.GRANULARITY,
      periods: getArrayOfFormattedDatesBetweenDates(
        electricityContract.detail.contractualStartDate,
        dayjs().endOf('year').toISOString(),
        'year'
      ).map((yearISO) => ({
        label: dayjs(yearISO).format('YYYY'),
        selected: dayjs(yearISO).format('YYYY') === dayjs().year().toString(),
        value: yearISO
      })),
      fuel: {
        electricity: true,
        gas: true
      }
    }
  }

  return electricityContract.dynamicTariff
    ? {
        ...defaultGranularity,
        [Granularity.DAY]: {
          text: t('consumption:chart.header.pagination.month'),
          id: Granularity.DAY,
          type: NavigationItemType.GRANULARITY,
          periods: getArrayOfFormattedDatesBetweenDates(
            electricityContract.detail.contractualStartDate,
            dayjs().endOf('month').toISOString(),
            // 'MMM YYYY',
            'month'
          ).map((monthISO) => ({
            label: dayjs(monthISO).format('MMM YYYY'),
            selected: dayjs(monthISO).format('MMM YYYY') === dayjs().format('MMM YYYY'),
            value: monthISO
          })),
          fuel: {
            electricity: true,
            gas: false
          }
        },
        [Granularity.HOUR]: {
          text: t('consumption:chart.header.pagination.day'),
          id: Granularity.HOUR,
          type: NavigationItemType.GRANULARITY,
          periods: getArrayOfFormattedDatesBetweenDates(
            electricityContract.detail.contractualStartDate,
            dayjs().endOf('day').toISOString(),
            'day'
          ).map((dateISO) => ({
            label: dayjs(dateISO).format('DD/MM/YYYY'),
            selected: dayjs(dateISO).format('DD/MM/YYYY') === dayjs().subtract(3, 'day').format('DD/MM/YYYY'),
            value: dateISO
          })),
          fuel: {
            electricity: true,
            gas: false
          }
        }
      }
    : defaultGranularity
}

/**
 * Generates the previous billing cycles navigation items based on the given previousBillingCyclesPeriods & activeProductType
 *
 * @param {PreviousBillingCyclePeriod[]} previousBillingCyclesPeriods
 * @param {ProductType} activeProductType
 * @returns {{ [key: string]: NavigationItem }}
 */
export const generatePreviousBillingCycleNavigationItems = (
  activeProductType: ProductType,
  previousBillingCyclesPeriods?: PreviousBillingCyclePeriod[]
): { [key: string]: NavigationItem } => {
  return (
    previousBillingCyclesPeriods?.reduce(
      (
        prev: {
          [key: string]: NavigationItem
        },
        curr: PreviousBillingCyclePeriod
      ) => {
        if (
          (activeProductType === ProductType.ELECTRICITY && curr.fuel?.electricity) ||
          (activeProductType === ProductType.GAS && curr.fuel?.gas)
        )
          prev[curr.id] = {
            type: NavigationItemType.CYCLE,
            text: `${dayjs(curr.startDate).format('DD/MM/YY')} - ${dayjs(curr.endDate).format('DD/MM/YYYY')}`,
            id: curr.id,
            fuel: curr.fuel
          } as NavigationItem
        return prev
      },
      {}
    ) || {}
  )
}

/**
 * Generates the showEstimatedUsage based on the given selectedNavigationOption, activeProductType & volumes
 *
 * @param {NavigationItem} selectedNavigationOption
 * @param {ProductType} activeProductType
 * @param {VolumeEntry[]} volumes
 * @returns {ShowEstimatedUsage}
 */
export const generateShowEstimatedUsage = (
  selectedNavigationOption: NavigationItem,
  activeProductType: ProductType,
  volumes?: VolumeEntry[]
): ShowEstimatedUsage => {
  return {
    all: selectedNavigationOption?.type === NavigationItemType.GRANULARITY,
    consumption: !!volumes?.some((entry) => entry.consumption.unbilled),
    injection: activeProductType === ProductType.ELECTRICITY && !!volumes?.some((entry) => entry.injection?.unbilled)
  }
}
