import styles from './PriceDisplay.module.scss'
import { formatAmount } from 'utils/format'
import { useTranslation } from 'react-i18next'
import { PriceDisplayProps } from './types'

const PriceDisplay = ({ amount, label }: PriceDisplayProps) => {
  const { t } = useTranslation()
  return (
    <div className={styles.price}>
      <span>&euro;</span>
      <strong>{formatAmount(amount)}</strong>
      <small>{label || t('perMonth', '/maand')}</small>
    </div>
  )
}

export default PriceDisplay
