import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialAppState } from 'store/app/initial'
import { switchLanguage } from './thunks'
import { UrlSearchParamsKeys } from 'store/app/types'

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    activateBetaMode: (state) => {
      return {
        ...state,
        betaMode: true
      }
    },
    saveRedirect: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        redirect: payload,
        error: null
      }
    },
    deleteRedirect: (state) => {
      return {
        ...state,
        redirect: null,
        error: null
      }
    },
    saveUrlSearchParam: (state, { payload }: PayloadAction<{ key: UrlSearchParamsKeys; value: string }>) => {
      return {
        ...state,
        urlSearchParams: {
          ...state.urlSearchParams,
          [payload.key]: payload.value
        }
      }
    }
  },
  extraReducers: (builder) => {
    // IS PENDING

    // IS FULFILLED
    builder.addCase(switchLanguage.fulfilled, (state, { payload }) => {
      state.language = payload.language
    })

    // IS REJECTED
  }
})

export const { activateBetaMode, saveRedirect, deleteRedirect, saveUrlSearchParam } = appSlice.actions

export default appSlice.reducer
