import { useMemo } from 'react'
import dayjs from 'dayjs'
import annotationPlugin, { CoreAnnotationOptions } from 'chartjs-plugin-annotation'
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js'
import { ConsumptionChartProps, ConsumptionWrapperProps } from './types'
import { MinMaxData } from 'components/Charts/types'
import { getAlternatingBackground } from 'utils/chartjs'
import { getConsumptionChartDatasets } from './chartjs.config'
import { calculateMinMaxConsumptionData, getMemoizedHistoryModifier } from './utils'
import ConsumptionChartDesktop from './ChartDesktop'
import ConsumptionChartMobile from './ChartMobile'
import ChartLegend from 'components/Charts/ChartLegend/ChartLegend.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { getMemoizedSelectedContracts } from 'utils/contracts.ts'
import { ProductType } from 'types/types.ts'
import { yScaleTitle } from 'components/Charts/plugins.ts'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, Tooltip, annotationPlugin, yScaleTitle)

const ConsumptionChart = ({
  activeProductType,
  data,
  isFetchingVolumes,
  granularity,
  hasInjection,
  isTablet,
  showEstimatedUsage,
  hidePriceHistory
}: ConsumptionWrapperProps) => {
  // Redux
  const { selectedContracts } = useStoreSelector((store) => store.user)

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // Memoized constants
  const priceHistoryModifier = getMemoizedHistoryModifier({ dataset: data, type: activeProductType })
  const months = useMemo<string[]>(() => data.map((consumption) => dayjs(consumption.datetime).toISOString()), [data])
  const minMaxData = useMemo<MinMaxData>(
    () => calculateMinMaxConsumptionData(data, activeProductType, priceHistoryModifier),
    [data, activeProductType, priceHistoryModifier]
  )
  const alternatingBackground = useMemo<CoreAnnotationOptions>(() => getAlternatingBackground(months), [months])

  const chartData = useMemo<any>(
    () => ({
      labels: months,
      datasets: getConsumptionChartDatasets({
        labels: months,
        data,
        type: activeProductType,
        showInjection: hasInjection,
        showEstimatedUsage,
        historyModifier: priceHistoryModifier,
        hideHistory:
          hidePriceHistory || data.every((entry) => !entry.history[activeProductType === ProductType.GAS ? 'gas' : 'electricity']),
        hasSmartMeter: !!electricityContract.smartMeter
      })
    }),
    [
      months,
      data,
      activeProductType,
      hasInjection,
      showEstimatedUsage,
      priceHistoryModifier,
      hidePriceHistory,
      electricityContract.smartMeter
    ]
  )

  const chartProps: ConsumptionChartProps = {
    activeProductType,
    alternatingBackground,
    chartData,
    isFetchingVolumes,
    labels: months,
    minMaxData,
    priceHistoryModifier,
    showEstimatedUsage,
    granularity
  }

  return isTablet ? (
    <ConsumptionChartMobile {...chartProps}>
      <ChartLegend {...{ chartData }} />
    </ConsumptionChartMobile>
  ) : (
    <ConsumptionChartDesktop {...chartProps}>
      <ChartLegend {...{ chartData }} />
    </ConsumptionChartDesktop>
  )
}

export default ConsumptionChart
