import { ContractTypeIconProps } from './types'
import ElecricityIcon from 'components/Icon/ContractTypeIcon/Icons/Electricity'
import GasIcon from 'components/Icon/ContractTypeIcon/Icons/Gas'
import { ProductType } from 'types/types.ts'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover.tsx'
import styles from './ContractTypeIcon.module.scss'
import { useTranslation } from 'react-i18next'

const ContractTypeIcon = ({ type, active = false }: ContractTypeIconProps) => {
  const { t } = useTranslation()

  return (
    <TooltipOnHover tooltipContent={t(type)} tooltipClassname={styles['tooltip-wrapper']}>
      {type === ProductType.ELECTRICITY ? <ElecricityIcon {...{ active }} /> : <GasIcon {...{ active }} />}
    </TooltipOnHover>
  )
}

export default ContractTypeIcon
