import { BadgeColors } from 'components/Badge/types.ts'
import { HeadingProps } from '@boltenergy-be/design-system/dist/components/heading/types'
import { PropsWithChildren } from 'react'

export type AvailableCardTags = 'div' | 'section' | 'article' | 'aside' | 'header' | 'nav' | 'button'
export type CardProps<Tag extends keyof JSX.IntrinsicElements = 'div'> = {
  badge?: {
    text: string
    color: BadgeColors
  }
  border?: boolean
  image?: {
    src: string
    alt: string
    className?: string
  }
  as?: keyof Pick<JSX.IntrinsicElements, Tag>
  variant?: CardVariants
  color?: CardColors
  padding?: 500 | 600
} & JSX.IntrinsicElements[Tag]

export type CardTitleProps = PropsWithChildren<Partial<Pick<HeadingProps<'div'>, 'as' | 'variant' | 'className' | 'weight'>>>

export type CardHeaderProps = PropsWithChildren<JSX.IntrinsicElements['header']>

export enum CardVariants {
  DEFAULT = 'default',
  DEFAULT_MORE_PADDING = 'default-more-padding',
  OUTLINED = 'outlined',
  SUBDUED = 'subdued'
}

export enum CardColors {
  PINK = 'pink'
}
