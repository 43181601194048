import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { routes } from 'types/routes.ts'
import { useNavigate } from 'react-router-dom'
import { AddContractSteps } from 'features/contracts/add/types.ts'
import { FormButtonsProps } from 'features/contracts/components/form-buttons/types.ts'
import styles from 'features/contracts/components/form-buttons/FormButtons.module.scss'
import { Button } from '@boltenergy-be/design-system'

import { UserTypes } from 'store/auth/types.ts'
import mixpanel from 'mixpanel-browser'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds.ts'
import { useLocation } from 'react-router'
import { TERMINATE_FLOW } from 'features/contracts/terminate/constants.ts'
import { ADD_CONTRACT_FLOW, MOVE_CONTRACT_FLOW } from 'features/contracts/add/constants.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { TerminateSteps } from 'features/contracts/terminate/types.ts'

const FormButtons = ({
  currentStep,
  subStep,
  loading = false,
  setCurrentStep,
  submitDisabled = false,
  onSubmit,
  onPrevious,
  trackingId
}: FormButtonsProps) => {
  // Redux
  const { userType } = useStoreSelector((store) => store.auth)
  const { move } = useStoreSelector((store) => store.user)
  const isSales = useMemo<boolean>(() => userType === UserTypes.SALES, [userType])

  // i18n
  const { t } = useTranslation()

  // React Router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Constants
  const steps =
    pathname === routes.CONTRACTS_TERMINATE ? TERMINATE_FLOW : pathname === routes.CONTRACTS_MOVE ? MOVE_CONTRACT_FLOW : ADD_CONTRACT_FLOW

  /**
   * Returns previous step based on the current step
   *
   * @param {string} currentStep
   * @return {string}
   */
  const getPreviousStep = (currentStep: AddContractSteps | TerminateSteps): AddContractSteps | TerminateSteps => {
    const currentIndex = steps.findIndex((step) => step === currentStep)
    return currentIndex - 1 >= 0 ? steps[currentIndex - 1] : steps[currentIndex]
  }

  return (
    <div className={styles['form-footer']}>
      <Button
        type="button"
        variant="tertiary"
        leadingIcon="chevronLeft"
        onClick={() => {
          mixpanel.track(MX_CONTRACT_FLOW.previousStep, { flow: move.flow, type: trackingId, currentStep: subStep || currentStep })
          if (currentStep === AddContractSteps.ADDRESS || currentStep === TerminateSteps.CHURN) {
            if (isSales) {
              navigate(routes.SALES_HOME)
            } else {
              navigate(-1)
            }
          } else {
            if (onPrevious) {
              onPrevious()
            } else {
              setCurrentStep(getPreviousStep(currentStep))
            }
          }
        }}
      >
        {t('back')}
      </Button>

      <Button
        type="submit"
        disabled={submitDisabled}
        loading={loading}
        onClick={() => {
          if (trackingId)
            mixpanel.track(MX_CONTRACT_FLOW.nextStep, { flow: move.flow, type: trackingId, currentStep: subStep || currentStep })
          if (onSubmit) onSubmit()
        }}
      >
        {currentStep === AddContractSteps.CONFIRMATION ? t('confirm') : t('next')}
      </Button>
    </div>
  )
}

export default FormButtons
