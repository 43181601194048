import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import classNames from 'classnames'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DatePicker, { registerLocale } from 'react-datepicker'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import Toggle from 'components/Toggle/Toggle'
import { dateToString, getStartDateRange, isValidEAN } from 'utils/addContract'
import { Store } from 'store/types'
import { Language } from 'store/app/types'
import {
  AddContractStepProps,
  AddContractSteps,
  CounterTypes,
  EanCaptureType,
  MeterDataFormInputs,
  MeterType,
  Situation,
  StartDateRange
} from '../../types.ts'
import { DATE_FORMAT_REACT_DATEPICKER } from 'constants/constants'
import contractsStyles from '../../../Contracts.module.scss'
import nl from 'date-fns/locale/nl-BE'
import fr from 'date-fns/locale/fr'
import { getEanData } from 'api/addContract'
import { UserTypes } from 'store/auth/types'
import { Contract, ContractStatus, EnergyType } from 'types/types'
import { Button } from '@boltenergy-be/design-system'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import Card from 'components/Card/Card.tsx'
import { Banner, Heading } from '@boltenergy-be/design-system'
import { Region } from 'api/types.ts'
import { checkHasMeterAndNumberInputs } from 'utils/contracts.ts'
import 'dayjs/locale/nl'
import 'dayjs/locale/fr'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import DocumentUpload from 'features/contracts/components/document-upload/DocumentUpload.tsx'
import PreviousInhabitantForm from '../../components/PreviousInhabitantForm/PreviousInhabitantForm.tsx'

// Day.js
dayjs.extend(localeData)
dayjs.extend(isBetween)

const MeterDetailsStep = ({ isMove, addContractData, setCurrentStep, setNextStep, eanLookupData }: AddContractStepProps) => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)
  const { userType } = useSelector((store: Store) => store.auth)
  const { customers } = useSelector((store: Store) => store.user)

  // i18n
  const { t } = useTranslation('contracts')

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [showContractStartDatePicker, setShowContractStartDatePicker] = useState<boolean>(
    !!addContractData.meterDetails.customContractStartDate
  )
  const [switchDate, setSwitchDate] = useState<string>()
  const [startDateRange, setStartDateRange] = useState<StartDateRange>(getStartDateRange(isMove ? Situation.MOVE : Situation.SWITCH))
  const [gasEanAtElectricityEanField, setGasEanAtElectricityEanField] = useState<boolean>(false)
  const [electricityEanAtGasEanField, setElectricityEanAtGasEanField] = useState<boolean>(false)

  // Locale for React Datepicker
  registerLocale(language, language === Language.NL ? nl.nlBE : fr.fr)

  // Load the correct locale for Day.js
  useEffect(() => {
    dayjs.locale(language === Language.NL ? 'nl' : 'fr')
  }, [language])

  // React Hook Form
  const hookForm = useForm<MeterDataFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      ...addContractData[AddContractSteps.METER_DETAILS],
      situation: isMove ? Situation.MOVE : Situation.SWITCH,
      docUpload: addContractData[AddContractSteps.METER_DETAILS].docUpload ?? null
    }
  })
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = hookForm
  const watchContractStartDate = watch('contractStartDate')
  const watchCounterType = watch('counterType')
  const watchEanCaptureType = watch('eanCaptureType')
  const watchElectricityEan = watch('electricity.ean')
  const watchElectricityExclNightEan = watch('electricity.eanExclNight')
  const watchGasEan = watch('gas.ean')
  const watchHasExclNight = watch('hasExclNight')
  const watchHasExclNightEan = watch('hasExclNightEan')
  const watchHasSolarPanels = watch('hasSolarPanels')
  const watchMeterType = watch('meterType')
  const watchSituation = watch('situation')
  const watchDynamicTariff = watch('dynamicTariff')
  const watchWalloniaSolarPanelsSince2024 = watch('walloniaSolarPanelsSince2024')

  // Constants
  const { initial, min, max } = startDateRange
  const showMeterReadAndNumberInputs = checkHasMeterAndNumberInputs({
    situation: watchSituation,
    counterType: watchCounterType,
    contractStartDate: watchContractStartDate
  })
  const { needsGas, region } = addContractData[AddContractSteps.ADDRESS]
  const isSuperUser = userType === UserTypes.SUPER_USER
  const isMeterReadingMonthVisible =
    watchHasSolarPanels && watchSituation === Situation.SWITCH
      ? region === Region.WALLONIA
        ? true
        : watchCounterType === CounterTypes.ANALOG
      : false

  // Date constants
  const maxDate = switchDate && dayjs(switchDate).isAfter(max) ? switchDate : max
  const minDate = switchDate && dayjs(switchDate).isBefore(min) ? switchDate : min

  /**
   * Sets the sitation state to the one in the addContractData state to persist state when going back
   */
  useEffect(() => {
    if (addContractData[AddContractSteps.METER_DETAILS].situation === Situation.SWITCH && isMove) {
      setValue('situation', Situation.MOVE)
    } else {
      setValue('situation', addContractData[AddContractSteps.METER_DETAILS].situation)
    }
  }, [addContractData, isMove, setValue])

  /**
   * Reset contract start date to initial when situation is new connection or switch
   */
  useEffect(() => {
    if (watchSituation === Situation.NEW_CONNECTION || watchSituation === Situation.SWITCH) {
      setValue('contractStartDate', initial.toISOString())
    }
  }, [initial, setValue, watchSituation])

  /**
   * Checks if the given contract start date is between the min & max allowed dates
   *
   * @param {Date} contractStartDate
   * @returns boolean
   */
  const contractStartDateIsBetweenMinAndMax = (contractStartDate: string) => {
    const startDate = dayjs(minDate).subtract(1, 'day')
    const endDate = dayjs(maxDate).add(1, 'day')

    return dayjs(contractStartDate).isBetween(startDate, endDate, 'day')
  }

  /**
   * Checks if the given EAN is already used
   *
   * @param {string} ean
   * @returns {boolean}
   */
  const eanIsAlreadyUsed = (ean: string): boolean => {
    return Object.values(customers)
      .map((customer) => customer.contracts)
      .flat(2)
      .some(
        (contract: Contract | undefined) =>
          // Check if contract is defined (can be undefined due to .flat(2))
          !!contract &&
          [
            ContractStatus.ACTIVE,
            ContractStatus.EFFECTIVE,
            ContractStatus.ILC_ONGOING,
            ContractStatus.INACTIVE,
            ContractStatus.REJECTED
          ].includes(contract.detail.status) &&
          contract.ean === ean
      )
  }

  /**
   * Handles the situation change & sets the new contract start date
   */
  useEffect(() => {
    const newStartDateRange = getStartDateRange(watchSituation)
    setStartDateRange(newStartDateRange)
    setValue('contractStartDate', newStartDateRange.initial.toISOString())
  }, [setValue, watchSituation])

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {MeterDataFormInputs} data
   */
  const onSubmit = async (data: MeterDataFormInputs) => {
    setLoading(true)
    setSwitchDate('')

    try {
      // Only check EAN data if the user knows the meter details
      const contractStartDate = dayjs(data.contractStartDate).format('YYYY-MM-DD')

      // Fetch the EAN data
      const [electricityEanData, gasEanData] = await Promise.all([
        getEanData(EnergyType.ELECTRICITY, data.electricity.ean.toString(), contractStartDate),
        ...(data.gas?.ean ? [getEanData(EnergyType.GAS, data.gas.ean.toString(), contractStartDate)] : [])
      ])

      if (electricityEanData) {
        // Three scenarios to be blocked
        // 1. Gas EAN filled in at electricity
        const gasEanAtElec = electricityEanData.energyType === EnergyType.GAS
        // 2. Electricity EAN filled in at gas
        const elecEanAtGas = gasEanData?.energyType === EnergyType.ELECTRICITY
        // 3. Invalid contract start date & no document uploaded
        const contractStartDateIsInvalid = electricityEanData.invalidContractStartDate && (!data.docUpload || data.docUpload?.length === 0)

        setGasEanAtElectricityEanField(gasEanAtElec)
        setElectricityEanAtGasEanField(elecEanAtGas)
        if (electricityEanData.switchDate && contractStartDateIsInvalid) {
          setSwitchDate(electricityEanData.switchDate)
          if (!isMove) {
            setValue('situation', Situation.MOVE)
          }
          // Scroll to top (where form is located)
          window.scrollTo(0, 0)
        }

        if (!gasEanAtElec && !elecEanAtGas && !contractStartDateIsInvalid) {
          saveDataAndGoToNextStep(data)
        }
      } else {
        saveDataAndGoToNextStep(data)
      }
    } catch (error) {
      log({ error: error as Error, identifier: `[${Flow.CONTRACT}:submitMeterDetails]` })
    } finally {
      setLoading(false)
    }
  }

  /**
   * Saves the data & goes to the next step
   *
   * @param {MeterDataFormInputs} data
   */
  const saveDataAndGoToNextStep = (data: MeterDataFormInputs) => {
    if (showContractStartDatePicker) {
      // Add custom key to data object to save "custom contract start date" state
      data.customContractStartDate = true
    } else {
      delete data.customContractStartDate
    }

    // Delete docUpload if situation is not move or if situation is move but no doc is selected
    if (data.situation !== Situation.MOVE || (data.situation === Situation.MOVE && data.docUpload?.length === 0)) {
      delete data.docUpload
    }

    setNextStep(data, AddContractSteps.CUSTOMER_DATA)
  }

  /**
   * Updates the Ean Capture Type input to "Overwritten by customer"
   */
  const updateEanCaptureTypeToOverwrittenIfNecessary = () => {
    if (watchEanCaptureType === EanCaptureType.PREFILLED) {
      setValue('eanCaptureType', EanCaptureType.OVERWRITTEN)
    }
  }

  /**
   * Handles the meter reading month select
   *
   * @param {number} selectedMonthIndex
   */
  const handleMeterReadingMonthSelectChange = (selectedMonthIndex: number): void => {
    if (!isNaN(selectedMonthIndex)) {
      // Define the start date
      let startDate = dayjs()
        .month(selectedMonthIndex - 1)
        .date(15)
        .hour(12)

      // Check if start date is in the future and add a year if not
      if (startDate.isBefore(dayjs())) startDate = startDate.add(1, 'year')

      // Show the contract start date picker
      setShowContractStartDatePicker(true)

      // Set the value in RHF values
      setValue('contractStartDate', startDate.toISOString())
    }
  }

  /**
   * Add EAN lookup data to form data
   */
  useEffect(() => {
    if (eanLookupData) {
      const { electricity, gas } = eanLookupData

      if (electricity || gas) {
        setValue('eanCaptureType', EanCaptureType.PREFILLED)

        if (electricity) setValue('electricity.ean', electricity)
        if (gas) setValue('gas.ean', gas)
      } else {
        setValue('eanCaptureType', EanCaptureType.NOT_FOUND)
      }
    }
  }, [eanLookupData])

  return (
    <form className={contractsStyles['form-card']} onSubmit={handleSubmit(onSubmit)}>
      <div className={contractsStyles['form-content']}>
        <Card.Title as="h1" className={contractsStyles.title}>
          {t('add.steps.meterDetails.title')}
        </Card.Title>

        <div className="row">
          {/* MY SITUATION */}
          <div className="column form-group mt-none">
            {isMove ? (
              <>
                <h3 className={contractsStyles['label-large']}>{t('add.steps.meterDetails.form.situation.moveLabel')}</h3>
                <Toggle
                  active={watchSituation}
                  options={[
                    { value: isMove ? Situation.MOVE : Situation.SWITCH, label: t('yes', { ns: 'common' }) },
                    { value: Situation.NEW_CONNECTION, label: t('no', { ns: 'common' }) }
                  ]}
                  onClick={(value) => setValue('situation', value)}
                />
              </>
            ) : (
              <fieldset className={contractsStyles['radio-buttons-container']}>
                <Heading as="h3" className={contractsStyles['label-large']}>
                  {t('add.steps.meterDetails.form.situation.addLabel')}
                </Heading>

                {/* SWITCH */}
                <label>
                  <input
                    type="radio"
                    id="situation"
                    checked={watchSituation === Situation.SWITCH}
                    value={Situation.SWITCH}
                    onChange={(e) => setValue('situation', e.currentTarget.value as Situation)}
                  />
                  {t(`add.steps.meterDetails.form.situation.options.${Situation.SWITCH}`)}
                </label>

                {/* MOVE */}
                <label>
                  <input
                    type="radio"
                    id="situation"
                    checked={watchSituation === Situation.MOVE}
                    value={Situation.MOVE}
                    onChange={(e) => setValue('situation', e.currentTarget.value as Situation)}
                  />
                  {t(`add.steps.meterDetails.form.situation.options.${Situation.MOVE}`)}
                </label>

                {/* NEW CONNECTION */}
                <label>
                  <input
                    type="radio"
                    id="situation"
                    checked={watchSituation === Situation.NEW_CONNECTION}
                    value={Situation.NEW_CONNECTION}
                    onChange={(e) => setValue('situation', e.currentTarget.value as Situation)}
                  />
                  {t(`add.steps.meterDetails.form.situation.options.${Situation.NEW_CONNECTION}`)}
                </label>
              </fieldset>
            )}
          </div>
        </div>

        {/* METER READING MONTH */}
        {isMeterReadingMonthVisible && (
          <div className="row">
            <fieldset className="column form-group">
              <Heading as="h3" className={contractsStyles['label-large']}>
                {t('add.steps.meterDetails.form.meterReadingMonth.label')}
              </Heading>

              <select
                className={classNames(contractsStyles['metering-month'], 'is-block', { error: 'meterReadingMonth' in errors })}
                {...register('meterReadingMonth', { required: true })}
                onChange={(e) => handleMeterReadingMonthSelectChange(parseInt(e.currentTarget.value))}
              >
                <option value="" disabled>
                  {t('add.steps.meterDetails.form.meterReadingMonth.select')}
                </option>
                {dayjs.months().map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>

              {errors.meterReadingMonth && errors.meterReadingMonth.type === 'required' && (
                <p className="text-negative">{t('required', { ns: 'validation' })}</p>
              )}

              <p className="mt-100">{t('add.steps.meterDetails.form.meterReadingMonth.explanation')}</p>
            </fieldset>
          </div>
        )}

        <div className="row">
          <fieldset className="column form-group">
            <Heading as="h3" className={contractsStyles['label-large']}>
              {t('add.steps.meterDetails.form.contractStartDate.label')}
            </Heading>

            {/* CONTRACT START DATE */}
            {!showContractStartDatePicker || watchSituation === Situation.NEW_CONNECTION ? (
              <p className={contractsStyles.description}>
                {watchSituation === Situation.NEW_CONNECTION ? (
                  t('add.steps.meterDetails.form.contractStartDate.values.newConnection')
                ) : (
                  <>
                    {parse(
                      t('add.steps.meterDetails.form.contractStartDate.values.moveOrSwitch', {
                        initial: dateToString(initial)
                      })
                    )}
                    <Button
                      type="button"
                      representation="link"
                      className={contractsStyles.link}
                      onClick={() => setShowContractStartDatePicker(true)}
                    >
                      {t('edit', { ns: 'common' })}
                    </Button>
                  </>
                )}
              </p>
            ) : (
              <div className="date-picker-container">
                <div className="date-picker-element">
                  <Controller
                    name="contractStartDate"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => (value ? contractStartDateIsBetweenMinAndMax(value) : false)
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          placeholderText={t('add.steps.meterDetails.form.contractStartDate.placeholder')}
                          locale={language}
                          selected={dayjs(value ?? initial).toDate()}
                          disabledKeyboardNavigation
                          maxDate={new Date(maxDate)}
                          minDate={new Date(minDate)}
                          dateFormat={DATE_FORMAT_REACT_DATEPICKER}
                          className="form-control"
                          calendarClassName="date-picker-calendar"
                          onChange={(date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
                          calendarStartDay={1}
                        />
                      )
                    }}
                  />
                </div>
                <Button
                  type="button"
                  representation="link"
                  className={classNames(contractsStyles.link, contractsStyles['no-margin'])}
                  onClick={() => {
                    setValue('contractStartDate', initial.toISOString()) // Reset contract start date back to initial
                    setShowContractStartDatePicker(false)
                  }}
                >
                  {t('add.steps.meterDetails.form.contractStartDate.standard')}
                </Button>
              </div>
            )}

            {watchSituation === Situation.MOVE && showContractStartDatePicker && switchDate !== watchContractStartDate && (
              <Banner type="informative" className={contractsStyles['info-block-container']}>
                {t('add.steps.meterDetails.form.contractStartDate.attentionMove')}
              </Banner>
            )}

            {switchDate && switchDate !== watchContractStartDate && userType === UserTypes.SALES && watchSituation === Situation.MOVE ? (
              <Banner className={contractsStyles['overlap-warning']} type="warning">
                <span className={contractsStyles['warning-content']}>
                  {parse(t('add.steps.meterDetails.form.previousInhabitant.warning', { date: dayjs(switchDate).format('DD/MM/YYYY') }))}
                </span>
              </Banner>
            ) : (
              switchDate &&
              switchDate !== watchContractStartDate && (
                <div className={contractsStyles['info-block-container']}>
                  <span className={classNames('help-block text-negative')}>
                    {parse(
                      t('add.steps.meterDetails.form.contractStartDate.wrongSwitchDate', {
                        date: dayjs(switchDate).format('DD/MM/YYYY')
                      })
                    )}
                  </span>
                </div>
              )
            )}
          </fieldset>
        </div>

        {/* PREVIOUS INHABITANT */}
        {switchDate && switchDate !== watchContractStartDate && watchSituation === Situation.MOVE && userType === UserTypes.SALES && (
          <FormProvider {...hookForm}>
            <PreviousInhabitantForm />
          </FormProvider>
        )}

        {/* DOC UPLOAD */}
        {watchSituation === Situation.MOVE && (
          <FormProvider {...hookForm}>
            <DocumentUpload bigLabel />
          </FormProvider>
        )}

        <div className="row">
          {/* HAS SOLAR PANELS */}
          <fieldset className="column form-group">
            <Heading as="h3" className={contractsStyles['label-large']}>
              {t('add.steps.meterDetails.form.hasSolarPanels')}
            </Heading>

            <Toggle
              active={watchHasSolarPanels}
              options={[
                { value: true, label: t('yes', { ns: 'common' }) },
                { value: false, label: t('no', { ns: 'common' }) }
              ]}
              onClick={(value) => setValue('hasSolarPanels', value)}
            />
          </fieldset>
        </div>

        <div className="row">
          {/* COUNTER TYPE */}
          <fieldset className="column form-group">
            <Heading as="h3" className={contractsStyles['label-large']}>
              {t('add.steps.meterDetails.form.counterType')}
            </Heading>
            <Toggle
              active={watchCounterType}
              options={[
                { value: CounterTypes.DIGITAL, label: t('yes', { ns: 'common' }) },
                { value: CounterTypes.ANALOG, label: t('no', { ns: 'common' }) }
              ]}
              onClick={(value) => {
                setValue('counterType', value)
                if (value === CounterTypes.ANALOG) {
                  setValue('dynamicTariff', false)
                  if (addContractData['address'].region === Region.WALLONIA) {
                    setValue('walloniaSolarPanelsSince2024', false)
                  }
                }
              }}
            />
          </fieldset>
        </div>

        {/* DYNAMIC TARIFF */}
        {watchCounterType === CounterTypes.DIGITAL && addContractData['address'].region === Region.FLANDERS && !watchHasExclNight && (
          <div className="row">
            <fieldset className="column form-group">
              <Heading as="h3" className={contractsStyles['label-large']}>
                {t('add.steps.meterDetails.form.dynamicTariff.label', 'Wil je graag dynamische prijzen?')}
              </Heading>

              <Toggle
                className="mb-400"
                active={watchDynamicTariff}
                options={[
                  { label: t('common:yes', 'Ja'), value: true },
                  { label: t('common:no', 'Nee'), value: false }
                ]}
                onClick={(value) => setValue('dynamicTariff', value)}
              />
              <p>
                {t(
                  'add.steps.meterDetails.form.dynamicTariff.description',
                  'Met de optie dynamische prijzen zal je elektriciteitsverbruik volgens de marktprijs per uur afgerekend worden. Door je energieverbruik goed te plannen, kan je besparen. Je kan dit op elk moment stopzetten.'
                )}{' '}
                <a
                  href={
                    language === Language.NL ? 'https://boltenergie.be/dynamische-prijzen' : 'https://www.boltenergie.be/fr/prix-dynamiques'
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('readMore', { ns: 'common' })}.
                </a>
              </p>
            </fieldset>
          </div>
        )}

        <hr className={contractsStyles.divider} />

        <fieldset>
          <Heading as="h3" variant="h5">
            {t('add.steps.meterDetails.form.electricity.title')}
          </Heading>

          <div className="row">
            <div className="column form-group">
              <div className={contractsStyles['checkboxes-container']}>
                {/* ELECTRICITY METER TYPE */}
                <div className={contractsStyles['checkbox-container']}>
                  <input
                    type="checkbox"
                    id="meterType"
                    checked={watchMeterType === MeterType.DOUBLE_RATE}
                    onChange={(e) => setValue('meterType', e.currentTarget.checked ? MeterType.DOUBLE_RATE : MeterType.SINGLE_RATE)}
                  />
                  <label htmlFor="meterType">{t('add.steps.meterDetails.form.electricity.meterType')}</label>
                </div>

                {/* HAS EXCLUSIVE NIGHT METER (LOCAL STATE) */}
                <div className={contractsStyles['checkbox-container']}>
                  <input
                    type="checkbox"
                    id="hasExclNight"
                    checked={watchHasExclNight}
                    onChange={(e) => setValue('hasExclNight', e.currentTarget.checked)}
                  />
                  <label htmlFor="hasExclNight">{t('add.steps.meterDetails.form.electricity.hasExclNight')}</label>
                </div>

                {/* WALLONIA SOLAR PANELS INSTALLED SINCE 2024*/}
                {addContractData['address'].region === Region.WALLONIA &&
                  watchCounterType === CounterTypes.DIGITAL &&
                  watchHasSolarPanels && (
                    <div className={contractsStyles['checkbox-container']}>
                      <input
                        type="checkbox"
                        id="walloniaHasSolarPanelsSince2024"
                        checked={watchWalloniaSolarPanelsSince2024}
                        onChange={(e) => setValue('walloniaSolarPanelsSince2024', e.currentTarget.checked)}
                      />
                      <label htmlFor="walloniaHasSolarPanelsSince2024">
                        {t('add.steps.meterDetails.form.electricity.walloniaSolarPanelsSince2024') ||
                          'solar panels installed after 01/01/2024'}
                      </label>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="row">
            {/* ELECTRICITY EAN */}
            <div className="form-group fullwidth">
              <label htmlFor="electricityEan">{t('add.steps.meterDetails.form.electricity.EAN')}</label>
              <p>{t('add.steps.meterDetails.form.eanInfo')}</p>
              <input
                id="electricityEan"
                {...register('electricity.ean', {
                  required: true,
                  validate: {
                    valid: (value) => isValidEAN(value as string),
                    ...(!isSuperUser && {
                      notAlreadyUsed: (value) => !eanIsAlreadyUsed(value as string)
                    })
                  }
                })}
                className={classNames('form-control', { error: errors?.electricity?.ean })}
                placeholder="54144..."
                inputMode="numeric"
                onChange={() => {
                  setGasEanAtElectricityEanField(false)
                  updateEanCaptureTypeToOverwrittenIfNecessary()
                }}
              />
              {errors && errors.electricity?.ean && (
                <span className="help-block text-negative">
                  {errors.electricity.ean.type === 'required' && t('required', { ns: 'validation' })}
                  {errors.electricity.ean.type === 'valid' && t('invalid.EAN', { ns: 'validation' })}
                  {errors.electricity.ean.type === 'notAlreadyUsed' && t('invalid.EANAlreadyUsed', { ns: 'validation' })}
                </span>
              )}
              {watchEanCaptureType === EanCaptureType.PREFILLED && !!watchElectricityEan && (
                <span className="help-block text-positive">{t('add.steps.meterDetails.form.eanPrefilled')}</span>
              )}
              {gasEanAtElectricityEanField && (
                <span className="help-block text-negative">{t('invalid.gasEanAtElectricityEanField', { ns: 'validation' })}</span>
              )}
              {isSuperUser && eanIsAlreadyUsed(watchElectricityEan as string) && (
                <span className="help-block text-warning">{t('invalid.EANAlreadyUsed', { ns: 'validation' })}</span>
              )}
            </div>
          </div>

          {showMeterReadAndNumberInputs && (
            <>
              <div className="row">
                {watchMeterType === MeterType.SINGLE_RATE ? (
                  <>
                    {/* ELECTRICITY METER READING TOTAL (SINGLE RATE) */}
                    <div className="column form-group">
                      <label htmlFor="electricityTotalSingle">{t('add.steps.meterDetails.form.electricity.total')}</label>
                      <div className={contractsStyles['meter-input']}>
                        <input
                          id="electricityTotalSingle"
                          {...register('electricity.total', {
                            required: true,
                            validate: {
                              isNotEan: (value: number | null) => !!value && !isValidEAN(value.toString(10)),
                              valid: (value: number | null) => !!value && value > 0
                            }
                          })}
                          className={classNames('form-control', { error: errors?.electricity?.total })}
                          inputMode="numeric"
                        />
                        <div className={classNames(contractsStyles.postfix, { [contractsStyles.error]: errors?.electricity?.total })}>
                          ,000
                        </div>
                      </div>
                      {errors.electricity?.total && (
                        <span className="help-block text-negative">
                          {errors.electricity.total.type === 'isNotEan' && t('invalid.looksLikeEan', { ns: 'validation' })}
                          {errors.electricity.total.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.electricity.total.type === 'valid' && t('invalid.meterReading', { ns: 'validation' })}
                        </span>
                      )}
                    </div>
                    <div className="column" />
                  </>
                ) : (
                  <>
                    {/* ELECTRICITY METER READING DAY (DOUBLE RATE) */}
                    <div className="column form-group">
                      <label htmlFor="electricityTotalDoubleDay">{t('add.steps.meterDetails.form.electricity.day')}</label>
                      <div className={contractsStyles['meter-input']}>
                        <input
                          id="electricityTotalDoubleDay"
                          {...register('electricity.day', {
                            required: true,
                            validate: {
                              isNotEan: (value: number | null) => !!value && !isValidEAN(value.toString(10)),
                              valid: (value: number | null) => !!value && value > 0
                            }
                          })}
                          className={classNames('form-control', { error: errors?.electricity?.day })}
                          inputMode="numeric"
                        />
                        <div className={classNames(contractsStyles.postfix, { [contractsStyles.error]: errors?.electricity?.day })}>
                          ,000
                        </div>
                      </div>
                      {errors.electricity?.day && (
                        <span className="help-block text-negative">
                          {errors.electricity.day.type === 'isNotEan' && t('invalid.looksLikeEan', { ns: 'validation' })}
                          {errors.electricity.day.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.electricity.day.type === 'valid' && t('invalid.meterReading', { ns: 'validation' })}
                        </span>
                      )}
                    </div>

                    {/* ELECTRICITY METER READING NIGHT (DOUBLE RATE) */}
                    <div className="column form-group">
                      <label htmlFor="electricityTotalDoubleNight">{t('add.steps.meterDetails.form.electricity.night')}</label>
                      <div className={contractsStyles['meter-input']}>
                        <input
                          id="electricityTotalDoubleNight"
                          {...register('electricity.night', {
                            required: true,
                            validate: {
                              isNotEan: (value: number | null) => !!value && !isValidEAN(value.toString(10)),
                              valid: (value: number | null) => !!value && value > 0
                            }
                          })}
                          className={classNames('form-control', { error: errors?.electricity?.night })}
                          inputMode="numeric"
                        />
                        <div className={classNames(contractsStyles.postfix, { [contractsStyles.error]: errors?.electricity?.night })}>
                          ,000
                        </div>
                      </div>
                      {errors.electricity?.night && (
                        <span className="help-block text-negative">
                          {errors.electricity.night.type === 'isNotEan' && t('invalid.looksLikeEan', { ns: 'validation' })}
                          {errors.electricity.night.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.electricity.night.type === 'valid' && t('invalid.meterReading', { ns: 'validation' })}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {/* ELECTRICITY METER NUMBER */}
                <div className="column form-group">
                  <label htmlFor="electricityMeterNumber">{t('add.steps.meterDetails.form.electricity.meterNumber')}</label>
                  <input
                    id="electricityMeterNumber"
                    {...register('electricity.meterNumber', {
                      required: true,
                      validate: (value: number | null) => !!value && !isValidEAN(value.toString(10))
                    })}
                    className={classNames('form-control', { error: errors?.electricity?.meterNumber })}
                    inputMode="numeric"
                  />
                  {errors.electricity?.meterNumber && (
                    <span className="help-block text-negative">
                      {errors.electricity.meterNumber.type === 'required' && t('required', { ns: 'validation' })}
                      {errors.electricity.meterNumber.type === 'validate' && t('invalid.looksLikeEan', { ns: 'validation' })}
                    </span>
                  )}
                </div>
                <div className="column" />
              </div>

              {watchHasExclNight && (
                <>
                  <label className={classNames('mt-500', contractsStyles['checkbox-container'])}>
                    <input type="checkbox" checked={watchHasExclNightEan} onChange={(e) => setValue('hasExclNightEan', e.target.checked)} />
                    {t(
                      'add.steps.meterDetails.form.electricity.hasExclNightEanNumber',
                      'Ik heb een aparte EAN-nummer voor exclusief nacht'
                    )}
                  </label>
                  {watchHasExclNightEan && (
                    <div className="row">
                      {/* ELECTRICITY EXCL. NIGHT EAN NUMBER */}
                      <div className="form-group">
                        <label htmlFor="electricityEanExclNight">{t('add.steps.meterDetails.form.electricity.exclEAN')}</label>
                        <input
                          id="electricityEanExclNight"
                          {...register('electricity.eanExclNight', {
                            required: true,
                            validate: {
                              valid: (value) => (value ? isValidEAN(value as string) : true),
                              ...(!isSuperUser && {
                                notAlreadyUsed: (value) => (value ? !eanIsAlreadyUsed(value as string) : true)
                              })
                            }
                          })}
                          className={classNames('form-control', { error: errors?.electricity?.eanExclNight })}
                          placeholder="54144..."
                        />
                        {errors && errors.electricity?.eanExclNight && (
                          <span className="help-block text-negative">
                            {errors.electricity.eanExclNight.type === 'required' && t('required', { ns: 'validation' })}
                            {errors.electricity.eanExclNight.type === 'valid' && t('invalid.EAN', { ns: 'validation' })}
                            {errors.electricity.eanExclNight.type === 'notAlreadyUsed' && t('invalid.EANAlreadyUsed', { ns: 'validation' })}
                          </span>
                        )}
                        {isSuperUser && eanIsAlreadyUsed(watchElectricityExclNightEan as string) && (
                          <span className="help-block text-warning">{t('invalid.EANAlreadyUsed', { ns: 'validation' })}</span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="row">
                    {/* ELECTRICITY METER EXCL. NIGHT */}
                    <div className="column form-group">
                      <label htmlFor="electricityTotalExclNight">{t('add.steps.meterDetails.form.electricity.exclNight')}</label>
                      <div className={contractsStyles['meter-input']}>
                        <input
                          id="electricityTotalExclNight"
                          {...register('electricity.exclNight', {
                            required: true,
                            validate: {
                              isNotEan: (value: number | null) => !!value && !isValidEAN(value.toString(10)),
                              valid: (value: number | null) => !!value && value > 0
                            }
                          })}
                          className={classNames('form-control', { error: errors?.electricity?.exclNight })}
                          inputMode="numeric"
                        />
                        <div className={classNames(contractsStyles.postfix, { [contractsStyles.error]: errors?.electricity?.exclNight })}>
                          ,000
                        </div>
                      </div>
                      {errors && errors.electricity?.exclNight && (
                        <span className="help-block text-negative">
                          {errors.electricity?.exclNight.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.electricity?.exclNight.type === 'validate' && t('invalid.meterReading', { ns: 'validation' })}
                        </span>
                      )}
                      {errors.electricity?.exclNight && (
                        <span className="help-block text-negative">
                          {errors.electricity.exclNight.type === 'isNotEan' && t('invalid.looksLikeEan', { ns: 'validation' })}
                          {errors.electricity.exclNight.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.electricity.exclNight.type === 'valid' && t('invalid.meterReading', { ns: 'validation' })}
                        </span>
                      )}
                    </div>
                    <div className="column" />
                  </div>
                </>
              )}
            </>
          )}
        </fieldset>

        {needsGas && (
          <>
            <hr className={contractsStyles.divider} />

            <fieldset>
              <Heading as="h3" variant="h5">
                {t('add.steps.meterDetails.form.gas.title')}
              </Heading>

              <div className="row">
                {/* GAS EAN */}
                <div className="form-group fullwidth">
                  <label htmlFor="gas.ean">{t('add.steps.meterDetails.form.gas.EAN')}</label>
                  <p>{t('add.steps.meterDetails.form.eanInfoGas')}</p>
                  <input
                    id="gas.ean"
                    {...register('gas.ean', {
                      required: true,
                      validate: {
                        valid: (value) => isValidEAN(value as string),
                        ...(!isSuperUser && {
                          notAlreadyUsed: (value) => !eanIsAlreadyUsed(value as string)
                        })
                      }
                    })}
                    className={classNames('form-control', { error: errors?.gas?.ean })}
                    placeholder="54144..."
                    onChange={() => {
                      setElectricityEanAtGasEanField(false)
                      updateEanCaptureTypeToOverwrittenIfNecessary()
                    }}
                  />
                  {errors.gas?.ean && (
                    <span className="help-block text-negative">
                      {errors.gas.ean.type === 'required' && t('required', { ns: 'validation' })}
                      {errors.gas.ean.type === 'valid' && t('invalid.EAN', { ns: 'validation' })}
                      {errors.gas.ean.type === 'notAlreadyUsed' && t('invalid.EANAlreadyUsed', { ns: 'validation' })}
                    </span>
                  )}
                  {watchEanCaptureType === EanCaptureType.PREFILLED && !!watchGasEan && (
                    <span className="help-block text-positive">{t('add.steps.meterDetails.form.eanPrefilled')}</span>
                  )}
                  {electricityEanAtGasEanField && (
                    <span className="help-block text-negative">{t('invalid.electricityEanAtGasEanField', { ns: 'validation' })}</span>
                  )}
                  {isSuperUser && eanIsAlreadyUsed(watchGasEan as string) && (
                    <span className="help-block text-warning">{t('invalid.EANAlreadyUsed', { ns: 'validation' })}</span>
                  )}
                </div>
              </div>

              {showMeterReadAndNumberInputs && (
                <>
                  <div className="row">
                    {/* GAS METER READING TOTAL */}
                    <div className="column form-group">
                      <label htmlFor="gasTotal">{t('add.steps.meterDetails.form.gas.total')}</label>
                      <div className={contractsStyles['meter-input']}>
                        <input
                          id="gasTotal"
                          {...register('gas.total', {
                            required: true,
                            validate: {
                              isNotEan: (value: number | null) => !!value && !isValidEAN(value.toString(10)),
                              valid: (value: number | null) => !!value && value > 0
                            }
                          })}
                          className={classNames('form-control', { error: errors?.gas?.total })}
                          inputMode="numeric"
                        />
                        <div className={classNames(contractsStyles.postfix, { [contractsStyles.error]: errors?.gas?.total })}>,000</div>
                      </div>
                      {errors.gas?.total && (
                        <span className="help-block text-negative">
                          {errors.gas.total.type === 'isNotEan' && t('invalid.looksLikeEan', { ns: 'validation' })}
                          {errors.gas.total.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.gas.total.type === 'valid' && t('invalid.meterReading', { ns: 'validation' })}
                        </span>
                      )}
                    </div>
                    <div className="column" />
                  </div>

                  <div className="row">
                    {/* GAS METER NUMBER */}
                    <div className="column form-group">
                      <label htmlFor="gasMeterNumber">{t('add.steps.meterDetails.form.gas.meterNumber')}</label>
                      <input
                        id="gasMeterNumber"
                        {...register('gas.meterNumber', {
                          required: true,
                          validate: (value: number | null) => !!value && !isValidEAN(value.toString(10))
                        })}
                        className={classNames('form-control', { error: errors?.gas?.meterNumber })}
                        inputMode="numeric"
                      />
                      {errors.gas?.meterNumber && (
                        <span className="help-block text-negative">
                          {errors.gas.meterNumber.type === 'required' && t('required', { ns: 'validation' })}
                          {errors.gas.meterNumber.type === 'validate' && t('invalid.looksLikeEan', { ns: 'validation' })}
                        </span>
                      )}
                    </div>
                    <div className="column" />
                  </div>
                </>
              )}
            </fieldset>
          </>
        )}
      </div>

      <FormButtons
        trackingId={ContractFlowTrackingTypes.ADD}
        currentStep={AddContractSteps.METER_DETAILS}
        loading={loading}
        setCurrentStep={(step) => setCurrentStep(step as AddContractSteps)}
      />
    </form>
  )
}

export default MeterDetailsStep
