import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'
import SalesApp from 'pages/Sales/SalesApp'
import { getSalesUserData } from 'store/user/thunks/sales'
import { logout } from 'utils/app'
import { routes } from 'types/routes'
import { deleteRedirect } from 'store/app/slice'
import { UserTypes } from 'store/auth/types'
import { isAuthenticated } from 'utils/localStorageService'
import { setUserType } from 'utils/userType'
import { getUserTypeLoginPage } from 'utils/user'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'

const SalesContainer: FC = () => {
  // REDUX STORE
  const { redirect } = useStoreSelector((store) => store.app)
  const dispatch = useStoreDispatch()

  // React Router
  const navigate = useNavigate()

  // Auth constants
  const authenticatedUserTypes = isAuthenticated()

  /**
   * Handle things on first render
   */
  useEffect(() => {
    // Redirect to the regular portal or logout if the user is not a sales user
    if (!authenticatedUserTypes[UserTypes.SALES]) {
      logout(() => navigate(getUserTypeLoginPage(UserTypes.SALES)), UserTypes.SALES)
    }

    // Change the page title
    document.title = 'Bolt Sales Portal'

    // Set the user type
    setUserType(UserTypes.SALES)

    // Fetch the sales user data
    dispatch(getSalesUserData())
  }, [])

  useEffect(() => {
    if (redirect && redirect.includes('/sales')) {
      navigate(redirect)
    }
    // Clear redirect from AppStore
    dispatch(deleteRedirect())
  }, [redirect])

  /**
   * Handles the logout with correct redirect
   */
  const handleLogout = () => logout(() => navigate(routes.LOGIN_SALES))

  return (
    <SalesApp logout={handleLogout}>
      <Outlet />
    </SalesApp>
  )
}

export default SalesContainer
