import { FC, useEffect } from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { routes } from 'types/routes'
import { validateToken } from 'store/auth/thunks'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { useStoreDispatch } from 'hooks/store'

const ProcessToken: FC = () => {
  // REDUX
  const dispatch = useStoreDispatch()

  // React Router
  const { token } = useParams<{ token: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isPasswordResetTokenPage = matchPath({ path: routes.RESET_PASSWORD_TOKEN }, pathname)
  const isSalesPasswordResetTokenPage = matchPath({ path: routes.RESET_PASSWORD_TOKEN_SALES }, pathname)
  const isActivationTokenPage = matchPath({ path: routes.ACTIVATE_TOKEN }, pathname)
  const isSalesActivationTokenPage = matchPath({ path: routes.ACTIVATE_TOKEN_SALES }, pathname)

  const isActivationToken = !!isActivationTokenPage || !!isSalesActivationTokenPage
  const isPasswordReset = !!isPasswordResetTokenPage || !!isSalesPasswordResetTokenPage

  useEffect(() => {
    if (token && (isActivationToken || isPasswordReset)) {
      dispatch(
        validateToken({
          token,
          isActivation: isActivationToken,
          isSales: !!isSalesActivationTokenPage || !!isSalesPasswordResetTokenPage,
          navigateTo: (destination) => navigate(destination)
        })
      )
    }
  }, [])

  return <LoadingDots />
}

export default ProcessToken
