import { getConsumptionPlannerChartOptions } from 'components/Charts/ConsumptionPlanner/chartjs.config.ts'
import { Chart } from 'react-chartjs-2'
import { ConsumptionPlannerChartProps } from './types.ts'

const ConsumptionPlannerChartMobile = ({ chartData, labels }: ConsumptionPlannerChartProps) => {
  // Constants
  const options = getConsumptionPlannerChartOptions(labels, true)

  return <Chart type="bar" options={options as any} data={chartData} />
}

export default ConsumptionPlannerChartMobile
