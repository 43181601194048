import { ImageCmsModel } from 'types/cms'
import { Region } from 'api/types'
import { Language } from 'store/app/types'
import { BlocksContent } from '@strapi/blocks-react-renderer'
import { SourceType } from 'types/user.ts'

export enum Product {
  EARLY_BIRD_OFFLINE = 'EarlyBirdOff',
  EARLY_BIRD_ONLINE = 'EarlyBirdOn',
  VARIABLE_OFFLINE = 'VariableOff',
  VARIABLE_ONLINE = 'VariableOn',
  FIX = 'Fix',
  GO = 'Go'
}

export enum ELProduct {
  EarlyBirdOff = 'Variable Belpex Electricity - Early Bird Offline',
  EarlyBirdOn = 'Variable Belpex Electricity - Early Bird Online',
  Fix = 'Fix Electricity - Offline',
  VariableOff = 'Variable Belpex Electricity - Offline',
  VariableOn = 'Variable Belpex Electricity - Online',
  Go = 'Variable Belpex Electricity - Go'
}

export enum NGProduct {
  EarlyBirdOff = 'Variable TTF Gas - Early Bird Offline',
  EarlyBirdOn = 'Variable TTF Gas - Early Bird Online',
  Fix = 'Fix Gas - Offline',
  VariableOff = 'Variable TTF Gas - Offline',
  VariableOn = 'Variable TTF Gas - Online',
  Go = 'Variable TTF Gas - Go'
}

export enum ProductConfigOption {
  TYPE = 'type',
  PLATFORM_COST = 'platformCost',
  INSTALMENT_PERIODICITY = 'instalmentPeriodicity',
  DIRECT_DEBIT_MANDATORY = 'directDebitMandatory',
  CONTACT_OPTIONS = 'contactOptions',
  BILLING_OPTIONS = 'billingOptions',
  AVAILABLE_IN_REGIONS = 'availableInRegions'
}

export enum ProductTypes {
  VARIABLE = 'variable',
  FIX = 'fix'
}

export enum ProductInstalments {
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum ProductContactOptions {
  PHONE = 'phone',
  DIGITAL = 'digital'
}

export enum ProductBillingOptions {
  PAPER = 'paper',
  DIGITAL = 'digital'
}

export enum CustomerType {
  RESIDENTIAL = 'res',
  PROFESSIONAL = 'pro'
}

export type AllProducts = {
  electricity: {
    [key in ELProduct]: ProductData
  }
  gas: {
    [key in NGProduct]: ProductData
  }
}

export type ProductData = {
  details: ProductDetails
  pricelists: ProductPriceListUrls
}

export type ProductDetails = {
  id: string
  code: string
  description: string
  priceExplanation: string
  platformCost: number
  slug: string
  serverLocation: string
  version: number
}
export type ProductPriceListUrls = {
  [key in CustomerType]: PriceListUrls
}
export type PriceListUrls = {
  [key in Language]: string
}

export type ProductContent = {
  id: number
  description: string
  icon: ImageCmsModel
  name: string
  slug: Product
  recommended?: boolean
  perks: BlocksContent | null
  alternativePerks?: BlocksContent | null
  conditions: BlocksContent | null
}

export type ProductConfigOptions = {
  [ProductConfigOption.TYPE]: ProductTypes
  [ProductConfigOption.PLATFORM_COST]: {
    [CustomerType.PROFESSIONAL]: number
    [CustomerType.RESIDENTIAL]: number
  }
  [ProductConfigOption.INSTALMENT_PERIODICITY]: ProductInstalments
  [ProductConfigOption.DIRECT_DEBIT_MANDATORY]: boolean
  [ProductConfigOption.CONTACT_OPTIONS]: ProductContactOptions[]
  [ProductConfigOption.BILLING_OPTIONS]: ProductBillingOptions[]
  [ProductConfigOption.AVAILABLE_IN_REGIONS]: {
    [key in SourceType]: Region[]
  }
}
