import styles from './InfoCard.module.scss'
import { InfoCardProps } from './types'
import useWindowSize from 'hooks/useWindowSize'
import InfoCardContent from './InfoCardContent'
import ProducerSliderLayout from 'features/contracts/add/components/ProducerSliderLayout/ProducerSliderLayout'

export const InfoCard = (props: InfoCardProps) => {
  // Custom hooks
  const { isTablet } = useWindowSize()

  return isTablet ? (
    <ProducerSliderLayout {...props}>
      <InfoCardContent {...props} />
    </ProducerSliderLayout>
  ) : (
    <aside className={styles.container}>
      <InfoCardContent {...props} />
    </aside>
  )
}

export default InfoCard
