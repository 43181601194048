import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { DurationTimestampProps } from 'components/DurationTimestamp/types.ts'

const DurationTimeStamp = ({ startDate = dayjs(), endDate }: DurationTimestampProps): string => {
  // I18N
  const { t } = useTranslation('referral')

  // Constants
  const millisecondsPerHour = 1000 * 60 * 60
  const millisecondsPerDay = millisecondsPerHour * 24
  const remainingTime = dayjs(endDate).diff(dayjs(startDate))
  const remainingDays = Math.round(remainingTime / millisecondsPerDay)
  const remainingHours = Math.round((remainingTime % millisecondsPerDay) / millisecondsPerHour)

  return t('activeAction.timeLeft', {
    days: t(`activeAction.day.${remainingDays === 1 ? 'singular' : 'plural'}`, { remaining: remainingDays }),
    hours: t(`activeAction.hour.${remainingHours === 1 ? 'singular' : 'plural'}`, { remaining: remainingHours })
  })
}

export default DurationTimeStamp
