import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Steak: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M10.5 14.675a1.825 1.825 0 1 0 0 3.65 1.825 1.825 0 0 0 0-3.65ZM7.325 16.5a3.175 3.175 0 1 1 6.35 0 3.175 3.175 0 0 1-6.35 0Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M11.35 5.744c.966-2.015 2.732-3.494 4.872-3.494 1.662 0 3.11.9 4.117 2.242 1.006 1.342 1.606 3.161 1.606 5.139 0 2.587-1.03 4.925-2.674 6.255-.7.567-1.29 1.229-1.61 1.966a8.14 8.14 0 0 1-15.606-3.24 8.139 8.139 0 0 1 8.138-8.14c.485 0 .949-.292 1.158-.728Zm4.872-1.994c-1.386 0-2.717.967-3.518 2.642A2.809 2.809 0 0 1 10.61 7.94c.862.722 1.44 1.8 1.562 3.05a.75.75 0 0 1-1.492.147 2.957 2.957 0 0 0-3.016-2.666 6.618 6.618 0 0 0-1.02.528 3.18 3.18 0 0 1 2.107 2.356.75.75 0 1 1-1.467.313 1.682 1.682 0 0 0-1.996-1.295.749.749 0 0 1-.218.014 6.639 6.639 0 1 0 11.214 6.867c.452-1.039 1.237-1.882 2.042-2.533 1.23-.995 2.118-2.868 2.118-5.09 0-1.684-.513-3.18-1.306-4.239-.794-1.058-1.832-1.642-2.917-1.642Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Steak.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Steak
