import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { SalesEvent } from 'types/sales'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

/**
 * Filters the given events
 *
 * @param {SalesEvent[]} events
 * @returns {SalesEvent[]}
 */
export const filterAndSortEvents = (events: SalesEvent[]): SalesEvent[] => {
  const today = dayjs()

  return events
    .filter((event) => {
      if (!event.availabilityStartDate && !event.availabilityEndDate) return true

      if (!event.availabilityStartDate && event.availabilityEndDate) return today.isSameOrBefore(dayjs(event.availabilityEndDate))

      if (event.availabilityStartDate && !event.availabilityEndDate) return today.isSameOrAfter(dayjs(event.availabilityStartDate))

      return today.isSameOrAfter(dayjs(event.availabilityStartDate)) && today.isSameOrBefore(dayjs(event.availabilityEndDate))
    })
    .sort((e1, e2) => {
      if (!e1.eventStartDate) return 1
      if (dayjs(e1.eventStartDate).isBefore(dayjs(e2.eventStartDate))) return -1
      if (dayjs(e1.eventStartDate).isAfter(dayjs(e2.eventStartDate))) return 1
      return 0
    })
}

export const checkIsSales = (): boolean => window.location.pathname.includes('/sales')
