import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Microwave: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path fill={iconColor} d="M18 7.25a.75.75 0 0 1 .75.75v8a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75Z" />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M2.25 6.742a2.25 2.25 0 0 1 2.25-2.25h15a2.25 2.25 0 0 1 2.25 2.25v10.453a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.742Zm2.25-.75a.75.75 0 0 0-.75.75v10.453c0 .415.336.75.75.75h9.75V5.992H4.5Zm11.25 0v11.953h3.75a.75.75 0 0 0 .75-.75V6.742a.75.75 0 0 0-.75-.75h-3.75Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M9.562 12.473c.596-.655.85-.724.941-.723.095 0 .349.076.941.73a.75.75 0 0 0 1.112-1.007c-.577-.637-1.23-1.218-2.044-1.223-.818-.005-1.478.572-2.068 1.223l-.013.015c-.28.326-.494.528-.662.645-.16.112-.238.117-.263.117-.027 0-.108-.008-.273-.124-.172-.12-.388-.323-.67-.644a.75.75 0 1 0-1.126.99c.31.354.618.66.935.882.323.226.696.393 1.122.396.428.003.805-.158 1.132-.386.318-.222.626-.53.936-.89Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Microwave.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Microwave
