import { FC, useState } from 'react'
import Cookies from 'js-cookie'
import { InstalmentInfoModalProps as Props } from './types'
import Modal from 'components/Modal/Modal'
import { Button } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './InstalmentInfoModal.module.scss'
import { NEVER_SHOW_INSTALMENT_INFO_MODAL } from '../../constants'
import parse from 'html-react-parser'
import { getProductFromProductCode } from 'utils/products'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import { Product } from 'types/products'
import { Heading } from '@boltenergy-be/design-system'

const InstalmentInfoModal: FC<Props> = ({ isOpen, setClose }) => {
  // Redux
  const { selectedContracts } = useSelector((store: Store) => store.user)
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // Local state
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('billing')

  // Constants
  const elProduct = getProductFromProductCode(electricityContract?.detail?.productCode)

  /**
   * Closes the modal and sets the cookie to never show it again
   */
  const closeModal = () => {
    if (checkboxChecked) {
      Cookies.set(NEVER_SHOW_INSTALMENT_INFO_MODAL, 'true', { expires: 365 })
    }

    setClose()
  }

  return (
    <Modal setClose={() => closeModal()} {...{ isOpen }} className={styles.modal}>
      <Heading as="h1" variant="h5" className="mb-300">
        {t('billingCycles.current.instalmentInfoModal.title')}
      </Heading>

      <p>{parse(t(`billingCycles.current.instalmentInfoModal.${elProduct === Product.FIX ? 'descriptionFix' : 'description'}`))}</p>

      <div className={styles.footer}>
        <form>
          <label className={styles.checkbox}>
            <input type="checkbox" checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} />
            <span>{t('billingCycles.current.instalmentInfoModal.checkbox')}</span>
          </label>
        </form>

        <Button onClick={closeModal}>{t('billingCycles.current.instalmentInfoModal.close')}</Button>
      </div>
    </Modal>
  )
}

export default InstalmentInfoModal
