import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Store } from 'store/types'
import useWindowSize from 'hooks/useWindowSize'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget'
import { WidgetSlug } from 'types/feedbackWidget'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget'
import { useGetYearOverviewMetricsQuery } from 'store/queries/bolt-api'
import MobileSection from 'components/EnergyOverview/MobileSection/MobileSection'
import { formatAmount } from 'utils/format'
import SectionPersonal from 'components/EnergyOverview/SectionPersonal'
import SectionCommunity from 'components/EnergyOverview/SectionCommunity'
import Card from 'components/Card/Card'
import styles from './EnergyOverview.module.scss'
import LoadingCard from 'components/LoadingCard/LoadingCard'
import { useState } from 'react'

const EnergyOverview = () => {
  // REDUX STORE
  const {
    info: { email },
    selectedContracts
  } = useSelector((store: Store) => store.user)

  // Contracts
  const { electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // REDUX QUERY
  const { data: yearOverviewData, isLoading } = useGetYearOverviewMetricsQuery({
    email,
    electricityContractNumber: electricityContract.id,
    gasContractNumber: gasContract?.id
  })

  // i18n
  const { t } = useTranslation('energyOverview')

  // Local state
  const [isFeedbackWidgetOpen, setIsFeedbackWidgetOpen] = useState<boolean>(false)

  // Custom hook
  const { openFeedbackWidget, feedbackWidgetData } = useOpenFeedbackWidget(0, WidgetSlug.ENERGY_OVERVIEW, isFeedbackWidgetOpen)

  // Window size
  const { isMobile } = useWindowSize()

  return (
    <>
      <div className={styles.container}>
        {isLoading ? (
          <>
            <LoadingCard key="personal-card" />

            <LoadingCard key="community-card" />
          </>
        ) : yearOverviewData ? (
          !isMobile ? (
            <>
              <Card as="section" className={styles['slider-card']}>
                <SectionPersonal
                  openFeedbackWidget={(state) => setIsFeedbackWidgetOpen(state)}
                  data={yearOverviewData.yearOverviewMetrics.personal}
                  communityReferrals={yearOverviewData.yearOverviewMetrics.community.referrals.total || 0}
                />
              </Card>

              <Card as="section" className={styles['slider-card']}>
                <SectionCommunity
                  openFeedbackWidget={(state) => setIsFeedbackWidgetOpen(state)}
                  data={yearOverviewData.yearOverviewMetrics.community}
                />
              </Card>
            </>
          ) : (
            <>
              <MobileSection
                title={t('personal.title')}
                fact={t('personal.mobileFact', {
                  usage: formatAmount(yearOverviewData.yearOverviewMetrics.personal.electricity.consumed.total)
                })}
                button={{ text: t('personal.mobileButtonText'), color: 'primary' }}
              >
                <SectionPersonal
                  openFeedbackWidget={(state) => setIsFeedbackWidgetOpen(state)}
                  data={yearOverviewData.yearOverviewMetrics.personal}
                  communityReferrals={yearOverviewData.yearOverviewMetrics.community.referrals.total || 0}
                />
              </MobileSection>

              <MobileSection
                color="blue"
                title={t('community.title')}
                fact={t('community.mobileFact', {
                  households: formatAmount(yearOverviewData.yearOverviewMetrics.community.households.people)
                })}
                button={{ text: t('community.mobileButtonText'), color: 'primary-on-accent' }}
              >
                <SectionCommunity
                  openFeedbackWidget={(state) => setIsFeedbackWidgetOpen(state)}
                  data={yearOverviewData.yearOverviewMetrics.community}
                />
              </MobileSection>
            </>
          )
        ) : (
          <Card className={styles['slider-card']}>
            <Card.Title>{t('personal.title')}</Card.Title>
            <div className={styles.placeholder}>
              <p>{t('personal.noData')}</p>
            </div>
          </Card>
        )}
      </div>

      {/* FeedbackWidget */}
      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default EnergyOverview
