import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Vacation: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M9.5 6.25c.28 0 .54.16.67.41l4.28 8.4 1.4-2.44a.75.75 0 0 1 1.3 0l3.9 6.76a.75.75 0 0 1-.65 1.12H3a.75.75 0 0 1-.66-1.09l6.5-12.75a.75.75 0 0 1 .66-.41ZM12.17 19l1.38-2.4L9.5 8.66 4.23 19h7.94Zm4.33-4.5 2.6 4.5h-5.2l2.6-4.5ZM18 7.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM18 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Vacation.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Vacation
