// TYPES

import { Language } from 'store/app/types'
import { Address, Contract, ContractStatus, SalesforceLegalForm } from './types'
import { SignChannel, SimulationType } from 'features/contracts/add/types'

export type SalesEvent = {
  _id: string
  eventId: string
  eventName: string
  partnerId: string
  eventStartDate?: Date
  eventEndDate?: Date
  availabilityStartDate?: Date
  availabilityEndDate?: Date
  parameters: Record<SalesParameterKeys, string | string[]>
  hidden?: boolean
}

export type APISalesUser = {
  _id: string
  activated: boolean
  agentId: string
  createdAt: string
  email: string
  firstName: string
  language: Language
  lastName: string
  partnerId: string
  refreshToken: string
  token: string
}

export type SalesPartner = {
  id: string
  name: string
  allowAccessToAllCustomers: boolean
}

export type SalesOpportunity = {
  contactId: string
  name: string
  postalCode: string
  stage: OpportunityStage
  serviceContracts: {
    electricity: serviceContract
    gas?: serviceContract
  }
}

type serviceContract = {
  ean: string
  status?: ContractStatus | null
  cancelDate?: string // ISO
  mmdChurnDate?: string // ISO
}

export type Contact = {
  email: string
  firstName: string
  language: Language
  lastName: string
  phone: string
  salesforceId: string
  status: ContactStatus

  // Relations
  accounts: Account[]
}

export type Account = {
  firstName: string
  lastName: string
  company?: Company
  contactSalesforceId: string
  customerNumber: string
  salesforceId: string
  type: AccountType
  name: string

  // Relations
  billingContracts: AccountBillingContract[]
  opportunities: Opportunity[]
}

export type Company = {
  name: string
  vatApplication: string | boolean
  enterpriseNumber: string
  legalForm: SalesforceLegalForm
}

export type AccountBillingContract = {
  opportunitySalesforceId: string

  // Relations
  serviceContracts: {
    electricity: Contract
    gas?: Contract
  }
}

export type Opportunity = {
  accountSalesforceId: string
  address: Address
  eventName: string
  extraAddressFlow: boolean
  forcedAmount: number
  ibanNumber: string | null
  paymentMode: PaymentMode
  pricePerMonthInclVat: number
  salesforceId: string
  signChannel: SignChannel
  simulationType: SimulationType
  stage: OpportunityStage
}

export type OpportunityCase = {
  subject: string
  message: string
}

export type SalesParameter = {
  key: string
  value: string
  createdAt: Date
  updatedAt?: Date
}

// RESPONSE TYPES

export type GetSalesUserDataResponse = {
  user: APISalesUser
}

export type GetSalesPartnerByIdResponse = {
  partner: SalesPartner
}

// ENUMS

export enum PaymentMode {
  DOMICILATION = 'Domiciliation',
  TRANSFER = 'Transfer'
}

export enum SalesParameterKeys {
  ADJUSTABLE_PRODUCT = 'adjustable_product',
  AVAILABLE_PRODUCTS = 'available_products',
  BANNER = 'banner',
  CAMPAIGN_NAME = 'campaign_name',
  DEFAULT_PRODUCT = 'default_product',
  EAN_CODE_MANDATORY = 'ean_code_mandatory',
  EXECUTE_CREDIT_CHECK = 'execute_credit_check',
  NO_OPTIN_ONE = 'no_optin_one',
  PREVIOUS_SUPPLIER = 'ask_previous_supplier',
  PROMO_CODE = 'promocode',
  RESET_BUTTON = 'reset_button',
  SIGN_CHANNEL = 'sign_channel',
  SIMULATION_SO = 'simulation_so',
  SIMULATION_TYPE = 'simulation_type',
  SOURCE_TYPE = 'source_type'
}

export enum OpportunityStage {
  CLOSED_LOST = 'Closed Lost',
  CLOSED_WON = 'Closed Won',
  IN_PROGRESS = 'In Progress',
  NEEDS_ANALYSIS = 'Needs Analysis',
  NEGOTIATION = 'Negotiation',
  NEW = 'New',
  NO_EAN = 'No EAN',
  PARKED = 'Parked',
  PROPOSAL = 'Proposal',
  QUALIFICATION = 'Qualification',
  REGISTRATION_STARTED = 'Registration Started',
  TO_BE_CONFIRMED = 'To Be Confirmed'
}

export enum AccountType {
  CUSTOMER = 'Customer',
  PROSPECT = 'Prospect'
}

export enum ContactStatus {
  COLD = 'Cold',
  CUSTOMER = 'Customer',
  HOT = 'Hot',
  SUPER_HOT = 'Super Hot',
  WARM = 'Warm'
}
