import { createElement } from 'react'
import { Heading } from '@boltenergy-be/design-system'
import styles from './Card.module.scss'
import Badge from 'components/Badge/Badge.tsx'
import classNames from 'classnames'
import { AvailableCardTags, CardHeaderProps, CardProps, CardTitleProps, CardVariants } from './types'

const Title = ({ children, as = 'h2', variant = 'h5', ...props }: CardTitleProps) => {
  return <Heading {...{ as, variant, ...props }}>{children}</Heading>
}

const Header = ({ children, ...headerProps }: CardHeaderProps) => {
  return (
    <header className={styles['card-header']} {...headerProps}>
      {children}
    </header>
  )
}

const Card = <Tag extends AvailableCardTags>({
  badge,
  border = true,
  image,
  children,
  className,
  as = 'div' as Tag,
  variant = CardVariants.DEFAULT,
  color,
  ...tagProps
}: CardProps<Tag>) => {
  return createElement(
    as,
    {
      className: classNames(
        styles.card,
        styles[variant],
        color && { [styles[color]]: !!color },
        { [styles['hide-border']]: !border },
        className
      ),
      ...tagProps
    },
    <>
      {!!badge && <Badge text={badge.text} className={styles.badge} color={badge.color} />}
      {!!image && (
        <img
          className={classNames(styles.img, { [styles['only-img']]: !!image && !children }, image.className)}
          src={image.src}
          alt={image.alt}
        />
      )}
      {!!children && children}
    </>
  )
}

Card.Title = Title
Card.Header = Header

export default Card
