import { FC, FormEvent, useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Rating } from 'react-simple-star-rating'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import styles from './FeedbackWidget.module.scss'
import { FeedbackWidgetProps as Props, FeedbackWidgetStep } from 'components/FeedbackWidget/types'
import FeedbackWidgetIcon from 'components/FeedbackWidget/FeedbackWidgetIcon'
import { Close } from 'assets/svg'
import { Button } from '@boltenergy-be/design-system'

const FeedbackWidget: FC<Props> = ({ id, openFeedbackWidget, showTrigger, data }) => {
  // Local state
  const [widgetOpen, setWidgetOpen] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(0)
  const [step, setStep] = useState<FeedbackWidgetStep>(FeedbackWidgetStep.RATING)
  const [feedback, setFeedback] = useState<string>('')

  // i18n
  const { t } = useTranslation()

  /**
   * Opens the widget from parent
   */
  useEffect(() => {
    setWidgetOpen(!!openFeedbackWidget)
  }, [openFeedbackWidget])

  // Constants
  const feedbackWidgetCookie = useMemo<boolean>(() => !!Cookies.get(`FeedbackWidget-${id}`), [])

  /**
   * Handles the on submit
   */
  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault() // prevent page refresh

    // Push entry to API
    try {
      await request(
        getAxiosRequestConfig(Methods.POST, '/feedback-widgets/entry', {
          feedbackWidgetId: id,
          score: rating,
          feedback,
          data
        })
      )

      setStep(FeedbackWidgetStep.THANKS)
    } catch (err) {
      const { message } = err as Error
      throw new Error(message)
    }
  }

  /**
   * Set cookie, close and reset the widget
   */
  const closeWidget = () => {
    // set cookie that expires in 30 days
    Cookies.set(`FeedbackWidget-${id}`, 'hidden', { expires: 30, sameSite: 'Lax' })

    // close the widget
    setWidgetOpen(false)
  }

  /**
   * Handles the click on the rating
   * @param {number} value
   */
  const handleClickRating = (value: number) => {
    setRating(value)
    setStep(FeedbackWidgetStep.FEEDBACK)
  }

  return (
    <>
      {showTrigger && (
        <Button
          className={styles.button}
          onClick={() => {
            if (!widgetOpen) {
              setStep(FeedbackWidgetStep.RATING)
              setRating(0)
              setFeedback('')
            }
            setWidgetOpen(!widgetOpen)
          }}
        >
          {t(`${widgetOpen ? 'close' : 'button'}`, { ns: 'feedbackWidget' })}
        </Button>
      )}
      <dialog className={styles['feedback-widget']} open={!(!showTrigger && feedbackWidgetCookie) && widgetOpen}>
        <button onClick={() => closeWidget()} className={styles.close}>
          <Close />
        </button>
        <h2>{step === FeedbackWidgetStep.THANKS ? t('thanks.message', { ns: 'feedbackWidget' }) : t('title', { ns: 'feedbackWidget' })}</h2>

        {/* FIRST STEP - rating */}
        {step === FeedbackWidgetStep.RATING && (
          <>
            <p>{t('rating.description', { ns: 'feedbackWidget' })}</p>
            <Rating
              onClick={handleClickRating}
              initialValue={rating}
              emptyIcon={<FeedbackWidgetIcon />}
              fillIcon={<FeedbackWidgetIcon isActive />}
              emptyClassName={styles.icon}
              fillClassName={styles.icon}
              showTooltip={false}
            />
            <div className={styles.indication}>
              <span>{t('rating.indication.bad', { ns: 'feedbackWidget' })}</span>
              <span>{t('rating.indication.good', { ns: 'feedbackWidget' })}</span>
            </div>
          </>
        )}

        {/* SECOND STEP - feedback */}
        {step === FeedbackWidgetStep.FEEDBACK && (
          <form action="#" onSubmit={handleSubmit} className={styles.form}>
            <label>{t('feedback.description', { ns: 'feedbackWidget' })}</label>
            <textarea
              name="feedback"
              id="feedback"
              cols={26}
              rows={3}
              value={feedback}
              onChange={(e) => setFeedback(e.currentTarget.value)}
            />
            <Button type="submit">{t('feedback.submit', { ns: 'feedbackWidget' })}</Button>
          </form>
        )}

        {/* THIRD STEP - thank you message */}
        {step === FeedbackWidgetStep.THANKS && <p>{t('thanks.paragraph', { ns: 'feedbackWidget' })}</p>}
      </dialog>
    </>
  )
}

export default FeedbackWidget
