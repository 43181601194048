import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router'
import { EMAIL } from 'constants/regex'
import { routes } from 'types/routes'
import { requestNewPassword, resetPassword } from 'store/auth/thunks'
import { ResetPasswordFormFieldsType } from './types'
import { getAccessToken } from 'utils/localStorageService'
import { AuthErrorCodes } from 'types/errorCodes'
import styles from '../Auth.module.scss'
import NeedHelp from 'components/NeedHelp/NeedHelp'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Button, Heading } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'

const ResetPassword: FC = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { error, loading } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ResetPasswordFormFieldsType>({ mode: 'onBlur' })
  const watchPassword = watch('password')

  // i18n
  const { t } = useTranslation('auth')

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isRequestNewPasswordPage = pathname === routes.REQUEST_NEW_PASSWORD
  const isSalesRequestNewPasswordPage = pathname === routes.REQUEST_NEW_PASSWORD_SALES
  const isRequestNewPassword = isRequestNewPasswordPage || isSalesRequestNewPasswordPage

  const isActivationPage = pathname === routes.ACTIVATE
  const isSalesActivationPage = pathname === routes.ACTIVATE_SALES
  const isSalesPage = pathname.includes('/sales')

  // Local state
  const [success, setSuccess] = useState<boolean>(false)

  // Constants
  const email: string = sessionStorage.getItem('resetEmail') ?? ''

  /**
   * Triggered on first render
   */
  useEffect(() => {
    if (!isRequestNewPassword) {
      if (!getAccessToken() || email === '') {
        navigate(pathname.includes('/sales') ? routes.LOGIN_SALES : routes.LOGIN)
      }
    }
  }, [])

  /**
   * Handles the form submit after validation
   *
   * @param {ResetPasswordFormFieldsType} data
   */
  const onSubmit = async (data: ResetPasswordFormFieldsType) => {
    // Check if send reset password mail page...
    if (isRequestNewPassword) {
      // Send the register mail
      const { email } = data
      await dispatch(requestNewPassword({ email: email.toLowerCase(), language, isSales: isSalesPage }))
    }
    // ...or the reset password page itself
    else {
      // Register the user
      const { email, password } = data
      await dispatch(
        resetPassword({ email: email.toLowerCase(), password, isSales: isSalesPage, navigateTo: (destination) => navigate(destination) })
      )
    }

    setSuccess(true)
  }

  return (
    <>
      <div className={styles['auth-body']}>
        {isSalesPage ? (
          <Heading as="h1" variant="h3">
            {t('title')}
          </Heading>
        ) : (
          <Heading as="h1" variant="h3">
            {isRequestNewPassword ? t('title') : t('titleConfirmation')}
          </Heading>
        )}
        <p className={styles.description}>{isRequestNewPassword ? t('descriptions.reset') : t('descriptions.resetConfirmation')}</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="email">{t('email')}</label>
            <input
              id="email"
              readOnly={!isRequestNewPassword}
              autoComplete="off"
              // Disable lastpass autofill
              data-lpignore="true"
              className={classNames('form-control', { error: errors?.email })}
              placeholder={t('email')}
              {...register('email', { required: true, pattern: EMAIL })}
              defaultValue={email}
            />

            {errors.email && (
              <span className="help-block text-negative">
                {errors.email.type === 'required' && t('required', { ns: 'validation' })}
                {errors.email.type === 'pattern' && t('invalid.email', { ns: 'validation' })}
              </span>
            )}
          </div>

          {!isRequestNewPassword && (
            <>
              <div className="form-group">
                <label htmlFor="password">{t('password')}</label>
                <input
                  type="password"
                  autoComplete="new-password"
                  // Disable lastpass autofill
                  data-lpignore="true"
                  className={classNames('form-control', { error: errors?.password })}
                  placeholder={t('password')}
                  {...register('password', { required: true, minLength: 8 })}
                />

                {errors.password && (
                  <span className="help-block text-negative">
                    {errors.password.type === 'required' && t('required', { ns: 'validation' })}
                    {errors.password.type === 'minLength' && t('formErrors.passwordMinimum')}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="confirmPassword">{t('formDescriptions.confirmPassword')}</label>
                <input
                  type="password"
                  autoComplete="new-password"
                  // Disable lastpass autofill
                  data-lpignore="true"
                  className={classNames('form-control', { error: errors?.confirmPassword })}
                  placeholder={t('formDescriptions.confirmPassword')}
                  {...register('confirmPassword', {
                    required: true,
                    validate: (val) => val === watchPassword
                  })}
                />

                {errors.confirmPassword && (
                  <span className="help-block text-negative">
                    {errors.confirmPassword.type === 'required' && t('required', { ns: 'validation' })}
                    {errors.confirmPassword.type === 'validate' && t('formErrors.passwordsNotEqual')}
                  </span>
                )}
              </div>
            </>
          )}

          <Button loading={loading} disabled={success}>
            {t(`buttons.${isActivationPage || isSalesActivationPage ? 'activate' : !isRequestNewPassword ? 'resetPassword' : 'sendEmail'}`)}
          </Button>
        </form>

        {!loading && (
          <>
            {error && error === AuthErrorCodes.USER_NOT_FOUND && <p className="text-negative center">{t(`formErrors.${error}`)}</p>}
            {!error && success && isRequestNewPassword && <p className="text-positive center">{t('emailSent.newPassword')}</p>}
          </>
        )}

        <Link size="small" href={routes.LOGIN}>
          {t('login')}
        </Link>
      </div>

      <div className={styles['auth-footer']}>
        <NeedHelp />
      </div>
    </>
  )
}

export default ResetPassword
