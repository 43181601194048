/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'utils/axios'
import { Methods, Response } from 'types/request'
import { AxiosError, AxiosRequestConfig } from 'axios'

export const newRequest = async <T>(options: AxiosRequestConfig): Promise<Response<T>> => {
  try {
    const {
      data: { data, error, message, success }
    } = await axios(options)

    return { data, error, message, success }
  } catch (err) {
    const { response } = err as AxiosError

    if (response) {
      return response.data as Response<T>
    }

    return {
      success: false,
      message: 'ApiError'
    }
  }
}

/**
 * Make a request with the given options
 *
 * @param options
 */
const request = async (options: AxiosRequestConfig) => {
  try {
    const { data: responseData } = await axios(options)

    return {
      success: true,
      data: responseData
    }
  } catch (err) {
    return {
      success: false,
      // @ts-ignore
      message: err.response ? err.response.data : 'ApiError'
    }
  }
}

export const getAxiosRequestConfig = (
  method: Methods,
  url: string,
  data: Record<string, unknown> | null = null,
  params: Record<string, unknown> | null = null,
  headers: Record<string, string> | null = null
): AxiosRequestConfig => ({
  method,
  url,
  ...(data !== null && { data }),
  ...(params !== null && { params }),
  ...(headers !== null && { headers })
})

export default request
