import { SalesPartner } from 'types/sales'
import { Address, Contract, Customer, Producer, ProducerEnergyType, ProducerState } from 'types/types'
import { Product } from 'types/products'
import { User } from 'types/user'

export type UserStore = {
  loading: boolean
  error: string | null
  success: boolean
  userLoading: boolean
  userError: string | null
  info: User
  customers: CustomerLibrary
  producers: Producer[]
  location: MapsLocation
  selectedCustomer: string
  selectedCustomerProduct?: Product
  selectedContracts: Contract[]
  contractUpdatedStatus: string
  producer: Producer
  sales?: SalesProps
  move: {
    flow: MoveFlows
    started?: MoveFlowState
    stopped?: MoveFlowState
  }
}

export type MoveFlowState = {
  address: Address
  date: string | null // ISO
}

export enum MoveFlows {
  START = 'start',
  START_STOP = 'start-stop',
  STOP_START = 'stop-start',
  STOP = 'stop'
}

export type CustomerLibrary = {
  [customerId: string]: Customer
}

export type SalesProps = {
  _id: string
  agentId: string
  activated: boolean
  partner: SalesPartner
}

export type MapsLocation = {
  lat: number
  lng: number
}

export type UserAddress = {
  streetName: string
  streetNumber: string
  streetBox: string
  townName: string
  postalCode: string | number
}

export const initialProducer: Producer = {
  id: '',
  isFeatured: {
    nl: false,
    fr: false
  },
  isPopular: {
    nl: false,
    fr: false
  },
  location: {
    nl: '',
    fr: ''
  },
  isSoldOut: false,
  state: ProducerState.AVAILABlE,
  energyType: ProducerEnergyType.BIO,
  energyRegion: '',
  energyUsed: 0,
  currentCapacity: 0,
  maxCapacity: 0,
  images: {
    header: {
      url: ''
    },
    profile: {
      url: ''
    },
    extra: []
  },
  slug: '',
  parentSlug: '',
  name: {
    nl: '',
    fr: ''
  },
  description: {
    nl: '',
    fr: ''
  },
  longDescription: {
    nl: '',
    fr: ''
  },
  facts: {
    nl: [''],
    fr: ['']
  },
  enabled: false,
  salesforceId: '',
  minOccupation: 0,
  order: 0,
  coordinates: {
    lat: 0,
    long: 0
  }
}
