import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { initialUserState } from 'store/user/initial'
import { salesReducers } from './reducers/sales'
import { customerReducers } from './reducers/customer'
import { commonUserReducers } from './reducers/common'
import { getCustomersForSalesPartner, getSalesUserData } from './thunks/sales'
import { getUserData, selectContracts, updateBilling, updateCustomer, updateInstalment } from './thunks/customer'
import { ContractType } from 'types/types'

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    ...commonUserReducers,
    ...customerReducers,
    ...salesReducers
  },
  extraReducers: (builder) => {
    // SPECIFIC THUNK REDUCERS
    builder.addCase(selectContracts.fulfilled, (state, { payload }) => {
      state.location = payload.location
    })

    builder.addCase(updateBilling.fulfilled, (state, { payload }) => {
      const { billingContract } = payload
      const newContracts = state.selectedCustomer ? [...state.customers[state.selectedCustomer].contracts] : []

      const contractIndex = newContracts.findIndex((contracts) => contracts.find((c) => c.id === billingContract.id))
      newContracts[contractIndex].forEach((ctr, index) => {
        if (ctr.type === ContractType.BILLING) {
          newContracts[contractIndex][index] = billingContract
        }
      })

      state.loading = false
      state.error = null
      state.success = true
      state.selectedContracts = newContracts[contractIndex] // TODO: refactor to reference instead of duplicate object
      if (state.selectedCustomer) state.customers[state.selectedCustomer].contracts = newContracts
    })

    builder.addCase(updateCustomer.fulfilled, (state, { payload }) => {
      const { customer } = payload
      const newCustomers = state.customers

      newCustomers[customer.id] = {
        ...newCustomers[customer.id],
        ...customer
      }

      state.loading = false
      state.error = null
      state.success = true
      state.customers = newCustomers
    })

    builder.addCase(updateInstalment.fulfilled, (state, { payload }) => {
      const { billingContractId, electricity, gas } = payload
      const newContracts = state.selectedCustomer ? [...state.customers[state.selectedCustomer].contracts] : []

      const contractIndex = newContracts.findIndex((contracts) => contracts.find((c) => c.id === billingContractId))

      newContracts[contractIndex].forEach((ctr, index) => {
        if (ctr.type === electricity.type) {
          newContracts[contractIndex][index] = electricity
        }

        if (gas && ctr.type === gas.type) {
          newContracts[contractIndex][index] = gas
        }
      })

      state.loading = false
      state.error = null
      state.success = true
      state.selectedContracts = newContracts[contractIndex] // TODO: refactor to reference instead of duplicate object
      if (state.selectedCustomer) state.customers[state.selectedCustomer].contracts = newContracts
    })

    // MATCHERS (combine multiple thunks)
    // IS PENDING
    builder.addMatcher(isPending(getCustomersForSalesPartner), (state) => {
      state.userError = null
    })

    builder.addMatcher(isPending(getSalesUserData, getUserData), (state) => {
      state.userError = null
      state.userLoading = true
    })

    builder.addMatcher(isPending(updateBilling, updateCustomer, updateInstalment), (state) => {
      state.error = null
      state.loading = true
      state.success = false
    })

    // IS FULFILLED
    builder.addMatcher(isFulfilled(getCustomersForSalesPartner), (state, { payload }) => {
      state.customers = payload.customers
      state.producers = payload.producers
      state.userError = null
    })

    builder.addMatcher(isFulfilled(getSalesUserData, getUserData), (state, { payload }) => {
      const { producers, customers, user, sales } = payload

      state.userError = null
      state.userLoading = false
      state.info = user
      state.customers = customers
      state.producers = producers
      state.sales = sales
    })

    // IS REJECTED
    builder.addMatcher(isRejected(getCustomersForSalesPartner, getSalesUserData, getUserData), (state, { payload }) => {
      state.userError = payload || null
      state.userLoading = false
    })

    builder.addMatcher(isRejected(updateBilling, updateCustomer, updateInstalment), (state, { payload }) => {
      state.error = payload || null
      state.loading = false
      state.success = false
    })
  }
})

export const {
  clearSalesCustomerData,
  clearUser,
  resetMoveFlow,
  selectCustomer,
  setMoveFlow,
  setMoveFlowState,
  setProducer,
  setSelectedContracts
} = userSlice.actions

export default userSlice.reducer
