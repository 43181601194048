import { FC } from 'react'
import { IconProps as Props } from './types'

const Elecricity: FC<Props> = ({ active }) => {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <path
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm.756 5.925a.469.469 0 0 0-.526.198l-4.375 6.875a.469.469 0 0 0 .395.72h2.656v3.907a.469.469 0 0 0 .864.252l4.375-6.875a.469.469 0 0 0-.395-.72h-2.656V6.374a.469.469 0 0 0-.338-.45Z"
          fill="#23D5AE"
          fillRule="evenodd"
        />
      ) : (
        <g fill="none" fillRule="evenodd">
          <path
            d="M3.563 3.467 9.806 9.93l-1.951 3.068a.469.469 0 0 0-.05.4l.034.078c.082.15.24.243.411.243h2.656v3.906a.469.469 0 0 0 .864.252l2.269-3.566 6.198 6.416a11.957 11.957 0 0 1-7.932 3.27L12 24C5.373 24 0 18.627 0 12c0-3.338 1.363-6.358 3.563-8.533ZM12 0c6.627 0 12 5.373 12 12 0 2.82-.973 5.413-2.6 7.461l-6.415-6.638 1.16-1.821a.469.469 0 0 0 .05-.4l-.034-.078a.469.469 0 0 0-.411-.243h-2.656V6.375a.469.469 0 0 0-.864-.252l-1.477 2.319-5.886-6.093A11.942 11.942 0 0 1 11.695.004Z"
            fill="#BAB9BC"
          />
          <path stroke="#BAB9BC" strokeLinecap="square" d="M1.018 1.125 22.393 23.25" />
        </g>
      )}
    </svg>
  )
}

export default Elecricity
