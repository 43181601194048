import { Row } from 'components/Table/types.ts'
import styles from './StackedInvoicesView.module.scss'
import { Fragment } from 'react'
import { StackedInvoicesViewProps } from 'pages/App/Billing/Invoices/InvoicesTable/StackedInvoicesView/types.ts'

const StackedInvoicesView = ({ rows }: StackedInvoicesViewProps) => {
  return (
    <ul>
      {!!rows.length &&
        rows.map((row: Row, rowIdx: number) => (
          <li className={styles['list-item']} key={rowIdx}>
            {row.cells.map(({ value }, cellIdx) => (
              <Fragment key={cellIdx}>{value}</Fragment>
            ))}
          </li>
        ))}
    </ul>
  )
}

export default StackedInvoicesView
