import { InvoiceTableTabs, useInvoicesTableProps, useInvoicesTableReturnType } from 'pages/App/Billing/Invoices/hooks/types.ts'
import { Row } from 'components/Table/types.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { INVOICES_TABLE_BREAKPOINT, SUPERUSER_TABLE_HEADERS, TABLE_HEADERS } from 'pages/App/Billing/Invoices/constants.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { filterDocumentsBasedOnRoute, memoizedFilterOutDirectDebitBounce } from 'pages/App/Billing/Invoices/hooks/utils.tsx'
import { getInvoiceRows, getTransactionRows } from 'pages/App/Billing/Invoices/hooks/rows.tsx'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api'

/**
 * Returns the table 'headers' and 'rows' for a given route.
 * @param {useInvoicesTableProps} props
 */
const useInvoicesTable = ({ tab }: useInvoicesTableProps): useInvoicesTableReturnType => {
  // Window size
  const { isSmaller } = useWindowSize(INVOICES_TABLE_BREAKPOINT)

  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { customers, selectedCustomer } = useStoreSelector((store) => store.user)
  const { data } = useGetCustomerAccountingQuery({
    customerNumber: selectedCustomer
  })

  // constants
  const filteredDocumentsByTypeBasedOnRoute = filterDocumentsBasedOnRoute({ tab, documents: data?.documents ?? [] })
  const usesDirectDebit = customers[selectedCustomer]?.paymentDetails?.directDebit
  const filteredDocuments = memoizedFilterOutDirectDebitBounce({
    documents: filteredDocumentsByTypeBasedOnRoute,
    usesDirectDebit,
    tab
  })

  switch (tab) {
    case InvoiceTableTabs.SUPER_USER_CUSTOMER:
    case InvoiceTableTabs.SUPER_USER_SUPPLIER: {
      return {
        headers: SUPERUSER_TABLE_HEADERS,
        rows: getInvoiceRows({
          documents: filteredDocuments,
          usesDirectDebit,
          isSuperuser: true,
          language,
          customers,
          selectedCustomer
        }) as Row[]
      }
    }

    case InvoiceTableTabs.OUTSTANDING_INVOICES:
    case InvoiceTableTabs.ALL_INVOICES: {
      return {
        headers: TABLE_HEADERS,
        rows: getInvoiceRows({
          documents: filteredDocuments,
          usesDirectDebit,
          isSmaller,
          isSuperuser: false,
          language,
          customers,
          selectedCustomer
        }) as Row[]
      }
    }

    case InvoiceTableTabs.ALL_TRANSACTIONS: {
      return {
        headers: TABLE_HEADERS,
        rows: getTransactionRows({
          documents: filteredDocuments,
          usesDirectDebit,
          language: language
        })
      }
    }

    default:
      return {
        headers: [],
        rows: []
      }
  }
}

export default useInvoicesTable
