import i18next from 'i18next'
import pattern from 'patternomaly'
import { Chart as ChartJS, ScriptableContext, ScriptableScaleContext } from 'chart.js'
import { CoreAnnotationOptions } from 'chartjs-plugin-annotation'
import { ChartLabels, MinMaxData } from 'components/Charts/types'
import { CHART_COLORS } from 'components/Charts/constants'
import { createChartDataArray, formatChartDateLabel } from 'utils/chartjs'
import { PreviousBillingCycle } from 'pages/App/Billing/BillingCycles/types'
import { formatAmount } from 'utils/format'
import { getSettlementColor } from '../utils'
import { externalTooltipHandler } from 'components/Charts/chartjs.config.ts'
import { Language } from 'store/app/types.ts'
import { Granularity } from 'types/contracts.ts'

export const getBillingCycleChartOptions = (
  labels: ChartLabels,
  alternatingBackground: CoreAnnotationOptions,
  handleResize: (chart: ChartJS, labels: ChartLabels) => void,
  language: Language,
  minMax?: MinMaxData,
  isMobile?: boolean
) => {
  return {
    responsive: true,
    aspectRatio: isMobile ? 1 : 2,
    onResize: (chart: ChartJS) => {
      if (chart?.boxes?.length) {
        handleResize(chart, labels)
      }
    },
    categoryPercentage: 0.75,
    barPercentage: isMobile ? 0.85 : 1,
    interaction: {
      mode: 'index'
    },
    scales: {
      y: {
        suggestedMin: minMax?.minDataValue,
        suggestedMax: minMax?.maxDataValue,
        ticks: {
          font: {
            family: "'Commuters Sans', sans-serif",
            size: 10
          },
          color: CHART_COLORS.textSubdued,
          padding: 5,
          callback: (value: number) => '€ ' + formatAmount(value)
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: (context: ScriptableScaleContext) => (context.tick.value === 0 ? CHART_COLORS.gridBaseline : CHART_COLORS.gridLine)
        }
      },
      x: {
        grid: {
          lineWidth: 0
        },
        ticks: {
          callback: (value: number) => {
            if (value === labels.length - 1) return labels[value]
            return formatChartDateLabel(labels[value], Granularity.MONTH, language)
          },
          color: CHART_COLORS.textSubdued,
          font: {
            family: "'Commuters Sans', sans-serif",
            size: 10
          }
        }
      }
    },
    plugins: {
      annotation: {
        common: {
          drawTime: 'beforeDraw'
        },
        annotations: {
          ...alternatingBackground,
          lastColumn: {
            type: 'box',
            backgroundColor: pattern.draw('diagonal-right-left', '#EAEAEB', '#E1E1E5', 20),
            borderWidth: 0,
            xMax: 0.5 + labels.length - 1,
            xMin: -0.5 + labels.length - 1
          }
        }
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      },
      tooltip: {
        enabled: false,
        external: (context: any) => externalTooltipHandler({ context, totalColumns: labels.length - 1, isCombined: true }),
        titleAlign: 'center',
        // filter out settlement data (datasetIndex = 2) & don't show tooltip on settlement bar
        filter: (context: ScriptableContext<'bar'>) => context.datasetIndex !== 2 && context.dataIndex !== labels.length - 1,
        callbacks: {
          label: (context: ScriptableContext<'bar'>) => {
            const defaultText = `€ ${formatAmount(context.raw as number)}`
            return context.dataIndex !== labels.length - 1 ? `${context.dataset.label}: ${defaultText}` : defaultText
          },
          title: (context: any) => formatChartDateLabel(context?.[0]?.label, Granularity.MONTH, language, true)
        }
      }
    }
  }
}

export const getBillingCycleChartDatasets = (labels: ChartLabels, data: PreviousBillingCycle, isMobile?: boolean) => {
  const borderRadius = isMobile ? 2 : 4
  const maxBarThickness = isMobile ? 18 : 26

  return [
    {
      label: i18next.t('billingCycles.chart.legendPrevious.billedInstalment', { ns: 'billing' }),
      backgroundColor: CHART_COLORS.lightblueLight,
      data: createChartDataArray(
        labels.length,
        data.months.map(({ billedAmount }) => billedAmount)
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    },
    {
      label: i18next.t('billingCycles.chart.legendPrevious.actualInstalment', { ns: 'billing' }),
      backgroundColor: CHART_COLORS.greenDark,
      data: createChartDataArray(
        labels.length,
        data.months.map(({ actualPrice }) => actualPrice)
      ),
      skipNull: true,
      maxBarThickness,
      borderRadius
    },
    {
      label: i18next.t('billingCycles.chart.legendPrevious.settlement', { ns: 'billing' }),
      backgroundColor: getSettlementColor(data.settlement),
      data: createChartDataArray(labels.length, [data.settlement], labels.length - 1),
      skipNull: true,
      maxBarThickness,
      borderRadius
    }
  ]
}
