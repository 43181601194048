import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import { AccountingInfoProps } from 'pages/App/Billing/Invoices/InvoicesTable/AccountingInfo/types.ts'

const AccountingInfo = ({ document }: AccountingInfoProps) => {
  // constants
  const matchingNumber = document.matchingNumber ?? 0

  if (matchingNumber < 0) return <Cell variant={CellVariant.ACCOUNTING_INFO_NEGATIVE}>{matchingNumber}</Cell>
  if (matchingNumber > 0) return <Cell variant={CellVariant.ACCOUNTING_INFO_POSITIVE}>{matchingNumber}</Cell>

  // matchingNumber === 0
  return null
}

export default AccountingInfo
