import { UserTypes } from 'store/auth/types'
import { setUserType as setStoreUserType } from 'store/auth/slice'
import { store } from 'store'
import { checkIsSales } from 'utils/sales'
import { isAuthenticated } from 'utils/localStorageService'

/**
 * Get the session user type (doesn't persist on tabs)
 * @returns {UserTypes | null}
 */
export const getSessionUserType = (): UserTypes | null => {
  const userType = sessionStorage.getItem('user_type')
  return userType ? (JSON.parse(userType) as UserTypes) : null
}

/**
 * Get the user type from the store or session
 * @returns {UserTypes | null}
 */
export const getUserType = (): UserTypes | null => {
  const sessionsUserType = getSessionUserType()
  const storeUserType = store.getState().auth.userType
  return storeUserType || sessionsUserType
}

/**
 * Identify the user type based on route & authenticatedForUserType
 * @returns {UserTypes}
 */
export const identifyUserType = (): UserTypes => {
  const isSalesRoute = checkIsSales()
  const isAuthenticatedForUserType = isAuthenticated()
  const localSuperUser = localStorage.getItem('is_superuser') === 'true'

  if (isSalesRoute) {
    return UserTypes.SALES
  }

  if (localSuperUser && isAuthenticatedForUserType[UserTypes.SUPER_USER]) {
    return UserTypes.SUPER_USER
  }

  return UserTypes.CUSTOMER
}

/**
 * Set the session user type (doesn't persist on tabs)
 * @param {UserTypes} userType
 */
export const setSessionUserType = (userType: UserTypes | null) => {
  sessionStorage.setItem('user_type', JSON.stringify(userType))
}

/**
 * Set the user type in the store and session
 * @param {UserTypes} userType
 */
export const setUserType = (userType: UserTypes | null) => {
  setSessionUserType(userType)
  store.dispatch(setStoreUserType(userType))
}

/**
 * Clear the session user type (doesn't persist on tabs)
 */
export const clearSessionUserType = () => {
  sessionStorage.removeItem('user_type')
}
