import classNames from 'classnames'
import TooltipOnClick from 'components/Tooltip/TooltipOnClick'
import { InfoBadgeProps } from './types'
import styles from './InfoBadge.module.scss'

const InfoBadge = ({ tooltipContent, type, tooltipWidth = 360 }: InfoBadgeProps) => {
  return (
    <TooltipOnClick tooltipContent={tooltipContent} classNameTrigger={styles['badge-container']} width={tooltipWidth}>
      <div
        className={classNames(styles.badge, {
          [styles.default]: type === 'default',
          [styles.warning]: type === 'warning'
        })}
      >
        ?
      </div>
    </TooltipOnClick>
  )
}

export default InfoBadge
