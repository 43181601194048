import styles from './ChartLegend.module.scss'
import { CHART_COLORS } from '../constants'
import classNames from 'classnames'
import { ChartLegendProps } from './types.ts'

const getDataSetIndicatorColor = ({ backupBackgroundColor, backgroundColor, legendStateInactive }: any): string => {
  if (legendStateInactive) return CHART_COLORS.gray
  return backupBackgroundColor || backgroundColor
}

const getIndicatorType = (dataset: any) => {
  if (dataset.type === 'line' || !dataset.backgroundColor) {
    return styles['line-indicator']
  }
  return styles.indicator
}

const ChartLegend = ({ chartData }: ChartLegendProps) => {
  return (
    <ul className={styles.legend}>
      {chartData.datasets.map((dataset: any) => (
        <li key={dataset.id || dataset.label}>
          <div className={classNames(styles['legend-item'], { [styles.inactive]: dataset.legendStateInactive })}>
            <div
              className={getIndicatorType(dataset)}
              style={
                dataset.type === 'line' || !dataset.backgroundColor
                  ? { borderColor: dataset.borderColor }
                  : { background: getDataSetIndicatorColor(dataset) }
              }
            />
            <span>{dataset.label}</span>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default ChartLegend
