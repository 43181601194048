import { StatusProps } from 'pages/App/Billing/Invoices/InvoicesTable/Status/types.ts'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import { getStatus } from 'pages/App/Billing/Invoices/InvoicesTable/Status/utils.ts'

const Status = ({ document, usesDirectDebit }: StatusProps) => {
  return (
    <Cell variant={getStatus({ document, usesDirectDebit: usesDirectDebit }).variant as CellVariant}>
      {getStatus({ document, usesDirectDebit: usesDirectDebit }).value}
    </Cell>
  )
}

export default Status
