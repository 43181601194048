export type AppStore = {
  betaMode: boolean
  language: Language
  redirect: string | null
  error: string | null
  urlSearchParams: {
    [key in UrlSearchParamsKeys]?: string
  }
}

// ENUMS

export enum Language {
  NL = 'nl',
  FR = 'fr'
}

export enum UrlSearchParamsKeys {
  CONTACT_ID = 'contact_id',
  CUSTOMER_EMAIL = 'customer_email',
  FIXED_PRICE_RENEWAL_CONFIRMATION = 'fixed_price_renewal_confirmation'
}
