import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Wind: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor, isFilled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      {isFilled ? (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M9.179 6.087a2.956 2.956 0 1 1 5.642 0l-.645 2.063a2.28 2.28 0 0 1-4.352 0l-.645-2.063ZM19.015 15.044a2.956 2.956 0 1 1-3.697 4.262l-1.136-1.839a2.28 2.28 0 0 1 2.852-3.288l1.98.865ZM8.059 19.359a2.956 2.956 0 1 1-3.545-4.39l2.01-.794A2.28 2.28 0 0 1 9.26 17.56l-1.2 1.798ZM12 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12 3.75c-.98 0-1.68.95-1.39 1.89l.65 2.06a.78.78 0 0 0 1.48 0l.65-2.06c.3-.94-.4-1.89-1.39-1.89ZM9.18 6.09a2.96 2.96 0 1 1 5.64 0l-.64 2.06a2.28 2.28 0 0 1-4.36 0l-.64-2.06ZM18.93 18.7c.65-.74.38-1.89-.52-2.28l-1.98-.87a.78.78 0 0 0-.97 1.13l1.13 1.84c.52.83 1.7.93 2.34.19Zm.08-3.66a2.96 2.96 0 1 1-3.7 4.27l-1.13-1.84a2.28 2.28 0 0 1 2.85-3.3l1.98.87ZM4.47 18.63c.62.77 1.8.71 2.34-.1l1.2-1.8a.78.78 0 0 0-.93-1.16l-2.01.8a1.46 1.46 0 0 0-.6 2.26Zm3.59.73a2.96 2.96 0 1 1-3.55-4.4l2.01-.79a2.28 2.28 0 0 1 2.74 3.4l-1.2 1.79ZM12 13.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm0 1.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
          clipRule="evenodd"
        />
      )}
    </svg>
  )
}

Wind.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Wind
