export type FeedbackWidgetType = {
  _id: string
  name: string
  slug: string
  createdAt: string
  isActive: boolean
}

// ENUMS

export enum WidgetSlug {
  ADDED_CONTRACT = 'added-contract',
  ADDED_METER_READINGS = 'added-meter-readings',
  CONSUMPTION_DYNAMIC_TARIFF_RELEASE = 'consumption-dynamic-tariff-release',
  CONSUMPTION_METER_READINGS = 'consumption-meter-readings',
  ENERGY_OVERVIEW = 'energy-overview',
  INSTALMENT_EDIT = 'instalment-edit',
  MOVE_FLOW = 'move-flow',
  PREVIOUS_BILLING_CYCLES = 'previous-billing-cycles',
  INVOICES = 'invoices'
}
