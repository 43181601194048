import { StatusProps } from 'pages/App/Billing/Invoices/InvoicesTable/Status/types.ts'
import { DocumentType } from 'types/types.ts'
import dayjs from 'dayjs'
import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import i18next from 'i18next'

/**
 * Returns the status as variant and text value, for a given document
 *
 * @param {StatusProps} props
 */
export const getStatus = ({ document, usesDirectDebit }: StatusProps) => {
  // Constants
  const isCustomerInvoice = document?.documentType === DocumentType.INVOICE
  const today = dayjs()
  const invoiceDueDate = dayjs(isCustomerInvoice ? document?.dueDate : '')

  // ORDER IS IMPORTANT
  // ------------------

  // 1. Payments
  if (document.documentType === DocumentType.PAYMENT) {
    return {
      variant: CellVariant.STATUS_PAYMENT_PAYED_BANKTRANSACTION,
      value: document.amount < 0 ? i18next.t('status.boltRecieved', { ns: 'invoices' }) : i18next.t('status.boltPayed', { ns: 'invoices' })
    }
  }

  // 2. 'Bolt has received this amount' / 'Bolt has paid this amount'
  if (document.amount < 0 && document.paid) {
    return {
      variant: CellVariant.STATUS_PAYMENT_PAYED,
      value: i18next.t('status.boltPayed', { ns: 'invoices' })
    }
  }

  // 3. “Will be refunded to you”
  if (document.amount < 0 && !document.paid) {
    return {
      variant: CellVariant.STATUS_PAYMENT_PAYED,
      value: i18next.t('status.willBeRefunded', { ns: 'invoices' })
    }
  }

  // 4. 'Paid' / 'Bolt has paid this amount'
  if (document.paid) {
    return {
      variant: CellVariant.STATUS_PAYMENT_PAYED,
      value: i18next.t('status.paid', { ns: 'invoices' })
    }
  }

  // 5. 'Direct debit failed'
  if (isCustomerInvoice && today.isAfter(invoiceDueDate.add(4, 'days'), 'day') && usesDirectDebit) {
    return {
      variant: CellVariant.STATUS_PAYMENT_OVERDUE,
      value: i18next.t('status.directDebitFailed', { ns: 'invoices' })
    }
  }

  // 6. 'Payable at XX/XX/XXXX' - bank transfer overdue
  if (isCustomerInvoice && today.isAfter(invoiceDueDate, 'day') && !usesDirectDebit) {
    return {
      variant: CellVariant.STATUS_PAYMENT_OVERDUE,
      value: `${i18next.t('status.paymentDue', { ns: 'invoices' })} ${dayjs(invoiceDueDate).format('DD/MM/YYYY')}`
    }
  }

  // 7. 'Payable at XX/XX/XXXX' - directDebit approaching
  if (isCustomerInvoice && invoiceDueDate.add(4, 'days').isAfter(today, 'day') && usesDirectDebit) {
    return {
      variant: CellVariant.STATUS_PAYMENT_OPEN_DOMICELED,
      value: `${i18next.t('status.directDebitDue', { ns: 'invoices' })} ${dayjs(invoiceDueDate).format('DD/MM/YYYY')}`
    }
  }

  // 8. 'Payable at XX/XX/XXXX' - bank transfer approaching
  if (isCustomerInvoice && invoiceDueDate.isAfter(today, 'day') && !usesDirectDebit) {
    return {
      variant: CellVariant.STATUS_PAYMENT_OPEN,
      value: `${i18next.t('status.paymentDue', { ns: 'invoices' })} ${dayjs(invoiceDueDate).format('DD/MM/YYYY')}`
    }
  }

  return {
    variant: '',
    value: ''
  }
}
