import Auth from 'pages/Auth/Auth'
import { FC, useEffect } from 'react'
import { Language } from 'store/app/types'
import { Store } from 'store/types'
import { Outlet } from 'react-router'
import { isAuthenticated } from 'utils/localStorageService'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from 'types/routes'
import { setUserType } from 'utils/userType'
import { UserTypes } from 'store/auth/types'
import { USER_TYPE_CONFIG } from 'constants/userTypes'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { switchLanguage } from 'store/app/thunks'

const AuthContainer: FC = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store: Store) => store.app)
  const dispatch = useStoreDispatch()

  // Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  /**
   * Handles the switch language action
   */
  const handleSwitchLanguage = () => {
    dispatch(
      switchLanguage({
        language: language === Language.NL ? Language.FR : Language.NL
      })
    )
  }

  useEffect(() => {
    const authenticatedUserTypes = isAuthenticated()
    const routesByUserType: { [key in UserTypes]: (routes | string)[] } = {
      [UserTypes.CUSTOMER]: [USER_TYPE_CONFIG.customer.login, routes.REGISTER],
      [UserTypes.SUPER_USER]: [USER_TYPE_CONFIG.superuser.login],
      [UserTypes.SALES]: [USER_TYPE_CONFIG.sales.login]
    }

    const routesToMatch = [
      ...routesByUserType[UserTypes.CUSTOMER],
      ...routesByUserType[UserTypes.SUPER_USER],
      ...routesByUserType[UserTypes.SALES]
    ]

    if (routesToMatch.includes(pathname as routes)) {
      for (const userType in routesByUserType) {
        // If the userType is already authenticated and the URL is the correct one, redirect to the correct page & set the userType
        if (authenticatedUserTypes[userType as UserTypes] && routesByUserType[userType as UserTypes].includes(pathname as routes)) {
          setUserType(userType as UserTypes)
          navigate(USER_TYPE_CONFIG[userType as UserTypes].home, { replace: true })
          break
        }
      }
    }
  }, [pathname])

  return (
    <Auth {...{ language, switchLanguage: handleSwitchLanguage }}>
      <Outlet />
    </Auth>
  )
}

export default AuthContainer
