import { Cloudinary } from '@cloudinary/url-gen'
import { CLOUDINARY_NAME } from 'constants/envs.ts'
import { Cloudinary as CloudinaryCore } from 'cloudinary-core'

// Cloudinary instance with our cloudinary name
export const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_NAME
  }
})

export const cld_core = new CloudinaryCore({ cloud_name: CLOUDINARY_NAME })
