import { Suspense, FC, useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import styles from './mapStyles.json'
import { GMAP_KEY } from 'constants/envs'
import { ProducerMapProps as Props } from './types'
import Marker from './Marker/Marker'

const ProducerMap: FC<Props> = ({ producerLocation, userLocation }) => {
  // Local state
  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)
  const [line, setLine] = useState<any>(null)

  // Constants
  const centerCoord = {
    lat: (producerLocation.lat + userLocation.lat) / 2,
    lng: (producerLocation.lng + userLocation.lng) / 2
  }

  /**
   * Triggered everytime the map, maps, producerLocation or userLocation changes
   */
  useEffect(() => {
    if (map && maps) {
      drawLine(map, maps)
    }
  }, [map, maps, producerLocation, userLocation])

  /**
   * Draws line from the producer location to the user location
   *
   * @param map
   * @param maps
   */
  const drawLine = (map: any, maps: any) => {
    if (line) line.setMap(null)

    const lineCoordinates = [producerLocation, userLocation]
    const polyLine = new maps.Polyline({
      path: lineCoordinates,
      strokeColor: 'hsl(167, 72%, 49%)',
      strokeOpacity: 1.0,
      strokeWeight: 3
    })

    setLine(polyLine)
    polyLine.setMap(map)
  }

  return (
    <Suspense fallback={<div className="card" />}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GMAP_KEY || '' }}
        center={centerCoord}
        defaultZoom={8}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map)
          setMaps(maps)
        }}
        options={{
          styles,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          draggable: true,
          zoomControl: true,
          minZoom: 4,
          maxZoom: 18,
          fullscreenControl: false,
          gestureHandling: 'cooperative'
        }}
      >
        <Marker icon="flash" lat={producerLocation.lat} lng={producerLocation.lng} />
        <Marker icon="home" lat={userLocation.lat} lng={userLocation.lng} />
      </GoogleMapReact>
    </Suspense>
  )
}

export default ProducerMap
