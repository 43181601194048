import styles from './Invoices.module.scss'
import classNames from 'classnames'
import InvoicesStatusBanner from 'pages/App/Billing/Invoices/InvoicesStatusBanner/InvoicesStatusBanner.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { hasMultipleActiveElectricityContracts } from 'utils/contracts.ts'
import Icon from 'components/Icon/Icon.tsx'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card.tsx'
import { Link, useLocation } from 'react-router-dom'
import { routes } from 'types/routes.ts'
import { UserTypes } from 'store/auth/types.ts'
import { AccountingEntity } from 'types/types.ts'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api'
import { Tab } from 'components/Subnav/types.ts'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget.ts'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import { useEffect, useMemo, useState } from 'react'
import SubnavWithoutNavigation from 'components/Subnav/SubNavWithoutNavigation.tsx'
import useInvoicesTable from 'pages/App/Billing/Invoices/hooks/useInvoicesTable.tsx'
import { InvoiceTableTabs } from 'pages/App/Billing/Invoices/hooks/types.ts'
import NoInvoices from 'pages/App/Billing/Invoices/NoInvoices/NoInvoices.tsx'
import InvoicesTable from 'pages/App/Billing/Invoices/InvoicesTable/InvoicesTable.tsx'
import TableLoadingSkeleton from 'pages/App/Billing/Invoices/TableLoadingSkeleton/TableLoadingSkeleton.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { INVOICES_TABLE_BREAKPOINT } from 'pages/App/Billing/Invoices/constants.ts'
import mixpanel from 'mixpanel-browser'
import { MX_INVOICE_FLOW } from 'constants/trackingIds.ts'

const Invoices = () => {
  // i18n
  const { t } = useTranslation('invoices')

  // Window size
  const { isSmaller } = useWindowSize(INVOICES_TABLE_BREAKPOINT)

  // Feedback widget
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget(10, WidgetSlug.INVOICES)

  // Redux State
  const { userType } = useStoreSelector((store) => store.auth)
  const isSuperUser = userType === UserTypes.SUPER_USER
  const { selectedCustomer, customers } = useStoreSelector((store) => store.user)

  // Redux query
  const { data, isLoading, isFetching } = useGetCustomerAccountingQuery({
    customerNumber: selectedCustomer
  })

  // React router
  const { search } = useLocation()
  const query = useMemo<URLSearchParams>(() => new URLSearchParams(search), [search])
  const tabFromQuery =
    query.get('tab') && Object.values(InvoiceTableTabs).includes(query.get('tab') as InvoiceTableTabs)
      ? (query.get('tab') as InvoiceTableTabs)
      : undefined

  // Local state
  const [tabs, setTabs] = useState<Tab[]>([])
  const [activeTab, setActiveTab] = useState<InvoiceTableTabs>(
    tabFromQuery || (isSuperUser ? InvoiceTableTabs.SUPER_USER_CUSTOMER : InvoiceTableTabs.OUTSTANDING_INVOICES)
  )

  // Table data
  const { headers, rows } = useInvoicesTable({ tab: activeTab })

  // Constants
  const customer = customers[selectedCustomer]

  // track invoice tab viewed event
  useEffect(() => {
    mixpanel.track(MX_INVOICE_FLOW.invoiceTabViewed, { tab: activeTab })
  }, [activeTab])

  useEffect(() => {
    const getSubNavTabs = () => {
      const tabs: Tab[] = [
        { label: t('subNav.outstandingInvoices'), path: InvoiceTableTabs.OUTSTANDING_INVOICES },
        { label: t('subNav.allInvoices'), path: InvoiceTableTabs.ALL_INVOICES },
        { label: t('subNav.allTransactions'), path: InvoiceTableTabs.ALL_TRANSACTIONS }
      ]

      if (isSuperUser) {
        const isAlsoSupplier = data?.documents?.some((doc) => doc.accountingEntity === AccountingEntity.SUPPLIER)
        if (isAlsoSupplier) {
          tabs.unshift({ label: t('subNav.superuserDistributor'), path: InvoiceTableTabs.SUPER_USER_SUPPLIER })
        }

        tabs.unshift({
          label: t(isAlsoSupplier ? 'subNav.superuserClient' : 'subNav.superuser'),
          path: InvoiceTableTabs.SUPER_USER_CUSTOMER
        })
      }

      setTabs(tabs)
    }

    getSubNavTabs()
  }, [data?.documents, isSuperUser])

  return (
    <>
      <div className={classNames('container', styles.container)}>
        {hasMultipleActiveElectricityContracts(customers) && (
          <div className={styles.information}>
            <Icon name="info" />
            <p>
              {t('invoicesInformation.text')} {selectedCustomer}
            </p>
          </div>
        )}

        <InvoicesStatusBanner />

        <Card as="section" className={styles.outlet}>
          <SubnavWithoutNavigation
            tabs={tabs}
            activeTabPath={activeTab}
            setActiveTab={(path) => setActiveTab(path as InvoiceTableTabs)}
            className={styles.subnav}
            noPushElement
          />
          {isLoading || isFetching ? (
            <TableLoadingSkeleton {...{ isSmaller }} />
          ) : rows.length ? (
            <InvoicesTable {...{ isSmaller, headers, rows, activeTab }} />
          ) : (
            <NoInvoices tab={activeTab} setActiveTab={(tab) => setActiveTab(tab)} />
          )}
        </Card>

        {!customer?.paymentDetails?.directDebit && (
          <Card as="section">
            <Card.Title as="h2">{t('paymentMethodBanner.title')}</Card.Title>
            <p className="mb-300">{t('paymentMethodBanner.text')}</p>
            <Link to={routes.USER_PAYMENT}>{t('paymentMethodBanner.linkText')}</Link>
          </Card>
        )}
      </div>

      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default Invoices
