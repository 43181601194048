import { BadgeColors, BadgeProps } from './types'
import styles from './Badge.module.scss'
import classNames from 'classnames'
import { Icon } from '@boltenergy-be/design-system'

const Badge = ({ className, color = BadgeColors.ORANGE, icons, text }: BadgeProps) => {
  return (
    <span className={classNames(styles.badge, styles[color ?? BadgeColors.ORANGE], className)}>
      {!!icons && (
        <>
          {Array.isArray(icons) ? (
            <div className={styles.icons}>
              {icons.map((icon) => (
                <Icon name={icon} key={icon} />
              ))}
            </div>
          ) : (
            <Icon name={icons} />
          )}
        </>
      )}
      {text}
    </span>
  )
}

export default Badge
