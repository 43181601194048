const Invoices = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.5 19.75a2.25 2.25 0 0 1-2.25-2.25V9.75h19.5v7.75a2.25 2.25 0 0 1-2.25 2.25h-15Zm17.25-11.5V6.5a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 2.25 6.5v1.75h19.5ZM6 11.2c0-.11.09-.2.2-.2h2.6c.11 0 .2.09.2.2v1.6a.2.2 0 0 1-.2.2H6.2a.2.2 0 0 1-.2-.2v-1.6Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Invoices
