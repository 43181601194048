import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Slider from '@mui/material/Slider'
import classNames from 'classnames'
import { Store } from 'store/types'
import { formatAmount } from 'utils/format'
import { AddContractStepProps, AddContractSteps, InstalmentStepError, SimulationType } from '../../types'
import contractStyles from '../../../Contracts.module.scss'
import styles from './InstalmentStep.module.scss'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { roundToTwoDecimals } from 'utils/number'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import Toggle from 'components/Toggle/Toggle'
import { ELProduct } from 'types/products'
import parse from 'html-react-parser'
import { BOLT_GO_PRICES } from 'constants/products'
import { EnergyType } from 'types/types'
import Card from 'components/Card/Card.tsx'
import EditableInstalmentField from 'features/contracts/add/components/EditableInstalmentField/EditableInstalmentField.tsx'
import StepSummary from 'features/contracts/add/components/StepSummary/StepSummary.tsx'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'

const InstalmentStep = ({ isMove, isSales, setCurrentStep, setNextStep, addContractData }: AddContractStepProps) => {
  // Redux
  const { selectedContracts } = useSelector((store: Store) => store.user)

  // Local state
  const [instalmentTotal, setInstalmentTotal] = useState<number>(0)
  const [instalmentElectricity, setInstalmentElectricity] = useState<number>(0)
  const [instalmentGas, setInstalmentGas] = useState<number>(0)
  const [editingInstalmentElectricity, setEditingInstalmentElectricity] = useState<boolean>(false)
  const [editingInstalmentGas, setEditingInstalmentGas] = useState<boolean>(false)
  const [error, setError] = useState<InstalmentStepError>()
  const [simulationType, setSimulationType] = useState<SimulationType>(addContractData.instalmentData.simulationType)

  // i18n
  const { t } = useTranslation('contracts')

  // Contracts
  const contracts = isSales ? undefined : getMemoizedSelectedContracts(selectedContracts)

  // Constants
  const { needsGas } = addContractData.address
  const minInstalment = 20
  const currentElectricityInstalment = isSales ? (needsGas ? 25 : 50) : Number(contracts?.electricityContract.detail.instalment) || 20
  const currentGasInstalment = isSales ? (needsGas ? 25 : 0) : Number(contracts?.gasContract?.detail.instalment) || 0
  const currentInstalment = roundToTwoDecimals(currentElectricityInstalment + currentGasInstalment)
  const maxInstalment = useMemo<number>(() => (currentInstalment ? Math.ceil(currentInstalment / 500) * 500 : 500), [currentInstalment])
  const isBoltGo = addContractData[AddContractSteps.PRODUCT].electricity === ELProduct.Go

  /**
   * Function to divide gas and electricity instalment if total instalment changes
   * @param {number} value
   */
  const setInstalments = (value: number) => {
    if (isSales && needsGas) {
      recalculateTotalInstalment()
    } else {
      setInstalmentTotal(value)
    }

    if (needsGas) {
      const elRatio = currentElectricityInstalment === currentInstalment ? 0.5 : currentElectricityInstalment / currentInstalment
      const gasRatio = 1 - elRatio
      setInstalmentElectricity(roundToTwoDecimals(value * elRatio))
      setInstalmentGas(roundToTwoDecimals(value * gasRatio))
    } else {
      setInstalmentElectricity(roundToTwoDecimals(value))
    }
  }

  /**
   * Function that recalculates total instalment after user changed gas or electricity instalment through input
   */
  const recalculateTotalInstalment = () => {
    validate()
    setInstalmentTotal(roundToTwoDecimals(instalmentElectricity + instalmentGas))
  }

  const validate = () => {
    if (simulationType === SimulationType.FIXED_AMOUNT) {
      if (!isSales && instalmentTotal < 20) {
        setError({ type: 'total', message: t('add.steps.instalment.form.errors.instalmentTotal') })
        return false
      }
      if (needsGas) {
        if (instalmentElectricity === 0) {
          setError({ type: 'electricity', message: t('add.steps.instalment.form.errors.instalmentElectricity') })
          return false
        }
        if (instalmentGas === 0) {
          setError({ type: 'gas', message: t('add.steps.instalment.form.errors.instalmentGas') })
          return false
        }
      }
    }

    setError(undefined)
    return true
  }

  const onSubmit = () => {
    const valid = validate()

    if (valid) {
      // Clear errors
      setError(undefined)

      // Save data to addContractData state object
      const newInstalmentData = {
        simulationType,
        instalments: [SimulationType.FIXED_AMOUNT, SimulationType.BOLT_GO].includes(simulationType)
          ? {
              electricity: needsGas ? instalmentElectricity : instalmentTotal,
              ...(needsGas && { gas: instalmentGas })
            }
          : {
              electricity: 0
            }
      }

      setNextStep(newInstalmentData, AddContractSteps.METER_DETAILS)
    }
  }

  /**
   * Set default instalment values
   */
  useEffect(() => {
    if (isBoltGo) {
      if ([SimulationType.EAV, SimulationType.FIXED_AMOUNT].includes(addContractData.instalmentData.simulationType))
        setSimulationType(SimulationType.BOLT_GO)
      if (needsGas) {
        setInstalmentElectricity(BOLT_GO_PRICES[EnergyType.ELECTRICITY])
        setInstalmentGas(BOLT_GO_PRICES[EnergyType.GAS])
        setInstalmentTotal(BOLT_GO_PRICES[EnergyType.ELECTRICITY] + BOLT_GO_PRICES[EnergyType.GAS])
      } else {
        setInstalmentTotal(BOLT_GO_PRICES[EnergyType.ELECTRICITY])
      }
    } else if (addContractData.instalmentData.instalments.electricity !== 0) {
      if (simulationType === SimulationType.BOLT_GO) setSimulationType(SimulationType.EAV)
      const { instalmentData } = addContractData

      if (simulationType === SimulationType.FIXED_AMOUNT) {
        if (typeof instalmentData.instalments.gas !== 'undefined' && needsGas) {
          setInstalmentElectricity(instalmentData.instalments.electricity)
          setInstalmentGas(instalmentData.instalments.gas)
          setInstalmentTotal(instalmentData.instalments.electricity + instalmentData.instalments.gas)
        } else {
          setInstalmentTotal(instalmentData.instalments.electricity)
        }
      }
    } else {
      // Set default instalment to instalment of current address
      if (needsGas) {
        const hasNoGas = roundToTwoDecimals(currentElectricityInstalment) === currentInstalment && !currentGasInstalment
        setInstalmentElectricity(roundToTwoDecimals(hasNoGas ? currentElectricityInstalment / 2 : currentElectricityInstalment))
        setInstalmentGas(roundToTwoDecimals(hasNoGas ? currentElectricityInstalment / 2 : currentGasInstalment))
        setInstalmentTotal(currentInstalment)
      } else {
        setInstalmentTotal(currentInstalment)
      }
    }
  }, [isBoltGo, currentInstalment, needsGas])

  return (
    <div className={classNames(contractStyles['form-card'], styles['instalment-step'])}>
      <div className={contractStyles['form-content']}>
        <Card.Title>{t(isBoltGo ? 'add.steps.instalment.boltGo.title' : 'add.steps.instalment.title')}</Card.Title>

        {!isBoltGo && (
          <Toggle
            active={simulationType}
            onClick={(value) => setSimulationType(value)}
            options={[
              { value: SimulationType.EAV, label: t('add.steps.instalment.form.EAV.toggleLabel') },
              { value: SimulationType.FIXED_AMOUNT, label: t('add.steps.instalment.form.fixedAmount.toggleLabel') }
            ]}
          />
        )}
        {simulationType === SimulationType.EAV ? (
          <p className={styles.description}>{t('add.steps.instalment.form.EAV.description')}</p>
        ) : isBoltGo ? (
          <div className={styles['description-container']}>{parse(t('add.steps.instalment.boltGo.description'))}</div>
        ) : (
          <>
            <div className={styles.description}>
              {isSales
                ? t(`add.steps.instalment.form.fixedAmount.description.${isMove ? 'moveSales' : 'addSales'}`)
                : t(`add.steps.instalment.form.fixedAmount.description.${isMove ? 'move' : 'add'}`)}
            </div>
            <div className={styles['slider-container']}>
              <Slider
                className={styles['instalment-slider']}
                min={minInstalment}
                max={maxInstalment}
                step={5}
                valueLabelDisplay="on"
                valueLabelFormat={(value) => `€ ${formatAmount(value)}`}
                onChange={(_, value) => setInstalments(Number(value))}
                value={instalmentTotal}
              />
              <div className={classNames(styles['mark-label'], styles['mark-label-min'])}>
                <data value={formatAmount(minInstalment)} className={styles.positioner}>
                  € {formatAmount(minInstalment)}
                </data>
              </div>
              <div className={classNames(styles['mark-label'], styles['mark-label-max'])}>
                <data value={formatAmount(maxInstalment)} className={styles.positioner}>
                  € {formatAmount(maxInstalment)}
                </data>
              </div>
            </div>
          </>
        )}

        {simulationType !== SimulationType.EAV && (
          <StepSummary
            className={styles.summary}
            badgeText={t(isBoltGo ? 'add.steps.instalment.boltGo.badgeText' : 'add.steps.instalment.form.stepSummary.badgeText')}
          >
            <div className={styles['instalments-container']}>
              {needsGas && (
                <div>
                  {/* ELECTRICITY */}
                  <EditableInstalmentField
                    activeStateOnly={!!isSales}
                    isError={error?.type === 'electricity'}
                    value={instalmentElectricity}
                    setValue={(newValue) => setInstalmentElectricity(newValue)}
                    recalculateTotalInstalment={recalculateTotalInstalment}
                    name="electricityInstalment"
                    label={t('add.steps.instalment.form.stepSummary.fixedAmount.labels.instalmentElectricity')}
                    setEditingCheck={(state) => setEditingInstalmentElectricity(state)}
                    notEditable={isBoltGo}
                  />

                  {/* GAS */}
                  <EditableInstalmentField
                    activeStateOnly={!!isSales}
                    isError={error?.type === 'gas'}
                    value={instalmentGas}
                    setValue={(newValue) => setInstalmentGas(newValue)}
                    recalculateTotalInstalment={recalculateTotalInstalment}
                    name="electricityInstalment"
                    label={t('add.steps.instalment.form.stepSummary.fixedAmount.labels.instalmentGas')}
                    setEditingCheck={(state) => setEditingInstalmentGas(state)}
                    notEditable={isBoltGo}
                  />
                </div>
              )}
              {(!isSales || (isSales && !needsGas)) && (
                <fieldset className="form-group">
                  <label
                    htmlFor="totalInstalment"
                    className={classNames({
                      [styles.error]: error?.type === 'total'
                    })}
                  >
                    {needsGas
                      ? t(
                          isBoltGo
                            ? 'add.steps.instalment.boltGo.instalmentTotal'
                            : 'add.steps.instalment.form.stepSummary.fixedAmount.labels.instalmentTotal'
                        )
                      : t(
                          isBoltGo
                            ? 'add.steps.instalment.boltGo.instalmentTotalOnlyElec'
                            : 'add.steps.instalment.form.stepSummary.fixedAmount.labels.instalmentTotalOnlyElec'
                        )}
                  </label>
                  <div className={classNames(styles['input-wrapper'], 'prepend-input-wrapper')}>
                    <span className="prepend">€</span>
                    <input
                      id="totalInstalment"
                      type="number"
                      step="1"
                      min={minInstalment}
                      style={{ width: `calc(1.5rem + ${maxInstalment.toFixed(2).length}ch)` }}
                      className={classNames(styles['total-instalment-input'], 'form-control')}
                      value={instalmentTotal}
                      disabled={isBoltGo}
                      onChange={(e) => {
                        setInstalments(Number(e.target.value))
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onSubmit()
                        }
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </div>
                </fieldset>
              )}
            </div>
          </StepSummary>
        )}

        {simulationType === SimulationType.FIXED_AMOUNT && error && (
          <p className={classNames('text-negative', styles['text-error'])}>{error.message}</p>
        )}
      </div>

      <FormButtons
        currentStep={AddContractSteps.INSTALMENT_DATA}
        setCurrentStep={(step) => setCurrentStep(step as AddContractSteps)}
        submitDisabled={editingInstalmentElectricity || editingInstalmentGas}
        trackingId={ContractFlowTrackingTypes.ADD}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default InstalmentStep
