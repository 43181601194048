import { EditableTextFieldProps } from './types'
import styles from './EditableFields.module.scss'
import { useTranslation } from 'react-i18next'
import PhoneFormField from 'components/FormFields/PhoneFormField/PhoneFormField'
import { useFormContext } from 'react-hook-form'
import { maskWithAsterisk } from 'utils/format.ts'

const EditablePhoneField = ({ isEditing, masked, name, required = false, value }: EditableTextFieldProps) => {
  // i18n
  const { t } = useTranslation('sales')

  // React Hook Form
  const hookForm = useFormContext()
  const {
    formState: { errors }
  } = hookForm

  return (
    <div className={styles.phone}>
      {isEditing ? (
        <PhoneFormField
          defaultValue={value}
          errors={errors}
          isRequired={required}
          label={t('details.contactData.phone')}
          hookFormSelector={name}
          {...hookForm}
        />
      ) : (
        <span>{masked ? maskWithAsterisk(value) : value}</span>
      )}
    </div>
  )
}

export default EditablePhoneField
