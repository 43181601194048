import Card from 'components/Card/Card.tsx'
import { CardVariants } from 'components/Card/types.ts'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from './YourAddresses.module.scss'
import { useStoreSelector } from 'hooks/store.ts'
import { formatContractStatus, getMemoizedContractsLibrary } from 'utils/contracts.ts'
import Badge from 'components/Badge/Badge.tsx'
import { AvailableContract } from 'components/AddressPicker/types.ts'
import { ContractStatus } from 'pages/App/Move/YourAddresses/types.ts'
import dayjs from 'dayjs'
import FooterFaq from 'components/Faq/FooterFaq/FooterFaq'
import { Language } from 'store/app/types'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { FaqSectionSlugs } from 'types/faq'

const YourAddresses = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { customers } = useStoreSelector((store) => store.user)

  // i18n
  const { t } = useTranslation('move')

  // Constants
  const contracts = getMemoizedContractsLibrary({ customers })

  // API
  const { data: faq, isLoading: faqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.MOVE_FR : FaqSectionSlugs.MOVE_NL
  })

  /**
   * Get the contract status
   * @param {AvailableContract} contract
   * @returns {ContractStatus[]}
   */
  const getContractStatus = ({ serviceContracts }: AvailableContract): ContractStatus[] => {
    const electricityStatus = formatContractStatus(serviceContracts.electricityContract)
    const gasStatus = serviceContracts?.gasContract ? formatContractStatus(serviceContracts.gasContract) : null

    if (!gasStatus) {
      return [
        {
          color: electricityStatus.color,
          icons: ['electricity'],
          type: 'EL',
          text: t(`yourAddresses.status.${electricityStatus.key}`, { date: formatStatusDate(electricityStatus.date) })
        }
      ]
    }

    if (JSON.stringify(electricityStatus) === JSON.stringify(gasStatus)) {
      return [
        {
          color: electricityStatus.color,
          icons: ['electricity', 'gas'],
          type: 'EL+GAS',
          text: t(`yourAddresses.status.${electricityStatus.key}`, { date: formatStatusDate(electricityStatus.date) })
        }
      ]
    }

    return [
      {
        color: electricityStatus.color,
        icons: ['electricity'],
        type: 'EL',
        text: t(`yourAddresses.status.${electricityStatus.key}`, { date: formatStatusDate(electricityStatus.date) })
      },
      {
        color: gasStatus.color,
        icons: ['gas'],
        type: 'GAS',
        text: t(`yourAddresses.status.${gasStatus.key}`, { date: formatStatusDate(gasStatus.date) })
      }
    ]
  }

  const formatStatusDate = (date: string | undefined) => {
    return dayjs(date).locale(language).format('D MMMM YYYY')
  }

  return (
    <>
      <Card variant={CardVariants.DEFAULT_MORE_PADDING} className={classNames('container', styles['your-addresses'])} as="section">
        <header>
          <Card.Title as="h1" variant="h3">
            {t('yourAddresses.title')}
          </Card.Title>
          <p>{t('yourAddresses.description')}</p>
        </header>

        <ul className={styles['address-list']}>
          {Object.values(contracts).map((contract) => {
            const statuses = getContractStatus(contract)

            return (
              <li key={contract.id}>
                <div className={styles.address}>
                  <strong>
                    {contract.serviceContracts.electricityContract.address.streetName}{' '}
                    {contract.serviceContracts.electricityContract.address.streetNumber}
                    {contract.serviceContracts.electricityContract.address.streetBox
                      ? `, ${contract.serviceContracts.electricityContract.address.streetBox}`
                      : ''}
                  </strong>
                  <span>
                    {contract.serviceContracts.electricityContract.address.postalCode}{' '}
                    {contract.serviceContracts.electricityContract.address.townName}
                  </span>
                </div>

                <div className={styles.statuses}>
                  {statuses.map((status) => (
                    <Badge key={`${contract.id}-${status.type}`} className={styles.badge} {...status} />
                  ))}
                </div>
              </li>
            )
          })}
        </ul>
      </Card>
      <FooterFaq isLoading={faqLoading} category={faq?.category} subtitle={t('faq.subtitle')} />
    </>
  )
}

export default YourAddresses
