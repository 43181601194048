import { ContractsIconProps } from 'assets/svg/contracts/types.ts'

const Move = ({ accent = 'currentColor' }: ContractsIconProps) => {
  // Constants
  const isCurrentColor = accent === 'currentColor'

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M24.3333 12.5542C24.3333 11.5664 23.8396 10.644 23.0177 10.0961L15.0542 4.78714C14.0121 4.09238 12.6544 4.09238 11.6123 4.78714L3.64877 10.0961C2.82691 10.644 2.33325 11.5664 2.33325 12.5542V23.8363C2.33325 25.5498 3.72232 26.9388 5.43582 26.9388H21.2307C22.9442 26.9388 24.3333 25.5498 24.3333 23.8363L24.3333 12.5542Z"
          fill={isCurrentColor ? accent : `var(${accent})`}
          fillOpacity={isCurrentColor ? '0.1' : '1'}
        />
        <path
          d="M15 20H31M31 20L26.9444 16M31 20L26.9444 24"
          stroke={isCurrentColor ? accent : 'var(--text-default)'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Move
