const {
  VITE_API_URL,
  VITE_CMS_URL,
  VITE_FACEBOOK_APP_ID,
  VITE_GMAP_KEY,
  VITE_GMAP_STATIC_KEY,
  VITE_MIXPANEL_TOKEN,
  VITE_NODE_ENV,
  VITE_SYSTEM_DOWN,
  VITE_WEBSITE_URL,
  VITE_CLOUDINARY_CLOUD_NAME
} = import.meta.env

export const API_URL = VITE_API_URL ?? ''
export const NODE_ENV = VITE_NODE_ENV ?? 'staging'
export const GMAP_KEY = VITE_GMAP_KEY
export const CMS_URL = VITE_CMS_URL
export const GMAP_STATIC_KEY = VITE_GMAP_STATIC_KEY
export const WEBSITE_URL = VITE_WEBSITE_URL
export const MIXPANEL_TOKEN = VITE_MIXPANEL_TOKEN as string
export const FACEBOOK_APP_ID = VITE_FACEBOOK_APP_ID as string
export const SYSTEM_DOWN = VITE_SYSTEM_DOWN === 'true'
export const CLOUDINARY_NAME = VITE_CLOUDINARY_CLOUD_NAME
