const DownLoadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99999 3.33325V12.4999V3.33325ZM9.99999 12.4999L13.3333 9.16659L9.99999 12.4999ZM9.99999 12.4999L6.66666 9.16659L9.99999 12.4999Z"
        fill="currentcolor"
      />
      <path
        d="M9.99999 3.33325V12.4999M9.99999 12.4999L13.3333 9.16659M9.99999 12.4999L6.66666 9.16659"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 12.5C2.5 14.8336 2.5 14.3337 2.95414 15.225C3.35361 16.009 3.99103 16.6464 4.77504 17.0458C5.66634 17.5 6.83312 17.5 9.16667 17.5H10.8333C13.1669 17.5 14.3337 17.5 15.225 17.0458C16.009 16.6464 16.6464 16.009 17.0458 15.225C17.5 14.3337 17.5 14.8336 17.5 12.5"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownLoadIcon
