import { CaseReducer } from '@reduxjs/toolkit'
import { MoveFlows, UserStore } from 'store/user/types'

const clearSalesCustomerData: CaseReducer<UserStore> = (state) => {
  return {
    ...state,
    customers: {},
    producers: [],
    move: {
      flow: MoveFlows.START
    }
    // TODO: figure out if these are still necessary
    // userSuccess: false,
    // userLoading: false
  }
}

export const salesReducers = {
  clearSalesCustomerData
}
