import { FC, useEffect, useRef } from 'react'
import { InfoModalProps as Props } from 'components/EnergyOverview/InfoModal/types'
import styles from './InfoModal.module.scss'
import { Close } from 'assets/svg'

// Make sure the parent has position: relative for the backdrop
const InfoModal: FC<Props> = ({ isOpen, closeFn, children }) => {
  // Ref
  const modal = useRef<HTMLDialogElement>(null)

  // Close on escape event listener
  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeFn()
      }
    }

    document.addEventListener('keydown', (e) => closeOnEscape(e))

    return () => {
      document.removeEventListener('keypress', (e) => closeOnEscape(e))
    }
  }, [closeFn, modal])

  return (
    <>
      {isOpen && <div className={styles.backdrop} />}
      <dialog ref={modal} className={styles.dialog} open={isOpen}>
        {children}
        <form method="dialog">
          <button aria-label="close" className={styles.close} onClick={closeFn}>
            <Close className={styles.icon} />
          </button>
        </form>
      </dialog>
    </>
  )
}

export default InfoModal
