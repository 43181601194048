import { icons } from '@boltenergy-be/design-system/dist/components/icon/icons'

export type BadgeProps = {
  className?: string
  color?: BadgeColors
  icons?: keyof typeof icons | Array<keyof typeof icons>
  text: string
}

export enum BadgeColors {
  GREEN = 'green',
  GREY = 'grey',
  ORANGE = 'orange',
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue'
}
