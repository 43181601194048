import { Methods } from 'types/request'
import request, { getAxiosRequestConfig } from 'utils/request'

/**
 * Logs the billingCycleViewed event in the API
 *
 * @param {string} billingContractNumber
 */
export const logBillingCycleViewedEvent = (billingContractNumber: string) => {
  request(getAxiosRequestConfig(Methods.POST, `/events/${billingContractNumber}/billing-cycle-viewed`))
}
