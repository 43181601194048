import { Children, PropsWithChildren } from 'react'
import Marquee from 'react-easy-marquee'
import useWindowSize from 'hooks/useWindowSize'
import { VerticalSliderProps } from './types'
import styles from './VerticalSlider.module.scss'

const VerticalSlider = ({ children, duration = 12500, reverse = false }: PropsWithChildren<VerticalSliderProps>) => {
  // Window size
  const { isMobile } = useWindowSize()

  return (
    <Marquee axis="Y" height="100%" pauseOnHover={!isMobile} duration={duration} reverse={reverse}>
      {Children.map(children, (child) => {
        return <div className={styles['vertical-slider-item']}>{child}</div>
      })}
    </Marquee>
  )
}

export default VerticalSlider
