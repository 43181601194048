import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Store } from 'store/types'
import { ContactMode, Town } from 'types/types'
import { LETTERS_NUMBERS_SLASHES_DASHES, POSTAL_CODE } from 'constants/regex'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { updateBilling, updateCustomer } from 'store/user/thunks/customer'
import Card from 'components/Card/Card.tsx'
import { Button, Heading } from '@boltenergy-be/design-system'
import { ContractResponseCodes } from 'types/errorCodes.ts'
import styles from './Contact.module.scss'
import Toggle from 'components/Toggle/Toggle.tsx'
import { ContactFormInputs } from 'pages/App/User/Payment/types.ts'

const Contact: FC = () => {
  // REDUX
  const { error, loading, selectedCustomer, customers, selectedContracts } = useStoreSelector((store: Store) => store.user)
  const customer = customers[selectedCustomer]
  const dispatch = useStoreDispatch()

  // Contracts
  const { billingContract } = getMemoizedSelectedContracts(selectedContracts)

  // i18n
  const { t } = useTranslation('user')

  // Local state
  const [saved, setSaved] = useState(false)
  const [towns, setTowns] = useState<Town[]>([])

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<ContactFormInputs>({
    mode: 'onChange',
    defaultValues: {
      emailContactMode: customer.contact.contactMode === ContactMode.EMAIL,
      address: customer.address
    }
  })
  const watchTownName = watch('address.townName')

  /**
   * Fetches the towns based on the current postal code
   * Triggered on first render
   */
  useEffect(() => {
    if (customer.address.postalCode) fetchTowns(Number(customer.address.postalCode))
  }, [])

  /**
   * Fetches the towns
   *
   * @param postalCode
   */
  const fetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      try {
        clearErrors()

        // Fetch the towns & the region
        const { success, data } = await request(getAxiosRequestConfig(Methods.GET, `towns/${postalCode}`))

        if (success) {
          const { towns } = data
          setTowns(towns)

          const filteredTowns = towns.filter((town: Town) => town.townName === customer.address.townName)
          setValue('address.townName', filteredTowns.length > 0 ? filteredTowns[0].townName : towns[0].townName)
        } else {
          setError('address.postalCode', { type: 'validate' })
        }
      } catch (e) {
        setError('address.postalCode', { type: 'api' })
      }
    } else {
      setError('address.postalCode', { type: 'validate' })
    }
  }

  /**
   * Returns if a given postal code is valid
   *
   * @param postalCode
   * @returns boolean
   */
  const isValidPostalCode = (postalCode: number): boolean => {
    return postalCode.toString().length === 4
  }

  /**
   * Handles the form submission after validation by React Hook Form
   *
   * @param {ContactFormInputs} data
   */
  const onSubmit = (data: ContactFormInputs) => {
    // Update the customer (only map the fields that have changed since it's a PATCH request)
    dispatch(
      updateCustomer({
        customerId: customer.id,
        customer: {
          address: {
            ...customer.address,
            streetName: data.address.streetName,
            streetBox: data.address.streetBox,
            streetNumber: data.address.streetNumber,
            postalCode: data.address.postalCode,
            townName: data.address.townName
          },
          contact: {
            contactMode: data.emailContactMode ? ContactMode.EMAIL : ContactMode.POST
          }
        }
      })
    )

    // Update billing contract
    dispatch(
      updateBilling({
        billingContractId: billingContract.id,
        updateBillingContractData: {
          deliveryMode: data.emailContactMode ? ContactMode.EMAIL : ContactMode.POST
        }
      })
    )

    setSaved(true)
  }

  return (
    <div className="container">
      <Card>
        <Heading as="h1" variant="h3">
          {t('contact.title')}
        </Heading>
        <p>{t('contact.description')}</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading as="h2" variant="h5" className="mt-500">
            {t('contact.fields.preferences.title')}
          </Heading>

          <div className="row">
            <div className="column form-group">
              <label htmlFor="emailContactMode">{t('contact.fields.preferences.label')}</label>
              <Controller
                name="emailContactMode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    active={value}
                    onClick={onChange}
                    options={[
                      { value: true, label: t('contact.fields.preferences.receiveEmail') },
                      { value: false, label: t('contact.fields.preferences.receivePost') }
                    ]}
                  />
                )}
              />
            </div>
          </div>

          <Heading as="h2" variant="h5" className="mt-500">
            {t('contact.fields.billing.title')}
          </Heading>

          <div className="row">
            {/*  STREET */}
            <div className={classNames('column form-group', styles.streetName)}>
              <label htmlFor="address.streetName">{t('contact.fields.billing.streetName')}</label>
              <input
                {...register('address.streetName', { required: true })}
                className={classNames('form-control', { error: errors?.address?.streetName })}
              />
              {errors?.address?.streetName && (
                <span className="help-block text-negative">
                  {errors.address.streetName.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>

            {/* STREET NUMBER */}
            <div className="column form-group">
              <label htmlFor="address.streetNumber">{t('contact.fields.billing.streetNumber')}</label>
              <input
                maxLength={5}
                {...register('address.streetNumber', {
                  required: true,
                  maxLength: 5,
                  pattern: LETTERS_NUMBERS_SLASHES_DASHES
                })}
                className={classNames('form-control', { error: errors?.address?.streetNumber })}
              />
              {errors?.address?.streetNumber && (
                <span className="help-block text-negative">
                  {errors.address.streetNumber.type === 'required' && t('required', { ns: 'validation' })}
                  {(errors.address.streetNumber.type === 'maxLength' || errors?.address?.streetName?.type === 'pattern') &&
                    t('invalid.streetNumber', { ns: 'validation' })}
                </span>
              )}
            </div>

            {/* STREET BOX */}
            <div className="column form-group">
              <label htmlFor="address.streetBox">{t('contact.fields.billing.streetBox')}</label>
              <input
                maxLength={5}
                {...register('address.streetBox')}
                className={classNames('form-control', { error: errors?.address?.streetBox })}
              />
            </div>
          </div>

          <div className="row">
            {/* POSTAL CODE */}
            <div className={classNames('column form-group')}>
              <label htmlFor="address.postalCode">{t('contact.fields.billing.postalCode')}</label>
              <input
                {...register('address.postalCode', {
                  required: true,
                  pattern: POSTAL_CODE,
                  validate: (val) => isValidPostalCode(Number(val))
                })}
                className={classNames('form-control', { error: errors?.address?.postalCode })}
                onChange={(e) => fetchTowns(Number(e.target.value))}
              />
              {errors?.address?.postalCode && (
                <span className="help-block text-negative">
                  {errors.address.postalCode.type === 'required' && t('required', { ns: 'validation' })}
                  {(errors.address.postalCode.type === 'pattern' || errors.address.postalCode.type === 'validate') &&
                    t('invalid.postalCode', { ns: 'validation' })}
                </span>
              )}
            </div>

            {/* TOWN NAME */}
            <div className="column form-group">
              <label htmlFor="address.townName">{t('contact.fields.billing.townName')}</label>
              <select {...register('address.townName', { required: true })} value={watchTownName} className={classNames('form-control')}>
                {towns.map((town) => (
                  <option key={town.townCode} value={town.townName}>
                    {town.townName}
                  </option>
                ))}
              </select>
              {errors?.address?.townName && (
                <span className="help-block text-negative">
                  {errors.address.townName.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>
          </div>

          <Button type="submit" disabled={Object.keys(errors).length > 0} loading={loading}>
            {t('save', { ns: 'common' })}
          </Button>

          {!loading && saved && (
            <span className="help-block">
              {(error === null ||
                (error !== 'updateCustomer' &&
                  error !== 'updateBilling' &&
                  error !== ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND)) && (
                <span className="text-positive">{t('formFeedback.saved', { ns: 'common' })}</span>
              )}
              {error &&
                (error === 'updateCustomer' ||
                  error === 'updateBilling' ||
                  error === ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND) && (
                  <span className="text-negative">
                    {error === ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND &&
                      t('payment.errors.mercatorErrorNotFound', { ns: 'user' })}
                    {t('error', { ns: 'common' })}
                  </span>
                )}
            </span>
          )}
        </form>
      </Card>
    </div>
  )
}

export default Contact
