import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import StackedInvoicesView from 'pages/App/Billing/Invoices/InvoicesTable/StackedInvoicesView/StackedInvoicesView.tsx'
import { Table } from 'components/Table/Table.tsx'
import { TableHeaderValueType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'
import styles from 'pages/App/Billing/Invoices/InvoicesTable/InvoiceTable.module.scss'
import { TableLoadingSkeletonProps } from 'pages/App/Billing/Invoices/TableLoadingSkeleton/types.ts'

const TableLoadingSkeleton = ({ isSmaller }: TableLoadingSkeletonProps) => {
  // Constants
  const placeholderHeaders = Array.from({ length: 4 }, (idx: number) => ({
    value: <LoadingSkeleton.Paragraph key={idx} lines={1} />,
    id: ''
  }))
  const placeholderCells = Array.from({ length: 4 }, () => ({ value: <LoadingSkeleton.Paragraph lines={1} /> }))
  const placeholderRows = Array.from({ length: 4 }, () => ({ cells: placeholderCells }))

  return (
    <LoadingSkeleton>
      {isSmaller ? (
        <StackedInvoicesView rows={placeholderRows} />
      ) : (
        <Table headers={placeholderHeaders as unknown as TableHeaderValueType[]} rows={placeholderRows} className={styles.table} />
      )}
    </LoadingSkeleton>
  )
}

export default TableLoadingSkeleton
