import styles from './ConsumptionSection.module.scss'
import { Banner, Heading } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card.tsx'
import LoadingDots from 'components/LoadingDots/LoadingDots.tsx'
import ButtonMeterReadings from 'features/MeterReadings/ButtonMeterReadings/ButtonMeterReadings.tsx'
import { Granularity, MeterReadingSource } from 'types/contracts.ts'
import { FormProvider, useForm } from 'react-hook-form'
import ConsumptionNavigation from 'components/Charts/ConsumptionNavigation/ConsumptionNavigation.tsx'
import { ProductType } from 'types/types.ts'
import ConsumptionChart from 'components/Charts/Consumption/ConsumptionChart.tsx'
import { ConsumptionNavigationForm, NavigationItemType } from 'components/Charts/ConsumptionNavigation/types.ts'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Response } from 'types/request.ts'
import { ELProduct } from 'types/products.ts'
import { Button } from '@boltenergy-be/design-system'
import { routes } from 'types/routes.ts'
import AddMeterReadingsModal from 'features/MeterReadings/AddMeterReadingsModal/AddMeterReadingsModal.tsx'
import { AddMeterReadingsModalEntryPoint } from 'features/MeterReadings/AddMeterReadingsModal/types.ts'
import { resetBillShock } from 'store/contracts/slice.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { determineAccessRights, getMemoizedSelectedContracts } from 'utils/contracts.ts'
import {
  generateGranularityNavigationItems,
  generateShowEstimatedUsage
} from 'pages/App/Consumption/YourConsumption/ConsumptionSection/utils.ts'
import { useGetVolumesQuery } from 'store/queries/bolt-api'
import { generateGetVolumesQuery } from 'pages/App/Consumption/utils.ts'
import { ISO_DATETIME_FORMAT } from 'constants/constants.ts'
import { getPreviousBillingCyclePeriods } from 'store/contracts/thunks'
import { popSuccessToast } from 'utils/toast.ts'
import { TransfoErrorCodes } from 'types/billShock.ts'
import { ContractResponseCodes } from 'types/errorCodes.ts'
import { GranularityOptions, ShowEstimatedUsage } from './types.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import Totals from 'pages/App/Consumption/YourConsumption/TotalsBlock/TotalsBlock.tsx'
import { formatAmount } from 'utils/format.ts'
import { ConsumptionEvents, ConsumptionTrackingParams } from 'types/tracking.ts'
import mixpanel from 'mixpanel-browser'
import { ReturnLater } from 'components/ReturnLater/ReturnLater.tsx'
import { checkHasFixedProduct } from 'utils/products.ts'

const ConsumptionSection = () => {
  // Redux
  const {
    previousBillingCycles: {
      periods: previousBillingCyclesPeriods,
      loadingPeriods: isLoadingPreviousBillingCyclesPeriods,
      error: previousBillingCyclesError
    },
    meterReadings: { data: meterReadingsData, loading: meterReadingsLoading }
  } = useStoreSelector((store) => store.contracts)
  const { selectedContracts } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Contracts
  const { billingContract, electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)

  // Window size
  const { isTablet } = useWindowSize()

  // i18n
  const { t } = useTranslation('consumption')

  // React router
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = useMemo<URLSearchParams>(() => new URLSearchParams(search), [search])

  // Local state
  const [isAddMeterReadingsModalOpen, setIsAddMeterReadingsModalOpen] = useState<boolean>(false)
  const [triggerSuccessCallback, setTriggerSuccessCallback] = useState<boolean>(false)

  // Contracts
  const isMonthly = !!(electricityContract.monthlyBilling && (gasContract ? gasContract?.monthlyBilling : true))
  const granularities: GranularityOptions = generateGranularityNavigationItems(electricityContract)
  const startAsBillingCycle = !isMonthly && !!query.get('cycle')

  // React hook form
  const hookForm = useForm<ConsumptionNavigationForm>({
    defaultValues: {
      view: startAsBillingCycle ? NavigationItemType.CYCLE : Granularity.MONTH,
      period: startAsBillingCycle
        ? (query.get('cycle') as string)
        : granularities[Granularity.MONTH].periods?.find((period) => period.selected)?.value,
      activeProduct: (query.get('type') as ProductType) || ProductType.ELECTRICITY
    }
  })
  const watchView = hookForm.watch('view')
  const watchPeriod = hookForm.watch('period')
  const activeProductType = hookForm.watch('activeProduct')

  // Redux Query
  const {
    data,
    isLoading: isVolumesLoading,
    isFetching: isFetchingVolumes,
    isError,
    error,
    refetch
  } = useGetVolumesQuery(
    {
      serviceContractId: gasContract && activeProductType === ProductType.GAS ? gasContract?.id : electricityContract.id,
      granularity: generateGetVolumesQuery(watchView, watchPeriod, previousBillingCyclesPeriods).granularity,
      from: generateGetVolumesQuery(watchView, watchPeriod, previousBillingCyclesPeriods).from.format(ISO_DATETIME_FORMAT),
      until: generateGetVolumesQuery(watchView, watchPeriod, previousBillingCyclesPeriods).until.format(ISO_DATETIME_FORMAT)
    },
    { skip: !accessRights.consumption.showContent }
  )

  // useMemo
  const billingCycles = undefined
  // TODO: temporary disabled
  /*useMemo<{ [key: string]: NavigationItem } | undefined>(
    () => (!isMonthly ? generatePreviousBillingCycleNavigationItems(activeProductType, previousBillingCyclesPeriods) : undefined),
    [activeProductType, isMonthly, previousBillingCyclesPeriods]
  )*/

  // Constants
  const hasInjection = !!data?.volumes?.some((entry) => (entry.injection?.billed || entry.injection?.unbilled || 0) !== 0)
  const defaultGranularity = granularities[Granularity.MONTH]
  const selectedNavigationItem =
    watchView !== NavigationItemType.CYCLE
      ? granularities?.[watchView] || defaultGranularity
      : billingCycles
        ? billingCycles[watchPeriod]
        : defaultGranularity

  // The return of useMemo (needs a value from selectedNavigationOption local state)
  const showEstimatedUsage = useMemo<ShowEstimatedUsage>(
    () => generateShowEstimatedUsage(selectedNavigationItem, activeProductType, data?.volumes),
    [activeProductType, selectedNavigationItem, data?.volumes]
  )

  /**
   * Triggered everytime billingContract or previousBillingCyclesPeriods changes
   * Fetches the billed periods if the data is not yet available
   */
  useEffect(() => {
    if (
      accessRights.consumption.showContent &&
      !isLoadingPreviousBillingCyclesPeriods &&
      !previousBillingCyclesError &&
      !previousBillingCyclesPeriods
    ) {
      dispatch(getPreviousBillingCyclePeriods({ billingContractId: billingContract.id }))
    }
  }, [
    accessRights,
    billingContract.id,
    dispatch,
    previousBillingCyclesError,
    isLoadingPreviousBillingCyclesPeriods,
    previousBillingCyclesPeriods
  ])

  /**
   * Triggered everytime volumes changes & triggerSuccessCallback is true
   * Sets the triggerSuccessCallback to false and opens the feedback widget
   */
  useEffect(() => {
    if (!isFetchingVolumes && triggerSuccessCallback) {
      popSuccessToast(t('addMeterReadingsModal.form.successToast', { ns: 'common' }))
      setTriggerSuccessCallback(false)
    }
  }, [isFetchingVolumes, triggerSuccessCallback, t])

  /**
   * Track the consumption navigation item viewed
   */
  useEffect(() => {
    mixpanel.track(ConsumptionEvents.CONSUMPTION_NAVIGATION_ITEM_VIEWED, {
      [ConsumptionTrackingParams.VIEW]: watchView
    })
  }, [watchView])

  /**
   * Returns the correct consumption error for the given code
   *
   * @param {TransfoErrorCodes | null} code
   * @returns {ReactElement}
   */
  const getConsumptionError = (code?: TransfoErrorCodes | ContractResponseCodes | string): ReactElement => {
    // Own API errors
    if (code === ContractResponseCodes.NO_SMR3_VOLUMES_AVAILABLE_YET) {
      return <Banner type="informative">{t('yourConsumption.info.noUsage.SMR3.tooRecent')}</Banner>
    }

    if ([ContractResponseCodes.NO_VOLUMES, ContractResponseCodes.NO_USABLE_VOLUMES].includes(code as ContractResponseCodes)) {
      return (
        <Banner type="informative">
          {electricityContract.dynamicTariff && activeProductType === ProductType.ELECTRICITY
            ? t('yourConsumption.info.noUsage.SMR3.unavailable')
            : t(`yourConsumption.info.noUsage.nonSMR3.${electricityContract.smartMeter ? 'digital' : 'analog'}`)}
        </Banner>
      )
    }

    // API & Transfo errors
    if (
      [
        TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG,
        TransfoErrorCodes.E444_MISSING_DECENTRALIZED_PRODUCTION,
        TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES,
        TransfoErrorCodes.E456_DELIVERY_OUT_OF_SCOPE
      ].includes(code as TransfoErrorCodes)
    ) {
      return (
        <Banner type="informative">
          {t(
            `transfoErrors.${
              code as
                | TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG
                | TransfoErrorCodes.E444_MISSING_DECENTRALIZED_PRODUCTION
                | TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES
                | TransfoErrorCodes.E456_DELIVERY_OUT_OF_SCOPE
            }`,
            {
              ns: 'common'
            }
          )}
        </Banner>
      )
    }

    return <div className={styles.error}>{t('errorTryLater', { ns: 'common' })}</div>
  }

  return (
    <>
      <section className={styles['consumption-section']}>
        <Heading as="h1" variant="h5">
          {t('chart.title')}
        </Heading>

        {!accessRights.consumption.showContent ? (
          <ReturnLater description={t('yourConsumption.returnLater')} />
        ) : (
          <>
            {accessRights.meterReadings.canAccess && accessRights.meterReadings.showContent && (
              <Card as="section" className={styles['card-meter-readings']}>
                <div className={styles['title-container']}>
                  <Card.Title variant="h6" weight={600}>
                    {t('chart.meterReadings.title')}
                  </Card.Title>
                  <small>{t('chart.meterReadings.caption')}</small>
                </div>

                <div className={styles['button-container']}>
                  {meterReadingsLoading ? (
                    <div className={styles['loading-container']}>
                      <LoadingDots />
                    </div>
                  ) : (
                    <ButtonMeterReadings
                      setModalOpen={() => setIsAddMeterReadingsModalOpen(true)}
                      electricityLastMeterReadingDate={
                        meterReadingsData?.electricity?.source === MeterReadingSource.CLIENT_PROVIDED
                          ? meterReadingsData.electricity.date
                          : undefined
                      }
                      gasLastMeterReadingDate={
                        meterReadingsData?.gas?.source === MeterReadingSource.CLIENT_PROVIDED ? meterReadingsData.gas.date : undefined
                      }
                    />
                  )}
                </div>
              </Card>
            )}

            <Card as="section" className={styles.card}>
              {!isVolumesLoading && !isLoadingPreviousBillingCyclesPeriods && !!selectedNavigationItem ? (
                <>
                  {/* FILTERING + METER READINGS */}
                  <header className={styles.header}>
                    <FormProvider {...hookForm}>
                      <ConsumptionNavigation
                        isLoading={isFetchingVolumes}
                        hideEnergyTypeToggle={!gasContract}
                        billingCycles={billingCycles || {}}
                        {...{ granularities }}
                      />
                    </FormProvider>
                  </header>

                  {/* CONSUMPTION CHART */}
                  {!isError && !error && data ? (
                    <>
                      <Totals>
                        {/* Total consumption */}
                        <Totals.Block
                          tooltip={t(`chart.totals.consumption.tooltip.${activeProductType === ProductType.GAS ? 'gas' : 'electricity'}`)}
                          isLoading={isFetchingVolumes}
                          label={t('chart.totals.consumption.text')}
                          color={activeProductType === ProductType.GAS ? 'purple' : 'green'}
                        >
                          <data value={data.totalConsumption}>{formatAmount(data.totalConsumption, 0)} kWh</data>
                        </Totals.Block>

                        {/* Total injection */}
                        {data.totalInjection ? (
                          <Totals.Block
                            tooltip={t('chart.totals.injection.tooltip')}
                            isLoading={isFetchingVolumes}
                            label={t('chart.totals.injection.text')}
                            color="yellow"
                          >
                            <data value={Math.abs(data.totalInjection)}>{formatAmount(Math.abs(data.totalInjection), 0)} kWh</data>
                          </Totals.Block>
                        ) : null}
                      </Totals>

                      <ConsumptionChart
                        {...{
                          isFetchingVolumes,
                          granularity: watchView === NavigationItemType.CYCLE ? Granularity.MONTH : watchView,
                          hasInjection,
                          isTablet,
                          showEstimatedUsage
                        }}
                        data={!isFetchingVolumes ? data.volumes : []}
                        activeProductType={!gasContract ? ProductType.ELECTRICITY : activeProductType}
                        hidePriceHistory={checkHasFixedProduct()}
                      />

                      {!isFetchingVolumes && data.nonSmr3VolumesAvailable && electricityContract.dynamicTariff && (
                        <Banner type="informative">{t('yourConsumption.info.noUsage.SMR3.partlyUnavailable')}</Banner>
                      )}
                    </>
                  ) : (
                    getConsumptionError(((error as FetchBaseQueryError).data as Response).message)
                  )}

                  {!isFetchingVolumes &&
                    electricityContract.detail?.productCode !== ELProduct.Go &&
                    watchView === NavigationItemType.CYCLE && (
                      <Button
                        className={styles['billing-cycle-button']}
                        onClick={() =>
                          navigate({
                            pathname: routes.BILLING_CYCLES,
                            search: `cycle=${watchPeriod}`
                          })
                        }
                      >
                        {t('chart.back')}
                      </Button>
                    )}
                </>
              ) : (
                <LoadingSkeleton>
                  <LoadingSkeleton.Rectangle height={40} />
                  <div className={styles['totals-loader']}>
                    <LoadingSkeleton.Rectangle height={60} />
                    <LoadingSkeleton.Rectangle height={60} />
                  </div>
                  <LoadingSkeleton.Rectangle aspectRatio="3 / 1" />
                  <LoadingSkeleton.Paragraph />
                </LoadingSkeleton>
              )}
            </Card>
          </>
        )}
      </section>

      <AddMeterReadingsModal
        entryPoint={AddMeterReadingsModalEntryPoint.ConsumptionPage}
        isOpen={isAddMeterReadingsModalOpen}
        setClose={() => setIsAddMeterReadingsModalOpen(false)}
        onSuccess={() => {
          // Clear billshock because billshock data in store now has wrong meter readings data
          dispatch(resetBillShock())

          // Refetch volumes
          refetch()
          if (query.get('cycle')) {
            // clear the current search params
            navigate(routes.CONSUMPTION_YOUR_CONSUMPTION, {
              replace: true
            })
          }
          setTriggerSuccessCallback(true)
        }}
      />
    </>
  )
}

export default ConsumptionSection
