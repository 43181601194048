const Producer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.75 11.123c0-.73-.438-1.411-1.166-1.816l-7.059-3.922a3.215 3.215 0 0 0-3.05 0L3.416 9.307c-.728.405-1.166 1.086-1.166 1.816v8.335c0 1.266 1.231 2.292 2.75 2.292h.25v-1.513c0-1.857.895-3.506 2.278-4.537a4.11 4.11 0 1 1 7.36-3.365 3.593 3.593 0 0 1 1.976 5.392 4.892 4.892 0 0 1 1.886 3.863v.16H19c1.519 0 2.75-1.026 2.75-2.292v-8.335ZM8.95 21.59v.16h-2.2v-1.513c0-1.448.74-2.723 1.863-3.467a4.087 4.087 0 0 0 2.067.693c.048.09.1.178.155.264A4.892 4.892 0 0 0 8.95 21.59Zm1.5 0c0-1.157.577-2.179 1.46-2.793.56.36 1.225.568 1.94.568.714 0 1.38-.208 1.94-.568a3.397 3.397 0 0 1 1.46 2.793v.16h-6.8v-.16Zm2.81-9.36a2.61 2.61 0 1 0-3 3.659 3.593 3.593 0 0 1 3-3.659Zm-1.502 3.544a2.092 2.092 0 1 1 4.183 0 2.092 2.092 0 0 1-4.183 0ZM19.126 1.481a.416.416 0 0 1 .5-.217l2.445.767a.416.416 0 0 1 .22.63l-.923 1.363.764.183a.416.416 0 0 1 .185.71l-3.055 2.814a.416.416 0 0 1-.688-.392l.404-1.912-1.047-.431a.416.416 0 0 1-.217-.564l1.412-2.95Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Producer
