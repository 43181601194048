export type SalesCase = {
  name: string
  subject: string
  status: CaseStatus
  createdDate: string // ISO
}

export enum CaseStatus {
  CANCELED = 'Canceled',
  CLOSED = 'Closed',
  NEW = 'New',
  ON_HOLD = 'On Hold',
  OPEN = 'Open',
  WAITING_FOR_CUSTOMER = 'Waiting for customer',
  RESOLVED = 'Resolved'
}
