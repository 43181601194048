import { ReactNode } from 'react'
import dayjs from 'dayjs'
import i18next from 'i18next'
import parse from 'html-react-parser'
import { PeriodTypes } from '../../types'
import styles from '../../BillingCycles.module.scss'
import Icon from 'components/Icon/Icon'
import Badge from 'components/Badge/Badge'
import { BadgeColors } from 'components/Badge/types'
import { Electricity, Gas } from 'assets/svg'
import { BillingCyclePeriod } from 'types/billShock'

/**
 * Generates the billing cycle title section for the given period type
 *
 * @template T
 * @param {PeriodTypes} periodType
 * @param {BillingCyclePeriod | SettlementDefaultProps} period
 * @param {BadgeColors} badgeColor
 * @returns {ReactNode}
 */
export const getTitleSection = (periodType: PeriodTypes, period?: BillingCyclePeriod, badgeColor?: BadgeColors): ReactNode => {
  return (
    <div className={styles[`${periodType}-title-section`]}>
      <div className={styles.period}>
        <span className={styles['period-title']}>{i18next.t('billingCycles.period.title', { ns: 'billing' })}</span>
        {period && (
          <span className={styles['period-dates']}>
            <Icon name="calendarRange" color="currentColor" />
            <span className={styles['period-dates-text']}>
              {parse(
                i18next.t('billingCycles.period.value', {
                  startDate: dayjs(period.startDate).format('DD/MM/YYYY'),
                  endDate: dayjs(period.endDate).format('DD/MM/YYYY'),
                  ns: 'billing'
                })
              )}
            </span>
          </span>
        )}
      </div>

      <div className={styles['badges-container']}>
        {periodType === PeriodTypes.PREVIOUS && (
          <div className={styles['fuel-container']}>
            {period?.fuel?.electricity && (
              <div className={styles['fuel-icon']}>
                <Electricity isFilled iconColor="currentColor" />
              </div>
            )}
            {period?.fuel?.gas && (
              <div className={styles['fuel-icon']}>
                <Gas isFilled iconColor="currentColor" />
              </div>
            )}
          </div>
        )}
        <Badge color={badgeColor || BadgeColors.GREY} text={i18next.t(`billingCycles.${periodType}.badge`, { ns: 'billing' })} />
      </div>
    </div>
  )
}
