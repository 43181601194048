import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { API_URL } from 'constants/envs'
import { getAccessToken, getAccessTokenFromSession, getRefreshToken, setTokens } from 'utils/localStorageService'
import { getUserType, identifyUserType } from 'utils/userType'
import { getRefreshTokenUrlForUserType } from 'utils/app'
import { Methods } from 'types/request'
import { AuthTokens } from 'types/auth'
import { Mutex } from 'async-mutex'
import { handleLogout } from 'utils/axios'

// Create a new mutex
const mutex = new Mutex()

/**
 * Create a new baseQuery for the bolt api requests
 */
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const accessTokenLight = getAccessTokenFromSession('access_token_light')
    const token = getAccessToken()

    if (token && !accessTokenLight) {
      headers.set('Authorization', `Bearer ${token.id}`)
    }
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

/**
 * Redux toolkit query baseQuery that handles re-authentication on 401 errors
 */
export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // Mutex is used to prevent multiple refresh token requests when multiple calls fail on 401
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  // Mutex is unlocked, fire the request
  let result = await baseQuery(args, api, extraOptions)

  // if the request returns a 401 error, try to refresh the token
  if (result.error && ((result.error.status === 'PARSING_ERROR' && result.error.originalStatus === 401) || result.error?.status === 401)) {
    const userType = getUserType() || identifyUserType()
    const refreshToken = getRefreshToken(userType)

    if (refreshToken) {
      // Has a refresh token for the userType
      // Checking whether the mutex is not locked yet to start the token refresh
      if (!mutex.isLocked()) {
        // Mutex is not locked, lock it so other calls will wait for the mutex to be released
        const release = await mutex.acquire()

        try {
          // try to get a new token based on the refresh token url for the current user type
          const refreshTokenUrl = getRefreshTokenUrlForUserType(userType)
          const refreshResult = await baseQuery(
            { url: `${API_URL}/${refreshTokenUrl}`, method: Methods.POST, body: { refreshToken: refreshToken.id } },
            api,
            extraOptions
          )

          if (refreshResult.data) {
            // Refresh token was valid and received new tokens, set them in local storage
            const { token, refreshToken } = refreshResult.data as unknown as AuthTokens
            setTokens({ token, refreshToken }, userType)

            // Retry the original request
            result = await baseQuery(args, api, extraOptions)
          } else {
            // Refresh token was invalid, logout
            handleLogout(userType)
          }
        } finally {
          // Release the mutex so other calls can start that are waiting for mutex unlock
          release()
        }
      } else {
        // If the mutex is locked, it means that another call is already refreshing the token
        await mutex.waitForUnlock()

        // Mutex was released, retry the original request
        result = await baseQuery(args, api, extraOptions)
      }
    } else {
      // No refresh token found, logout
      handleLogout(userType)
    }
  }

  // Call finished, return the result
  return result
}
