import classNames from 'classnames'
import styles from './ListWithSeparator.module.scss'
import { ListWithSeparatorProps } from './types'

const ListWithSeparator = ({ children, className }: ListWithSeparatorProps) => {
  return (
    <ul className={classNames(className, styles['list'])}>
      {children.map((child, index: number) => (
        <li key={index} className={styles['list-item']}>
          {child}
        </li>
      ))}
    </ul>
  )
}

export default ListWithSeparator
