import SimulationDetails from './PropositionDetailCards/SimulationDetails'
import ContractDetails from './PropositionDetailCards/ContractDetails'
import styles from './Details.module.scss'
import { PropositionDetailsProps } from './types'
import { useState } from 'react'
import EditSimulationDetails from './PropositionDetailCards/EditSimulationDetails'
import { Button, Heading, Icon } from '@boltenergy-be/design-system'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard.tsx'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock.tsx'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Opportunity, OpportunityStage } from 'types/sales.ts'
import { useResendOpportunityEmailMutation, useUpdateOpportunityMutation } from 'store/queries/bolt-api'
import EditingButton from 'components/EditingButton/EditingButton'
import NewCaseDrawer from './NewCaseDrawer/NewCaseDrawer'
import ConfirmationTooltip from 'components/ConfirmationTooltip/ConfirmationTooltip'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover'
import { popErrorToast, popSuccessToast } from 'utils/toast'
import classNames from 'classnames'
import { SimulationType } from 'features/contracts/add/types.ts'
import { removeSpacesAndDots } from 'utils/format.ts'

const PropositionDetails = ({
  opportunity,
  contract,
  contactId,
  accountId,
  setActiveEditing,
  propositionNumber,
  identifier,
  activeEditing,
  customerName,
  salesParameters
}: PropositionDetailsProps) => {
  // i18n
  const { t } = useTranslation('sales')

  // Local constant
  const isEditing = activeEditing === identifier

  // Local state
  const [drawerOpen, setDrawerOpen] = useState(false)

  // Mutation
  const [updateOpportunity, { isLoading: updateOpportunityIsLoading }] = useUpdateOpportunityMutation()
  const [resendOpportunityEmail, { isLoading: resendOpportunityEmailIsLoading }] = useResendOpportunityEmailMutation()

  // React Hook Form
  const hookForm = useForm<Partial<Opportunity>>({
    mode: 'onBlur',
    defaultValues: {
      forcedAmount: opportunity.forcedAmount,
      ibanNumber: removeSpacesAndDots(opportunity.ibanNumber ?? ''),
      paymentMode: opportunity.paymentMode,
      address: {
        postalCode: opportunity.address.postalCode,
        streetName: opportunity.address.streetName,
        streetNumber: opportunity.address.streetNumber,
        streetBox: opportunity.address.streetBox,
        townCode: opportunity.address.townCode,
        townName: opportunity.address.townName
      }
    }
  })
  const { handleSubmit } = hookForm

  /**
   * Updates the Opportunity object in the API
   *
   * @param {Partial<Opportunity>} data
   */
  const handleOpportunityUpdate = async (data: Partial<Opportunity>) => {
    try {
      // Update the opportunity
      const updatedOpportunity = await updateOpportunity({
        opportunity: { ...data, salesforceId: opportunity.salesforceId },
        accountId,
        contactId
      }).unwrap()

      // Check if the opportunity was updated
      if (updatedOpportunity) {
        // Remove the editing state
        setActiveEditing(undefined)

        // Show success toast
        popSuccessToast(t('details.toast.success'))
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    } catch (error) {
      popErrorToast(t('error', { ns: 'common' }))
    }
  }

  /**
   * Handles the resend opportunity email
   *
   * @returns {Promise<void>}
   */
  const handleResendOpportunityEmail = async () => {
    try {
      const result = await resendOpportunityEmail({ accountId, contactId, opportunityId: opportunity.salesforceId })
      if (result?.data) {
        popSuccessToast(t('details.toast.resend'))
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    } catch (e) {
      popErrorToast(t('error', { ns: 'common' }))
    }
  }

  return (
    <section className={styles.proposition}>
      <header className={styles.heading}>
        <Heading as="h3">
          {t('details.simulation.simulation')} {propositionNumber}
        </Heading>
        <div className={classNames(styles['button-container'], { [styles['button-container-no-editing']]: !isEditing })}>
          <EditingButton
            isDisabled={
              (opportunity.stage !== OpportunityStage.REGISTRATION_STARTED && opportunity.stage !== OpportunityStage.TO_BE_CONFIRMED) ||
              !!activeEditing
            }
            setIsEditing={setActiveEditing}
            isLoading={updateOpportunityIsLoading}
            onSubmit={handleSubmit(handleOpportunityUpdate)}
            {...{ identifier, isEditing }}
          />
          {!isEditing && (
            <>
              {/** div to prevent overlay item from spawning item gap */}
              <div>
                <ConfirmationTooltip
                  label={t('details.emailModal.optIn')}
                  confirmation={t('details.emailModal.confirm')}
                  onConfirm={handleResendOpportunityEmail}
                  disabled={
                    !!activeEditing ||
                    ![
                      OpportunityStage.REGISTRATION_STARTED,
                      OpportunityStage.TO_BE_CONFIRMED,
                      OpportunityStage.NO_EAN,
                      OpportunityStage.CLOSED_WON
                    ].includes(opportunity.stage) ||
                    (opportunity.stage === OpportunityStage.REGISTRATION_STARTED &&
                      opportunity.simulationType !== SimulationType.PRICING_SIMULATION)
                  }
                  isLoading={resendOpportunityEmailIsLoading}
                >
                  <TooltipOnHover tooltipContent={t('details.tooltips.resend')} tooltipClassname={styles['hover-tooltip']}>
                    <Icon name="envelope" />
                  </TooltipOnHover>
                </ConfirmationTooltip>
              </div>

              <div>
                <ConfirmationTooltip
                  label={t('details.closeOpportunityModal.optIn')}
                  confirmation={t('confirm', { ns: 'common' })}
                  onConfirm={() => handleOpportunityUpdate({ stage: OpportunityStage.CLOSED_LOST })}
                  disabled={
                    ![OpportunityStage.TO_BE_CONFIRMED, OpportunityStage.NO_EAN, OpportunityStage.REGISTRATION_STARTED].includes(
                      opportunity.stage
                    ) || !!activeEditing
                  }
                  isLoading={updateOpportunityIsLoading}
                >
                  <TooltipOnHover tooltipContent={t('details.tooltips.close')} tooltipClassname={styles['hover-tooltip']}>
                    <Icon name="crossCircle" />
                  </TooltipOnHover>
                </ConfirmationTooltip>
              </div>

              <Button variant="tertiary" disabled={!!activeEditing || !contract} onClick={() => setDrawerOpen(true)}>
                <TooltipOnHover tooltipContent={t('details.tooltips.new')} tooltipClassname={styles['hover-tooltip']}>
                  <Icon name="chatExclamation" />
                </TooltipOnHover>
              </Button>
            </>
          )}
        </div>
      </header>

      <CollapsibleCard
        as="section"
        titleSection={
          <Heading as="h4" className={styles.title}>
            {t('details.simulation.title')}
          </Heading>
        }
        contentClassName={styles['details-block']}
      >
        <DataBlock label={t('details.simulation.stage')} withMargin={false}>
          {opportunity.stage}
        </DataBlock>
        <DataBlock label={t('details.simulation.signingMethod')} withMargin={false}>
          {opportunity.signChannel}
        </DataBlock>
        <DataBlock label={t('details.simulation.registrationOrigin')} withMargin={false}>
          {opportunity.eventName}
        </DataBlock>
        <DataBlock label={t('details.simulation.secondAddress')} withMargin={false}>
          {opportunity.extraAddressFlow ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' })}
        </DataBlock>
        <DataBlock label={t('details.simulation.simulationType')} withMargin={false}>
          {opportunity.simulationType}
        </DataBlock>

        {isEditing ? (
          <FormProvider {...hookForm}>
            <EditSimulationDetails {...{ opportunity }} />
          </FormProvider>
        ) : (
          <SimulationDetails {...{ opportunity }} />
        )}
      </CollapsibleCard>

      {contract && <ContractDetails contract={contract.serviceContracts.electricity} />}
      {contract?.serviceContracts?.gas && <ContractDetails contract={contract.serviceContracts.gas} />}
      <NewCaseDrawer
        opportunityId={opportunity.salesforceId}
        {...{ accountId, customerName, contactId, salesParameters, drawerOpen, setDrawerOpen }}
      />
    </section>
  )
}

export default PropositionDetails
