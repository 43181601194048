import { Icon } from '@boltenergy-be/design-system'
import classNames from 'classnames'
import { reportButtonProps } from './types'
import styles from './ReportingStatsButton.module.scss'
import buttonStyles from '../ReportingStatsCard.module.scss'

const ReportingStatsButton = ({ stage, customClassName, isActive, onClick, heading, description }: reportButtonProps) => {
  return (
    <button
      data-key={stage}
      className={classNames(
        customClassName,
        { [buttonStyles.inactive]: isActive && isActive !== stage },
        { [styles.disabled]: heading === undefined }
      )}
      onClick={onClick}
      disabled={heading === undefined}
    >
      <data value={heading} className={styles['stat-number']}>
        {heading}
      </data>
      <span className={styles.description}>
        {description}
        <Icon name="chevronRight" />
      </span>
    </button>
  )
}

export default ReportingStatsButton
