import { Banner, Heading } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './Details.module.scss'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import EditableTextField from 'components/EditeableFormFields/EditableTextField'
import { useState } from 'react'
import EditingButton from 'components/EditingButton/EditingButton'
import { Contact, ContactStatus } from 'types/sales'
import { useUpdateSalesContactMutation } from 'store/queries/bolt-api'
import { ContactDetailsProps } from './types'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { popErrorToast, popSuccessToast } from 'utils/toast'
import EditablePhoneField from 'components/EditeableFormFields/EditablePhoneField'
import { ContactResponseCodes } from 'components/SalesDetails/constants.ts'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Response } from 'types/request.ts'

const ContactDetails = ({ contact, activeEditing, setActiveEditing, identifier }: ContactDetailsProps) => {
  // i18n
  const { t } = useTranslation(['sales', 'common'])

  // React Hook Form
  const hookForm = useForm<Partial<Contact>>()
  const {
    clearErrors,
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
    setError
  } = hookForm

  // Local constants
  const isEditing = activeEditing === identifier

  // Local state
  const [nameWarning, setNameWarning] = useState(false)

  // Mutation
  const [updateContact, { isLoading }] = useUpdateSalesContactMutation()

  /**
   * Updates the Contact object in the API
   *
   * @param {Partial<Contact>} data
   */
  const onSave = async (data: Partial<Contact>) => {
    // Clear errors
    clearErrors('email')

    // Set the name warning if the first or last name have been updated
    setNameWarning(!!dirtyFields.firstName || !!dirtyFields.lastName)

    try {
      // Update the contact
      const updatedContact = await updateContact({ contact: { ...data, salesforceId: contact.salesforceId } }).unwrap()

      // Check if the contact was updated
      if (updatedContact) {
        // Remove the editing state
        setActiveEditing(undefined)

        // Show success toast
        popSuccessToast(t('details.toast.success'))
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    } catch (error) {
      if (((error as FetchBaseQueryError).data as Response).message === ContactResponseCodes.CONTACT_EMAIL_IN_USE) {
        setError('email', { type: 'emailInUse', message: t('common:emailInUse') })
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    }
  }

  return (
    <section>
      <header className={styles.heading}>
        <Heading as="h1" variant="h3" className={styles.username}>
          {contact.firstName} {contact.lastName}
        </Heading>
        <EditingButton
          isDisabled={contact.status === ContactStatus.CUSTOMER || !!activeEditing}
          setIsEditing={setActiveEditing}
          onSubmit={handleSubmit(onSave)}
          {...{ identifier, isEditing, isLoading }}
        />
      </header>

      <CollapsibleCard
        titleSection={
          <Heading as="h4" className={styles.title}>
            {t('details.contactData.title')}
          </Heading>
        }
        contentClassName={styles['details-block']}
      >
        {nameWarning && (
          <Banner
            type="warning"
            className={classNames(styles['warning-banner'], styles['full-width'])}
            title={t('details.customer.warning.title')}
          >
            {t('details.customer.warning.description')}
          </Banner>
        )}

        <FormProvider {...hookForm}>
          <form>
            {/* First Name */}
            <DataBlock label={t('details.contactData.firstName')} withMargin={false}>
              <EditableTextField
                value={contact.firstName}
                name="firstName"
                required
                {...{
                  isEditing,
                  register,
                  errors
                }}
              />
            </DataBlock>

            {/* Last Name */}
            <DataBlock label={t('details.contactData.lastName')} withMargin={false}>
              <EditableTextField
                value={contact.lastName}
                name="lastName"
                required
                {...{
                  isEditing,
                  register,
                  errors
                }}
              />
            </DataBlock>

            {/* Phone */}
            <div className={styles['phone-field']}>
              {!isEditing && <label>{t('details.contactData.phone')}</label>}

              <EditablePhoneField
                value={contact.phone}
                masked
                name="phone"
                required
                {...{
                  isEditing,
                  register,
                  errors,
                  hookForm
                }}
              />
            </div>

            {/* Email */}
            <DataBlock label={t('details.contactData.email')} withMargin={false}>
              <EditableTextField
                value={contact.email}
                masked
                name="email"
                required
                {...{
                  isEditing,
                  register,
                  errors
                }}
              />
            </DataBlock>
          </form>
        </FormProvider>
      </CollapsibleCard>
    </section>
  )
}

export default ContactDetails
