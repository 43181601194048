import { NavigationItem } from './types'

export const getCycleIndex = (
  cycles: { [key: string]: NavigationItem },
  selected: string
): { previous: number | null; next: number | null } => {
  const currentIndex = Object.keys(cycles)?.findIndex((key) => key === selected)
  return {
    next: currentIndex !== Object.keys(cycles)?.length - 1 ? currentIndex + 1 : null,
    previous: currentIndex !== 0 ? currentIndex - 1 : null
  }
}

export const getGranularityPeriodIndex = (
  granularityPeriods: Required<NavigationItem>['periods'],
  selected: string
): { previous: number | null; next: number | null } => {
  const currentIndex = granularityPeriods.findIndex((period) => period.value === selected)
  return {
    next: currentIndex !== 0 ? currentIndex - 1 : null,
    previous: currentIndex !== granularityPeriods.length - 1 ? currentIndex + 1 : null
  }
}
