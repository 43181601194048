import { NODE_ENV } from 'constants/envs.ts'

/**
 * Returns the origin.location of the Bolt Website
 * for the current env
 *
 * @returns string
 */
export const getBoltWebsiteOrigin = () => {
  let origin

  switch (NODE_ENV) {
    // Local
    case 'local':
      origin = 'http://localhost:9000/'
      break

    // Staging
    case 'staging':
      origin = 'https://staging.boltenergie.be/'
      break

    // Production
    default:
      origin = 'https://boltenergie.be/'
  }

  return origin
}
