import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import useWindowSize from 'hooks/useWindowSize'
import classNames from 'classnames'
import { routes } from 'types/routes'
import { Store } from 'store/types'
import { getMemoizedAddressTiles, getMemoizedSelectedContracts, hasNoActiveContracts } from 'utils/contracts'
import { setSelectedUserDataInStore } from 'utils/user'
import Icon from 'components/Icon/Icon'
import styles from './AddressSwitcher.module.scss'

const AddressSwitcher = () => {
  // Redux
  const { customers, selectedCustomer, selectedContracts } = useSelector((store: Store) => store.user)

  // Local state
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  // Refs
  const dropdownRef = useRef<HTMLDivElement>(null)

  // i18n
  const { t } = useTranslation()

  // Window size
  const { isMobile } = useWindowSize()

  // History
  const navigate = useNavigate()

  // Constants
  const { billingContract, electricityContract } = getMemoizedSelectedContracts(selectedContracts)
  const addressTiles = getMemoizedAddressTiles(customers)
  const userAddress = useMemo<string>(
    () => `${electricityContract.address.streetName} ${electricityContract.address.streetNumber} ${electricityContract.address.streetBox}`,
    [electricityContract]
  )

  /**
   * Handles toggling of change address dropdown
   */
  const handleDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen)
  }

  /**
   * Handles the tile click
   *
   * @param {string} selectedCustomerId
   * @param {string} selectedBillingId
   * @returns void
   */
  const handleTileClick = (selectedCustomerId: string, selectedBillingId: string) => {
    setSelectedUserDataInStore(customers, selectedCustomerId, selectedBillingId)
    setDropdownIsOpen(false)
    navigate(routes.OVERVIEW_GENERAL)
    window.scrollTo({ top: 0 }) // Scroll to top
  }

  /**
   * Hides dropdown on click outside
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as HTMLDivElement)) {
        setDropdownIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  /**
   * Hides dropdown on ESC button press when dropdown is open
   */
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && dropdownIsOpen) {
        setDropdownIsOpen(false)
      }
    }

    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [dropdownIsOpen])

  /**
   * Toggles no-scroll-mobile class on body
   */
  useEffect(() => {
    if (dropdownIsOpen) document.body.classList.add('no-scroll-mobile')

    return () => {
      if (dropdownIsOpen) document.body.classList.remove('no-scroll-mobile')
    }
  }, [dropdownIsOpen])

  return (
    <>
      <button className={styles['address-container']} onClick={handleDropdown}>
        <div className={styles.info}>
          <small className={styles['customer-id']}>{customers[selectedCustomer]?.id}</small>
          <strong className={styles.address}>{userAddress}</strong>
        </div>
        <div className={styles['icon-container']}>
          <Icon name="arrowOpen" color="currentColor" />
        </div>
      </button>
      <CSSTransition in={dropdownIsOpen} timeout={isMobile ? 300 : 0} classNames="mobile-slide-up" unmountOnExit>
        <div ref={dropdownRef} className={styles['dropdown-container']}>
          <div className={styles.label}>{t('switchAddressDropdown.chooseAddress', { ns: 'contracts' })}</div>
          <ul>
            {addressTiles.map((addressTile, index) => {
              const { isProfessional, address, contracts, billingNumber, customerId: addressCustomerId } = addressTile

              return (
                <li key={`addressTile-${index}`}>
                  <button
                    className={classNames(styles.tile, {
                      [styles['none-active']]: hasNoActiveContracts(contracts),
                      [styles['active']]: customers[selectedCustomer]?.id === addressCustomerId && billingContract.id === billingNumber
                    })}
                    onClick={() => handleTileClick(addressCustomerId, billingNumber)}
                  >
                    <small className={styles['customer-id']}>
                      {addressCustomerId}
                      {isProfessional && ` (${t('company')})`}
                    </small>
                    <strong className={styles.street}>{`${address.streetName} ${address.streetNumber} ${address.streetBox}`}</strong>
                    <span className={styles['postal-code']}>{`${address.postalCode} ${address.townName}`}</span>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      </CSSTransition>
      <CSSTransition in={dropdownIsOpen} timeout={300} classNames="fade-in" unmountOnExit>
        <div className="overlay" />
      </CSSTransition>
    </>
  )
}

export default AddressSwitcher
