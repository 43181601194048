import { SvgProps } from 'assets/svg/types'

const Avocado = ({ hasBackdrop = false, backdropColor = '#23D5AE', iconColor = 'currentColor' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M11.91 13.97c-1.26 0-2.03.83-2.03 1.58 0 .74.77 1.57 2.03 1.57 1.26 0 2.04-.83 2.04-1.57 0-.75-.78-1.58-2.04-1.58Zm-3.53 1.58c0-1.82 1.71-3.08 3.53-3.08 1.82 0 3.54 1.26 3.54 3.08 0 1.81-1.72 3.07-3.54 3.07s-3.53-1.26-3.53-3.07Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M14.25 1.68a.75.75 0 1 0-1.33.7c.23.43.26.65.24.8-.02.18-.12.41-.42.84a.76.76 0 0 0-.04.06 4.67 4.67 0 0 0-3.09 1.51 7.38 7.38 0 0 0-1.9 4.21.66.66 0 0 1-.25.44 6.99 6.99 0 1 0 10.36 2.1 2.2 2.2 0 0 1-.28-1.34c.2-1.68-.12-3.3-.84-4.54a4.75 4.75 0 0 0-2.38-2.13c.17-.3.29-.62.33-.97.07-.58-.11-1.12-.4-1.68ZM9.2 9.98a5.88 5.88 0 0 1 1.5-3.35c.75-.78 1.65-1.15 2.51-1.04.86.1 1.65.67 2.19 1.62.54.94.82 2.22.65 3.61-.1.79.08 1.58.46 2.26a5.49 5.49 0 1 1-8.14-1.65c.45-.35.76-.87.83-1.44Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Avocado
