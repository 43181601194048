import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Trees: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M17.5 10.5v4a2 2 0 0 1-1.25 1.855V13.5a.75.75 0 0 0-1.5 0v2.855A2 2 0 0 1 13.5 14.5v-4a2 2 0 1 1 4 0Zm-2.75 7.42A3.501 3.501 0 0 1 12 14.5v-4a3.5 3.5 0 1 1 7 0v4a3.501 3.501 0 0 1-2.75 3.42v2.58a.75.75 0 0 1-1.5 0v-2.58Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M10 3.25A3.75 3.75 0 0 0 6.25 7v7a3.751 3.751 0 0 0 3 3.675V20a.75.75 0 0 0 1.5 0v-7a.75.75 0 0 0-1.5 0v3.122A2.251 2.251 0 0 1 7.75 14V7a2.25 2.25 0 0 1 4.482-.284 5 5 0 0 1 1.348-.834A3.752 3.752 0 0 0 10 3.25Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Trees.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Trees
