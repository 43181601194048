import { AuthStore, UserTypes } from './types'

const sessionsUserType = sessionStorage.getItem('user_type')
const sessionUserType = sessionsUserType ? (JSON.parse(sessionsUserType) as UserTypes) : null

export const initialAuthState: AuthStore = {
  loading: false,
  error: null,
  userType: sessionUserType ? sessionUserType : localStorage.getItem('is_superuser') === 'true' ? UserTypes.SUPER_USER : null,
  email: null
}
