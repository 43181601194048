import { CustomerAccountingDocument } from 'types/types.ts'
import { Language } from 'store/app/types.ts'
import { InvoicesTableProps } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'
import { CustomerLibrary } from 'store/user/types.ts'

export enum InvoiceTableTabs {
  ALL_INVOICES = 'allInvoices',
  OUTSTANDING_INVOICES = 'outstandingInvoices',
  ALL_TRANSACTIONS = 'allTransactions',
  SUPER_USER_CUSTOMER = 'superUserCustomer',
  SUPER_USER_SUPPLIER = 'superUserSupplier'
}

export type useInvoicesTableProps = {
  tab: InvoiceTableTabs
}

export type useInvoicesTableReturnType = Pick<InvoicesTableProps, 'headers' | 'rows'>

export type filteredInvoicesProps = {
  tab: useInvoicesTableProps['tab']
  documents: CustomerAccountingDocument[]
}

export type AccountingRowsProps = {
  documents: CustomerAccountingDocument[]
  usesDirectDebit: boolean
  isSmaller?: boolean
  isSuperuser?: boolean
  language: Language
  customers: CustomerLibrary
  selectedCustomer: string
}

export type FilterOutDirectDebitBounceProps = {
  documents: CustomerAccountingDocument[]
  usesDirectDebit: boolean
  tab: useInvoicesTableProps['tab']
}

export type TransactionRowsProps = Pick<AccountingRowsProps, 'documents' | 'usesDirectDebit' | 'language'>
