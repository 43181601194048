import { useTranslation } from 'react-i18next'
import { ProducerLocationProps } from 'components/ProducerLocation/types'
import classNames from 'classnames'
import styles from './ProducerLocation.module.scss'
import EnergyIcon from 'components/Icon/EnergyIcon/EnergyIcon'

const ProducerLocation = ({ energyType, energyName, location, boxed, className }: ProducerLocationProps) => {
  // i18n
  const { t } = useTranslation('producer')

  return (
    <div className={classNames(styles.wrapper, { [styles.boxed]: boxed }, className)}>
      <EnergyIcon type={energyType} />
      <span className={styles.energy}>{energyName || t(`energyType.${energyType}`)}</span>
      {location && <span className={styles.location}>{location}</span>}
    </div>
  )
}

export default ProducerLocation
