import { FC, useCallback, useMemo, useState } from 'react'
import parse, { Element } from 'html-react-parser'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaqCardProps as Props } from 'components/Faq/types'
import SearchBar from 'components/Search/SearchBar'
import styles from './FaqCard.module.scss'
import Icon from 'components/Icon/Icon'
import { routes } from 'types/routes'
import { FaqCategory } from 'types/types'
import { searchMultipleKeys } from 'utils/objects'
import Card from 'components/Card/Card.tsx'
import { Accordion, Heading } from '@boltenergy-be/design-system'

const FaqCard: FC<Props> = ({ title, categories }) => {
  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')
  const hasMatchingQuestions = useMemo<boolean>(
    () =>
      searchQuery
        ? categories.some((cat) => cat.questions.filter((q) => searchMultipleKeys([q.question, q.answer], searchQuery)).length)
        : true,
    [categories, searchQuery]
  )

  const checkHasQuestions = useCallback(
    /**
     * check if the category has matching questions with the searchQuery
     * inside callback to reduce triggers
     *
     * @param {FaqCategory} cat
     * @returns {boolean}
     */
    (cat: FaqCategory): boolean => cat.questions.filter((q) => searchMultipleKeys([q.question, q.answer], searchQuery)).length > 0,
    [searchQuery]
  )

  // i18n
  const { t } = useTranslation()

  return (
    <div className="container">
      <Card as="section">
        <Card.Title as="h1">{title || t('faq.title')}</Card.Title>
        <SearchBar
          className={styles.search}
          onInput={(query) => setSearchQuery(query)}
          placeholder={t('faq.searchPlaceholder')}
          size="small"
        />

        <ul>
          {hasMatchingQuestions ? (
            categories.map((cat) => {
              return (
                checkHasQuestions(cat) && (
                  <li className={styles['faq-section']} key={cat.id}>
                    {categories.length > 1 && (
                      <Heading as="h2" variant="h5" className="my-200">
                        {cat.name}
                      </Heading>
                    )}
                    <Accordion
                      items={cat.questions
                        .filter((q) => searchMultipleKeys([q.question, q.answer], searchQuery))
                        .map((item) => ({ title: parse(item?.question), children: parse(item?.answer) }))}
                    />
                    {categories.length === 1 && (
                      <div className={styles['more-faq']}>
                        <span>{t('faq.moreQuestions')}</span>
                        <Link to={routes.SUPPORT_FAQ}>{t('faq.viewFaq')}</Link>
                      </div>
                    )}
                  </li>
                )
              )
            })
          ) : (
            <div className={styles['no-results-container']}>
              <p>
                {parse(t('faq.noResults'), {
                  replace: (domNode) => {
                    if (domNode instanceof Element && domNode.attribs && domNode.attribs.id === 'icon') {
                      return <Icon name="openInNew" color="currentColor" size={10} />
                    }

                    return domNode
                  }
                })}{' '}
                <Link to={routes.SUPPORT_CONTACT}>{t('faq.contactUs')}</Link>
              </p>
            </div>
          )}
        </ul>
      </Card>
    </div>
  )
}

export default FaqCard
