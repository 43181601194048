import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { routes } from 'types/routes'
import SidebarItem from './SidebarItem'
import styles from './Sidebar.module.scss'
import AddressSwitcher from 'components/AddressSwitcher/AdressSwitcher'
import { SidebarProps } from './types'
import { Account, Contract, Friends, Invoices, Overview, Producer, Support, Usage, Move } from 'assets/svg/sidebar'
import { Icon } from '@boltenergy-be/design-system'

const Sidebar = ({ isSales, activeAction }: SidebarProps) => {
  // i18n
  const { t } = useTranslation()

  // React Router
  const { pathname } = useLocation()

  return (
    <nav className={styles.sidebar}>
      {!isSales && <AddressSwitcher />}
      <ul className={classNames(styles['item-container'], { [styles['is-sales']]: isSales })}>
        {isSales ? (
          <>
            <SidebarItem
              icon="general"
              text={t('nav.overview')}
              isSelected={pathname.includes(routes.SALES_HOME)}
              url={routes.SALES_HOME}
            />
            <SidebarItem
              icon={<Icon name="contract" />}
              text={t('nav.events')}
              isSelected={pathname.includes(routes.SALES_EVENTS)}
              url={routes.SALES_EVENTS}
            />
            <SidebarItem
              icon="add"
              text={t('nav.address')}
              isSelected={pathname.includes(routes.SALES_CONTRACTS_ADD)}
              url={routes.SALES_CONTRACTS_ADD}
            />
          </>
        ) : (
          <>
            <SidebarItem
              icon={<Overview />}
              text={t('nav.overview')}
              isSelected={pathname.includes(routes.OVERVIEW)}
              url={routes.OVERVIEW_GENERAL}
            />
            <SidebarItem
              icon={<Usage />}
              text={t('nav.usage')}
              isSelected={pathname.includes(routes.CONSUMPTION)}
              url={routes.CONSUMPTION_YOUR_CONSUMPTION}
            />
            <SidebarItem
              icon={<Invoices />}
              text={t('nav.billing')}
              isSelected={pathname.includes(routes.BILLING)}
              url={routes.BILLING_INVOICES}
            />

            <hr />

            <SidebarItem
              icon={<Producer />}
              text={t('nav.producer')}
              isSelected={pathname.includes(routes.PRODUCER)}
              url={routes.PRODUCER}
            />
            <SidebarItem
              icon={<Friends />}
              text={t('nav.friends')}
              isSelected={pathname.includes(routes.FRIENDS)}
              url={routes.FRIENDS}
              badge={activeAction ? t('action') : undefined}
            />

            <hr />

            <SidebarItem
              icon={<Contract />}
              text={t('nav.contract')}
              isSelected={pathname.includes(routes.CONTRACT)}
              url={routes.CONTRACT_MY_CONTRACT}
            />
            <SidebarItem icon={<Account />} text={t('nav.user')} isSelected={pathname.includes(routes.USER)} url={routes.USER_PERSONAL} />
            <SidebarItem icon={<Move />} text={t('nav.move')} isSelected={pathname.includes(routes.MOVE)} url={routes.MOVE_INITIATION} />

            <hr />

            <SidebarItem
              icon={<Support />}
              text={t('nav.support')}
              isSelected={pathname.includes(routes.SUPPORT)}
              url={routes.SUPPORT_FAQ}
            />
          </>
        )}
      </ul>
    </nav>
  )
}

export default Sidebar
