import { Table } from 'components/Table/Table'
import { Row } from 'components/Table/types'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './EventLog.module.scss'
import { headers } from './constants'
import { transformAuthModel, transformEventLogType } from './utils'
import { UserTypes } from 'store/auth/types'
import { getUserTypeHomepage } from 'utils/user'
import SeoTags from 'components/SeoTags/SeoTags'
import { useStoreSelector } from 'hooks/store'
import { useGetUserEventLogsQuery } from 'store/queries/bolt-api'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import Card from 'components/Card/Card.tsx'
import { routes } from 'types/routes.ts'
import Link from 'components/Link/Link.tsx'

const EventLog: FC = () => {
  // REDUX STORE
  const { userType } = useStoreSelector((store) => store.auth)
  const { info } = useStoreSelector((store) => store.user)

  // Redux queries
  const {
    data: userEventLogsData,
    isLoading,
    isError
  } = useGetUserEventLogsQuery({ email: info.email }, { skip: userType !== UserTypes.SUPER_USER })

  // Local state
  const [search, setSearch] = useState<string>('')

  // React Router
  const navigate = useNavigate()

  /**
   * Triggered on first render
   * Redirects to home when not authenticated as superuser
   */
  useEffect(() => {
    if (userType !== UserTypes.SUPER_USER) navigate(getUserTypeHomepage(userType))
  }, [])

  const rows: Row[] = useMemo(() => {
    return !userEventLogsData?.logs.length
      ? []
      : userEventLogsData?.logs
          .filter((eventLog) => {
            const { updaterEmail, userEmail, data } = eventLog

            return updaterEmail.includes(search) || userEmail.includes(search) || JSON.stringify(data).includes(search)
          })
          .map((eventLog) => ({
            cells: [
              {
                value: dayjs(eventLog.createdAt).format('DD/MM/YYYY HH:mm'),
                extraClasses: styles['td-text-top']
              },
              {
                value: transformEventLogType(eventLog.type),
                extraClasses: styles['td-text-top']
              },
              {
                value: eventLog.updaterEmail,
                extraClasses: styles['td-text-top']
              },
              {
                value: transformAuthModel(eventLog.updaterAuthModel),
                extraClasses: styles['td-text-top']
              },
              {
                value: eventLog.userEmail,
                extraClasses: styles['td-text-top']
              },
              {
                value: JSON.stringify(eventLog.data, null, '\t'),
                extraClasses: styles.json
              }
            ]
          }))
  }, [userEventLogsData?.logs, search])

  return (
    <div className={styles['event-log']}>
      <SeoTags title="Event log" />

      <Link href={routes.WELCOME} leadingIcon="chevronLeft" className={styles.back}>
        Go back
      </Link>

      <Card className={styles['event-log']}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search logs on emails & data..."
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>

        <br />
        {isError && <div>Error, contact IT</div>}
        {userEventLogsData?.logs.length ? (
          <Table {...{ headers, rows }} />
        ) : isLoading ? (
          <LoadingDots />
        ) : (
          <div>No event logs yet for this user</div>
        )}
      </Card>
    </div>
  )
}

export default EventLog
