import { ContractDataWithIndexes } from 'pages/App/Billing/Settlement/types'
import { Address, ClientType, Company, Contact, DeliveryMode, PaymentMode, Person } from './types'

export type BillingUpdatedEventData = CustomerAndContractNumber & {
  paymentMode: PaymentMode
  iban: string
  deliveryMode: DeliveryMode
}

type CustomerAndContractNumber = {
  customerNumber: string
  contractNumber: string
}

export type CustomerUpdatedEventData = {
  customerNumber: string
  clientType?: ClientType
  company?: Company
  person: Person
  address: Address
  contact: Contact
}

export type EventLog = {
  type: EventLogType
  updaterEmail: string
  updaterAuthModel: AuthModel
  userEmail: string
  data: Record<string, unknown>
  createdAt: Date
}

export type InstalmentUpdatedEventData = CustomerAndContractNumber & {
  instalment: number
}

export type SettlementRequestedEventData = {
  customerNumber: string
  isDigitalMeter: boolean
  contractsIndexes: ContractDataWithIndexes[]
}

// ENUMS

export enum EventLogType {
  BILLING_CYCLE_VIEWED = 'billingCycleViewed',
  CONTRACT_TERMINATED = 'contractTerminated',
  CREATE_CONTRACT = 'createContract',
  METER_READINGS_ADDED = 'meterReadingsAdded',
  PORTAL_BILLING_UPDATED = 'portalBillingUpdated',
  PORTAL_CUSTOMER_UPDATED = 'portalCustomerUpdated',
  PORTAL_INSTALMENT_UPDATED = 'portalInstalmentUpdated',
  PORTAL_SETTLEMENT_REQUESTED = 'portalSettlementRequested',
  SERVICE_CONTRACT_UPDATED = 'serviceContractUpdated'
}

export enum AuthModel {
  INTERNAL = 'internalUsers',
  SALES = 'salesUsers',
  USER = 'users'
}
