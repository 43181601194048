import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods } from 'types/request'
import { SwitchLanguagePayload } from './types'
import i18n from 'i18next'
import { createCookie } from 'utils/cookies'
import { UserTypes } from 'store/auth/types'
import type { RootState } from 'store'
import { isAuthenticated } from 'utils/localStorageService'

export const switchLanguage = createAsyncThunk<SwitchLanguagePayload, SwitchLanguagePayload, { rejectValue: string }>(
  'app/switchLanguage',
  async ({ language }, thunkAPI) => {
    try {
      // Change language in app
      await i18n.changeLanguage(language)

      // Set language cookie
      createCookie('language', language, 365)

      // Constants
      const userType = (thunkAPI.getState() as RootState).auth.userType
      const { _id } = (thunkAPI.getState() as RootState).user.info
      const authenticatedUserTypes = isAuthenticated()

      // Change language in db if user is authenticated for customer users & _id can be found & and is a customer user
      if (authenticatedUserTypes[UserTypes.CUSTOMER] && _id && userType === UserTypes.CUSTOMER) {
        const { success, message } = await newRequest(getAxiosRequestConfig(Methods.PATCH, `/users/${_id}`, { language }))

        if (!success) return thunkAPI.rejectWithValue(message ?? 'ApiError')
      }

      return { language }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
