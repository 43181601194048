import { useTranslation } from 'react-i18next'
import styles from './MoveBanner.module.scss'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { routes } from 'types/routes.ts'
import mixpanel from 'mixpanel-browser'
import Card from 'components/Card/Card.tsx'
import { CommonTrackingEvents } from 'types/tracking.ts'
import classNames from 'classnames'
import { PropsWithClassName } from 'types/utility.ts'

const MoveBanner = ({ className }: PropsWithClassName) => {
  // i18n
  const { t } = useTranslation()

  return (
    <Card className={classNames(styles.banner, className)}>
      <Card.Title>{t('moveBanner.title')}</Card.Title>
      <p>{parse(t('moveBanner.description'))}</p>
      <Link to={routes.CONTRACTS} onClick={() => mixpanel.track(CommonTrackingEvents.CLICK_MOVE_BANNER)}>
        {t('moveBanner.linkText')}
      </Link>
    </Card>
  )
}

export default MoveBanner
