import { FC, PropsWithChildren } from 'react'
import { SalesCardProps as Props } from './types'
import classNames from 'classnames'
import styles from './SalesCard.module.scss'

const SalesCard: FC<PropsWithChildren<Props>> = ({ extraClasses, onClick, children }) => {
  return (
    <div className={classNames(styles.card, extraClasses)} onClick={onClick} role="presentation">
      {children}
    </div>
  )
}

export default SalesCard
