import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Electricity: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor, isFilled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      {isFilled ? (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M13.21 2.28a.75.75 0 0 1 .54.72v6.25H18a.75.75 0 0 1 .633 1.153l-7 11A.75.75 0 0 1 10.25 21v-6.25H6a.75.75 0 0 1-.633-1.153l7-11a.75.75 0 0 1 .843-.317Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M13.21 2.28c.32.1.54.39.54.72v6.25H18a.75.75 0 0 1 .63 1.15l-7 11a.75.75 0 0 1-1.38-.4v-6.25H6a.75.75 0 0 1-.63-1.15l7-11a.75.75 0 0 1 .84-.32ZM7.37 13.25H11c.41 0 .75.34.75.75v4.42l4.88-7.67H13a.75.75 0 0 1-.75-.75V5.58l-4.88 7.67Z"
          clipRule="evenodd"
        />
      )}
    </svg>
  )
}

Electricity.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Electricity
