export const MX_CONTRACT_FLOW = {
  overview: 'open_contract_actions_overview',
  start: 'start_contract_flow',
  confirm: 'confirm_contract_flow',
  nextStep: 'next_step_contract_flow',
  previousStep: 'previous_step_contract_flow',
  attemptAbort: 'attempt_abort_contract_flow',
  from: {
    ADDRESS_DROPDOWN: 'address_dropdown',
    YOUR_CONTRACT: 'your_contract',
    QUICK_ACTIONS: 'quick_actions',
    FINISH_MOVE: 'finish_move',
    CONTRACT_OVERVIEW: 'contract_overview',
    HOME_PAGE: 'home_page'
  }
} as const

export const MX_INVOICE_FLOW = {
  invoiceTabViewed: 'invoice_tab_viewed',
  overview: 'open_invoice_overview',
  downloadPdf: 'download_invoice_pdf',
  payInvoice: 'pay_invoice',
  allTransactions: 'all_transactions'
} as const
