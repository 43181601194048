import Icon from 'components/Icon/Icon'
import LoadingCard from 'components/LoadingCard/LoadingCard'
import ProducerMap from 'components/Map/ProducerMap'
import { GMAP_KEY } from 'constants/envs'
import parse from 'html-react-parser'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import styles from './Producer.module.scss'
import { verifyCoordinates } from 'utils/user'
import SeoTags from 'components/SeoTags/SeoTags'
import Card from 'components/Card/Card.tsx'
import classNames from 'classnames'

const Producer: FC = () => {
  // REDUX
  const { language } = useSelector((store: Store) => store.app)
  const { location, producer } = useSelector((store: Store) => store.user)

  // i18n
  const { t } = useTranslation('producer')

  return (
    <>
      <SeoTags title={t('nav.producer', { ns: 'common' })} />

      <div className="content">
        <div className={classNames('container', styles.container)}>
          {!producer ? (
            <>
              <div className={styles['left-content']}>
                <LoadingCard />
                <LoadingCard />
              </div>

              <LoadingCard />
            </>
          ) : (
            <>
              <div className={styles['left-content']}>
                <Card
                  image={{
                    src: `${producer.images?.profile?.url}`,
                    alt: t('alt', '', {
                      producer: producer.name?.[language]
                    })
                  }}
                />

                {GMAP_KEY && verifyCoordinates([producer.coordinates?.lat && producer.coordinates?.long]) && (
                  <Card className={styles.gmap}>
                    <ProducerMap
                      producerLocation={{
                        lat: producer.coordinates.lat,
                        lng: producer.coordinates.long
                      }}
                      userLocation={location}
                    />
                  </Card>
                )}
              </div>

              <Card>
                <Card.Title as="h1" variant="h4">
                  <Icon className={styles['energy-icon']} name="flash" />
                  {producer?.name?.[language]}
                </Card.Title>
                {producer.longDescription && <div className={styles.text}>{parse(producer.longDescription?.[language])}</div>}
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Producer
