import { FC } from 'react'
import Icon from 'components/Icon/Icon'
import styles from './Tabbar.module.scss'
import { TabbarDropdownItemProps } from './types'
import { IconPath } from '../Icon/types'
import Badge from 'components/Badge/Badge'
import { BadgeColors } from 'components/Badge/types'

const TabbarDropdownItem: FC<TabbarDropdownItemProps> = ({ icon, text, onClick, badge }) => {
  return (
    <li>
      <button onClick={onClick}>
        <div className={styles['dropdown-icon']}>
          {typeof icon === 'string' ? <Icon name={icon as IconPath} color="currentColor" /> : icon}
        </div>
        <p className={styles['text-content']}>
          {text}
          {!!badge && <Badge text={badge} color={BadgeColors.YELLOW} className={styles.badge} />}
        </p>
      </button>
    </li>
  )
}

export default TabbarDropdownItem
