import styles from './TerminateSideCard.module.scss'
import TerminateCardContent from './TerminateCardContent.tsx'
import Card from 'components/Card/Card.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { getMemoizedSelectedContracts } from 'utils/contracts.ts'
import { useStoreSelector } from 'hooks/store.ts'
import Drawer from 'components/Drawer/Drawer.tsx'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Icon } from '@boltenergy-be/design-system'

const TerminateSideCard = () => {
  // Redux
  const { selectedContracts } = useStoreSelector((store) => store.user)

  // i18n
  const { t } = useTranslation('contracts')

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false)

  // Constants
  const contracts = getMemoizedSelectedContracts(selectedContracts)
  const { electricityContract } = contracts

  return isTablet ? (
    <>
      <Card as="button" className={styles['sticky-footer-card']} onClick={() => setShowMobileDrawer(true)}>
        <strong>{t('terminate.sideCard.address')}</strong>
        <div className={styles.address}>
          <span>
            {electricityContract.address.streetName} {electricityContract.address.streetNumber} {electricityContract.address.streetBox}
          </span>
          <span>
            {electricityContract.address.postalCode} {electricityContract.address.townName}
          </span>
        </div>

        <Icon name="chevronUp" className={styles['toggle-icon']} />
      </Card>

      <Drawer contentClassName={styles.drawer} isOpen={showMobileDrawer} onRequestClose={() => setShowMobileDrawer(false)}>
        <TerminateCardContent selectedContracts={contracts} />
      </Drawer>
    </>
  ) : (
    <Card as="aside" className={styles.card} border={false}>
      <TerminateCardContent selectedContracts={contracts} />
    </Card>
  )
}

export default TerminateSideCard
