import { ContractsIconProps } from 'assets/svg/contracts/types.ts'

const Terminate = ({ accent = 'currentColor' }: ContractsIconProps) => {
  // Constants
  const isCurrentColor = accent === 'currentColor'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6845 10.0962C23.5063 10.6441 24 11.5665 24 12.5542L24 23.8363C24 25.5498 22.6109 26.9389 20.8974 26.9389H5.10256C3.38907 26.9389 2 25.5498 2 23.8363V12.5542C2 11.5665 2.49365 10.6441 3.31552 10.0962L11.279 4.78719C12.3212 4.09242 13.6788 4.09242 14.721 4.78719L22.6845 10.0962Z"
        fill={isCurrentColor ? accent : `var(${accent})`}
        fillOpacity={isCurrentColor ? '0.1' : '1'}
      />
      <path
        d="M15 20H31M31 20L26.9444 16M31 20L26.9444 24"
        stroke={isCurrentColor ? accent : 'var(--text-default)'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Terminate
