import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { Chart as ChartJS } from 'chart.js'
import { getBillshockChartOptions } from 'components/Charts/BillingCycle/Current/chartjs.config'
import { ChartLabels } from 'components/Charts/types'
import { CurrentBillingCycleChartProps } from './types'
import { calculateChartDimensions } from 'utils/chartjs'
import { formatAmount } from 'utils/format'
import { CycleViews } from 'pages/App/Billing/BillingCycles/types'
import ChartLegend from 'components/Charts/ChartLegend/ChartLegend'
import SettlementPeriodIndicator from 'components/Charts/SettlementPeriodIndicator/SettlementPeriodIndicator'
import { useStoreSelector } from 'hooks/store.ts'

const CurrentBillingCycleChartMobile = ({
  data,
  activePeriod,
  chartData,
  alternatingBackground,
  activeMonthIndex,
  minMaxData,
  labels,
  settlementColor,
  adjustedBillShockAmount
}: CurrentBillingCycleChartProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation()

  // Local state
  const [columnWidth, setColumnWidth] = useState<number>(0)
  const [chartYAxisWidth, setChartYAxisWidth] = useState<number>(0)
  const [chartXAxisHeight, setChartXAxisHeight] = useState<number>(0)
  const [settlementOuterEdge, setSettlementOuterEdge] = useState<number>(0)

  // Reference
  const chartRef = useRef<any>(null)
  const monthIndicatorRef = useRef<HTMLDivElement>(null)
  const activeTooltipRef = useRef<HTMLDivElement>(null)

  /**
   * sets the local state sizes for custom styling
   * @param {ChartJS} chart
   * @param {ChartLabels} labels
   */
  const handleResizeChart = useCallback(
    (chart: ChartJS, labels: ChartLabels) => {
      if (chart) {
        const {
          settlementOuterEdge: outerEdge,
          chartWidth,
          chartYAxisWidth: YAxisWidth,
          chartXAxisHeight: XAxisHeight
        } = calculateChartDimensions(
          chart,
          activePeriod === CycleViews.PROPOSED ? (adjustedBillShockAmount ? adjustedBillShockAmount : 0) : data.settlement.billShockAmount
        )

        setSettlementOuterEdge(outerEdge)
        setColumnWidth(chartWidth / labels.length)
        setChartYAxisWidth(YAxisWidth)
        setChartXAxisHeight(XAxisHeight)
      }
    },
    [activePeriod, adjustedBillShockAmount, data.settlement.billShockAmount]
  )

  // Options
  const options = useMemo<any>(
    () => getBillshockChartOptions(labels, alternatingBackground, activeMonthIndex, handleResizeChart, language, minMaxData, true),
    [activeMonthIndex, alternatingBackground, handleResizeChart, labels, minMaxData, language]
  )

  // Set the state dimensions
  useEffect(() => {
    if (chartRef?.current) {
      handleResizeChart(chartRef.current, labels)
    }
  }, [chartRef, handleResizeChart, labels])

  // Set the styling of the active tooltip
  useEffect(() => {
    if (columnWidth && settlementOuterEdge && activeTooltipRef?.current) {
      activeTooltipRef.current.style.bottom = `${settlementOuterEdge}px`
      activeTooltipRef.current.style.right = `${columnWidth + 5}px`
    }
  }, [columnWidth, settlementOuterEdge])

  // Set the styling of the month indicator
  useEffect(() => {
    if (columnWidth && chartYAxisWidth && chartXAxisHeight && monthIndicatorRef?.current) {
      monthIndicatorRef.current.style.left = chartYAxisWidth + columnWidth * (activeMonthIndex + 1) - columnWidth / 2 - 1 + 'px'
      monthIndicatorRef.current.style.height = `calc(100% - ${chartXAxisHeight - 7}px)`
      monthIndicatorRef.current.style.opacity = '1'
    }
  }, [activeMonthIndex, chartXAxisHeight, chartYAxisWidth, columnWidth])

  return (
    <>
      <div className={styles['chart-wrapper']}>
        <Bar options={options} data={chartData} className={styles.chart} ref={chartRef} />

        {/* ACTIVE TOOLTIP */}
        <div className={styles['always-active-tooltip']} ref={activeTooltipRef}>
          <span>{t('currentBillingCycle.legend.billShockAmount', { ns: 'charts' })}</span>
          <div className={styles['tooltip-description']}>
            <div className={styles['colored-box']} style={{ backgroundColor: settlementColor }} />
            <strong>
              €{' '}
              {formatAmount(
                activePeriod === CycleViews.PROPOSED
                  ? adjustedBillShockAmount
                    ? adjustedBillShockAmount
                    : 0
                  : data.settlement.billShockAmount
              )}
            </strong>
          </div>
        </div>

        {/* ACTIVE MONTH INDICATOR */}
        <div className={styles['active-month-indicator']} ref={monthIndicatorRef} />
      </div>

      {/* CURRENT PERIOD INDICATOR */}
      <SettlementPeriodIndicator columns={labels.length - 1} {...{ columnWidth, chartYAxisWidth }} />

      {/* LEGEND */}
      <ChartLegend {...{ chartData }} />
    </>
  )
}

export default CurrentBillingCycleChartMobile
