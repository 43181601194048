import { AuthModel, EventLogType } from 'types/eventLog'

/**
 * Transforms the given auth model to a readable string
 *
 * @param {AuthModel} authModel
 * @returns string
 */
export const transformAuthModel = (authModel: AuthModel): string => {
  switch (authModel) {
    case AuthModel.INTERNAL:
      return 'Superuser'

    case AuthModel.SALES:
      return 'Sales user'

    case AuthModel.USER:
      return 'Customer'

    default:
      return '-'
  }
}

/**
 * Transforms the given event log type to a readable string
 *
 * @param {EventLogType} type
 * @returns {string}
 */
export const transformEventLogType = (type: EventLogType): string => {
  switch (type) {
    case EventLogType.BILLING_CYCLE_VIEWED:
      return 'Billing cycle viewed'

    case EventLogType.CONTRACT_TERMINATED:
      return 'Contract terminated'

    case EventLogType.CREATE_CONTRACT:
      return 'Contract created'

    case EventLogType.METER_READINGS_ADDED:
      return 'Meter readings added'

    case EventLogType.PORTAL_BILLING_UPDATED:
      return 'Billing updated'

    case EventLogType.PORTAL_CUSTOMER_UPDATED:
      return 'Customer updated'

    case EventLogType.PORTAL_INSTALMENT_UPDATED:
      return 'Instalment updated'

    case EventLogType.PORTAL_SETTLEMENT_REQUESTED:
      return 'Settlement requested'

    case EventLogType.SERVICE_CONTRACT_UPDATED:
      return 'Service contract updated'

    default:
      return '-'
  }
}
