import { InlineMessage } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from '../Details.module.scss'
import { SimulationDetailsProps } from '../types'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import { Opportunity, PaymentMode } from 'types/sales'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Town } from 'api/types'
import { getTown } from 'api/addContract'
import { isValidPostalCode } from 'utils/addContract'
import { useFormContext } from 'react-hook-form'
import Toggle from 'components/Toggle/Toggle'
import IBAN from 'iban'
import { ALLOWED_IBAN_COUNTRIES } from 'features/contracts/add/constants.ts'
import { removeSpacesAndDots } from 'utils/format.ts'

const EditSimulationDetails = ({ opportunity }: SimulationDetailsProps) => {
  // i18n
  const { t } = useTranslation('sales')

  // React hookform
  const hookform = useFormContext<Partial<Opportunity>>()
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = hookform
  const watchTownName = watch('address.townName')
  const watchPaymentMode = watch('paymentMode')

  // Local state
  const [selectableTowns, setSelectableTowns] = useState<Town[]>([])

  // Constants
  const ibanIsRequired = watchPaymentMode === PaymentMode.DOMICILATION

  // Set town from postcode on page load
  useEffect(() => {
    validatePostalCodeAndFetchTowns(
      typeof opportunity.address.postalCode === 'string' ? parseInt(opportunity.address.postalCode) : opportunity.address.postalCode
    )
  }, [])

  /**
   * Handles the town select change & sets the town code
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = selectableTowns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('address.townCode', townCode, { shouldDirty: true })
    setValue('address.townName', townName, { shouldDirty: true })
  }

  /**
   * Fetches the town names based on the given postalCode
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns } = response
        setSelectableTowns(towns)

        if (!towns.some((town) => town.townName === watchTownName)) {
          setValue('address.townName', towns[0].townName, { shouldDirty: true })
        }
        setValue('address.townCode', towns[0].townCode, { shouldDirty: true })
        return true
      }
    }

    return t('invalid.postalCode', { ns: 'validation' })
  }

  return (
    <form>
      <DataBlock label={t('details.simulation.pricePerMonth')}>
        <input defaultValue={opportunity.pricePerMonthInclVat} disabled />
      </DataBlock>

      <DataBlock label={t('details.simulation.forcedAmount')}>
        <input
          {...register('forcedAmount', { required: false, max: 10000, min: opportunity.pricePerMonthInclVat })}
          inputMode="numeric"
          type="number"
        />
        {errors?.forcedAmount?.type === 'max' && (
          <InlineMessage type="negative">{t('tooHigh', { ns: 'validation', max: 10000 })}</InlineMessage>
        )}
        {errors?.forcedAmount?.type === 'min' && (
          <InlineMessage type="negative">
            {t('tooLow', {
              ns: 'validation',
              min: opportunity.pricePerMonthInclVat
            })}
          </InlineMessage>
        )}
      </DataBlock>

      <span className={styles['full-width']}>
        <DataBlock label={t('details.simulation.paymentMethod')}>
          <Toggle
            {...register('paymentMode', { required: true })}
            options={Object.values(PaymentMode).map((value) => ({ label: value, value: value }))}
            onClick={(value) => {
              setValue('paymentMode', value as PaymentMode, { shouldDirty: true })
            }}
            active={watchPaymentMode}
          />
          {errors?.paymentMode?.type === 'required' && <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>}
        </DataBlock>
      </span>

      <span className={styles['full-width']}>
        <DataBlock label={t('details.simulation.IBAN')}>
          <input
            {...register('ibanNumber', {
              required: ibanIsRequired,
              validate: {
                allowed: (value) => (!ibanIsRequired && !value) || (!!value && ALLOWED_IBAN_COUNTRIES.includes(value.slice(0, 2))),
                valid: (value) => (!ibanIsRequired && !value) || (!!value && IBAN.isValid(value))
              }
            })}
            onPaste={(e) => {
              const paste = removeSpacesAndDots(e.clipboardData.getData('text'))
              e.currentTarget.value = paste
              setValue('ibanNumber', paste)
              e.preventDefault()
            }}
          />
          {errors?.ibanNumber && (
            <InlineMessage type="negative">
              {errors.ibanNumber.type === 'required' && t('required', { ns: 'validation' })}
              {errors.ibanNumber.type === 'valid' && t('invalid.iban', { ns: 'validation' })}
              {errors.ibanNumber.type === 'allowed' && t('invalid.ibanCountries', { ns: 'validation' })}
            </InlineMessage>
          )}
        </DataBlock>
      </span>

      <DataBlock label={t('details.simulation.address.street')}>
        <input {...register('address.streetName', { required: true })} />
        {errors?.address?.streetName?.type === 'required' && (
          <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
        )}
      </DataBlock>

      <div className={styles['double-column']}>
        <DataBlock label={t('details.simulation.address.number')}>
          <input {...register('address.streetNumber', { required: true })} />
          {errors?.address?.streetNumber?.type === 'required' && (
            <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
          )}
        </DataBlock>

        <DataBlock label={t('details.simulation.address.addition')}>
          <input {...register('address.streetBox')} />
          {errors?.address?.streetBox?.type === 'required' && (
            <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
          )}
        </DataBlock>
      </div>

      <DataBlock label={t('details.simulation.address.postalCode')}>
        <input
          {...register('address.postalCode', {
            required: true,
            validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
          })}
          onChange={(e) => {
            const value = e.target.value
            setValue('address.postalCode', value)
            validatePostalCodeAndFetchTowns(parseInt(value))
          }}
        />
        {errors?.address?.postalCode?.type === 'required' && (
          <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
        )}
      </DataBlock>

      <DataBlock label={t('details.simulation.address.locality')}>
        <select
          className={classNames('form-control', { error: errors?.address?.townName })}
          {...register('address.townName', { required: true })}
          onChange={(e) => handleTownSelectChange(e.target.value)}
          value={watchTownName}
        >
          <option disabled>{watch('address.postalCode') === '' ? 'text' : t('defaultPlaceholders.select', { ns: 'common' })}</option>
          {selectableTowns.map((town) => {
            return (
              <option key={town.townCode} value={town.townName}>
                {town.townName}
              </option>
            )
          })}
        </select>
        {errors?.address?.townName?.type === 'required' && (
          <InlineMessage type="negative">{t('required', { ns: 'validation' })}</InlineMessage>
        )}
      </DataBlock>
    </form>
  )
}

export default EditSimulationDetails
