import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const VacuumCleaner: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M12.6 4.72c-.47.24-.81.62-1.02 1.1-.12.26-.32.93-.5 1.6a57.43 57.43 0 0 0-.28 1.1l-.01.06-.01.03-.88 3.4c1.34.32 2.82.76 4.06 1.3.78.35 1.29.9 1.56 1.55.26.64.27 1.33.18 1.96a9.55 9.55 0 0 1-1.11 3.08c-.31.55-.9.85-1.48.85H7.85a3.24 3.24 0 0 1-5.6-2.25c0-1.3.77-2.43 1.87-2.94l.91-2.96a1.72 1.72 0 0 1 1.94-1.2c.39.07.9.16 1.47.28l.88-3.43v-.02l.03-.08a56.12 56.12 0 0 1 .29-1.12c.17-.63.4-1.43.57-1.82.33-.77.9-1.42 1.7-1.82 2-1.03 4.4-.03 5.46 2a15.7 15.7 0 0 1 .3 1.05l.37 1.86a937.13 937.13 0 0 1 2.1 11.95H22a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1 0-1.5h1.61a1319.45 1319.45 0 0 0-1.1-6.47 359.3 359.3 0 0 0-1.3-7l-.14-.55-.04-.15v-.01c-.75-1.43-2.31-1.93-3.44-1.35ZM8.74 18.5c0-1.7-1.3-3.1-2.97-3.24l.69-2.22a.22.22 0 0 1 .24-.16c1.48.25 4.5.86 6.64 1.8.44.2.66.46.78.75.12.3.15.7.09 1.18a8.1 8.1 0 0 1-.95 2.56c-.02.04-.07.08-.16.08H8.66c.06-.24.09-.5.09-.75ZM5.5 16.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

VacuumCleaner.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default VacuumCleaner
