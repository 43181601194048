import { FC, PropsWithChildren, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { routes } from 'types/routes'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import Appbar from 'components/Appbar/Appbar'
import { Store } from 'store/types'
import { SalesAppProps as Props } from './types'
import styles from 'pages/App/App.module.scss'
import Tabbar from 'components/Tabbar/Tabbar'
import Sidebar from 'components/Sidebar/Sidebar'
import useWindowSize from 'hooks/useWindowSize'
import mixpanel from 'mixpanel-browser'
import EnergyTransitionDocumentContextProvider from 'store/react-context/energy-transition-document/EnergyTransitionDocumentContextProvider.tsx'

const SalesApp: FC<PropsWithChildren<Props>> = ({ children, logout }) => {
  // Redux
  const { userType } = useSelector((store: Store) => store.auth)
  const { userLoading, userError } = useSelector((store: Store) => store.user)
  const isLoading = userLoading
  const isError = !!userError

  // React Router
  const { pathname } = useLocation()

  // i18n
  const { t } = useTranslation()

  // Window size
  const { isTablet } = useWindowSize()

  // Constants
  const hideSidebarAndSubnav = [routes.ACTIVATE_TOKEN_SALES, routes.CONTRACTS].some((route) => pathname.includes(route))

  // Define mixpanel super property
  useEffect(() => {
    mixpanel.register({
      userType: userType
    })
  }, [userType])

  return (
    <EnergyTransitionDocumentContextProvider>
      <Appbar logout={logout} />

      <main className={styles.container}>
        {isLoading && isError ? (
          <div className={styles.content}>
            <div className={styles.loading}>
              {isLoading && (
                <div>
                  <div className={styles['dots-container']}>
                    <LoadingDots />
                  </div>
                  {t('loadingData')}
                </div>
              )}
              {isError && <div>{t('error')}</div>}
            </div>
          </div>
        ) : (
          <>
            {!hideSidebarAndSubnav && (isTablet ? <Tabbar isSales /> : <Sidebar isSales />)}

            <div className={styles.content}>{children}</div>
          </>
        )}
      </main>
    </EnergyTransitionDocumentContextProvider>
  )
}

export default SalesApp
