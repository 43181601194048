import { FC, useState } from 'react'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Store } from 'store/types'
import { ContactFormInputs } from './types'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { updateCustomer } from 'store/user/thunks/customer'
import Card from 'components/Card/Card.tsx'
import { Button, Heading } from '@boltenergy-be/design-system'
import { isValidIBAN } from 'ibantools'
import { ContractResponseCodes } from 'types/errorCodes.ts'
import Toggle from 'components/Toggle/Toggle.tsx'

import { removeSpacesAndDots } from 'utils/format.ts'

const Payment: FC = () => {
  // REDUX
  const { error, loading, selectedCustomer, customers } = useStoreSelector((store: Store) => store.user)
  const customer = customers[selectedCustomer]
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation('user')

  // Local state
  const [saved, setSaved] = useState(false)

  // Constants
  const mandateNumber = customers[selectedCustomer]?.paymentDetails?.mandateNumber

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue
  } = useForm<ContactFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      directDebitPaymentMode: customer.paymentDetails.directDebit,
      iban: customer.paymentDetails.iban
    }
  })
  const watchDirectDebitPaymentMode = watch('directDebitPaymentMode')

  /**
   * Handles the form submission after validation by React Hook Form
   *
   * @param {ContactFormInputs} data
   */
  const onSubmit = (data: ContactFormInputs) => {
    // Update the customer (only map the fields that have changed since it's a PATCH request)
    dispatch(
      updateCustomer({
        customerId: customer.id,
        customer: {
          paymentDetails: {
            iban: data.iban,
            directDebit: data.directDebitPaymentMode
          }
        }
      })
    )

    setSaved(true)
  }

  return (
    <div className="container">
      <Card>
        <Heading as="h1" variant="h3">
          {t('payment.title')}
        </Heading>
        <p>{t('payment.description')}</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading as="h2" variant="h5" className="mt-500">
            {t('payment.fields.paymentMethod.title')}
          </Heading>

          <div className="row">
            <div className="column form-group">
              <label htmlFor="directDebitPaymentMode">{t('payment.fields.paymentMethod.label')}</label>
              <Controller
                name="directDebitPaymentMode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    active={value}
                    onClick={onChange}
                    options={[
                      { value: false, label: t('payment.fields.paymentMethod.bankTransfer') },
                      { value: true, label: t('payment.fields.paymentMethod.directDebit') }
                    ]}
                  />
                )}
              />
            </div>
          </div>

          <Heading as="h2" variant="h5" className="mt-500">
            {t('payment.fields.financial.title')}
          </Heading>
          <p>{t('payment.fields.financial.description')}</p>

          <div className="row">
            <div className="column form-group">
              <label htmlFor="iban">{t('payment.fields.financial.iban')}</label>
              <input
                {...register('iban', {
                  required: true,
                  validate: (value: string) => isValidIBAN(removeSpacesAndDots(value))
                })}
                className={classNames('form-control', {
                  error: errors && errors.iban
                })}
                onChange={(e) => {
                  setValue('iban', removeSpacesAndDots(e.target.value))
                }}
              />
              {errors && errors.iban && (
                <span className="help-block text-negative">
                  {errors.iban.type === 'required' && t('required', { ns: 'validation' })}
                  {errors.iban.type === 'validate' && t('invalid.iban', { ns: 'validation' })}
                </span>
              )}
            </div>

            {watchDirectDebitPaymentMode && (
              <div className="column form-group">
                <label htmlFor="mandate">{t('payment.fields.financial.mandate')}</label>
                <input name="mandate" className="form-control" value={mandateNumber ?? '/'} disabled />
              </div>
            )}
          </div>

          <Button type="submit" disabled={Object.keys(errors).length > 0} loading={loading}>
            {t('save', { ns: 'common' })}
          </Button>

          {!loading && saved && (
            <span className="help-block">
              {error && ['updateCustomer', ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND].includes(error) ? (
                <span className="text-negative">
                  {error === ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND && t('payment.errors.mercatorErrorNotFound', { ns: 'user' })}
                  {t('error', { ns: 'common' })}
                </span>
              ) : (
                <span className="text-positive">{t('formFeedback.saved', { ns: 'common' })}</span>
              )}
            </span>
          )}
        </form>
      </Card>
    </div>
  )
}

export default Payment
