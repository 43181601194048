export enum ApiResponseCodes {
  // 400
  ALREADY_CUSTOMER = 'BOLT-400-001',
  NOT_A_CUSTOMER = 'BOLT-400-002',
  REQUESTED_PRODUCER_FULL = 'BOLT-400-003',

  // 404
  NOT_FOUND = 'BOLT-404-001'
}

export enum AuthErrorCodes {
  EMAIL_UNKNOWN = 'EmailUnknown',
  USER_EXISTS = 'UserExists',
  USER_NOT_FOUND = 'UserNotFound',
  PASSWORD_MISMATCH = 'PasswordMismatch',
  PASSWORD_RESET_TOKEN_FAILED = 'PasswordResetTokenFailed',
  PASSWORD_RESET_TOKEN_MISMATCH = 'PasswordResetTokenMismatch',
  PASSWORD_RESET_TOKEN_NOT_FOUND = 'PasswordResetTokenNotFound',
  REFRESH_TOKEN_EXPIRED = 'RefreshTokenExpired',
  REQUEST_NEW_PASSWORD_FAILED = 'RequestNewPasswordFailed',
  REGISTRATION_FAILED = 'RegistrationFailed',
  WRONG_EMAIL_OR_PASSWORD = 'WrongEmailOrPassword'
}

export enum ContractResponseCodes {
  // 404
  CONTRACT_NOT_FOUND = 'BOLT-CO-404-001',
  ENERGY_COMMUNITY_MEMBERSHIP_NOT_FOUND = 'BOLT-CO-404-002',
  LINKED_ENERGY_COMMUNITY_NOT_FOUND = 'BOLT-CO-404-003',
  CONTRACT_INVOICES_NOT_FOUND = 'BOLT-CO-404-004',
  MERCATOR_CUSTOMER_NOT_FOUND = 'BOLT-CO-404-005',
  NO_VOLUMES = 'BOLT-CO-404-006',
  NO_USABLE_VOLUMES = 'BOLT-CO-404-007',

  // 400
  NO_SMR3_VOLUMES_AVAILABLE_YET = 'BOLT-CO-400-004'
}

export enum SalesErrorCodes {
  API_ERROR = 'APIError',
  SALES_USER_DEACTIVATED = 'SalesUserDeactivated',
  SALES_USER_NOT_FOUND = 'SalesUserNotFound',
  PASSWORD_MISMATCH = 'PasswordMismatch'
}
