import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Shower: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M12.75 14.5a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm4.15 2.191a3.25 3.25 0 1 0-4.8 0c-2.236.857-3.85 2.877-3.85 5.309a.75.75 0 0 0 1.5 0c0-2.282 2.058-4.25 4.75-4.25s4.75 1.968 4.75 4.25a.75.75 0 0 0 1.5 0c0-2.432-1.614-4.452-3.85-5.309Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M8.258 5.75A3.805 3.805 0 0 0 8.25 6v1c0 .414.336.75.75.75h10a.75.75 0 0 0 .75-.75V6A3.75 3.75 0 0 0 16 2.25h-4a3.75 3.75 0 0 0-3.317 2H8A4.75 4.75 0 0 0 3.25 9v13a.75.75 0 0 0 1.5 0V9A3.25 3.25 0 0 1 8 5.75h.258ZM9.75 6A2.25 2.25 0 0 1 12 3.75h4A2.25 2.25 0 0 1 18.25 6v.25h-8.5V6Z"
        clipRule="evenodd"
      />
      <circle cx="11" cy="10" r="1" fill={iconColor} />
      <circle cx="14" cy="9" r="1" fill={iconColor} />
      <circle cx="17" cy="10" r="1" fill={iconColor} />
    </svg>
  )
}

Shower.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Shower
