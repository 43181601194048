import { BoltAmbition, Province, Region } from 'pages/App/Consumption/EnergyOverview/types'
import { BOLT_AMBITIONS, PROVINCES_WITH_CITIES } from 'constants/energyOverview'
import { Language } from 'store/app/types'
import { City, ToCompareCities } from 'components/EnergyOverview/types'

/**
 * Returns the province code based on the given postal code
 *
 * @param {number} postalCode
 * @returns {Province|null}
 */
export const getProvinceCode = (postalCode: number): Province | null => {
  // Brussels
  if (postalCode >= 1000 && postalCode <= 1299) return Province.BRU

  // Flanders
  if (postalCode >= 8000 && postalCode <= 8999) return Province.WVL // West Flanders
  if (postalCode >= 9000 && postalCode <= 9999) return Province.OVL // East Flanders
  if (postalCode >= 2000 && postalCode <= 2999) return Province.ANT // Antwerp
  if (postalCode >= 3500 && postalCode <= 3999) return Province.LIM // Limburg
  if ((postalCode >= 1500 && postalCode <= 1999) || (postalCode >= 3000 && postalCode <= 3499)) return Province.VLB // Flemish Brabant

  // Wallonia
  if (postalCode >= 1300 && postalCode <= 1499) return Province.WLB // Walloon Brabant
  if ((postalCode >= 6000 && postalCode <= 6599) || (postalCode >= 7000 && postalCode <= 7999)) return Province.HAI // Hainaut
  if (postalCode >= 5000 && postalCode <= 5999) return Province.NMR // Namur
  if (postalCode >= 4000 && postalCode <= 4999) return Province.LIE // Liege
  if (postalCode >= 6600 && postalCode <= 6999) return Province.LUX // Luxemburg

  return null
}

/**
 * Returns the region of the user
 * @param {Province|null} province
 * @param {Language} language
 * @param {boolean} includeBru
 * @returns Region
 */
export const getSelectedRegion = (province: Province | null, language: Language, includeBru?: boolean): Region => {
  const region = PROVINCES_WITH_CITIES.find((prov) => prov.province === province)?.region

  if (!region) {
    return language === Language.FR ? Region.WAL : Region.FLA
  }

  if (!includeBru && region === Region.BRU) {
    return language === Language.FR ? Region.WAL : Region.FLA
  }

  return region
}

/**
 * Calculates which cities have to be shown in the carbon dioxide chart
 * based on current region and emission reduction
 * @param {Region} region
 * @param {number} communityEmission
 * @returns ToCompareCities
 *
 * !! Currently, not being used due to wrong calculations
 * !! This calculation compares the reduction with the total emission of 5 cities with the first higher city then the reduction value
 */
export const getComparableCities = (region: Region, communityEmission: number) => {
  return PROVINCES_WITH_CITIES.reduce(
    (prev: ToCompareCities, curr) => {
      // If you want the BRU city in here as well, add || curr.region === Region.BRU
      if (curr.region === region) {
        const firstBeatenCity = curr.cities.findIndex((city: City) => city.emission < communityEmission)
        let city

        if (firstBeatenCity === -1) {
          city = curr.cities[curr.cities.length - 1]
        } else if (firstBeatenCity === 0) {
          city = curr.cities[firstBeatenCity]
        } else {
          city = curr.cities[firstBeatenCity - 1]
        }

        prev.cities[curr.province] = city
        prev.highestEmissionCity = (prev.highestEmissionCity?.emission || 0) > city.emission ? prev.highestEmissionCity : city
      }

      return prev
    },
    { cities: {}, highestEmissionCity: undefined }
  )
}

/**
 * return ambition object for ambition chart
 *
 * @param {number} currentTotal
 * @param {region} region
 * @param {Language} language
 * @param {?string} previousCity
 * @returns
 */
export const getAmbition = (currentTotal: number, region: Region, language: Language, previousCity?: string): BoltAmbition => {
  const index = BOLT_AMBITIONS.findIndex((ambition) => ambition.amount > currentTotal)
  const relevantAmbitions = BOLT_AMBITIONS.map((ambition) => (region !== Region.BRU ? ambition[region] : ambition[region][language]))

  // Add Bolt to the array
  relevantAmbitions.splice(index, 0, 'Bolt')

  // if Bolt is not the first array, then replace the previous item with the previousCity if there is a given one.
  if (index > 0 && previousCity && !relevantAmbitions.includes(previousCity)) {
    relevantAmbitions.splice(index - 1, 1, previousCity)
  }

  // If there are more than 5 items in the array, remove the extra
  if (relevantAmbitions.length > 5) {
    // check if Bolt is in first or second half of the array
    if (relevantAmbitions.slice(0, Math.ceil(relevantAmbitions.length / 2)).indexOf('Bolt') !== -1) {
      relevantAmbitions.pop()
    } else {
      relevantAmbitions.shift()
    }
  }

  return {
    level: index + 1,
    list: relevantAmbitions,
    nextGoal: BOLT_AMBITIONS[index].amount
  }
}
