import { Contract } from 'types/types'
import { roundToTwoDecimals } from 'utils/number'

/**
 * Adds the given newInstalment to the given contract
 *
 * @param {Contract} contract
 * @param {number} newInstalment
 * @returns {Contract}
 */
export const addNewInstalmentToContract = (contract: Contract, newInstalment: number): Contract => {
  const newContract = Object.assign({}, JSON.parse(JSON.stringify(contract)))

  newContract.detail.instalment = Number(roundToTwoDecimals(newInstalment))

  return newContract
}
