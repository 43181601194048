export enum FaqSectionSlugs {
  INSTALMENT_WITH_PROPOSITION_NL = 'voorschot-aanpassen-met-voorstel',
  INSTALMENT_WITH_PROPOSITION_FR = 'ajuster-acompte-avec-proposition',
  INSTALMENT_WITHOUT_PROPOSITION_NL = 'voorschot-aanpassen-zonder-voorstel',
  INSTALMENT_WITHOUT_PROPOSITION_FR = 'ajuster-acompte-sans-proposition',
  SETTLEMENT_NL = 'prijsstijging-tussentijds-afrekenen',
  SETTLEMENT_FR = 'augmentationduprix-facture-intermediaire',
  INSTALMENT_NL = 'prijsstijging-voorschot',
  INSTALMENT_FR = 'augmentationduprix-acompte',
  BILLING_CYCLES_NL = 'afrekeningsperiodes',
  BILLING_CYCLES_FR = 'periodes-de-decompte',
  CONSUMPTION_NL = 'verbruik',
  CONSUMPTION_FR = 'consommation',
  METER_READINGS_NL = 'meterstanden',
  METER_READINGS_FR = 'releves-de-compteurs',
  COMMUNITY_SALES_NL = 'community-sales',
  COMMUNITY_SALES_FR = 'community-sales-fr',
  MOVE_NL = 'verhuizen-portal',
  MOVE_FR = 'demenager-portal',

  // FAQ PAGE (NL)
  ABOUT_BOLT_NL = 'over-bolt',
  SWITCH_NL = 'overstappen',
  USAGE_AND_PRICE_NL = 'verbruik-en-prijs',
  PAYMENT_NL = 'betalen',
  GOVERNMENT_MEASUREMENTS_NL = 'overheidsmaatregelen',

  // FAQ PAGE (FR)
  ABOUT_BOLT_FR = 'au-sujet-de-bolt',
  SWITCH_FR = 'changement-de-fournisseur',
  USAGE_AND_PRICE_FR = 'consommation-et-prix',
  PAYMENT_FR = 'regler-sa-facture',
  GOVERNMENT_MEASUREMENTS_FR = 'mesures-gouvernementales',

  // Energycrsis
  NEW_INSTALMENT_NL = 'aangepast-voorschot',
  PRICE_INCREASE_NL = 'prijsstijging-algemeen',
  NEW_INSTALMENT_FR = 'acompte-ajuste',
  PRICE_INCREASE_FR = 'augmentationduprix-general'
}
