import { useState, useEffect, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { TooltipOnClickProps } from './types'
import styles from './TooltipOnClick.module.scss'
import { usePopper } from 'react-popper'
import { Button } from '@boltenergy-be/design-system'

const TooltipOnClick = ({
  children,
  classNameTrigger,
  classNameTriggerTooltipActive,
  tooltipContent,
  tooltipClassName,
  width,
  tooltipPlacement = 'top',
  variant,
  disabled,
  isOpen = false
}: PropsWithChildren<TooltipOnClickProps>) => {
  // Local state
  const [tooltipIsOpen, setTooltipOpen] = useState(isOpen)
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null)

  // Popper
  const { styles: popperStyles, attributes } = usePopper(buttonElement, tooltipElement, {
    placement: tooltipPlacement,
    modifiers: [
      { name: 'preventOverflow', options: { altAxis: true, padding: 10 } },
      { name: 'flip', options: { padding: { top: 135 } } }
    ]
  })

  /**
   * Hides tooltip on click outside
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (tooltipElement && !tooltipElement.contains(e.target as HTMLDivElement)) {
        setTooltipOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [tooltipElement])

  /**
   * Hides tooltip on ESC button press when tooltip is open
   */
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        setTooltipOpen(false)
      }
    }

    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [])

  /**
   * Hides tooltip when isOpen prop changes to false
   */
  useEffect(() => {
    if (isOpen === false) {
      setTooltipOpen(false)
    }
  }, [isOpen])

  return (
    <>
      <div className={styles['trigger-container']}>
        {variant ? (
          <Button
            ref={setButtonElement}
            type="button"
            className={classNames(classNameTrigger, {
              [classNameTriggerTooltipActive as string]: classNameTriggerTooltipActive && tooltipIsOpen
            })}
            onClick={(e) => {
              e.preventDefault()
              setTooltipOpen(true)
            }}
            variant={variant}
            disabled={disabled}
          >
            {children}
          </Button>
        ) : (
          <button
            ref={setButtonElement}
            type="button"
            className={classNames(classNameTrigger, {
              [classNameTriggerTooltipActive as string]: classNameTriggerTooltipActive && tooltipIsOpen
            })}
            onClick={(e) => {
              e.preventDefault()
              setTooltipOpen(true)
            }}
            disabled={disabled}
          >
            {children}
          </button>
        )}
        {tooltipIsOpen && (
          <div
            ref={setTooltipElement}
            className={classNames(styles.tooltip, tooltipClassName)}
            style={{ ...popperStyles.popper, ...(width && { width }) }}
            {...attributes.popper}
          >
            {tooltipContent}
          </div>
        )}
      </div>
      {tooltipIsOpen && <div className={styles.overlay} />}
    </>
  )
}

export default TooltipOnClick
