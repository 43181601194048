import { useEffect } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoginFormFieldsType } from './types'
import { EMAIL } from 'constants/regex'
import { login } from 'store/auth/thunks'
import { matchPath, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { routes } from 'types/routes'
import { Store } from 'store/types'
import { AuthErrorCodes } from 'types/errorCodes'
import styles from '../Auth.module.scss'
import { UserTypes } from 'store/auth/types'
import { Button } from '@boltenergy-be/design-system'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { checkIsDown } from 'utils/app.ts'
import { Heading, Banner } from '@boltenergy-be/design-system'
import Badge from 'components/Badge/Badge.tsx'
import { BadgeColors } from 'components/Badge/types.ts'
import Link from 'components/Link/Link'

const Login = () => {
  // REDUX STORE
  const { error, loading } = useStoreSelector((store: Store) => store.auth)
  const dispatch = useStoreDispatch()

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormFieldsType>({ mode: 'onBlur' })

  // i18n
  const { t } = useTranslation('auth')

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isSuperUserLoginPage = matchPath({ path: routes.SUPER_USER_LOGIN }, pathname)
  const isSalesLoginPage = matchPath({ path: routes.LOGIN_SALES }, pathname)
  const isCustomerLoginPage = !isSuperUserLoginPage && !isSalesLoginPage

  // Constants
  const { isDown, message } = checkIsDown(
    isSuperUserLoginPage ? UserTypes.SUPER_USER : isSalesLoginPage ? UserTypes.SALES : UserTypes.CUSTOMER
  )

  /**
   * Handles the submit after the validation
   *
   * @param {LoginFormFieldsType} data
   */
  const onSubmit = async (data: LoginFormFieldsType) => {
    const { email, password } = data

    let userType = UserTypes.CUSTOMER
    if (isSalesLoginPage) userType = UserTypes.SALES
    if (isSuperUserLoginPage) userType = UserTypes.SUPER_USER

    await dispatch(login({ email: email.toLowerCase(), password, userType, navigateTo: (destination) => navigate(destination) }))
  }

  /**
   * Changes the page title
   */
  useEffect(() => {
    if (isSalesLoginPage) {
      document.title = 'Bolt Sales Portal'
    }
  }, [isSalesLoginPage])

  return (
    <>
      <div className={styles['auth-body']}>
        <header className={styles.header}>
          <Heading as="h1" variant="h3">
            {t(isSalesLoginPage ? 'titleSales' : 'title')}
          </Heading>
          {isSuperUserLoginPage && <Badge text={t('superuser')} color={BadgeColors.ORANGE} />}
        </header>
        {isCustomerLoginPage && <p className={styles.description}>{t('descriptions.login')}</p>}

        {isDown ? (
          <Banner type="informative" className={styles.maintenance}>
            <p>{message}</p>
          </Banner>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="email">{t('email')}</label>
              <input
                id="email"
                type="email"
                className={classNames('form-control', { error: errors?.email })}
                placeholder={t('email')}
                {...register('email', { required: true, pattern: EMAIL })}
              />

              {errors.email && (
                <span className="help-block text-negative">
                  {errors.email.type === 'required' && t('required', { ns: 'validation' })}
                  {errors.email.type === 'pattern' && t('invalid.email', { ns: 'validation' })}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="password">{t('password')}</label>
              <input
                id="password"
                type="password"
                className={classNames('form-control', { error: errors?.password })}
                placeholder={t('password')}
                {...register('password', { required: true })}
              />

              {errors.password && (
                <span className="help-block text-negative">
                  {errors.password.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>

            <Button isFullwidth loading={loading}>
              {t('buttons.login')}
            </Button>
          </form>
        )}

        {error &&
          [AuthErrorCodes.WRONG_EMAIL_OR_PASSWORD, AuthErrorCodes.PASSWORD_MISMATCH, AuthErrorCodes.USER_NOT_FOUND].includes(
            error as AuthErrorCodes
          ) && <p className="text-negative center">{t('formErrors.WrongEmailOrPassword')}</p>}

        {(isCustomerLoginPage || isSalesLoginPage) && (
          <Link variant="secondary" href={isSalesLoginPage ? routes.REQUEST_NEW_PASSWORD_SALES : routes.REQUEST_NEW_PASSWORD}>
            {t('mutedTexts.forgotPassword')}
          </Link>
        )}
      </div>

      {isCustomerLoginPage ? (
        <div className={styles['auth-footer']}>
          <p className="mb-300">{t('notYetRegister.description')}</p>
          <Link representation="button" isFullwidth variant="secondary" href={routes.REGISTER}>
            {t('notYetRegister.button')}
          </Link>
        </div>
      ) : (
        // Spacer for superuser login
        <div />
      )}
    </>
  )
}

export default Login
