import { determineAccessRights, getMemoizedSelectedContracts } from 'utils/contracts.ts'
import { useStoreSelector } from 'hooks/store.ts'
import ConsumptionSection from 'pages/App/Consumption/YourConsumption/ConsumptionSection/ConsumptionSection.tsx'
import PlanConsumptionSection from 'pages/App/Consumption/YourConsumption/PlanConsumptionSection/PlanConsumptionSection.tsx'
import styles from './YourConsumption.module.scss'
import classNames from 'classnames'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget'
import { useEffect, useState } from 'react'

const YourConsumption = () => {
  // Redux
  const { selectedContracts } = useStoreSelector((store) => store.user)

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)

  const [startOpenFeedbackWidgetTimer, setStartFeedbackWidgetTimer] = useState<boolean>(false)

  // Custom hook to open feedback widget after 10 seconds
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget(
    10,
    WidgetSlug.CONSUMPTION_DYNAMIC_TARIFF_RELEASE,
    startOpenFeedbackWidgetTimer
  )

  useEffect(() => {
    if (accessRights.consumption.showContent) {
      setStartFeedbackWidgetTimer(true)
    }
  }, [accessRights.consumption.showContent])

  return (
    <>
      <div className={classNames('container', styles.container)}>
        {accessRights.planConsumptionDynamic.canAccess && <PlanConsumptionSection />}

        <ConsumptionSection />
      </div>

      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default YourConsumption
