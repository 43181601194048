import { Electricity, Gas, Night, Sun } from 'assets/svg'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { Button } from '@boltenergy-be/design-system'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import AddMeterReadingsModal from 'features/MeterReadings/AddMeterReadingsModal/AddMeterReadingsModal'
import { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Direction, ProductType, TimeframeCode } from 'types/types'
import { popSuccessToast } from 'utils/toast'
import { MeterReadingSource } from 'types/contracts'
import dayjs from 'dayjs'
import styles from './MeterReadings.module.scss'
import { AddMeterReadingsModalEntryPoint } from 'features/MeterReadings/AddMeterReadingsModal/types'
import FooterFaq from 'components/Faq/FooterFaq/FooterFaq'
import resources from 'translations/dutch'
import { FaqSectionSlugs } from 'types/faq'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { Language } from 'store/app/types'
import { resetBillShock } from 'store/contracts/slice'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import Card from 'components/Card/Card.tsx'
import { Heading } from '@boltenergy-be/design-system'
import { ReturnLater } from 'components/ReturnLater/ReturnLater'
import { determineAccessRights, getMemoizedSelectedContracts } from 'utils/contracts'
import { Navigate } from 'react-router'
import { routes } from 'types/routes.ts'

const MeterReadings: FC = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const {
    meterReadings: { data, loading }
  } = useStoreSelector((store) => store.contracts)
  const { selectedContracts } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)

  // Redux queries
  const { data: faq, isLoading: faqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.METER_READINGS_FR : FaqSectionSlugs.METER_READINGS_NL
  })

  // Local state
  const [isAddMeterReadingsModalOpen, setIsAddMeterReadingsModalOpen] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('consumption')

  /**
   * Returns the correct meter reading entry based on the given product type & timeframe code
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {TimeframeCode} timeframeCode
   * @returns {number|undefined}
   */
  const getMeterReadingEntry = (type: ProductType, direction: Direction, timeframeCode: TimeframeCode): number | undefined => {
    if (type === ProductType.GAS) return data?.gas?.consumption?.singleRate

    switch (timeframeCode) {
      case TimeframeCode.HIGH:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.day

      case TimeframeCode.LOW:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.night

      case TimeframeCode.NIGHT_EXCLUSIVE:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.exclNight

      default:
        return data?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.singleRate
    }
  }

  /**
   * Returns the meter reading row for the given type, direction & timeframe code
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {TimeframeCode} timeframeCode
   * @returns {ReactElement}
   */
  const getMeterReadingRow = (type: ProductType, direction: Direction, timeframeCode: TimeframeCode): ReactElement => {
    const isGas = type === ProductType.GAS

    return (
      <div className={styles['meter-reading-row']}>
        <div className={styles['type-and-ean']}>
          <span className={styles.type}>
            {t(`${!isGas && direction === Direction.PRODUCTION ? 'injection' : type}`, { ns: 'common' })}
            &nbsp;
            {!isGas && timeframeCode !== TimeframeCode.TOTAL_HOUR && (
              <>
                {t(`indexes.${timeframeCode as keyof typeof resources.common.indexes}`, { ns: 'common' })}

                {timeframeCode === TimeframeCode.LOW || timeframeCode === TimeframeCode.NIGHT_EXCLUSIVE ? (
                  <Night iconColor="currentColor" />
                ) : (
                  <Sun iconColor="currentColor" />
                )}
              </>
            )}
          </span>

          <small className={styles.ean}>
            {t('ean', { ns: 'common' })}: {data?.[type]?.ean}
          </small>
        </div>

        <span className={styles['meter-reading']}>
          {getMeterReadingEntry(type, direction, timeframeCode)?.toFixed(0)}
          &nbsp;<small>{isGas ? parse('m<sup>3</sup>') : 'kWh'}</small>
        </span>
      </div>
    )
  }

  return !accessRights.meterReadings.canAccess ? (
    <Navigate to={routes.CONSUMPTION} replace />
  ) : !accessRights.meterReadings.showContent ? (
    <ReturnLater description={t('meterReadings.returnLater')} />
  ) : (
    <>
      <Card as="section" className={styles.card}>
        <Card.Title>{t('meterReadings.title')}</Card.Title>
        <div className={styles.description}>{parse(t('meterReadings.description'))}</div>

        {!loading ? (
          <>
            <div className={styles['last-meter-readings']}>
              <div className={styles['title-section']}>
                <span className={classNames(styles.icon, styles.electricity)}>
                  <Electricity isFilled />
                </span>
                <Heading as="h3" variant="h6" weight={600}>
                  {t('meterReadings.lastMeterReadings.electricity')}
                </Heading>
                <small>
                  {parse(
                    t(
                      `meterReadings.lastMeterReadings.${
                        data?.electricity?.source === MeterReadingSource.CLIENT_PROVIDED ? 'self' : 'networkOperator'
                      }`,
                      {
                        date: dayjs(data?.electricity?.date).format('DD/MM/YYYY')
                      }
                    )
                  )}
                </small>
              </div>

              <div className={styles['table-section']}>
                {/* CONSUMPTION ELECTRICITY INPUTS */}
                {typeof data?.electricity?.consumption.singleRate !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.TOTAL_HOUR)}
                {typeof data?.electricity?.consumption.doubleRate?.day !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.HIGH)}
                {typeof data?.electricity?.consumption.doubleRate?.night !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.LOW)}
                {typeof data?.electricity?.consumption.exclNight !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, TimeframeCode.NIGHT_EXCLUSIVE)}

                {/* INJECTION ELECTRICITY INPUTS */}
                {typeof data?.electricity?.injection?.singleRate !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.PRODUCTION, TimeframeCode.TOTAL_HOUR)}
                {typeof data?.electricity?.injection?.doubleRate?.day !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.PRODUCTION, TimeframeCode.HIGH)}
                {typeof data?.electricity?.injection?.doubleRate?.night !== 'undefined' &&
                  getMeterReadingRow(ProductType.ELECTRICITY, Direction.PRODUCTION, TimeframeCode.LOW)}
              </div>
            </div>

            {data?.gas && (
              <div className={styles['last-meter-readings']}>
                <div className={styles['title-section']}>
                  <span className={classNames(styles.icon, styles.gas)}>
                    <Gas isFilled />
                  </span>
                  <Heading as="h3" variant="h6" weight={600} className={styles.gas}>
                    {t('meterReadings.lastMeterReadings.gas')}
                  </Heading>
                  <small>
                    {parse(
                      t(
                        `meterReadings.lastMeterReadings.${
                          data.gas.source === MeterReadingSource.CLIENT_PROVIDED ? 'self' : 'networkOperator'
                        }`,
                        {
                          date: dayjs(data.gas.date).format('DD/MM/YYYY')
                        }
                      )
                    )}
                  </small>
                </div>

                <div className={styles['table-section']}>
                  {/* CONSUMPTION GAS INPUTS */}
                  {typeof data.gas?.consumption.singleRate !== 'undefined' &&
                    getMeterReadingRow(ProductType.GAS, Direction.CONSUMPTION, TimeframeCode.TOTAL_HOUR)}
                </div>
              </div>
            )}

            <Button className={styles.button} onClick={() => setIsAddMeterReadingsModalOpen(true)}>
              {t('meterReadings.button')}
            </Button>
          </>
        ) : (
          <LoadingDots />
        )}
      </Card>

      <FooterFaq isLoading={faqLoading} category={faq?.category} subtitle={t('faq.subtitles.meterReadings', { ns: 'common' })} />

      <AddMeterReadingsModal
        entryPoint={AddMeterReadingsModalEntryPoint.MeterReadingsPage}
        isOpen={isAddMeterReadingsModalOpen}
        setClose={() => setIsAddMeterReadingsModalOpen(false)}
        onSuccess={() => {
          // Clear billshock
          dispatch(resetBillShock())
          popSuccessToast(t('addMeterReadingsModal.form.successToast', { ns: 'common' }))
        }}
      />
    </>
  )
}

export default MeterReadings
