/**
 * Creates a cookie
 *
 * @param name
 * @param value
 * @param days
 */
export const createCookie = (name: string, value: string, days?: number) => {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = name + '=' + value + expires + '; path=/'
}

/**
 * Reads a cookie
 *
 * @param name
 */
export const readCookie = (name: string) => {
  const nameEQ = name + '='
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }

    if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length)
  }

  return null
}
