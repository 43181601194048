import { useTranslation } from 'react-i18next'
import { ChurnReasonInputs, ChurnReasons, TerminateStepProps, TerminateSteps } from '../../types'
import styles from '../../../Contracts.module.scss'
import Card from 'components/Card/Card.tsx'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons.tsx'
import { useForm } from 'react-hook-form'

const ChurnReason = ({ setCurrentStep, terminateData, setNextStep }: TerminateStepProps) => {
  // i18n
  const { t } = useTranslation('contracts')

  // React Hook form
  const { handleSubmit, register, formState } = useForm<{ reason: ChurnReasonInputs }>({
    defaultValues: {
      reason: terminateData[TerminateSteps.CHURN]
    }
  })

  /**
   * Handles the form submit
   * @param {{reason: ChurnReasonInputs}} data
   */
  const onSubmit = (data: { reason: ChurnReasonInputs }) => {
    setNextStep(data.reason, TerminateSteps.METER_DETAILS)
  }

  /**
   *
   * @param {ChurnReasons} reason
   * @returns {any}
   */
  const getReasonKey = (reason: ChurnReasons): keyof typeof ChurnReasons => {
    return Object.keys(ChurnReasons)[Object.values(ChurnReasons).indexOf(reason)] as keyof typeof ChurnReasons
  }

  return (
    <form className={styles['form-card']} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['form-content']}>
        <Card.Title>{t('terminate.churn.title')}</Card.Title>

        <div className="row">
          {/* MY SITUATION */}
          <div className="column form-group">
            <fieldset className={styles['radio-buttons-container']}>
              <strong>{t('terminate.churn.label')}</strong>

              {Object.values(ChurnReasons).map((reason) => (
                <label htmlFor={getReasonKey(reason)} key={reason} className={styles['checkbox-container']}>
                  <input {...register('reason', { required: true })} type="radio" id={getReasonKey(reason)} value={reason} />
                  {t(`terminate.churn.reasons.${reason}`)}
                </label>
              ))}

              {formState?.errors?.reason && (
                <p className="help-block text-negative">
                  {formState?.errors?.reason?.type === 'required' && t('selectOption', { ns: 'validation' })}
                </p>
              )}
            </fieldset>
          </div>
        </div>
      </div>

      <FormButtons currentStep={TerminateSteps.CHURN} setCurrentStep={(step) => setCurrentStep(step as TerminateSteps)} />
    </form>
  )
}

export default ChurnReason
