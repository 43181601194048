import { ProductType } from 'types/types'
import { MinMaxData } from '../types'
import Bugsnag from '@bugsnag/js'
import { Volume, VolumeEntry } from 'pages/App/Consumption/YourConsumption/ConsumptionSection/types.ts'
import { memoize } from 'proxy-memoize'
import { HistoryModifier } from 'components/Charts/Consumption/types.ts'

/**
 * Helper function to calculate the sum for a given Volume
 *
 * @param {Volume} type
 * @returns {number}
 */
export const sumVolume = (type?: Volume): number => {
  return (type?.billed || 0) + (type?.unbilled || 0)
}

/**
 * calculate min and max consumption & history data for Y axis
 * @param {VolumeEntry[]} data
 * @param {ProductType} activeType
 * @param {number} modifier
 * @returns {MinMaxData}
 */
export const calculateMinMaxConsumptionData = (data: VolumeEntry[], activeType: ProductType, modifier: number): MinMaxData => {
  let max = 0
  let min = 0
  let maxHistory = 0

  const isElek = activeType === ProductType.ELECTRICITY

  data.forEach(({ consumption, injection, history }) => {
    max = Math.max(sumVolume(consumption), max)
    maxHistory = Math.ceil(10 * Math.max(((isElek ? history.electricity : history.gas) || 0) * modifier, maxHistory)) / 10

    // Only calculate min if there is electricity injection data
    if (injection) {
      min = Math.min(sumVolume(injection), min)
    }
  })

  // Highest of both values
  const combinedMax = Math.max(max, maxHistory)

  // Rounded values
  const maxDataValue = Math.ceil(combinedMax)
  const minDataValue = Math.floor(min)

  if (maxHistory > combinedMax) {
    Bugsnag.notify(Error('Chart error found, maxHistory is higher than the Y-axis max value'))
  }

  return {
    maxDataValue,
    minDataValue
  }
}

/**
 * Recursively multiplies a value until it is greater than half of another given value.
 * Returns the total multiplication.
 *
 * @param {number} value - The value to be multiplied.
 * @param {number} compareValue - The reference value to compare against.
 * @param {number} thresholdMultiplier - The threshold multiplier to use in the comparison.
 * @param {number} multiplier - The multiplier to use in each recursion.
 * @param {number} [total=1] - The accumulated total multiplication.
 * @returns {number} - The total multiplication.
 */
const calculateMultiplierUntilAboveAverage = (
  value: number,
  compareValue: number,
  thresholdMultiplier: number,
  multiplier: number,
  total: number = 1
): number => {
  if (value > compareValue * thresholdMultiplier) {
    return total
  }
  return calculateMultiplierUntilAboveAverage(value * multiplier, compareValue, thresholdMultiplier, multiplier, total * multiplier)
}

/**
 * Calculate the modifier for the history data
 *
 * @param {VolumeEntry[]} dataset
 * @param {ProductType} type
 * @returns {number}
 */
const getHistoryModifier = (dataset: VolumeEntry[], type: ProductType): number => {
  let max = 0
  let maxHistory = 0

  dataset.forEach((entry) => {
    max = Math.max(sumVolume(entry.consumption), max)
    maxHistory = Math.max(type === ProductType.ELECTRICITY ? entry.history.electricity || 0 : entry.history.gas || 0, maxHistory)
  })

  let modifierString = '1'
  const amountOfZeros: number = Math.round(max).toString().length

  for (let i = amountOfZeros; i > 0; i--) {
    modifierString += '0'
  }

  const thresholdMultiplier = 0.75
  const maxHistoryWithModifier = Number(modifierString) * maxHistory
  const maxValueWithBuffer = max + max / 10
  const maxHistoryExceedsMaxValue = maxHistoryWithModifier > maxValueWithBuffer
  const maxHistoryIsVeryLow = maxHistoryWithModifier < maxValueWithBuffer * thresholdMultiplier

  if (maxHistoryExceedsMaxValue) {
    return Number(modifierString) / 2
  }

  if (maxHistoryIsVeryLow) {
    const multiplier = calculateMultiplierUntilAboveAverage(maxHistoryWithModifier, maxValueWithBuffer, thresholdMultiplier, 2)
    return Number(modifierString) * multiplier
  }

  return Number(modifierString) || 100
}

export const getMemoizedHistoryModifier = memoize<HistoryModifier, number>(({ dataset, type }) => getHistoryModifier(dataset, type))
