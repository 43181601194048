import { FC, PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'
import { SeoTagsProps as Props } from './types'

const SeoTags: FC<PropsWithChildren<Props>> = ({ title, children }) => {
  return (
    <Helmet>
      <title>{title} | My Bolt</title>
      {children}
    </Helmet>
  )
}

export default SeoTags
