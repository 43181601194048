import { FC } from 'react'
import { IconProps as Props } from './types'

const Gas: FC<Props> = ({ active }) => {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <path
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm.524 6.466a.312.312 0 0 0-.416-.022c-1.446 1.163-1.877 2.823-1.964 4.082a3.389 3.389 0 0 1-.758-.719 2.934 2.934 0 0 1-.274-.426l-.031-.065a.994.994 0 0 1-.012-.025.313.313 0 0 0-.513-.11A5.297 5.297 0 0 0 7 12.937c0 1.36.519 2.72 1.556 3.757a5.29 5.29 0 0 0 2.662 1.443.313.313 0 0 0 .307-.502c-.155-.193-.463-.654-.463-1.26 0-.394.169-.903.448-1.396.215-.38.482-.724.75-.973.24.286.454.485.652.63.252.186.468.276.643.349.184.077.301.128.425.238.273.242.394.46.454.667.064.22.066.445.066.737a.92.92 0 0 1-.176.587.313.313 0 0 0 .396.46 5.31 5.31 0 0 0 1.349-.98 5.297 5.297 0 0 0 1.556-3.756c0-1.36-.519-2.72-1.556-3.757-.484-.484-1.04-.85-1.599-1.218l-.013-.009c-.638-.42-1.292-.852-1.933-1.488Z"
          fill="#23D5AE"
          fillRule="evenodd"
        />
      ) : (
        <g fill="none" fillRule="evenodd">
          <path
            d="m3.563 3.467 6.6 6.833a7.528 7.528 0 0 0-.02.226 3.389 3.389 0 0 1-.757-.719 2.934 2.934 0 0 1-.274-.426l-.031-.065a1.002 1.002 0 0 1-.012-.025.313.313 0 0 0-.513-.11A5.297 5.297 0 0 0 7 12.937c0 1.36.519 2.72 1.556 3.757a5.29 5.29 0 0 0 2.662 1.443.313.313 0 0 0 .307-.502c-.155-.193-.463-.654-.463-1.26 0-.394.169-.903.448-1.396.215-.38.482-.724.75-.973.24.286.454.485.652.63.252.186.468.276.643.349.184.077.301.128.425.238.273.242.394.46.454.667.064.22.066.445.066.737a.92.92 0 0 1-.176.587.313.313 0 0 0 .396.46 5.31 5.31 0 0 0 1.484-1.12l4.033 4.173A11.959 11.959 0 0 1 12 24C5.373 24 0 18.627 0 12c0-3.338 1.363-6.358 3.563-8.533ZM12 0c6.627 0 12 5.373 12 12 0 2.82-.973 5.413-2.6 7.461l-4.227-4.375a5.318 5.318 0 0 0-1.104-5.905c-.484-.484-1.04-.85-1.599-1.218l-.013-.008c-.638-.42-1.292-.853-1.933-1.49a.312.312 0 0 0-.416-.021 4.995 4.995 0 0 0-1.448 1.903L4.867 2.349A11.946 11.946 0 0 1 12 0Z"
            fill="#BAB9BC"
          />
          <path stroke="#BAB9BC" strokeLinecap="square" d="M1.018 1.125 22.393 23.25" />
        </g>
      )}
    </svg>
  )
}

export default Gas
