import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Gas: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor, isFilled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      {isFilled ? (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M12.172 3.11a.5.5 0 0 1 .666.035c1.026 1.018 2.073 1.71 3.094 2.382l.02.014c.894.588 1.785 1.175 2.558 1.949A8.475 8.475 0 0 1 21 13.5c0 2.175-.83 4.35-2.49 6.01a8.495 8.495 0 0 1-2.159 1.57.5.5 0 0 1-.632-.737c.185-.26.281-.524.281-.94 0-.467-.003-.828-.105-1.179-.096-.331-.29-.68-.727-1.068-.198-.176-.386-.257-.674-.377l-.006-.003a4.545 4.545 0 0 1-1.03-.557 5.984 5.984 0 0 1-1.041-1.01c-.43.398-.857.949-1.201 1.558-.446.788-.716 1.603-.716 2.233 0 .97.492 1.708.74 2.015a.5.5 0 0 1-.492.803A8.463 8.463 0 0 1 6.49 19.51 8.475 8.475 0 0 1 4 13.5c0-2.175.83-4.35 2.49-6.01a.5.5 0 0 1 .818.168l-.465.185.465-.185.003.007.018.041c.018.039.046.098.087.173.082.151.213.367.402.613.273.356.667.773 1.212 1.15.14-2.015.829-4.67 3.143-6.532Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke={iconColor || 'currentColor'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15.62 20.13a8 8 0 0 0 2.04-12.79c-.74-.73-1.6-1.3-2.5-1.9-1.02-.67-2.1-1.38-3.17-2.44C9.5 5 9 8 9 10 7 9 6.34 7.34 6.34 7.34a7.97 7.97 0 0 0 0 11.32 7.96 7.96 0 0 0 4.01 2.17 3.81 3.81 0 0 1-.85-2.33c0-1.5 1.26-4.11 2.5-5 .7 1.4 1.52 1.87 2.2 2.26.3.16.57.32.8.52 1 .9 1 1.71 1 2.62 0 .52-.13.89-.38 1.23Z"
        />
      )}
    </svg>
  )
}

Gas.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Gas
