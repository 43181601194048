import styles from './Charts.module.scss'
import { ExternalTooltipHandlerProps } from './types'
import { ChartType } from 'chart.js'

/**
 * create or get the tooltip HTML element
 * @param chart
 * @param {boolean} isMobile
 * @returns {HTMLDivElement}
 */
const getOrCreateTooltip = (chart: any, isMobile?: boolean): HTMLDivElement => {
  let tooltipEl: HTMLDivElement = chart.canvas.parentNode.querySelector(`div.${styles.tooltip}`)

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.classList.add(styles.tooltip)

    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  if (isMobile) {
    tooltipEl.classList.add(styles.mobile)
  } else {
    tooltipEl.classList.remove(styles.mobile)
  }

  return tooltipEl
}

/**
 * Handles the creation of the custom tooltip
 * @param {ExternalTooltipHandlerProps} props
 */
export const externalTooltipHandler = ({
  context,
  hideTitle,
  isCombined,
  totalColumns,
  skipLast,
  isMobile
}: ExternalTooltipHandlerProps) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const isFirstDataset = tooltip?.dataPoints?.[0]?.dataIndex === 0
  const lastFewColumnIndexes = Math.ceil(totalColumns / 4)
  const isLastDatasets = tooltip?.dataPoints?.[0]?.dataIndex >= totalColumns - lastFewColumnIndexes

  if (typeof tooltip === 'undefined' || typeof tooltip.labelColors === 'undefined' || !tooltip.body?.[0]?.lines?.[0]?.length) return
  if (!tooltip?.labelColors[0]) return

  const tooltipEl = getOrCreateTooltip(chart, isMobile)

  // Move small triangle (caret) under tooltip if first element
  if (isFirstDataset) {
    tooltipEl.classList.add(styles.first)
  } else if (isLastDatasets) {
    tooltipEl.classList.add(styles.last)
  } else {
    tooltipEl.classList.remove(styles.first)
    tooltipEl.classList.remove(styles.last)
  }

  const container = document.createElement('div')
  container.classList.add(styles['tooltip-container'])
  tooltipEl.appendChild(container)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    if (!hideTitle) {
      const heading = document.createElement('h4')
      heading.style.margin = '0px'

      const text = document.createTextNode(tooltip.title[0])

      heading.appendChild(text)
      container.appendChild(heading)
    }

    const contentWrapper = document.createElement('div')
    contentWrapper.classList.add(styles['tooltip-content-wrapper'])

    // create HTML content nodes for square + label
    if (!isCombined) {
      const content = document.createElement('div')
      content.classList.add(styles['tooltip-description'])

      // colored square
      const colors = tooltip.labelColors[0]
      const backupColor = tooltip.dataPoints[0].dataset.backupBackgroundColor
      const boxSpan = document.createElement('span')
      boxSpan.classList.add(styles['colored-box'])
      if (colors.backgroundColor instanceof CanvasPattern && backupColor) {
        boxSpan.style.background = backupColor
      } else {
        boxSpan.style.background = colors.backgroundColor
      }
      content.appendChild(boxSpan)

      // text
      const valueSpan = document.createElement('span')
      valueSpan.classList.add(styles['label-value'])
      const value = document.createTextNode(tooltip.body[0].lines[0])
      valueSpan.appendChild(value)
      content.appendChild(valueSpan)
      contentWrapper.appendChild(content)
    } else {
      tooltip.body.forEach((entry: any, i: number) => {
        const dataset = tooltip.dataPoints[i].dataset
        const chartType = dataset.type as ChartType

        // skip value if 0
        if (!entry?.lines?.length) {
          return
        }

        // Skip the last entry (settlement)
        if (skipLast && i === tooltip.body.length - 1) return

        const content = document.createElement('div')
        content.classList.add(styles['tooltip-description'])
        content.style.justifyContent = 'flex-start'

        const boxSpan = document.createElement('span')
        boxSpan.classList.add(styles['colored-box'])
        boxSpan.classList.add(styles[chartType])

        if (dataset.backgroundColor instanceof CanvasPattern && dataset?.backupBackgroundColor) {
          boxSpan.style.background = dataset?.backupBackgroundColor
        } else {
          boxSpan.style.background = chartType === 'line' ? dataset.pointBackgroundColor : dataset.backgroundColor
        }
        content.appendChild(boxSpan)

        // text
        const valueSpan = document.createElement('span')
        valueSpan.classList.add(styles['label-value'])
        const value = document.createTextNode(entry.lines[0])
        valueSpan.appendChild(value)
        content.appendChild(valueSpan)

        contentWrapper.appendChild(content)
      })
    }

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove()
    }

    // Add new children
    container.appendChild(contentWrapper)
    tooltipEl.appendChild(container)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  let tooltipLeft = 0
  if (isFirstDataset) tooltipLeft = 50
  if (isLastDatasets) tooltipLeft = -50

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.left = `${isMobile ? chart.width / 2 : positionX + tooltip.caretX + tooltipLeft}px`
  tooltipEl.style.top = isMobile ? '0' : positionY + tooltip.caretY - tooltip._size.height - (isCombined ? 0 : 10) + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
}
