import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  LoginPayload,
  LoginSuccessPayload,
  RegisterPayload,
  RejectValue,
  RequestNewPasswordPayload,
  ResetPasswordPayload,
  ValidateTokenPayload
} from './types'
import { setUserType } from 'store/auth/slice'
import { setSessionUserType } from 'utils/userType'
import { UserTypes } from 'store/auth/types'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import { AuthTokens } from 'types/auth'
import { setIsAuthenticated, setIsSuperUser, setTokens } from 'utils/localStorageService'
import { activateBetaMode, deleteRedirect } from 'store/app/slice'
import { getUserTypeHomepage } from 'utils/user'
import { routes } from 'types/routes'
import { smr3BetaSuperUsers } from 'pages/App/Consumption/smrBetaUsers'

export const login = createAsyncThunk<LoginSuccessPayload, LoginPayload, RejectValue>(
  'auth/login',
  async ({ email, password, userType, navigateTo }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      let url: string

      switch (userType) {
        case UserTypes.SALES:
          url = '/auth/sales/login'
          break
        case UserTypes.SUPER_USER:
          url = '/auth/internal/login'
          break
        default:
          url = '/auth/login'
      }

      const { success, data, message } = await request(
        getAxiosRequestConfig(Methods.POST, url, {
          email,
          password,
          asPortalSuperUser: userType === UserTypes.SUPER_USER
        })
      )

      if (!success || !data?.tokens) {
        return thunkAPI.rejectWithValue(message ?? 'login')
      }

      // Save the tokens
      setIsAuthenticated(true, userType)
      setTokens(data.tokens as AuthTokens, userType)

      // Set superuser state
      if (userType === UserTypes.SUPER_USER) {
        setIsSuperUser(true)

        if (smr3BetaSuperUsers.includes(email)) {
          thunkAPI.dispatch(activateBetaMode())
        }
      }

      // Navigate to correct page
      navigateTo(getUserTypeHomepage(userType))

      return { email }
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const register = createAsyncThunk<boolean, RegisterPayload, RejectValue>(
  'auth/register',
  async ({ email, firstName, lastName, language }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setUserType(UserTypes.CUSTOMER))
      setSessionUserType(UserTypes.CUSTOMER)

      const { success, message } = await request(
        getAxiosRequestConfig(Methods.POST, '/auth/register', {
          email,
          firstName,
          lastName,
          language
        })
      )

      if (!success) {
        return thunkAPI.rejectWithValue(message ?? 'register')
      }

      return success
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const requestNewPassword = createAsyncThunk<boolean, RequestNewPasswordPayload, RejectValue>(
  'auth/requestNewPassword',
  async ({ email, language, isSales }, thunkAPI) => {
    try {
      const userType = isSales ? UserTypes.SALES : UserTypes.CUSTOMER
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      const { success, message } = await request(
        getAxiosRequestConfig(Methods.POST, isSales ? '/auth/sales/request-new-password' : '/auth/request-new-password', {
          email,
          language
        })
      )

      if (!success) {
        return thunkAPI.rejectWithValue(message ?? 'requestNewPassword')
      }

      return success
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const resetPassword = createAsyncThunk<boolean, ResetPasswordPayload, RejectValue>(
  'auth/resetPassword',
  async ({ email, password, isSales, navigateTo }, thunkAPI) => {
    try {
      const userType = isSales ? UserTypes.SALES : UserTypes.CUSTOMER
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      const { success, message } = await request(
        getAxiosRequestConfig(Methods.POST, isSales ? '/auth/sales/reset-password' : '/auth/reset-password', {
          email,
          password
        })
      )

      if (!success) {
        return thunkAPI.rejectWithValue(message ?? 'resetPassword')
      }

      setIsAuthenticated(true, userType)

      navigateTo(getUserTypeHomepage(userType))

      return success
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const validateToken = createAsyncThunk<boolean, ValidateTokenPayload, RejectValue>(
  'auth/validateToken',
  async ({ token, isActivation, isSales, navigateTo }, thunkAPI) => {
    try {
      const userType = isSales ? UserTypes.SALES : UserTypes.CUSTOMER
      thunkAPI.dispatch(setUserType(userType))
      setSessionUserType(userType)

      const { success, data, message } = await request(
        getAxiosRequestConfig(Methods.POST, isSales ? '/auth/sales/validate-token' : '/auth/validate-token', {
          token
        })
      )

      if (!success) {
        navigateTo(isSales ? routes.LOGIN_SALES : routes.LOGIN)
        return thunkAPI.rejectWithValue(message ?? 'validateToken')
      }

      const { valid, email, token: newToken, refreshToken } = data

      if (isSales && !valid) {
        navigateTo(routes.LOGIN_SALES)
        return thunkAPI.rejectWithValue('[validateToken]: token invalid')
      }

      setTokens({ token: newToken, refreshToken }, userType)
      sessionStorage.setItem('resetEmail', email)

      if (isSales) {
        thunkAPI.dispatch(deleteRedirect())
        navigateTo(isActivation ? routes.ACTIVATE_SALES : routes.RESET_PASSWORD_SALES)
        return true
      }

      navigateTo(isActivation ? routes.ACTIVATE : routes.RESET_PASSWORD)
      return true
    } catch (err) {
      const { message } = err as Error
      navigateTo(isSales ? routes.LOGIN_SALES : routes.LOGIN)
      return thunkAPI.rejectWithValue(message)
    }
  }
)
