import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Bio: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor, isFilled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      {isFilled ? (
        <>
          <path
            fill={iconColor}
            fillRule="evenodd"
            d="M16.698 17.907A8.25 8.25 0 0 1 3.25 11.375V6A2.75 2.75 0 0 1 6 3.25h5.5a8.25 8.25 0 0 1 6.285 13.595 25.254 25.254 0 0 0 3.528 2.152.75.75 0 0 1-.673 1.34 26.785 26.785 0 0 1-3.942-2.43Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            d="M17.5 16.5c-1.87-1.533-3.701-3.16-5.139-5.184a.75.75 0 1 0-1.222.868c1.47 2.07 3.45 3.726 5.361 5.316l1-1Z"
          />
        </>
      ) : (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M16.7 17.9a8.25 8.25 0 0 1-13.45-6.53V6A2.75 2.75 0 0 1 6 3.25h5.38a2.78 2.78 0 0 1 .12 0 8.25 8.25 0 0 1 6.29 13.6c1.11.8 2.3 1.53 3.52 2.15a.75.75 0 0 1-.67 1.34c-1.38-.7-2.7-1.51-3.94-2.43ZM4.75 6c0-.69.56-1.25 1.25-1.25H11.5a6.75 6.75 0 0 1 5.1 11.18 27.55 27.55 0 0 1-4.99-5.36.75.75 0 1 0-1.22.86 29.08 29.08 0 0 0 5.1 5.52 6.75 6.75 0 0 1-10.73-5.53l-.01-.02V6Z"
          clipRule="evenodd"
        />
      )}
    </svg>
  )
}

Bio.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Bio
