import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import styles from './EnergyOverviewSlide.module.scss'
import { BrandColors, EnergyOverviewSlideProps } from 'components/Slider/EnergyOverviewSlide/types'

const EnergyOverviewSlide = ({
  children,
  centerData,
  className,
  color = BrandColors.GREEN,
  data,
  description,
  label,
  metric
}: PropsWithChildren<EnergyOverviewSlideProps>) => {
  return (
    <div className={styles.slide}>
      <div
        style={{ backgroundColor: color }}
        className={classNames(styles['big-data'], className, { [styles['is-smaller']]: data.length > 5 })}
      >
        <div className={styles['text-wrapper']}>
          <span className={styles.label}>{label}</span>
          <h4 className={classNames(styles.data, { [styles.centered]: centerData })}>{data}</h4>
          {metric && <label className={styles.metric}>{parse(metric)}</label>}
        </div>
      </div>

      <div className={styles.info}>{children}</div>

      <div className={styles.description}>
        <p>{parse(description)}</p>
      </div>
    </div>
  )
}

export default EnergyOverviewSlide
