import { Region } from 'api/types.ts'
import { Product } from 'types/products.ts'
import { CounterTypes } from 'features/contracts/add/types.ts'
import { CheckSmartBillingOptions, MonthlyBillingEligibility } from 'types/smartBillingOptions.ts'
import { Contract } from 'types/types.ts'
import { isActiveContract } from './contracts.ts'

/**
 * Check if the contract has smart meter options
 * @param {CheckSmartBillingOptions['contract']} contract
 * @returns {boolean}
 */
export const checkHasSmartMeterOptionsForContract = (contract?: Contract): boolean => {
  if (!contract) return false
  return !!(contract.smartMeter && contract.region === Region.FLANDERS)
}

/**
 * Check if the meterDetails has smart meter options
 * @param {CheckSmartBillingOptions['meterDetails']} meterDetails
 * @param {CheckSmartBillingOptions['region']} region
 * @returns {boolean}
 */
export const checkHasSmartMeterOptionsForMeterDetails = (
  meterDetails: CheckSmartBillingOptions['meterDetails'],
  region: CheckSmartBillingOptions['region']
): boolean => {
  if (!meterDetails) return false
  return meterDetails.counterType === CounterTypes.DIGITAL && !meterDetails.hasExclNight && region === Region.FLANDERS
}

/**
 * Check if the contract is eligible for dynamic tariff
 * @param {CheckSmartBillingOptions} props
 * @returns {boolean}
 */
export const checkIsDynamicTariffEligible = ({ electricityContract, product, region, meterDetails }: CheckSmartBillingOptions): boolean => {
  // Dynamic tariff is not available for Bolt Fix
  if (!product || product === Product.FIX) return false

  // Check meterDetails for dynamic tariff eligibility (coming from contract flow)
  if (meterDetails) {
    return checkHasSmartMeterOptionsForMeterDetails(meterDetails, region)
  }

  // Check contract for dynamic tariff eligibility
  return checkHasSmartMeterOptionsForContract(electricityContract)
}

/**
 * Check if the contract is eligible for monthly billing
 * @param {CheckSmartBillingOptions} props
 * @returns {boolean}
 */
export const checkIsMonthlyBillingEligible = ({
  electricityContract,
  gasContract,
  product,
  meterDetails,
  needsGas,
  region
}: CheckSmartBillingOptions): MonthlyBillingEligibility => {
  // Dynamic tariff is not available for Bolt Go
  if (!product || product === Product.GO)
    return {
      electricity: false,
      gas: false
    }

  // Check meterDetails for monthly billing eligibility (coming from contract flow)
  if (meterDetails) {
    const isEligible = checkHasSmartMeterOptionsForMeterDetails(meterDetails, region)
    return {
      electricity: isEligible,
      gas: needsGas ? isEligible : false
    }
  }

  // Check contract for monthly billing eligibility
  return {
    electricity: checkHasSmartMeterOptionsForContract(electricityContract),
    gas: gasContract ? checkHasSmartMeterOptionsForContract(gasContract) : false
  }
}

/**
 * Check if the contract has monthly billing
 * @param {MonthlyBillingEligibility} eligibility
 * @param {Contract} electricityContract
 * @param {Contract} gasContract
 * @returns {boolean | undefined}
 */
export const checkIsMonthlyBilling = (
  eligibility: MonthlyBillingEligibility,
  electricityContract: Contract,
  gasContract?: Contract
): boolean | undefined => {
  return (
    eligibility.electricity &&
    electricityContract.monthlyBilling &&
    (gasContract && isActiveContract(gasContract) && eligibility.gas ? gasContract.monthlyBilling : true)
  )
}
