import { PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import Icon from 'components/Icon/Icon'
import { ModalProps } from './types'
import styles from './Modal.module.scss'

const modalRootEl = document.getElementById('modal-root')

const Modal = ({ isStacked, isOpen, setClose, className, withCloseButton, stopEvents, children }: PropsWithChildren<ModalProps>) => {
  // Refs
  const modalRef = useRef<HTMLDivElement>(null)

  /**
   * Hides modal on click outside and on ESC
   */
  useEffect(() => {
    if (stopEvents) {
      return
    }

    const handleClickOutside = (e: Event) => {
      if (modalRef.current && !modalRef.current.contains(e.target as HTMLDivElement)) {
        setClose()
      }
    }
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setClose()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('keydown', closeOnEscape)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('keydown', closeOnEscape)
    }
  }, [stopEvents])

  /**
   * Stop scroll on body if modal is open
   */
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isOpen])

  return (
    <>
      <CSSTransition nodeRef={modalRef} in={isOpen} timeout={300} classNames="fade-in-scale-up" unmountOnExit>
        <div className={classNames(styles.container, { [styles.stacked]: isStacked })}>
          <div ref={modalRef} className={classNames(styles.modal, className)}>
            {withCloseButton && (
              <button className={styles['close-container']} onClick={() => setClose()}>
                <Icon name="close" color="currentColor" />
              </button>
            )}
            {children}
          </div>
        </div>
      </CSSTransition>
      <CSSTransition in={isOpen} timeout={300} classNames="fade-in" unmountOnExit>
        <div className={classNames(styles.backdrop, { [styles.stacked]: isStacked })} />
      </CSSTransition>
    </>
  )
}

const ModalWithPortal = ({ withCloseButton = true, ...remaingProps }: PropsWithChildren<ModalProps>) => {
  const props = { withCloseButton, ...remaingProps }

  if (modalRootEl) {
    return createPortal(<Modal {...props} />, modalRootEl)
  }

  return <Modal {...props} />
}

ModalWithPortal.defaultProps = {
  withCloseButton: true
}

export default ModalWithPortal
