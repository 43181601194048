import styles from 'features/contracts/terminate/components/TerminateSideCard/TerminateSideCard.module.scss'
import { Button } from '@boltenergy-be/design-system'

import { ClientType, ProductType } from 'types/types.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import ContractTypeIcon from 'components/Icon/ContractTypeIcon/ContractTypeIcon.tsx'
import { useNavigate } from 'react-router'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { routes } from 'types/routes.ts'
import { saveRedirect } from 'store/app/slice.ts'
import { useTranslation } from 'react-i18next'
import { getProductFromProductCode } from 'utils/products.ts'
import { TerminateCardContentProps } from 'features/contracts/terminate/components/TerminateSideCard/types.ts'

const TerminateCardContent = ({ selectedContracts }: TerminateCardContentProps) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { customers, selectedCustomer } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Redux queries
  const { data: productsContent, isLoading: loadingProductsContent } = useGetProductContentQuery({ language })

  // React router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('contracts')

  // Constants
  const currentCustomer = customers[selectedCustomer]
  const { electricityContract, gasContract } = selectedContracts
  const product = getProductFromProductCode(electricityContract.detail.productCode)
  const hasOneAddress = Object.keys(customers).length === 1 && Object.values(customers)[0].contracts.length === 1

  /**
   * Handles the click on the change address button
   */
  const handleChangeAddressButtonClick = () => {
    dispatch(saveRedirect(routes.CONTRACTS_TERMINATE))
    navigate(routes.WELCOME)
  }

  return (
    <>
      <section className={styles.section}>
        <strong>{t('terminate.sideCard.address')}</strong>
        <div className={styles.address}>
          <span>
            {electricityContract.address.streetName} {electricityContract.address.streetNumber} {electricityContract.address.streetBox}
          </span>
          <span>
            {electricityContract.address.postalCode} {electricityContract.address.townName}
          </span>
          {!hasOneAddress && (
            <Button representation="link" onClick={handleChangeAddressButtonClick}>
              {t('terminate.sideCard.editAddress')}
            </Button>
          )}
        </div>
      </section>

      <hr className={styles.divider} />

      <section className={styles.section}>
        <strong>{t('customerNumber', { ns: 'common' })}</strong>
        <span>
          {currentCustomer.id}{' '}
          {currentCustomer.clientType === ClientType.PROFESSIONAL && (
            <small className={styles.company}>({t('company', { ns: 'common' })})</small>
          )}
        </span>
      </section>

      {product && (
        <>
          <hr className={styles.divider} />
          <section className={styles.section}>
            {loadingProductsContent ? (
              <LoadingSkeleton>
                <LoadingSkeleton.Title />
              </LoadingSkeleton>
            ) : (
              <strong>{productsContent?.[product]?.name}</strong>
            )}

            <ul className={styles.products}>
              <li>
                <ContractTypeIcon type={ProductType.ELECTRICITY} active />
                {t('electricity', { ns: 'common' })}
              </li>

              {gasContract && (
                <li>
                  <ContractTypeIcon type={ProductType.GAS} active />
                  {t('gas', { ns: 'common' })}
                </li>
              )}
            </ul>
          </section>
        </>
      )}
    </>
  )
}

export default TerminateCardContent
