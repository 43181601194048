import styles from './LoadingCard.module.scss'
import Card from 'components/Card/Card.tsx'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'

const LoadingCard = () => (
  <Card className={styles['card-loading']}>
    <LoadingSkeleton>
      <LoadingSkeleton.Title />
      <LoadingSkeleton.Paragraph lines={3} />
    </LoadingSkeleton>
  </Card>
)

export default LoadingCard
