import { useState } from 'react'
import classNames from 'classnames'
import { EMAIL } from 'constants/regex'
import { routes } from 'types/routes'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { register as registerUser } from 'store/auth/thunks'
import { RegisterFormFieldsType } from './types'
import { AuthErrorCodes } from 'types/errorCodes'
import styles from '../Auth.module.scss'
import NeedHelp from 'components/NeedHelp/NeedHelp'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Button, Heading } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'

const Register = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { loading, error } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterFormFieldsType>({ mode: 'onBlur' })

  // i18n
  const { t } = useTranslation('auth')

  // Local state
  const [submitted, setSubmitted] = useState<boolean>(false)

  /**
   * Handles the form submit after validation
   *
   * @param {RegisterFormFieldsType} data
   */
  const onSubmit = async (data: RegisterFormFieldsType) => {
    // Register the user
    const { email, firstName, lastName } = data
    await dispatch(
      registerUser({
        email: email.toLowerCase().trim(),
        firstName,
        lastName,
        language
      })
    )

    setSubmitted(true)
  }

  return (
    <>
      <div className={styles['auth-body']}>
        <Heading as="h1" variant="h3">
          {t('titleRegister')}
        </Heading>
        <p className={styles.description}>{t('descriptions.register')}</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="email">{t('email')}</label>
            <input
              id="email"
              autoComplete="off"
              // Disable lastpass autofill
              data-lpignore="true"
              className={classNames('form-control', { error: errors?.email })}
              placeholder={t('email')}
              {...register('email', { required: true, pattern: EMAIL })}
            />

            {errors.email && (
              <span className="help-block text-negative">
                {errors.email.type === 'required' && t('required', { ns: 'validation' })}
                {errors.email.type === 'pattern' && t('invalid.email', { ns: 'validation' })}
              </span>
            )}
          </div>

          <>
            <div className="form-group">
              <label htmlFor="firstName">{t('firstName')}</label>
              <input
                id="firstName"
                autoComplete="off"
                // Disable lastpass autofill
                data-lpignore="true"
                className={classNames('form-control', { error: errors?.firstName })}
                placeholder={t('firstName')}
                {...register('firstName', { required: true })}
              />

              {errors.firstName && (
                <span className="help-block text-negative">
                  {errors.firstName.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">{t('lastName')}</label>
              <input
                id="lastName"
                autoComplete="off"
                // Disable lastpass autofill
                data-lpignore="true"
                className={classNames('form-control', { error: errors?.lastName })}
                placeholder={t('lastName')}
                {...register('lastName', { required: true })}
              />

              {errors.lastName && (
                <span className="help-block text-negative">
                  {errors.lastName.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>
          </>

          <Button disabled={submitted && (!error || error === '')} loading={loading}>
            {t('buttons.register')}
          </Button>
        </form>

        {!loading && (
          <>
            {error && (
              <p className="text-negative center">
                {error === AuthErrorCodes.EMAIL_UNKNOWN && t('formErrors.UserNotFound')}
                {error === AuthErrorCodes.USER_EXISTS && t('formErrors.UserExists')}
                {error === AuthErrorCodes.REGISTRATION_FAILED && t('error', { ns: 'common' })}
              </p>
            )}
            {!error && error !== '' && submitted && <p className="text-positive center">{t('emailSent.register')}</p>}
          </>
        )}
        <ul className={styles['links-list']}>
          <li>
            <Link size="small" href={routes.LOGIN}>
              {t('login')}
            </Link>
          </li>
          <li>
            <Link as="a" href="https://www.boltenergie.be?simulation=1" rel="noopener noreferrer" target="_blank">
              {t('noCustomer')}
            </Link>
          </li>
        </ul>
      </div>

      <div className={styles['auth-footer']}>
        <NeedHelp />
      </div>
    </>
  )
}

export default Register
