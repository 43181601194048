type FlowFormat = `[${Flow}:${string}]` // [flow:function/api call naming] e.g. [simulation:createProspect]
type CmsFormat = `[CMS:${string}:${string}]` // [CMS:page:function] e.g. [CMS:career:getStaticProps]

export type IdentifierFormats = FlowFormat | CmsFormat

export type Logging<T extends IdentifierFormats> = {
  severity?: Severity // default is error
  identifier: T
  error: Error | string
  metaData?: {
    [section: string]: {
      [key: string]: unknown
    }
  }
}

export enum Flow {
  CONTRACT = 'contract',
  BILLING_CYCLES = 'billingCycles',
  METER_READINGS = 'meterReadings',
  COMMON = 'common'
}

export enum Severity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}
