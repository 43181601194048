import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import FaqCard from 'components/Faq/FaqCard/FaqCard'
import { FaqSectionSlugs } from 'types/faq'
import { useGetFaqByMultipleSlugsQuery } from 'store/queries/cms-api'
import { Language } from 'store/app/types'

const FAQ_CATEGORIES: { [key in Language]: FaqSectionSlugs[] } = {
  [Language.NL]: [FaqSectionSlugs.ABOUT_BOLT_NL, FaqSectionSlugs.SWITCH_NL, FaqSectionSlugs.USAGE_AND_PRICE_NL, FaqSectionSlugs.PAYMENT_NL],
  [Language.FR]: [FaqSectionSlugs.ABOUT_BOLT_FR, FaqSectionSlugs.SWITCH_FR, FaqSectionSlugs.USAGE_AND_PRICE_FR, FaqSectionSlugs.PAYMENT_FR]
}

const Faq: FC = () => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)
  const { data } = useGetFaqByMultipleSlugsQuery({
    slugs: FAQ_CATEGORIES[language],
    language
  })

  return <FaqCard categories={data?.categories?.length ? data.categories : []} />
}

export default Faq
