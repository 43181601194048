import styles from './ReportingCardsgroup.module.scss'
import buttonStyles from '../ReportingStatsCard.module.scss'
import { useTranslation } from 'react-i18next'
import { reportProps } from '../types'
import { memo, useState } from 'react'
import ReportingStatsButton from '../ReportingStatsButton/ReportingStatsButton'
import { SearchOpportunityTypes } from 'api/types'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'

const ReportingCardsGroup = memo(({ data, isLoading, onreportClick }: reportProps) => {
  // i18n
  const { t } = useTranslation('sales')

  // Local state
  const [activereport, setActivereport] = useState<SearchOpportunityTypes | null>(null)

  const onClickreport = (event: React.MouseEvent<HTMLButtonElement>) => {
    const activeKey = event.currentTarget.dataset.key as SearchOpportunityTypes
    setActivereport(activeKey === activereport ? null : activeKey)
    onreportClick(activeKey)
  }

  return (
    <>
      {isLoading ? (
        <LoadingSkeleton as="section" className={styles.reports}>
          <LoadingSkeleton.Rectangle height={112} />
          <LoadingSkeleton.Rectangle height={112} />
          <LoadingSkeleton.Rectangle height={112} />
          <LoadingSkeleton.Rectangle height={112} />
        </LoadingSkeleton>
      ) : (
        <section className={styles.reports}>
          <ReportingStatsButton
            stage={SearchOpportunityTypes.TO_BE_CONFIRMED}
            customClassName={buttonStyles.unconfirmed}
            isActive={activereport}
            onClick={onClickreport}
            heading={data?.toBeConfirmed}
            description={`${t('overview.report.unconfirmed')} `}
          />
          <ReportingStatsButton
            stage={SearchOpportunityTypes.EFFECTIVE}
            customClassName={buttonStyles.completed}
            isActive={activereport}
            onClick={onClickreport}
            heading={data?.effective}
            description={`${t('overview.report.effective')} `}
          />
          <ReportingStatsButton
            stage={SearchOpportunityTypes.CANCELLED}
            customClassName={buttonStyles.cancelled}
            isActive={activereport}
            onClick={onClickreport}
            heading={data?.cancelled}
            description={`${t('overview.report.cancelled')} `}
          />
          <ReportingStatsButton
            stage={SearchOpportunityTypes.TERMINATED}
            customClassName={buttonStyles.terminated}
            isActive={activereport}
            onClick={onClickreport}
            heading={data?.terminated}
            description={`${t('overview.report.terminated')} `}
          />
        </section>
      )}
    </>
  )
})

ReportingCardsGroup.displayName = 'reportBar'

export default ReportingCardsGroup
