import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { routes } from 'types/routes'
import Subnav from 'components/Subnav/Subnav'
import { Tab } from 'components/Subnav/types'
import { getBillShock } from 'store/contracts/thunks'
import { determineAccessRights, getMemoizedSelectedContracts } from 'utils/contracts'
import SeoTags from 'components/SeoTags/SeoTags'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'

const Billing = () => {
  // REDUX
  const {
    billShock: { data: billShockData }
  } = useStoreSelector((store) => store.contracts)
  const { info, selectedContracts } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Contracts
  const { billingContract, electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)
  const shouldFetchBillShock =
    accessRights.billingCycles.canAccess &&
    accessRights.billingCycles.showContent &&
    accessRights.instalment.canAccess &&
    accessRights.instalment.showContent

  // React Router
  const navigate = useNavigate()
  const location = useLocation()
  const handleTabClick = (idx: number) => navigate(getTabs()[idx].path)

  // i18n
  const { t } = useTranslation('billing')

  /**
   * Triggered whenever the location pathname changes
   * Fetch bill shock if necessary & not already fetched
   */
  useEffect(() => {
    if (
      [routes.BILLING_CYCLES, routes.BILLING_INSTALMENT].includes(location.pathname as routes) &&
      shouldFetchBillShock &&
      !billShockData
    ) {
      dispatch(
        getBillShock({
          billingContractId: billingContract.id,
          email: info.email,
          electricityContract,
          gasContract
        })
      )
    }
  }, [billShockData, billingContract, dispatch, electricityContract, gasContract, info, location, shouldFetchBillShock])

  /**
   * Generates the tabs & return them
   *
   * @return {Tab[]}
   */
  const getTabs = (): Tab[] => {
    const tabs: Tab[] = [{ label: t('nav.billing', { ns: 'common' }), path: routes.BILLING_INVOICES }]

    // Push billing cycles tab if allowed
    if (accessRights.billingCycles.canAccess) {
      tabs.push({ label: t('billingCyclesTab'), path: routes.BILLING_CYCLES })
    }

    // Push instalment tab if allowed
    if (accessRights.instalment.canAccess) {
      tabs.push({ label: t('instalmentTab'), path: routes.BILLING_INSTALMENT })
    }

    /**
     * Disabled until we rethink the intermediate settlement page
     if (isSuperUser) {
     tabs.push({ label: t('settlementTab'), path: Routes.BILLING_SETTLEMENT })
     }
     */

    return tabs
  }

  /**
   * Get page title
   *
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (location.pathname) {
      case routes.BILLING_CYCLES:
        return `${t('nav.billing', { ns: 'common' })} - ${t('billingCyclesTab')}`

      case routes.BILLING_INSTALMENT:
        return `${t('nav.billing', { ns: 'common' })} - ${t('instalmentTab')}`

      /**
       * Disabled until we rethink the intermediate settlement page
       case Routes.BILLING_SETTLEMENT:
       return `${t('nav.billing', { ns: 'common' })} - ${t('settlementTab')}`
       */

      default:
        return t('nav.billing', { ns: 'common' })
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <Subnav tabs={getTabs()} activeTab={handleTabClick} />

      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default Billing
