import { FC } from 'react'
import { Marker, StaticGoogleMap } from 'react-static-google-map'
import { useSelector } from 'react-redux'
import { GMAP_STATIC_KEY } from 'constants/envs'
import { StaticMapProps as Props } from './types'
import { Store } from 'store/types'
import styles from './StaticMap.module.scss'
import { STATIC_MAPS_STYLE } from 'constants/energyOverview'

const StaticMap: FC<Props> = ({ city, zoom }) => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)

  // Constants
  const cityName = typeof city === 'string' ? city : city[language]
  const location = `${cityName} Belgium`

  return (
    <div className={styles.map}>
      <StaticGoogleMap
        center={location}
        size="900x312"
        apiKey={GMAP_STATIC_KEY}
        zoom={zoom || 10}
        style={STATIC_MAPS_STYLE}
        language={language}
        scale={2}
      >
        <Marker location={location} size="tiny" />
      </StaticGoogleMap>
      <div className={styles.overlay} />
    </div>
  )
}

export default StaticMap
