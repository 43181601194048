import { AddContractSteps } from 'features/contracts/add/types.ts'
import styles from 'features/contracts/Contracts.module.scss'
import Card from 'components/Card/Card.tsx'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons.tsx'
import parse from 'html-react-parser'
import { EMAIL } from 'constants/regex.ts'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { ReferralFormInputs, ReferralStepProps } from './types.ts'
import classNames from 'classnames'

const ReferralStep = ({ setCurrentStep, setNextStep, defaultValues, isTerminate }: ReferralStepProps) => {
  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const { register, handleSubmit, watch, formState } = useForm<ReferralFormInputs>({
    defaultValues,
    mode: 'onBlur'
  })
  const { errors } = formState
  const watchAllFields = watch()
  const isFormEmpty = Object.values(watchAllFields).every((input) => !input)

  // Constants
  const translationPrefix = isTerminate ? 'terminate.referral' : 'add.steps.referral'

  /**
   * Handle form submission, only save the inputs if there are any given
   * @param {ReferralFormInputs} inputs
   */
  const onSubmit = (inputs: ReferralFormInputs) => {
    if (Object.values(inputs).every((input) => !input)) {
      setCurrentStep()
    } else {
      setNextStep(inputs)
    }
  }

  return (
    <form className={styles['form-card']} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['form-content']}>
        <div>
          <Card.Title className={styles['referral-title']}>{parse(t(`${translationPrefix}.title`))}</Card.Title>
          <p className="mt-200">{t(`${translationPrefix}.description`)}</p>
        </div>

        <fieldset>
          <div className="row">
            {/* FIRST NAME */}
            <div className="column form-group">
              <label htmlFor="firstName">{t(`${translationPrefix}.form.firstName`)}</label>
              <input
                id="firstName"
                {...register('firstName', { required: !isFormEmpty })}
                className={classNames('form-control', { error: errors?.firstName })}
              />
            </div>

            {/* LAST NAME */}
            <div className="column form-group">
              <label htmlFor="lastName">{t(`${translationPrefix}.form.lastName`)}</label>
              <input
                id="lastName"
                {...register('lastName', { required: !isFormEmpty })}
                className={classNames('form-control', { error: errors?.lastName })}
              />
            </div>
          </div>

          <div className="row">
            {/* EMAIL */}
            <div className="column form-group">
              <label htmlFor="email">{t(`${translationPrefix}.form.email`)}</label>
              <input
                id="email"
                {...register('email', { pattern: EMAIL, required: !isFormEmpty })}
                type="email"
                className={classNames('form-control', { error: errors?.email })}
              />
              {errors?.email?.type === 'pattern' && (
                <span className="help-block text-negative">{t('invalid.email', { ns: 'validation' })}</span>
              )}
            </div>
          </div>

          <p>{t(`${translationPrefix}.form.additionalInformation`)}</p>

          {errors && Object.values(errors)?.some(({ type }) => type === 'required') && (
            <p className="help-block text-negative">{t('invalid.requiredWhenNotAllEmpty', { ns: 'validation' })}</p>
          )}
        </fieldset>
      </div>

      <FormButtons currentStep={AddContractSteps.REFERRAL} setCurrentStep={setCurrentStep} trackingId={ContractFlowTrackingTypes.ADD} />
    </form>
  )
}

export default ReferralStep
