import { getCssVariable } from 'utils/app.ts'
import { ScriptableScaleContext } from 'chart.js'
import { GetBarChartDefaults } from 'components/Charts/types.ts'

export const CHART_COLORS = {
  greenDark: getCssVariable('--chart-green-dark', '#23d5ae'),
  greenLight: getCssVariable('--chart-green-light', '#b4f0e2'),
  greenHover: getCssVariable('--green-700', '#009e7b'),
  blueLight: getCssVariable('--chart-blue-light', '#a29fb5'),
  blueDark: getCssVariable('--chart-blue-dark', '#100061'),
  lightblueLight: getCssVariable('--chart-lightblue-light', '#c4e4ff'),
  lightblueDark: getCssVariable('--chart-lightblue-dark', '#69baff'),
  lightblueHover: getCssVariable('--lightblue-700', '#4690cf'),
  gray: getCssVariable('--blue-300', '#dad9e2'),
  grayHover: getCssVariable('--blue-500', '#a29fb5'),
  textSubdued: getCssVariable('--text-subdued', '#848375'),
  orange: getCssVariable('--accent-orange', '#ffa010'),
  purpleDark: getCssVariable('--chart-purple-dark', '#a564f0'),
  purpleLight: getCssVariable('--chart-purple-light', '#d8bff5'),
  purpleHover: getCssVariable('--purple-700', '#804eba'),
  yellowLight: getCssVariable('--chart-yellow-light', '#fff4b0'),
  yellowDark: getCssVariable('--chart-yellow-dark', '#f6df48'),
  yellowHover: getCssVariable('--yellow-700', '#e0af00'),
  gridBaseline: getCssVariable('--custom-chart-grid-baseline-color', '#838490'),
  gridLine: getCssVariable('--custom-chart-grid-color', '#DDDBE1')
} as const

/**
 * Get bar chart default values
 * @param {GetBarChartDefaults} { alternatingBackground, isMobile }
 */
export const getBarChartDefaults = ({ alternatingBackground, isMobile }: GetBarChartDefaults) => {
  return {
    responsive: true,
    animation: {
      duration: 0
    },
    categoryPercentage: 0.75,
    barPercentage: isMobile ? 0.85 : 1,
    layout: {
      padding: {
        top: 40,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    scales: {
      y: {
        type: 'linear',
        grace: '20%',
        ticks: {
          font: {
            family: "'Commuters Sans', sans-serif",
            size: 11
          },
          crossAlign: 'far',
          color: CHART_COLORS.textSubdued,
          padding: isMobile ? 4 : 7
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: (context: ScriptableScaleContext) => (context.tick.value === 0 ? CHART_COLORS.gridBaseline : CHART_COLORS.gridLine)
        }
      },
      x: {
        grid: {
          lineWidth: 0
        },
        ticks: {
          color: CHART_COLORS.textSubdued,
          font: {
            family: "'Commuters Sans', sans-serif",
            size: isMobile ? 9 : 10
          },
          minRotation: 0,
          maxRotation: 0
        }
      }
    },
    interaction: {
      intersect: !isMobile,
      mode: isMobile ? 'x' : 'index'
    },
    plugins: alternatingBackground
      ? {
          annotation: {
            common: {
              drawTime: 'beforeDraw'
            },
            annotations: alternatingBackground
          }
        }
      : undefined
  } as const
}
