import { Producer } from 'types/types'
import { Language } from 'store/app/types.ts'

/**
 * Calculates if the producer is sold out or not
 * @param {Producer} producer
 * @returns boolean
 */
export const isSoldOutWithCapacity = (producer: Producer): boolean => {
  // assume 3500 kWh
  const volume = 3500
  const used = Math.round(((producer.energyUsed + volume) / producer.maxCapacity) * 100)

  return used >= 100
}

/**
 * Function to add url params to cloudinary URL to scale image
 * @param {string} url
 * @param {number} [width]
 */
export const parseProducerImageUrl = (url?: string, width?: number) => {
  const widthToUse = width ? width : 800

  return url ? url.replace('/f_auto', `/w_${widthToUse},c_scale/f_auto`) : ''
}

/**
 * Returns the location of a producer in the correct language or fallback to a filled in language
 * @param {string} locale
 * @param {Producer['location']} location
 * @returns {string}
 */
export const getProducerLocation = (locale: Language, location?: Producer['location']): string => {
  if (!location) return ''
  return location[locale] || location.nl || location.fr
}
