import { ReactElement, useEffect, useMemo, useState } from 'react'
import { AddContractStepProps, AddContractSteps, CustomerDataFormInputs, InvoiceMedium, PaymentMethod } from '../../types'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import contractsStyles from '../../../Contracts.module.scss'
import styles from './CustomerData.module.scss'
import Icon from 'components/Icon/Icon'
import useIsComponentVisible from 'hooks/useIsComponentVisible'
import { ContractType, Customer, LegalFormSelectOptionKeys } from 'types/types'
import classNames from 'classnames'
import { BELGIAN_ENTERPRISE_NUMBER, LETTERS_NUMBERS_SLASHES_DASHES } from 'constants/regex'
import { dateStringToObject, hasNoNumber, isValidPostalCode, vatNumberToCompanyNumber } from 'utils/addContract'
import { ALLOWED_IBAN_COUNTRIES, GET_INITIAL_ADD_CONTRACT_DATA, OLD_LEGAL_FORM_TRANSLATIONS } from '../../constants'
import parse from 'html-react-parser'
import PhoneFormField from 'components/FormFields/PhoneFormField/PhoneFormField'
import { Language } from 'store/app/types'
import DateFormField from 'components/FormFields/DateFormField/DateFormField'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { Towns } from 'api/types'
import { getTown } from 'api/addContract'
import Toggle from 'components/Toggle/Toggle'
import IBAN from 'iban'
import { UserTypes } from 'store/auth/types'
import { checkIfProductIsDigitalOnly, getProductFromProductCode } from 'utils/products'
import { Product, ProductConfigOption } from 'types/products'
import { PRODUCTS_CONFIG } from 'constants/products'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import Card from 'components/Card/Card.tsx'
import { Banner, Heading } from '@boltenergy-be/design-system'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { removeSpacesAndDots } from 'utils/format.ts'
import { validateBelgianEnterpriseNumber } from 'utils/validation.ts'

const CustomerDataStep = ({ addContractData, isSales, setCurrentStep, setNextStep }: AddContractStepProps) => {
  // REDUX STORE
  const { userType } = useStoreSelector((store) => store.auth)
  const { language } = useStoreSelector((store) => store.app)
  const { customers, info } = useStoreSelector((store) => store.user)
  const currentCustomer = addContractData.customerData.customerNumber
    ? customers[addContractData.customerData.customerNumber]
    : isSales
      ? Object.values(customers)[0]
      : undefined

  // Redux queries
  const { data: productsContent } = useGetProductContentQuery({ language })

  // Local state
  const [customer, setCustomer] = useState<Customer | undefined>(currentCustomer)
  const [loading, setLoading] = useState<boolean>(false)
  const [registerAsCompany, setRegisterAsCompany] = useState<boolean>(false)
  const [towns, setTowns] = useState<Towns>([])
  const [sameCorrespondenceAddress, setSameCorrespondenceAddress] = useState<boolean>(
    !!addContractData.customerData.sameCorrespondenceAddress
  )

  // Constants.
  const billingContract = customer?.contracts?.[0]?.find((contract) => contract.type === ContractType.BILLING)
  const hasBillingContract = typeof billingContract !== 'undefined'
  const product = getProductFromProductCode(addContractData[AddContractSteps.PRODUCT].electricity) as Product
  const isDigitalOnly = product ? checkIfProductIsDigitalOnly(product)[ProductConfigOption.BILLING_OPTIONS] : true
  const isDirectDebitOnly = product ? PRODUCTS_CONFIG[product][ProductConfigOption.DIRECT_DEBIT_MANDATORY] : true

  // Memo's
  const newCustomerData = useMemo<boolean>(() => typeof customer === 'undefined', [customer])
  const isProfessional = useMemo<boolean>(() => !!customer?.company, [customer])
  const hasCustomersInDropdown = useMemo<boolean>(() => !!Object.keys(customers).length, [customers])

  // React Hook Form
  const hookForm = useForm<CustomerDataFormInputs>({
    defaultValues: {
      ...addContractData[AddContractSteps.CUSTOMER_DATA],

      // Set default invoiceMedium on digital if it's a digital only product or Bolt Go simulationType
      invoiceMedium: isDigitalOnly ? InvoiceMedium.DIGITAL : addContractData[AddContractSteps.CUSTOMER_DATA].invoiceMedium,

      // Set default paymentMethod on digital if it's a digital only product
      paymentMethod: isDirectDebitOnly ? PaymentMethod.DIRECT_DEBIT : addContractData[AddContractSteps.CUSTOMER_DATA].paymentMethod
    }
  })
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = hookForm
  const watchTownName = watch('correspondenceAddress.townName')
  const watchInvoiceMedium = watch('invoiceMedium')
  const watchPaymentMethod = watch('paymentMethod')

  // i18n
  const { t } = useTranslation('contracts')

  // Custom hook
  const { ref, isComponentVisible, setIsComponentVisible } = useIsComponentVisible(false)

  /**
   * Triggered on first render
   * Resets the form to the values of the selected customer
   */
  useEffect(() => {
    if (isSales && customer && !addContractData[AddContractSteps.CUSTOMER_DATA].firstName) {
      reset(generateDefaultFormValuesForCustomer(customer))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches towns when postalcode is present in registration data state object so towns are prefilled when navigating back
   */
  useEffect(() => {
    if ((hasBillingContract || addContractData.customerData.correspondenceAddress?.postalCode) && towns.length === 0) {
      const postalCode = hasBillingContract ? customer?.address.postalCode : addContractData.customerData.correspondenceAddress?.postalCode
      validatePostalCodeAndFetchTowns(postalCode as number)
    }
  }, [addContractData, towns])

  /**
   * Generates the content for the customer dropdown item
   *
   * @param {Customer|undefined} customer
   * @returns {ReactElement}
   */
  const generateCustomerDropdownItemContent = (customer: Customer): ReactElement => {
    const isProfessional = !!customer.company

    return (
      <>
        <span>
          {customer.id} {isProfessional && `(${t('company', { ns: 'common' })})`}
        </span>
        <span>
          {isProfessional && `${customer.company?.name} - `}
          {customer.person.firstName} {customer.person.lastName}
        </span>
      </>
    )
  }

  /**
   * Generates the form values for a given customer
   *
   * @param {Customer=} cust
   * @returns {CustomerDataFormInputs}
   */
  const generateDefaultFormValuesForCustomer = (cust?: Customer): CustomerDataFormInputs => {
    if (typeof cust === 'undefined') {
      return {
        ...GET_INITIAL_ADD_CONTRACT_DATA(language).customerData,
        customerNumber: undefined,
        email: userType === UserTypes.SALES && currentCustomer ? currentCustomer.contact.email : info.email,
        emailConfirmation: userType === UserTypes.SALES && currentCustomer ? currentCustomer.contact.email : info.email,
        mobilePhone: '',
        correspondenceAddress: {
          streetName: '',
          streetNumber: '',
          streetBox: '',
          postalCode: '',
          townCode: 0,
          townName: '',
          countryCode: 'BE'
        },
        invoiceMedium: InvoiceMedium.DIGITAL,
        paymentMethod: PaymentMethod.DIRECT_DEBIT,
        iban: ''
      }
    }

    const isCompany = !!cust.company

    return {
      customerNumber: cust.id,
      isCompany,
      ...(isCompany && {
        companyNumber: vatNumberToCompanyNumber(cust.company?.enterpriseNumber ?? ''),
        legalForm: cust.company?.legalForm ?? '',
        companyName: cust.company?.name || '',
        subjectToVat: cust.company?.vatApplication
      }),
      firstName: cust.person.firstName,
      lastName: cust.person.lastName,
      email: cust.contact.email,
      emailConfirmation: cust.contact.email,
      mobilePhone: cust.contact.phoneMobile || addContractData[AddContractSteps.CUSTOMER_DATA].mobilePhone,
      dateOfBirth: cust.person.birthDate ? dateStringToObject(cust.person.birthDate) : null,
      language: cust.language,
      correspondenceAddress: {
        streetName: customer?.address.streetName ?? '',
        streetNumber: customer?.address.streetNumber ?? '',
        streetBox: customer?.address.streetBox ?? '',
        postalCode: customer?.address.postalCode ?? '',
        townCode: customer?.address.townCode ?? 0,
        townName: customer?.address.townName ?? '',
        countryCode: 'BE'
      },
      paymentMethod: cust?.paymentDetails?.directDebit ? PaymentMethod.DIRECT_DEBIT : PaymentMethod.BANK_TRANSFER,
      invoiceMedium: billingContract?.deliveryMode?.toLowerCase() === 'email' ? InvoiceMedium.DIGITAL : InvoiceMedium.PAPER,
      iban: cust?.paymentDetails?.iban
    }
  }

  /**
   * Handles the customer button click
   *
   * @param {Customer|undefined} cust
   */
  const handleCustomerButtonClick = (cust?: Customer) => {
    setCustomer(cust)
    setIsComponentVisible(false)
    setSameCorrespondenceAddress(false)

    reset(generateDefaultFormValuesForCustomer(cust))
  }

  /**
   * Handles the town select change & sets the town code
   * TODO: write custom hook for this (same as in AddressStep.tsx)
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = towns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('correspondenceAddress.townCode', townCode)
    setValue('correspondenceAddress.townName', townName)
  }

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {CustomerDataFormInputs} data
   */
  const onSubmit = (data: CustomerDataFormInputs) => {
    setLoading(true)

    const { companyNumber } = data
    const formattedData = {
      ...data,
      isCompany: !!companyNumber
    }

    if (!customer && !registerAsCompany) {
      formattedData.isCompany = false
      delete formattedData.companyName
      delete formattedData.companyNumber
      delete formattedData.legalForm
      delete formattedData.subjectToVat
    }

    if (sameCorrespondenceAddress) {
      formattedData.correspondenceAddress = addContractData.address.deliveryAddress
      formattedData.sameCorrespondenceAddress = true
    }

    setNextStep(formattedData, AddContractSteps.CONFIRMATION)

    setLoading(false)
  }

  /**
   * Fetches the town names based on the given postalCode
   * TODO: write custom hook for this (same as in AddressStep.tsx)
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns } = response

        setTowns(towns)

        if (
          !towns.some(
            (town) => town.townName === (addContractData[AddContractSteps.CUSTOMER_DATA].correspondenceAddress?.townName || watchTownName)
          )
        ) {
          setValue('correspondenceAddress.townName', towns[0].townName)
          setValue('correspondenceAddress.townCode', towns[0].townCode)
        }

        return true
      }
    }

    return false
  }

  return (
    <FormProvider {...hookForm}>
      <form className={contractsStyles['form-card']} onSubmit={handleSubmit(onSubmit)}>
        <div className={contractsStyles['form-content']}>
          <Card.Title as="h2">{t('add.steps.customerData.title')}</Card.Title>

          <Banner type="informative">{t('add.steps.customerData.info')}</Banner>

          {/* CUSTOMER DROPDOWN */}
          {hasCustomersInDropdown && (
            <div className={styles['customer-dropdown']}>
              <div className={styles['button-container']}>
                <div className={styles['selected-customer']}>
                  {customer ? generateCustomerDropdownItemContent(customer) : t('add.steps.customerData.customerSelect.newCustomer')}
                </div>
                <button
                  className={styles['select-customer']}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsComponentVisible(true)
                  }}
                >
                  <Icon name="menuDown" />
                </button>
              </div>

              {isComponentVisible && (
                <>
                  <div ref={ref} className={styles['dropdown-container']}>
                    <div className={styles['dropdown-title']}>{t('add.steps.customerData.customerSelect.title')}:</div>
                    <div className={styles.customers}>
                      {Object.values(customers).map((cust, index) => (
                        <button
                          key={index}
                          className={classNames(styles['customer-button'], { [styles.active]: customer?.id === cust.id })}
                          onClick={() => handleCustomerButtonClick(cust)}
                        >
                          {generateCustomerDropdownItemContent(cust)}
                        </button>
                      ))}

                      <button
                        className={classNames(styles['new-customer-button'], { [styles.active]: customer === undefined })}
                        onClick={(e) => {
                          e.preventDefault()
                          handleCustomerButtonClick(undefined)
                        }}
                      >
                        + {t('add.steps.customerData.customerSelect.newCustomer')}
                      </button>
                    </div>
                  </div>
                  <div className="overlay dropdown-overlay" />
                </>
              )}
            </div>
          )}

          {newCustomerData && (
            <div style={{ marginTop: !hasCustomersInDropdown ? '20px' : 0 }} className="row">
              <div className="column form-group">
                <div className={contractsStyles['checkbox-container']}>
                  <input
                    id="registerAsCompany"
                    type="checkbox"
                    defaultChecked={registerAsCompany}
                    onChange={() => setRegisterAsCompany(!registerAsCompany)}
                  />
                  <label htmlFor="registerAsCompany">{t('add.steps.customerData.fields.registerAsCompany')}</label>
                </div>
              </div>
            </div>
          )}

          {(registerAsCompany || isProfessional) && (
            <fieldset>
              <Heading as="h3" className={contractsStyles['label-large']}>
                {t('add.steps.customerData.sections.company')}
              </Heading>

              <div className="row">
                {/* COMPANY NAME */}
                <div className="column form-group">
                  <label htmlFor="companyName">{t('add.steps.customerData.fields.companyName')}</label>
                  <input
                    id="companyName"
                    {...register('companyName', { required: true })}
                    className={classNames('form-control', { error: errors?.companyName })}
                    disabled={!newCustomerData}
                  />
                  {errors && errors.companyName && (
                    <span className="help-block text-negative">
                      {errors.companyName.type === 'required' && t('required', { ns: 'validation' })}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                {/* COMPANY VAT NUMBER */}
                <div className="column form-group">
                  <label htmlFor="companyNumber">{t('add.steps.customerData.fields.companyNumber')}</label>
                  <input
                    id="companyNumber"
                    {...register('companyNumber', {
                      required: true,
                      validate: {
                        format: (value: string | undefined) => value && BELGIAN_ENTERPRISE_NUMBER.test(removeSpacesAndDots(value)),
                        controlDigit: (value: string | undefined) => value && validateBelgianEnterpriseNumber(value)
                      }
                    })}
                    className={classNames('form-control', { error: errors?.companyNumber })}
                    disabled={!newCustomerData}
                    placeholder="0123456789"
                  />
                  {errors && errors.companyNumber && (
                    <span className="help-block text-negative">
                      {errors.companyNumber.type === 'required' && t('required', { ns: 'validation' })}
                      {(errors.companyNumber.type === 'format' || errors.companyNumber.type === 'controlDigit') &&
                        t('invalidCompanyNumber', 'Dit is geen geldig ondernemingsnummer.')}
                    </span>
                  )}
                </div>

                {/* COMPANY LEGAL FORM */}
                <div className="column form-group">
                  <label htmlFor="legalForm">{t('add.steps.customerData.fields.legalForm')}</label>
                  <select
                    id="legalForm"
                    {...register('legalForm', { required: true })}
                    className="form-control"
                    disabled={!newCustomerData}
                  >
                    <option value="" disabled>
                      {t('defaultPlaceholders.select', { ns: 'common' })}
                    </option>
                    {Object.keys(OLD_LEGAL_FORM_TRANSLATIONS)
                      .sort((a, b) => a.localeCompare(b))
                      .map((legalForm) => {
                        const translatedLegalForm = OLD_LEGAL_FORM_TRANSLATIONS[legalForm as LegalFormSelectOptionKeys][language]

                        return (
                          <option key={translatedLegalForm} value={translatedLegalForm}>
                            {translatedLegalForm}
                          </option>
                        )
                      })}
                  </select>
                  {errors && errors.legalForm && (
                    <span className="help-block text-negative">
                      {errors.legalForm.type === 'required' && t('required', { ns: 'validation' })}
                    </span>
                  )}
                </div>
              </div>

              {/* COMPANY SUBJECT TO VAT */}
              <div className="row">
                <div className="column form-group">
                  <div className={contractsStyles['checkbox-container']}>
                    <input type="checkbox" id="subjectToVat" {...register('subjectToVat')} disabled={!newCustomerData} />
                    <label htmlFor="subjectToVat">{t('add.steps.customerData.fields.subjectToVat')}</label>
                  </div>
                </div>
              </div>
            </fieldset>
          )}

          <fieldset>
            <Heading as="h3" className={contractsStyles['label-large']}>
              {t('add.steps.customerData.sections.personal')}
            </Heading>

            <div className="row">
              {/* FIRST NAME */}
              <div className="column form-group">
                <label htmlFor="firstName">{t('add.steps.customerData.fields.firstName')}</label>
                <input
                  id="firstName"
                  {...register('firstName', {
                    required: true,
                    minLength: 2,
                    validate: (value: string) => hasNoNumber(value)
                  })}
                  className={classNames('form-control', { error: errors?.firstName })}
                  readOnly={!newCustomerData}
                />
                {errors?.firstName && (
                  <span className="help-block text-negative">
                    {errors.firstName.type === 'required' && t('required', { ns: 'validation' })}
                    {errors.firstName.type === 'minLength' && t('min', { min: 2, ns: 'validation' })}
                    {errors.firstName.type === 'validate' &&
                      t('noNumbers', '', { attribute: t('add.steps.customerData.fields.firstName'), ns: 'validation' })}
                  </span>
                )}
              </div>

              {/* LAST NAME */}
              <div className="column form-group">
                <label htmlFor="lastName">{t('add.steps.customerData.fields.lastName')}</label>
                <input
                  id="lastName"
                  {...register('lastName', { required: true, validate: (value: string) => hasNoNumber(value) })}
                  className={classNames('form-control', { error: errors?.lastName })}
                  readOnly={!newCustomerData}
                />
                {errors?.lastName && (
                  <span className="help-block text-negative">
                    {errors.lastName.type === 'required' && t('required', { ns: 'validation' })}
                    {errors.lastName.type === 'validate' &&
                      t('noNumbers', '', {
                        attribute: t('add.steps.customerData.fields.lastName'),
                        ns: 'validation'
                      })}
                  </span>
                )}
              </div>
            </div>

            <div className="row">
              {/* EMAIL */}
              <div className="column form-group">
                <label htmlFor="email">{t('add.steps.customerData.fields.email')}</label>
                <input id="email" {...register('email')} className={classNames('form-control')} readOnly />
              </div>

              {/* EMAIL CONFIRMATION */}
              <div className="column form-group">
                <label htmlFor="emailConfirmation">{t('add.steps.customerData.fields.emailConfirmation')}</label>
                <input id="emailConfirmation" {...register('emailConfirmation')} className={classNames('form-control')} readOnly />
              </div>
            </div>

            {!isSales && newCustomerData && (
              <Banner type="informative" className={contractsStyles['alert-container']}>
                <p>{parse(t('add.steps.customerData.fields.emailInfo'))}</p>
              </Banner>
            )}

            <div className="row">
              {/* MOBILE PHONE */}
              <div className="column form-group">
                <PhoneFormField
                  errors={errors}
                  isRequired
                  isDisabled={!newCustomerData}
                  label={t('add.steps.customerData.fields.mobilePhone')}
                  {...hookForm}
                />
              </div>
            </div>

            <div className="row">
              {/* LANGUAGE */}
              <div className="column form-group">
                <label htmlFor="language">{t('add.steps.customerData.fields.language.label')}</label>
                <select id="language" {...register('language')} className="form-control" disabled={!newCustomerData}>
                  <option value={Language.NL}>{t('add.steps.customerData.fields.language.dutch')}</option>
                  <option value={Language.FR}>{t('add.steps.customerData.fields.language.french')}</option>
                </select>
              </div>

              {(newCustomerData && !registerAsCompany) || (!newCustomerData && !isProfessional) ? (
                <div className={classNames('column form-group', styles['date-form-field'])}>
                  {/* DATE OF BIRTH */}
                  <DateFormField
                    label={t('add.steps.customerData.fields.birthDate.label')}
                    isDisabled={!newCustomerData}
                    checkDateOfBirth
                  />
                </div>
              ) : (
                <div className="column" />
              )}
            </div>
          </fieldset>

          <fieldset>
            <Heading as="h3" className={contractsStyles['label-large']}>
              {t('add.steps.customerData.sections.correspondenceAddress')}
            </Heading>

            <div className="row">
              <div className="column form-group">
                <div className={contractsStyles['checkbox-container']}>
                  <input
                    type="checkbox"
                    id="sameCorrespondenceAddress"
                    checked={sameCorrespondenceAddress}
                    onChange={() => setSameCorrespondenceAddress(!sameCorrespondenceAddress)}
                  />
                  <label htmlFor="sameCorrespondenceAddress">{t('add.steps.customerData.fields.sameCorrespondenceAddress')}</label>
                </div>
              </div>
            </div>

            {!sameCorrespondenceAddress && (
              <>
                <div className="row">
                  {/* CORRESPONDENCE ADDRESS STREET */}
                  <div className="column form-group">
                    <label htmlFor="correspondenceAddress.streetName">
                      {t('add.steps.customerData.fields.correspondenceAddress.streetName')}
                    </label>
                    <input
                      id="correspondenceAddress.streetName"
                      {...register('correspondenceAddress.streetName', { required: true })}
                      className={classNames('form-control', { error: errors?.correspondenceAddress?.streetName })}
                    />
                    {errors && errors.correspondenceAddress?.streetName && (
                      <span className="help-block text-negative">
                        {errors.correspondenceAddress?.streetName.type === 'required' && t('required', { ns: 'validation' })}
                      </span>
                    )}
                  </div>

                  <div className="column">
                    <div className="row">
                      {/* CORRESPONDENCE ADDRESS STREET NUMBER */}
                      <div className="column form-group">
                        <label htmlFor="correspondenceAddress.streetNumber">
                          {t('add.steps.customerData.fields.correspondenceAddress.streetNumber')}
                        </label>
                        <input
                          id="correspondenceAddress.streetNumber"
                          maxLength={5}
                          {...register('correspondenceAddress.streetNumber', {
                            required: true,
                            maxLength: 5,
                            pattern: LETTERS_NUMBERS_SLASHES_DASHES
                          })}
                          className={classNames('form-control', { error: errors?.correspondenceAddress?.streetNumber })}
                        />
                        {errors && errors.correspondenceAddress?.streetNumber && (
                          <span className="help-block text-negative">
                            {errors.correspondenceAddress?.streetNumber.type === 'required' && t('required', { ns: 'validation' })}
                            {(errors.correspondenceAddress?.streetNumber.type === 'maxLength' ||
                              errors.correspondenceAddress?.streetNumber.type === 'pattern') &&
                              t('invalid.streetNumber', { ns: 'validation' })}
                          </span>
                        )}
                      </div>

                      {/* CORRESPONDENCE ADDRESS STREET BOX */}
                      <div className="column form-group">
                        <label htmlFor="correspondenceAddress.streetBox">
                          {t('add.steps.customerData.fields.correspondenceAddress.streetBox')}
                        </label>
                        <input
                          maxLength={5}
                          id="correspondenceAddress.streetBox"
                          {...register('correspondenceAddress.streetBox')}
                          className={classNames('form-control', { error: errors?.correspondenceAddress?.streetBox })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* CORRESPONDENCE ADDRESS POSTAL CODE */}
                  <div className="column form-group">
                    <label htmlFor="correspondenceAddress.postalCode">
                      {t('add.steps.customerData.fields.correspondenceAddress.postalCode')}
                    </label>
                    <input
                      id="correspondenceAddress.postalCode"
                      {...register('correspondenceAddress.postalCode', {
                        required: true,
                        validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
                      })}
                      onChange={(e) => {
                        const value = e.target.value
                        setValue('correspondenceAddress.postalCode', value)
                        validatePostalCodeAndFetchTowns(parseInt(value))
                      }}
                      inputMode="numeric"
                      className={classNames('form-control', { error: errors?.correspondenceAddress?.postalCode })}
                    />
                    {errors && errors.correspondenceAddress?.postalCode && (
                      <span className="help-block text-negative">
                        {errors.correspondenceAddress?.postalCode.type === 'required' && t('required', { ns: 'validation' })}
                        {errors.correspondenceAddress?.postalCode.type === 'validate' && t('invalid.postalCode', { ns: 'validation' })}
                      </span>
                    )}
                  </div>

                  {/* CORRESPONDENCE ADDRESS TOWN NAME */}
                  <div className="column form-group">
                    <label htmlFor="correspondenceAddress.townName">
                      {t('add.steps.customerData.fields.correspondenceAddress.townName')}
                    </label>
                    <select
                      id="correspondenceAddress.townName"
                      className={classNames('form-control', { error: errors?.correspondenceAddress?.townName })}
                      {...register('correspondenceAddress.townName', { required: true })}
                      onChange={(e) => handleTownSelectChange(e.target.value)}
                      value={watchTownName}
                    >
                      <option disabled>
                        {watch('correspondenceAddress.postalCode') === ''
                          ? t('add.steps.customerData.fields.correspondenceAddress.townNamePlaceholder')
                          : t('defaultPlaceholders.select', { ns: 'common' })}
                      </option>
                      {towns.map((town) => {
                        return (
                          <option key={`town.geoCode-${town.townCode}`} value={town.townName}>
                            {town.townName}
                          </option>
                        )
                      })}
                    </select>
                    {errors && errors.correspondenceAddress?.townName && (
                      <span className="help-block text-negative">
                        {errors.correspondenceAddress?.townName.type === 'required' && t('required', { ns: 'validation' })}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </fieldset>

          <fieldset className="row">
            {/* INVOICE MEDIUM */}
            <div className="column form-group">
              <Heading as="h3" className={contractsStyles['label-large']}>
                {isDigitalOnly
                  ? t('add.steps.customerData.invoiceMedium.digitalOnlyLabel')
                  : t('add.steps.customerData.invoiceMedium.label')}
              </Heading>

              {isDigitalOnly ? (
                <p className={styles['no-margin']}>
                  {t(
                    'add.steps.customerData.invoiceMedium.digitalOnly',
                    'Je hebt gekozen voor Bolt {{ product }}. Als je facturen op papier wil, moet je een ',
                    {
                      product: productsContent?.[product]?.name
                    }
                  )}
                  <button type="button" className={styles['inline-btn']} onClick={() => setCurrentStep(AddContractSteps.PRODUCT)}>
                    {t('add.steps.customerData.chooseAnotherTariff', 'ander tarief kiezen')}
                  </button>
                  .
                </p>
              ) : (
                <Toggle
                  active={watchInvoiceMedium}
                  options={[
                    {
                      value: InvoiceMedium.DIGITAL,
                      label: t('add.steps.customerData.fields.invoiceMedium.digital')
                    },
                    {
                      value: InvoiceMedium.PAPER,
                      label: t('add.steps.customerData.fields.invoiceMedium.paper')
                    }
                  ]}
                  onClick={(value) => setValue('invoiceMedium', value)}
                />
              )}
            </div>
          </fieldset>

          <fieldset>
            <div className="row">
              {/* PAYMENT METHOD */}
              <div className={classNames('column form-group', { [contractsStyles['less-margin-bottom']]: isDirectDebitOnly })}>
                <Heading as="h3" className={contractsStyles['label-large']}>
                  {isDirectDebitOnly
                    ? t('add.steps.customerData.paymentMethod.directDebitOnlyLabel')
                    : t('add.steps.customerData.paymentMethod.label')}
                </Heading>

                {!isDirectDebitOnly && (
                  <Toggle
                    active={watchPaymentMethod}
                    options={[
                      {
                        value: PaymentMethod.DIRECT_DEBIT,
                        label: t('add.steps.customerData.fields.paymentMethod.directDebit')
                      },
                      {
                        value: PaymentMethod.BANK_TRANSFER,
                        label: t('add.steps.customerData.fields.paymentMethod.bankTransfer')
                      }
                    ]}
                    onClick={(value) => setValue('paymentMethod', value)}
                  />
                )}
              </div>
            </div>

            {(isDirectDebitOnly || watchPaymentMethod === PaymentMethod.DIRECT_DEBIT) && (
              <>
                <div className="row">
                  {/* IBAN */}
                  <div className="column form-group mb-none">
                    <label htmlFor="iban">{t('add.steps.customerData.fields.iban')}</label>
                    <input
                      type="text"
                      className={classNames('form-control', { error: errors?.iban })}
                      {...register('iban', {
                        required: true,
                        validate: {
                          valid: (value) => value && IBAN.isValid(value),
                          allowed: (value) => value && ALLOWED_IBAN_COUNTRIES.includes(value.slice(0, 2))
                        }
                      })}
                      onPaste={(e) => {
                        const paste = removeSpacesAndDots(e.clipboardData.getData('text'))
                        e.currentTarget.value = paste
                        setValue('iban', paste)
                        e.preventDefault()
                      }}
                    />
                    {errors && errors.iban && (
                      <span className="help-block text-negative">
                        {errors.iban.type === 'required' && t('required', { ns: 'validation' })}
                        {errors.iban.type === 'valid' && t('invalid.iban', { ns: 'validation' })}
                        {errors.iban.type === 'allowed' && t('invalid.ibanCountries', { ns: 'validation' })}
                      </span>
                    )}
                  </div>
                  <div className="column" />
                </div>

                {isDirectDebitOnly && (
                  <p>
                    {t(
                      'add.steps.customerData.paymentMethod.directDebitOnly',
                      'Je hebt gekozen voor Bolt {{ product }}. Daardoor ben je verplicht te betalen via domiciliëring. Als je dat niet wil, moet je een ',
                      { product: productsContent?.[product]?.name }
                    )}
                    <button type="button" className={styles['inline-btn']} onClick={() => setCurrentStep(AddContractSteps.PRODUCT)}>
                      {t('add.steps.customerData.chooseAnotherTariff', 'ander tarief kiezen')}
                    </button>
                    .
                  </p>
                )}

                <small className={classNames(contractsStyles.description, contractsStyles['is-small'])}>
                  {t('add.steps.customerData.fields.mandateLegal')}
                </small>
              </>
            )}
          </fieldset>
        </div>

        <FormButtons
          currentStep={AddContractSteps.CUSTOMER_DATA}
          loading={loading}
          setCurrentStep={(step) => setCurrentStep(step as AddContractSteps)}
          trackingId={ContractFlowTrackingTypes.ADD}
        />
      </form>
    </FormProvider>
  )
}

export default CustomerDataStep
