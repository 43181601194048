import Icon from 'components/Icon/Icon'
import { SidebarItemProps } from './types'
import classNames from 'classnames'
import styles from './Sidebar.module.scss'
import { IconPath } from '../Icon/types'
import Badge from 'components/Badge/Badge'
import { BadgeColors } from 'components/Badge/types'
import { Link } from 'react-router-dom'

const SidebarItem = ({ icon, text, isSelected, url, badge }: SidebarItemProps) => (
  <li className={styles['sidebar-item']}>
    <Link
      to={url}
      className={classNames(styles.link, {
        [styles.selected]: isSelected
      })}
    >
      {typeof icon === 'string' ? <Icon name={icon as IconPath} /> : icon}
      <span className={styles['text-content']}>
        {text}
        {!!badge && <Badge text={badge} color={BadgeColors.YELLOW} className={styles.badge} />}
      </span>
    </Link>
  </li>
)

export default SidebarItem
