import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const WashingMachine: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M2.25 4.5A2.25 2.25 0 0 1 4.5 2.25h15a2.25 2.25 0 0 1 2.25 2.25v15a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25v-15Zm2.25-.75a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75h-15Z"
        clipRule="evenodd"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M12 9.75a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5ZM7.25 13a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Z"
        clipRule="evenodd"
      />
      <path
        stroke={iconColor || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.18 13s4.32 2.5 3.91 0c-.41-2.5 3.91 0 3.91 0"
      />
      <circle cx="6" cy="6" r="1" fill={iconColor} />
      <circle cx="9" cy="6" r="1" fill={iconColor} />
    </svg>
  )
}

WashingMachine.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default WashingMachine
