import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import dayjs from 'dayjs'
import { DATE_FORMAT_REACT_DATEPICKER } from 'constants/constants'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TerminateDataInputs, TerminateDataProps, TerminateSteps } from 'features/contracts/terminate/types'
import IndexesFormsInputs from 'components/IndexesFormInputs/IndexesFormInputs'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import styles from '../../../Contracts.module.scss'
import ConfirmationModal from 'features/MeterReadings/ConfirmationModal/ConfirmationModal'
import { ConfirmationModalProps } from 'features/MeterReadings/ConfirmationModal/types'
import { useStoreSelector } from 'hooks/store'
import Card from 'components/Card/Card.tsx'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import DocumentUpload from 'features/contracts/components/document-upload/DocumentUpload.tsx'

const MeterDetails = ({ setNextStep, setCurrentStep, terminateData }: TerminateDataProps) => {
  // REDUX STORE
  const { info, selectedContracts } = useStoreSelector((store) => store.user)
  const { meterReadings } = useStoreSelector((store) => store.contracts)

  // Contracts
  const { electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Local state
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [confirmationState, setConfirmationState] = useState<ConfirmationModalProps['state']>('LOW')
  const [hasVeryHighUsage, setHasVeryHighUsage] = useState<boolean>(false)
  const [hasVeryLowUsage, setHasVeryLowUsage] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const reactHookForm = useForm<TerminateDataInputs>({
    defaultValues: {
      ...terminateData[TerminateSteps.METER_DETAILS],
      docUpload: terminateData[TerminateSteps.METER_DETAILS].docUpload ?? null
    }
  })
  const { control, handleSubmit, watch, getValues } = reactHookForm
  const watchDate = watch('date')

  // Constants
  const isDateInFuture = dayjs(watchDate).isAfter(dayjs())

  /**
   * Handles the form submit
   * @param {TerminateDataInputs} data
   */
  const onSubmit = (data: TerminateDataInputs) => {
    if (hasVeryLowUsage || hasVeryHighUsage) {
      setConfirmationState(hasVeryHighUsage && hasVeryLowUsage ? 'BOTH' : hasVeryLowUsage ? 'LOW' : 'HIGH')
      setIsConfirmationModalOpen(true)
    } else {
      setNextStep(data, TerminateSteps.REFERRAL)
    }
  }

  return (
    <>
      <FormProvider {...reactHookForm}>
        <form className={styles['form-card']} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['form-content']}>
            <Card.Title>{t('terminate.meterDetails.title')}</Card.Title>

            {/* DATE */}
            <div className="form-group">
              <label htmlFor="date">{t('terminate.meterDetails.fields.date')}</label>
              <div className="date-picker-container">
                <div className="date-picker-element">
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactDatePicker
                          locale={info.language}
                          selected={dayjs(value).toDate()}
                          disabledKeyboardNavigation
                          maxDate={(terminateData[TerminateSteps.METER_DETAILS].digitalMeter ? dayjs().add(180, 'day') : dayjs()).toDate()}
                          minDate={dayjs().subtract(25, 'day').toDate()}
                          dateFormat={DATE_FORMAT_REACT_DATEPICKER}
                          className="form-control"
                          calendarClassName="date-picker-calendar"
                          onChange={onChange}
                          calendarStartDay={1}
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>

            {/* DOC UPLOAD */}
            <DocumentUpload />

            {terminateData[TerminateSteps.METER_DETAILS].digitalMeter || isDateInFuture ? (
              <div className="form-group">
                <label>{t('fields.meterReadings.label')}</label>
                <div className={styles.description}>
                  {t(
                    `fields.meterReadings.${terminateData[TerminateSteps.METER_DETAILS].digitalMeter ? 'messageDigitalMeter' : 'messageDateInFuture'}`
                  )}
                </div>
              </div>
            ) : (
              <IndexesFormsInputs
                {...{ electricityContract, gasContract, explicitRequired: !isDateInFuture }}
                meterReadingsData={meterReadings?.data}
                setHigherCheck={(result) => setHasVeryHighUsage(result)}
                setLowerCheck={(result) => setHasVeryLowUsage(result)}
                showEan
                showLastMeterReadingByDgo
              />
            )}
          </div>
          <FormButtons
            currentStep={TerminateSteps.METER_DETAILS}
            setCurrentStep={(step) => setCurrentStep(step as TerminateSteps)}
            trackingId={ContractFlowTrackingTypes.TERMINATE}
          />
        </form>
      </FormProvider>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setClose={() => setIsConfirmationModalOpen(false)}
        onSubmit={() => setNextStep(getValues(), TerminateSteps.REFERRAL)}
        state={confirmationState}
      />
    </>
  )
}

export default MeterDetails
