import { IdentifierFormats, Logging, Severity } from 'types/logging.ts'
import Bugsnag from '@bugsnag/js'

/**
 * Logs the error and metadata to Bugsnag
 * @param {Logging} paramsObject
 */
export const log = <T extends IdentifierFormats>({ error, metaData, identifier, severity = Severity.ERROR }: Logging<T>) => {
  const err = typeof error === 'string' ? new Error(error) : error

  Bugsnag.notify(err, (event) => {
    if (metaData) Object.keys(metaData).forEach((section) => event.addMetadata(section, metaData[section]))
    event.context = identifier
    event.severity = severity
  })
}
