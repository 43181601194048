import styles from 'features/contracts/done/Done.module.scss'
import parse from 'html-react-parser'
import { formatAddress, setSelectedUserDataInStore } from 'utils/user.ts'
import dayjs from 'dayjs'
import AddressPicker from 'components/AddressPicker/AddressPicker.tsx'
import { Button, InlineMessage } from '@boltenergy-be/design-system'
import FlowDoneCard from 'features/contracts/done/components/flow-done-card/FlowDoneCard.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import mixpanel from 'mixpanel-browser'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds.ts'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { routes } from 'types/routes.ts'
import { useState } from 'react'
import { getMemoizedContractsLibrary, getMemoizedSelectedContracts } from 'utils/contracts.ts'
import { useNavigate } from 'react-router-dom'
import { StartStopCardProps } from 'features/contracts/done/components/types.ts'
import { useTranslation } from 'react-i18next'
import { MoveFlows } from 'store/user/types.ts'
import Link from 'components/Link/Link.tsx'

const StopCard = ({ handleBack }: StartStopCardProps) => {
  // Redux
  const { move, customers, selectedContracts } = useStoreSelector((store) => store.user)

  // i18n
  const { t } = useTranslation('contracts')

  // React router
  const navigate = useNavigate()

  // Constants
  const availableContractsForTermination = getMemoizedContractsLibrary({ customers, availableOnly: true })
  const { billingContract } = getMemoizedSelectedContracts(selectedContracts)
  const hideActions = !!([MoveFlows.START_STOP, MoveFlows.STOP_START].includes(move.flow) && move.stopped && move.started)

  // Local state
  const [selectedBillingContractId, setSelectedBillingContractId] = useState<string>(
    availableContractsForTermination[billingContract.id] ? billingContract.id : Object.keys(availableContractsForTermination)[0]
  )

  /**
   * Handles start terminate flow button click
   */
  const handleStartTerminateFlow = () => {
    // Switch selected contract if the user has selected a different one
    if (selectedBillingContractId !== billingContract.id) {
      setSelectedUserDataInStore(
        customers,
        availableContractsForTermination[selectedBillingContractId].customerId,
        selectedBillingContractId
      )
    }

    mixpanel.track(MX_CONTRACT_FLOW.start, {
      flow: move.flow,
      type: ContractFlowTrackingTypes.TERMINATE,
      from: MX_CONTRACT_FLOW.from.FINISH_MOVE
    })

    // Go to terminate flow
    navigate(routes.CONTRACTS_TERMINATE)
  }

  return (
    <FlowDoneCard type="stopped" title={t(`done.stop.${move.stopped ? 'complete' : 'incomplete'}.title`)}>
      {move.stopped ? (
        <>
          <address className={styles.address}>{parse(formatAddress(move.stopped.address, true) || '')}</address>
          <span>{t('done.stop.complete.startDate', { date: dayjs(move.stopped.date).format('DD MMMM YYYY') })}</span>
          <p>{t('done.stop.complete.description')}</p>
          <InlineMessage type="positive">{t('done.statuses.complete')}</InlineMessage>
          {!hideActions && (
            <div className={styles.actions}>
              <Link representation="button" href={routes.MOVE_ADDRESSES}>
                {t('done.stop.complete.actions.toOverview')}
              </Link>
            </div>
          )}
        </>
      ) : (
        <>
          <p>{t('done.stop.incomplete.description')}</p>
          <AddressPicker
            label={t('done.stop.incomplete.addressPicker.label')}
            selectedContractId={selectedBillingContractId}
            setSelectedContractId={(contractId) => setSelectedBillingContractId(contractId)}
            options={availableContractsForTermination}
            error={{
              title: t('done.stop.incomplete.addressPicker.noValidContracts.title'),
              description: t('done.stop.incomplete.addressPicker.noValidContracts.description')
            }}
          />
          {Object.keys(availableContractsForTermination).length > 0 && (
            <div className={styles.actions}>
              <Button onClick={handleStartTerminateFlow}>{t('done.stop.incomplete.actions.startTerminate')}</Button>
              <Button onClick={handleBack} representation="link">
                {t('done.stop.incomplete.actions.postpone')}
              </Button>
            </div>
          )}
        </>
      )}
    </FlowDoneCard>
  )
}

export default StopCard
