import { ButtonLoadingIndicator, Heading, Pagination } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card'
import styles from './CasesCard.module.scss'
import { useState } from 'react'
import { paginate } from 'pages/App/Billing/Invoices/utils'
import { CaseStatus, SalesCase } from './types'
import { useTranslation } from 'react-i18next'
import { useGetSalesCasesQuery } from 'store/queries/bolt-api'
import ListWithSeparator from 'components/ListWithSeparator/ListWithSeparator'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'constants/constants'

const CasesCard = () => {
  // state
  const [currentPage, setCurrentPage] = useState(1)

  // i18n
  const { t } = useTranslation('sales')

  // Fetch data
  const { data: cases, isFetching } = useGetSalesCasesQuery()

  //local constants
  const pages = !cases || cases.cases.length <= 0 ? [] : paginate(cases.cases, 7)

  /**
   * function to determine the color of the status
   *
   * @param {CaseStatus} status
   * @returns {string} style class based on status
   */
  const determineStatusColor = (status: CaseStatus) => {
    switch (status) {
      case CaseStatus.NEW:
        return styles.default
      case CaseStatus.RESOLVED:
      case CaseStatus.CLOSED:
        return styles.positive
      case CaseStatus.CANCELED:
      case CaseStatus.ON_HOLD:
        return styles.negative
      case CaseStatus.OPEN:
        return styles.informative
      case CaseStatus.WAITING_FOR_CUSTOMER:
        return styles.warning
    }
  }

  return (
    <Card className={styles.cases}>
      <Heading as="h2" variant="h5">
        {t('overview.cases.title')}
      </Heading>{' '}
      {isFetching ? (
        <div className={styles.loading}>
          <ButtonLoadingIndicator />
        </div>
      ) : (
        <div className={styles['case-table']}>
          {pages && pages[currentPage - 1] && pages[currentPage - 1].length > 0 && (
            <ListWithSeparator>
              {pages[currentPage - 1].map((salesCase: SalesCase, index: number) => (
                <div key={index} className={styles['case-item']}>
                  <div>
                    <strong>{salesCase.name}</strong>
                    <small>{salesCase.subject}</small>
                  </div>
                  <div>
                    <strong className={determineStatusColor(salesCase.status)}>{salesCase.status}</strong>
                    <small>{dayjs(salesCase.createdDate).format(DATE_FORMAT)}</small>
                  </div>
                </div>
              ))}
            </ListWithSeparator>
          )}
          {pages.length > 1 && (
            <Pagination
              pagesLength={pages.length}
              activePageNumber={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              className={styles.pagination}
              maxWidth={320}
            />
          )}
        </div>
      )}
    </Card>
  )
}

export default CasesCard
