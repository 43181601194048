import { useEffect, useRef, useState } from 'react'
import { DrawerProps } from './types'
import { CSSTransition } from 'react-transition-group'
import useWindowSize from 'hooks/useWindowSize'
import styles from './Drawer.module.scss'
import classNames from 'classnames'
import Icon from 'components/Icon/Icon'
import { Heading } from '@boltenergy-be/design-system'

const Drawer = ({ children, extraHeaderElement, isOpen, onRequestClose, contentClassName, title, headerImage }: DrawerProps) => {
  // Refs
  const drawerRef = useRef<HTMLDivElement>(null)

  // Local state
  const [showOverlay, setShowOverlay] = useState(false)

  // Window size
  const { isTablet } = useWindowSize()

  /**
   * Hides modal on click outside and on ESC
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (drawerRef.current && !drawerRef.current.contains(e.target as HTMLDivElement)) {
        onRequestClose()
      }
    }
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRequestClose()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('keydown', closeOnEscape)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('keydown', closeOnEscape)
    }
  }, [onRequestClose])

  /**
   * Stop scroll on body if modal is open
   */
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isOpen])

  return (
    <>
      <CSSTransition
        nodeRef={drawerRef}
        in={isOpen}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowOverlay(true)}
        onExited={() => setShowOverlay(false)}
      >
        <div className={styles.drawer} ref={drawerRef}>
          <div
            className={classNames(styles['drawer-header'], {
              [styles['with-extra']]: !!extraHeaderElement,
              [styles['with-image']]: !!headerImage
            })}
          >
            {isTablet ? (
              <button className={styles['drawer-close']} onClick={onRequestClose}>
                <Icon name="x" size={14} viewBox="0 0 14 14" />
              </button>
            ) : (
              <Icon name="arrowCollapse" size={20} className={styles['drawer-close']} onClick={onRequestClose} />
            )}

            {extraHeaderElement}

            {headerImage && <img src={headerImage.url} alt={headerImage.alt} />}
          </div>

          <div className={classNames(styles['drawer-content'], contentClassName)}>
            {title && (
              <Heading as="h2" variant="h4" className="mb-500">
                {title}
              </Heading>
            )}
            {children}
          </div>
        </div>
      </CSSTransition>
      {showOverlay && <div className={styles.overlay} />}
    </>
  )
}

export default Drawer
