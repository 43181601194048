import classNames from 'classnames'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { EMAIL } from 'constants/regex'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UrlSearchParamsKeys } from 'store/app/types'
import { getUserData } from 'store/user/thunks/customer'
import { routes } from 'types/routes'
import { CustomerEmailForm, GetUserDataErrors } from './types'
import { UserTypes } from 'store/auth/types'
import styles from './CustomerEmail.module.scss'
import SeoTags from 'components/SeoTags/SeoTags'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import parse from 'html-react-parser'
import Card from 'components/Card/Card.tsx'
import { resetMoveFlow } from 'store/user/slice.ts'
import { Button } from '@boltenergy-be/design-system'

const CustomerEmail = () => {
  // REDUX
  const { urlSearchParams } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const { userLoading, customers, userError } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // React Router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('home')

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CustomerEmailForm>()

  // Constants
  const contactIdGiven = !!urlSearchParams[UrlSearchParamsKeys.CONTACT_ID]

  /**
   * Triggered everytime superuser changes
   * Checks if superuser & redirects to /home if not
   */
  useEffect(() => {
    if (userType === UserTypes.CUSTOMER || (customers && Object.keys(customers).length > 0)) navigate(routes.WELCOME)
    if (userType === UserTypes.SALES) navigate(routes.SALES_HOME)
  }, [userType, customers, urlSearchParams])

  /**
   * Returns the error code as a translated, readable error message for the end users
   *
   * @returns {string}
   */
  const getTranslatedUserError = (): string => {
    switch (userError) {
      case GetUserDataErrors.ACCOUNT_WITHOUT_CUSTOMERNUMBER:
        return 'There is a customer account in Salesforce for this email address that has no Haugazel customer number assigned to it.'

      case GetUserDataErrors.CONTRACT_WITHOUT_PRODUCER:
        return 'There is a contract in Haugazel for this email address that has no producer assigned to it. Check customized information on electricity contract.'

      case GetUserDataErrors.USER_NOT_FOUND:
        return `The given email address is not a customer of Bolt. An email address is recognized as a valid customer when:<br>
- <strong>Salesforce Contact:</strong> the "Status" field is set to "Customer" & the "Primary Contact" field is checked (this checkbox is checked automatically by assigning the Contact as the Primary Contact on the account & adding a Contact Role on the Account)<br>
- <strong>Salesforce Account:</strong> the "Type" field is set to "Customer" & the "Missing EAN" field is set to "No".
`

      default:
        return 'Error, contact IT'
    }
  }

  /**
   * Handles the form submit after validation by React Hook Form
   *
   * @param {CustomerEmailForm} data
   */
  const onSubmit = (data: CustomerEmailForm) => {
    const { email } = data
    dispatch(resetMoveFlow())
    dispatch(getUserData({ email }))
  }

  return (
    <>
      <SeoTags title="Customer email" />

      <div className={classNames('container', styles.container)}>
        <Card>
          <Card.Title as="h1" className="mb-300">
            {t('superUser.welcome')}
          </Card.Title>
          <p>{t(`superUser.description.${contactIdGiven ? 'contactId' : 'noContactId'}`)}</p>

          {contactIdGiven ? (
            // Fetching user data in <AppContainer /> is in progress
            <LoadingDots />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="email">{t('superUser.email')}</label>
                <input
                  {...register('email', { required: true, pattern: EMAIL })}
                  type="email"
                  className={classNames('form-control', {
                    error: errors?.email
                  })}
                  placeholder={t('superUser.email')}
                />
                {errors && errors.email && (
                  <small className="text-negative">
                    {errors.email.type === 'required' && t('required', { ns: 'validation' })}
                    {errors.email.type === 'pattern' && t('invalid.email', { ns: 'validation' })}
                  </small>
                )}
              </div>

              <Button loading={userLoading}>{t('superUser.button')}</Button>

              {userError && <p className="text-negative">{parse(getTranslatedUserError())}</p>}
            </form>
          )}
        </Card>
      </div>
    </>
  )
}

export default CustomerEmail
