import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './UsageBadges.module.scss'
import IconBadge from 'components/Badge/IconBadge/IconBadge'
import { formatAmount } from 'utils/format'
import { Electricity, Gas, Injection } from 'assets/svg'
import { UsageBadgesProps as Props } from './types'

const UsageBadges: FC<Props> = ({ electricity, gas, isEstimation }) => {
  // i18n
  const { t } = useTranslation('billing')

  return (
    <div className={styles['usage-badges']}>
      {!!electricity.consumption && (
        <div className={styles['usage-badge']}>
          <IconBadge
            label={t('billingCycles.current.usage.value', {
              value: formatAmount(Math.round(electricity.consumption))
            })}
            tooltipContent={t(`billingCycles.${isEstimation ? 'current' : 'previous'}.usage.tooltips.electricity.consumption`)}
            icon={<Electricity isFilled />}
          />
        </div>
      )}

      {!!electricity.injection && (
        <div className={styles['usage-badge']}>
          <IconBadge
            label={t('billingCycles.current.usage.value', {
              value: formatAmount(Math.round(electricity.injection))
            })}
            tooltipContent={t(`billingCycles.${isEstimation ? 'current' : 'previous'}.usage.tooltips.electricity.injection`)}
            icon={<Injection isFilled />}
          />
        </div>
      )}

      {!!gas && (
        <div className={styles['usage-badge']}>
          <IconBadge
            label={t('billingCycles.current.usage.value', {
              value: formatAmount(Math.round(gas))
            })}
            tooltipContent={t(`billingCycles.${isEstimation ? 'current' : 'previous'}.usage.tooltips.gas`)}
            icon={<Gas isFilled />}
          />
        </div>
      )}
    </div>
  )
}

export default UsageBadges
