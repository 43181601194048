import { useEffect, useState } from 'react'
import { UserTypes } from 'store/auth/types'
import { useGetFeedbackWidgetBySlugQuery } from 'store/queries/bolt-api'
import { WidgetSlug } from 'types/feedbackWidget'
import { useStoreSelector } from './store'

/**
 * Custom hook to trigger the feedback widget after x seconds
 *
 * @param {number} secondsBeforeOpening - Seconds before opening the feedback widget
 * @param {WidgetSlug} slug - Widget to open
 * @param {boolean} startTimer - If true, the timer will start and the widget will open
 */
const useOpenFeedbackWidget = (secondsBeforeOpening: number, slug: WidgetSlug, startTimer: boolean = true) => {
  // Local state
  const [openFeedbackWidget, setOpenFeedbackWidget] = useState<boolean>(false)

  // Redux
  const { userType } = useStoreSelector((store) => store.auth)

  // Redux query
  const { data: feedbackWidgetData } = useGetFeedbackWidgetBySlugQuery({ slug })

  // Constants
  const isSales = userType === UserTypes.SALES
  const isSuperUser = userType === UserTypes.SUPER_USER

  // Open feedback widget after x seconds
  useEffect(() => {
    if (startTimer && !isSales && !isSuperUser) {
      const triggerWidget = () => {
        setTimeout(() => {
          setOpenFeedbackWidget(true)
        }, secondsBeforeOpening * 1000)
      }

      triggerWidget()

      // Clean up
      return () => triggerWidget()
    }
    return
  }, [startTimer])

  return { openFeedbackWidget, feedbackWidgetData }
}

export default useOpenFeedbackWidget
