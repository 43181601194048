import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './SearchBar.module.scss'
import { Close, Search } from 'assets/svg'
import { SearchBarProps as Props } from './types'

const SearchBar: FC<Props> = ({ className, placeholder, onInput, size, disabled }) => {
  // Local state
  const [value, setValue] = useState<string>('')

  // Sends the input value back to the parent on value change
  useEffect(() => {
    onInput(value)
  }, [onInput, value])

  return (
    <div className={classNames(styles['search-bar'], className)}>
      <div className={styles['search-input-wrapper']}>
        <input
          className={classNames(styles['search-input'], { [styles['search-active']]: !!value }, { [styles.small]: size === 'small' })}
          type="text"
          role="searchbox"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(event) => setValue(event.currentTarget.value)}
        />
        <span className={classNames(styles['prepend-icon'], { [styles.small]: size === 'small' })}>
          <Search />
        </span>
        {!!value && (
          <button className={styles.clear} type="reset" onClick={() => setValue('')}>
            <Close />
          </button>
        )}
      </div>
    </div>
  )
}

export default SearchBar
