const CloseHouse = ({ className }: Partial<JSX.IntrinsicElements['svg']>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none" className={className}>
      <path
        d="M27.5 12.5542C27.5 11.5664 27.0063 10.644 26.1845 10.0961L18.221 4.78713C17.1788 4.09236 15.8212 4.09236 14.779 4.78713L6.81552 10.0961C5.99365 10.644 5.5 11.5664 5.5 12.5542V23.8362C5.5 25.5497 6.88907 26.9388 8.60256 26.9388H24.3974C26.1109 26.9388 27.5 25.5497 27.5 23.8362L27.5 12.5542Z"
        fill="#100061"
        fillOpacity="0.1"
      />
      <path d="M12.5 22L20.5 14M12.5 14L20.5 22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CloseHouse
