import { Language } from 'store/app/types.ts'

/**
 * Capitalizes the first letter of a given text
 * @param {string} text
 * @returns {string}
 */
export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * Formats a given amount to a nl locale string with 2 decimals or the given maximumFractionDigits
 *
 * @param {number} num
 * @param {number} [maximumFractionDigits]
 * @returns {string}
 */
export const formatAmount = (num: number, maximumFractionDigits?: number): string => {
  return num?.toLocaleString('nl-BE', {
    maximumFractionDigits: maximumFractionDigits ?? 2,
    minimumFractionDigits: maximumFractionDigits ?? 2
  })
}

/**
 * Formats a given amount to a nl or fr locale currency string with 2 decimals or the given maximumFractionDigits.
 * Removes ",00" on round currencies like € 100,00.
 *
 * @param {number} num
 * @param {number} maximumFractionDigits
 * @param {string} lang
 * @returns {string}
 */
export const formatAsCurrency = ({
  num,
  maximumFractionDigits,
  lang = Language.NL
}: {
  num: number
  maximumFractionDigits?: number
  lang?: Language.NL | Language.FR
}): string => {
  const fractionDigits = maximumFractionDigits ?? 2
  const intlNumberFormat = Intl.NumberFormat(lang, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: fractionDigits
  })
  const digitsRegex = new RegExp(`,0{${fractionDigits}}`)
  return isNaN(num) ? '' : intlNumberFormat.format(num).replace(digitsRegex, '')
}

/**
 * Formats a given number to a nl locale string with 2 decimals
 *
 * @deprecated Use formatAmount instead
 * @param {number} num
 * @param {boolean=} hasDecimals
 * @returns {string}
 */
export const formatNumber = (num: number, hasDecimals?: boolean): string => {
  const formatted = !hasDecimals ? Math.round(num) : Math.round((num + Number.EPSILON) * 100) / 100

  return formatted
    .toString()
    .replace(/\./g, ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

/**
 *  Mask the data with asterisks
 *
 * @param {string | number | null} [data]
 * @param {number} [maskOffset]
 * @returns {string}
 */
export const maskWithAsterisk = (data?: string | number | null, maskOffset: number = 4): string => {
  if (!data) return ''
  return data.toString().replace(/.(?=.{0,}$)/g, (match, offset) => (offset < maskOffset ? match : '*'))
}

/**
 * Removes all special characters and converts to lowercase
 * @param {string} text
 * @returns string
 */
export const normalizeText = (text: string): string => {
  return text
    ? text
        .normalize('NFD') // separate accents from letters and return Unicode codes
        .replace(/[\u0300-\u036f]/g, '') // replace diacritical characters with empty string
        .toLowerCase()
    : ''
}

/**
 * Removes spaces and dots in the given string & returns it
 *
 * @param {string} text
 * @returns {string}
 */
export const removeSpacesAndDots = (text: string): string => {
  // \s+ matches all whitespace characters, including spaces, non-break spaces, tabs, new lines and carriage returns
  const noSpaces = text.replace(/\s+/g, '')
  return noSpaces.replace(/\./g, '')
}
