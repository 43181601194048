import { PropsWithChildren, useMemo, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import styles from './AuthSlider.module.scss'
import classNames from 'classnames'
import { AuthSliderProps } from './types'

const AuthSlider = ({ producers, children }: PropsWithChildren<AuthSliderProps>) => {
  // Local state
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  // Ref
  const slider = useRef<any>(null)

  // Constants
  const settings = useMemo<Settings>(
    () => ({
      className: styles.slider,
      dotsClass: styles['dots-wrapper'],
      arrows: false,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000, // go to next slide after 10 seconds
      infinite: true,
      draggable: false,
      fade: true,
      accessibility: false,
      pauseOnHover: false,
      lazy: true,
      beforeChange: (_: number, nextSlide: number) => {
        setCurrentSlide(nextSlide)
        slider.current?.innerSlider.list
          .querySelector(`[data-index="${nextSlide}"] > div > div img`)
          .classList.remove('subtle-zoom-animation')
      },
      afterChange: (currentSlide: number) => {
        slider.current?.innerSlider.list
          .querySelector(`[data-index="${currentSlide}"] > div > div img`)
          .classList.add('subtle-zoom-animation')
      },
      appendDots: (dots: React.ReactNode): JSX.Element => (
        <nav>
          <ul className={styles.dots}>{dots}</ul>
        </nav>
      ),
      customPaging: (index) => (
        <div
          role="button"
          className={classNames(styles.dot, { [styles.active]: currentSlide === index })}
          aria-label={`slide ${index + 1}`}
        />
      )
    }),
    [currentSlide, producers]
  )

  return (
    <Slider ref={slider} {...settings}>
      {children}
    </Slider>
  )
}

export default AuthSlider
