import { Heading, Icon } from '@boltenergy-be/design-system'
import Card from 'components/Card/Card.tsx'
import styles from './ReturnLater.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ReturnLaterProps } from './types'

export const ReturnLater = ({ description }: ReturnLaterProps) => {
  //i18n
  const { t } = useTranslation('common')

  return (
    <Card className={classNames(styles['return-later-card'], 'container')} as="section">
      <div className={styles['icon-wrapper']}>
        <Icon className={styles.icon} name="hourglass" />
      </div>
      <div className={styles.text}>
        <Heading as="h5" className={styles.title}>
          {t('returnLater.title')}
        </Heading>
        <p className={styles.description}>{description}</p>
      </div>
    </Card>
  )
}
