import request, { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods } from 'types/request'
import { EanLookupResponseBody, GetEanDataResponseBody, TownResponse } from './types'
import { Address, EnergyType } from 'types/types'
import { Language } from 'store/app/types'
import { stringify } from 'qs'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'

/**
 * Fetches the EAN numbers based on the given language & address
 *
 * @param {Language} language
 * @param {Address} address
 * @returns {Promise<EanLookupResponseBody>}
 */
export const eanLookup = async (language: Language, address: Address): Promise<EanLookupResponseBody> => {
  const { success, data } = await newRequest<EanLookupResponseBody>(
    getAxiosRequestConfig(Methods.POST, '/market-message/preswitch/ean-lookup', { address, language })
  )

  return success && data ? data : { electricity: null, gas: null }
}

/**
 * Fetches the switch date for the given EAN
 *
 * @param {EnergyType} energyType
 * @param {string} ean
 * @param {string} contractStartDate
 * @returns {Promise<string|null>}
 */
export const getEanData = async (
  energyType: EnergyType,
  ean: string,
  contractStartDate?: string
): Promise<GetEanDataResponseBody | null> => {
  try {
    const query = stringify({ contractStartDate, ean, energyType }, { addQueryPrefix: true, skipNulls: true })

    const { success, data } = await newRequest<GetEanDataResponseBody>(
      getAxiosRequestConfig(Methods.GET, `/market-message/preswitch/ean-data${query}`)
    )

    return success && data ? data : null
  } catch (error) {
    log({ error: error as Error, identifier: `[${Flow.CONTRACT}:getEanData]` })
    return null
  }
}

/**
 * Fetch the town from the API based on the given postal code
 *
 * @param postalCode
 * @return Promise<TownResponse|null>
 */
export const getTown = async (postalCode: number): Promise<TownResponse> => {
  const response = await request(getAxiosRequestConfig(Methods.GET, `towns/${postalCode}`))

  return response.success ? response.data : null
}
