import { useState } from 'react'
import { CollapsibleCardProps as Props } from './types'
import styles from './CollapsibleCard.module.scss'
import classNames from 'classnames'
import Card from 'components/Card/Card.tsx'
import { Icon } from '@boltenergy-be/design-system'

const CollapsibleCard = ({ as, disabled, onOpen, startCollapsed, titleSection, children, contentClassName }: Props) => {
  // Local state
  const [isOpen, setIsOpen] = useState<boolean>(!startCollapsed)

  return (
    <Card className={classNames(styles.collapsible, { [styles.disabled]: disabled })} {...{ as }}>
      <header
        className={classNames(styles['title-section'], { [styles.open]: isOpen })}
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen)
            onOpen?.()
          }
        }}
        role="presentation"
      >
        {titleSection}
        <span className={styles['collapse-icon']}>
          <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} size={20} />
        </span>
      </header>

      {isOpen && <div className={contentClassName}>{children}</div>}
    </Card>
  )
}

export default CollapsibleCard
