import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetBillShockPayload,
  GetBillShockRejectedValue,
  GetBillShockSuccessPayload,
  GetMeterReadingsPayload,
  GetMeterReadingsSuccessPayload,
  GetPreviousBillingCyclePayload,
  GetPreviousBillingCyclePeriodsPayload,
  GetPreviousBillingCyclePeriodsSuccessPayload,
  GetPreviousBillingCycleRejectValue,
  GetPreviousBillingCycleSuccessPayload,
  RejectValue
} from './types'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { generateBillShockAxiosRequestConfig, generateGetMeterReadingsAxiosRequestConfig } from 'api/contracts'
import { Methods, Response } from 'types/request'
import { GetBillShockResponseData, GetPreviousBillingCyclePeriodsResponseData, GetPreviousBillingCycleResponseData } from 'types/billShock'
import Bugsnag from '@bugsnag/js'
import { GetLastMeterReadingResponseData } from 'types/contracts'

export const getBillShock = createAsyncThunk<GetBillShockSuccessPayload, GetBillShockPayload, GetBillShockRejectedValue>(
  'contracts/getBillShock',
  async ({ billingContractId, email, electricityContract, gasContract }, thunkAPI) => {
    try {
      const { success, data, message }: Response<GetBillShockResponseData> = await newRequest(
        generateBillShockAxiosRequestConfig(billingContractId, email, electricityContract, gasContract)
      )

      if (success && data?.billShock) {
        return { billShock: data.billShock }
      }

      if (!success && message) {
        return thunkAPI.rejectWithValue({ code: message, request: false })
      }

      Bugsnag.notify('Error while fetching bill shock data or empty response')
      return thunkAPI.rejectWithValue({ request: true })
    } catch (error) {
      return thunkAPI.rejectWithValue({ request: true })
    }
  }
)

export const getMeterReadings = createAsyncThunk<GetMeterReadingsSuccessPayload, GetMeterReadingsPayload, RejectValue>(
  'contracts/getMeterReadings',
  async ({ electricityContractNumber, gasContractNumber }, thunkAPI) => {
    try {
      const [electricity, gas] = await Promise.all([
        newRequest(generateGetMeterReadingsAxiosRequestConfig(electricityContractNumber)),
        ...(gasContractNumber ? [newRequest(generateGetMeterReadingsAxiosRequestConfig(gasContractNumber))] : [])
      ])

      const typedEl: Response<GetLastMeterReadingResponseData> = electricity as Response<GetLastMeterReadingResponseData>
      const typedGas: Response<GetLastMeterReadingResponseData> = gas as Response<GetLastMeterReadingResponseData>

      if (typedEl.success && typedEl?.data?.lastMeterReading) {
        return {
          meterReadings: {
            electricity: typedEl.data.lastMeterReading,
            ...(typedGas?.success &&
              typedGas?.data && {
                gas: typedGas.data.lastMeterReading
              })
          }
        }
      }

      return thunkAPI.rejectWithValue(typedEl.message || typedGas.message || 'getMeterReadings')
    } catch (error) {
      const { message } = error as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getPreviousBillingCyclePeriods = createAsyncThunk<
  GetPreviousBillingCyclePeriodsSuccessPayload,
  GetPreviousBillingCyclePeriodsPayload,
  RejectValue
>('contracts/getPreviousBillingCyclePeriods', async ({ billingContractId }, thunkAPI) => {
  try {
    const { success, data, message }: Response<GetPreviousBillingCyclePeriodsResponseData> = await newRequest(
      getAxiosRequestConfig(Methods.GET, `/contracts/${billingContractId}/billed-periods`)
    )

    if (success && data?.periods) {
      return { periods: data.periods }
    }

    return thunkAPI.rejectWithValue(message || 'getPreviousBillingCyclePeriods')
  } catch (error) {
    const { message } = error as Error
    return thunkAPI.rejectWithValue(message)
  }
})

export const getPreviousBillingCycle = createAsyncThunk<
  GetPreviousBillingCycleSuccessPayload,
  GetPreviousBillingCyclePayload,
  GetPreviousBillingCycleRejectValue
>('contracts/getPreviousBillingCycle', async ({ billingContractId, billingPeriodId }, thunkAPI) => {
  try {
    const { success, data, message }: Response<GetPreviousBillingCycleResponseData> = await newRequest(
      getAxiosRequestConfig(Methods.GET, `/contracts/${billingContractId}/billed-periods/${billingPeriodId}`)
    )

    if (success && data?.period) {
      return { previousBillingCycle: data.period }
    }

    return thunkAPI.rejectWithValue({ id: billingPeriodId, error: message || 'getPreviousBillingCycle' })
  } catch (error) {
    const { message } = error as Error
    return thunkAPI.rejectWithValue({ id: billingPeriodId, error: message })
  }
})
