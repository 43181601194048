import { FC } from 'react'
import styles from './Footer.module.scss'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import Logo from 'assets/logo.svg'
import { routes } from 'types/routes'
import { getUserTypeHomepage } from 'utils/user'
import { Store } from 'store/types'
import { Language } from 'store/app/types'
import useWindowSize from 'hooks/useWindowSize'
import { FooterProps } from './types'
import { useLocation } from 'react-router-dom'

const Footer: FC<FooterProps> = ({ isFullwidth }) => {
  // REDUX STORE
  const { userType } = useSelector((store: Store) => store.auth)
  const { language } = useSelector((store: Store) => store.app)

  // i18n
  const { t } = useTranslation()

  // Window size
  const { isMobile } = useWindowSize()

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  /**
   * Defines route when clicking on logo
   */
  const handleLogoClick = () => {
    if (pathname === routes.WELCOME) {
      navigate(routes.WELCOME)
    } else {
      navigate(getUserTypeHomepage(userType))
    }
  }

  return (
    <footer className={classNames(styles.footer, { [styles.fullwidth]: isFullwidth })}>
      <div className={classNames('container', styles['footer-container'])}>
        {/* LEFT */}
        {!isMobile && (
          <div className={styles.side}>
            <span>{t('footer.copyright', { year: dayjs().year() })}</span>
            <a
              href={language === Language.FR ? 'https://www.boltenergie.be/fr' : 'https://www.boltenergie.be/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              boltenergie.be
            </a>
          </div>
        )}

        {/* CENTER */}
        <img src={Logo} className={styles.logo} onClick={() => handleLogoClick()} alt="Bolt logo" />

        {isMobile && <span>{t('footer.copyright', { year: dayjs().year() })}</span>}

        {/* RIGHT */}
        <div className={styles.side}>
          {isMobile && (
            <a
              href={language === Language.FR ? 'https://www.boltenergie.be/fr' : 'https://www.boltenergie.be/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              boltenergie.be
            </a>
          )}
          <a
            href={`https://www.boltenergie.be/${language === Language.FR ? 'fr/termes-et-conditions' : 'nl/algemene-voorwaarden'}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.terms')}
          </a>

          <a
            href={`https://www.boltenergie.be/${
              language === Language.FR ? 'fr/mentions-legales-politique-de-confidentialite' : 'nl/disclaimer-privacy-policy'
            }`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.disclaimer')}
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
