import styles from './ConsumptionNavigation.module.scss'
import { Granularity } from 'types/contracts.ts'
import {
  ConsumptionNavigationForm,
  ConsumptionNavigationProps,
  DatePickerConfig,
  NavigationItemType
} from 'components/Charts/ConsumptionNavigation/types.ts'
import { getCycleIndex, getGranularityPeriodIndex } from 'components/Charts/ConsumptionNavigation/utils.ts'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover.tsx'
import classNames from 'classnames'
import { ProductType } from 'types/types.ts'
import { Electricity, Gas } from 'assets/svg'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useStoreSelector } from 'hooks/store.ts'
import mixpanel from 'mixpanel-browser'
import { ConsumptionEvents, ConsumptionTrackingParams } from 'types/tracking.ts'
import { getMemoizedSelectedContracts } from 'utils/contracts.ts'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Language } from 'store/app/types.ts'
import { nl } from 'date-fns/locale/nl'
import { fr } from 'date-fns/locale/fr'
import { Icon } from '@boltenergy-be/design-system'

const ConsumptionNavigation = ({ billingCycles, granularities, isLoading, hideEnergyTypeToggle }: ConsumptionNavigationProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)
  const { selectedContracts } = useStoreSelector((store) => store.user)

  // Locale for React Datepicker
  registerLocale(language, language === Language.NL ? nl : fr)

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // i18n
  const { t } = useTranslation('consumption')

  // React hook form
  const { control, register, watch, setValue } = useFormContext<ConsumptionNavigationForm>()
  const activeProductType = watch('activeProduct')
  const watchView = watch('view')
  const watchPeriod = watch('period')

  // Constants
  const disableTypeToggle =
    watchView === NavigationItemType.CYCLE &&
    !billingCycles[watchPeriod]?.fuel?.[activeProductType === ProductType.ELECTRICITY ? 'gas' : 'electricity']

  /**
   * Handle the change of the view
   * @param {Granularity | NavigationItemType.CYCLE} newView
   */
  const handleChangeView = (newView: Granularity | NavigationItemType.CYCLE) => {
    setValue('view', newView)

    if (newView === NavigationItemType.CYCLE) {
      setValue('period', Object.keys(billingCycles).sort((a, b) => Number(b) - Number(a))?.[0])
    } else {
      const defaultPeriod = granularities[newView]?.periods?.find((period) => period.selected)
      const newPeriod = defaultPeriod ? defaultPeriod?.value : granularities[newView]?.periods?.[0]?.value
      setValue('period', newPeriod ?? '')
    }
  }

  /**
   * Switch the active type
   */
  const switchActiveType = () => {
    // Define the new active type
    const newActiveType = activeProductType === ProductType.ELECTRICITY ? ProductType.GAS : ProductType.ELECTRICITY

    // If the new active type is gas and the view is hour or day, change the view to month
    if (newActiveType === ProductType.GAS && (watchView === Granularity.HOUR || watchView === Granularity.DAY)) {
      setValue('view', Granularity.MONTH)
    }

    // Track the event
    mixpanel.track(ConsumptionEvents.CONSUMPTION_FUEL_TYPE_TOGGLED, {
      [ConsumptionTrackingParams.FUEL_TYPE]: newActiveType
    })

    // Set the new active type
    setValue('activeProduct', newActiveType)
  }

  return (
    <form className={styles.form}>
      <div>
        {(electricityContract.dynamicTariff || !!Object.keys(billingCycles).length) && (
          <select
            {...register('view')}
            className={styles.granularity}
            onChange={(event) => handleChangeView(event.target.value as Granularity | NavigationItemType.CYCLE)}
          >
            {billingCycles && !!Object.keys(billingCycles).length && (
              <option value={NavigationItemType.CYCLE}>{t('chart.header.pagination.header')}</option>
            )}
            {Object.values(Granularity)
              .filter((g) => !!granularities[g] && granularities[g]?.fuel?.[activeProductType])
              .map((g) => (
                <option key={g} value={g} disabled={!granularities[g]?.fuel?.[activeProductType]}>
                  {granularities[g]!.text}
                </option>
              ))}
          </select>
        )}
      </div>

      <div className={styles.navigation}>
        <button
          className={styles.previous}
          type="button"
          disabled={
            watchView === NavigationItemType.CYCLE
              ? getCycleIndex(billingCycles, watchPeriod).previous === null
              : getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).previous === null
          }
          onClick={() => {
            if (watchView === NavigationItemType.CYCLE) {
              const previousCycleIndex = getCycleIndex(billingCycles, watchPeriod).previous
              if (typeof previousCycleIndex === 'number') {
                const previous = Object.values(billingCycles)[previousCycleIndex]
                setValue('period', previous.id)
              }
            } else {
              const previousGranularityIndex = getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).previous
              if (typeof previousGranularityIndex === 'number') {
                const previous = granularities[watchView]?.periods?.[previousGranularityIndex]
                setValue('period', previous?.value || '')
              }
            }
          }}
        >
          <Icon name="chevronLeft" />
        </button>

        {watchView === NavigationItemType.CYCLE ? (
          <select {...register('period')} className={styles.period} value={watchPeriod}>
            {Object.keys(billingCycles)
              .sort((a, b) => Number(b) - Number(a))
              .map((key) => (
                <option key={key} value={billingCycles[key].id}>
                  {billingCycles[key].text}
                </option>
              ))}
          </select>
        ) : (
          <Controller
            name="period"
            {...{ control }}
            render={({ field: { value } }) => {
              const { valueFormat, endOf, viewFormat }: DatePickerConfig =
                watchView === Granularity.HOUR
                  ? { valueFormat: 'YYYY-MM-DD', viewFormat: 'd MMMM YYYY', endOf: 'day' }
                  : watchView === Granularity.DAY
                    ? { valueFormat: 'YYYY-MM', viewFormat: 'MMMM YYYY', endOf: 'month' }
                    : { valueFormat: 'YYYY', viewFormat: 'YYYY', endOf: 'year' }

              return (
                <DatePicker
                  enableTabLoop={false}
                  locale={language}
                  dateFormat={viewFormat}
                  showYearPicker={watchView === Granularity.MONTH}
                  showMonthYearPicker={watchView === Granularity.DAY}
                  selected={dayjs(value).toDate()}
                  onChange={(date) => {
                    const periodToSelect = granularities[watchView]?.periods?.find(
                      (p) => dayjs(p.value).format(valueFormat) === dayjs(date).endOf(endOf).format(valueFormat)
                    )?.value
                    const defaultPeriod = granularities[watchView]?.periods?.[0]?.value

                    setValue('period', periodToSelect || defaultPeriod || '')
                  }}
                  maxDate={dayjs(granularities[watchView]?.periods?.[0]?.value).toDate()}
                  minDate={dayjs(granularities[watchView]?.periods?.[(granularities[watchView]?.periods?.length || 1) - 1]?.value).toDate()}
                  calendarStartDay={1}
                  calendarClassName={styles['datepicker-popup']}
                  className={styles['datepicker-input']}
                  showPopperArrow={false}
                />
              )
            }}
          />
        )}

        <button
          className={styles.next}
          type="button"
          disabled={
            watchView === NavigationItemType.CYCLE
              ? getCycleIndex(billingCycles, watchPeriod).next === null
              : getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).next === null
          }
          onClick={() => {
            if (watchView === NavigationItemType.CYCLE) {
              const nextCycleIndex = getCycleIndex(billingCycles, watchPeriod).next
              if (typeof nextCycleIndex === 'number') {
                const next = Object.values(billingCycles)[nextCycleIndex]
                setValue('period', next.id)
              }
            } else {
              const nextGranularityIndex = getGranularityPeriodIndex(granularities[watchView]?.periods || [], watchPeriod).next
              if (typeof nextGranularityIndex === 'number') {
                const next = granularities[watchView]?.periods?.[nextGranularityIndex]
                setValue('period', next?.value || '')
              }
            }
          }}
        >
          <Icon name="chevronRight" />
        </button>
      </div>

      <TooltipOnHover
        tooltipContent={t(`chart.header.toggle.${activeProductType}`)}
        containerStyles={classNames(styles['hover-container'], { [styles.hidden]: hideEnergyTypeToggle })}
        disabled={!disableTypeToggle}
      >
        <button type="button" onClick={switchActiveType} disabled={isLoading || disableTypeToggle} className={styles['toggle-btn']}>
          <div className={classNames(styles.electricity, { [styles.active]: activeProductType === ProductType.ELECTRICITY })}>
            <Electricity isFilled />
          </div>
          <div className={classNames(styles.gas, { [styles.active]: activeProductType === ProductType.GAS })}>
            <Gas isFilled />
          </div>
        </button>
      </TooltipOnHover>
    </form>
  )
}

export default ConsumptionNavigation
