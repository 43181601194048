import { getCssVariable } from 'utils/app.ts'

export const yScaleTitle = {
  id: 'yScaleTitle',
  afterDraw(chart: any, _: any, options: any) {
    if (!options.labels) return

    const {
      ctx,
      chartArea: { top },
      scales
    } = chart

    const yScales = Object.entries(scales)
      .filter(([key]) => key.includes('y'))
      .map(([, value]) => value)

    yScales.forEach((scale: any) => {
      const label = options.labels[scale.id]
      if (!label || !scale?.options?.display || !scale?.options?.ticks?.display) return

      ctx.save()
      ctx.font = '600 12px "Commuters Sans"'
      ctx.fillStyle = getCssVariable('--text-subdued')
      ctx.fillText(label, scale.position === 'right' ? scale.right - scale.width - 2 : scale.left, top - 20)
      ctx.restore()
    })
  }
}
