import icon from './paths'
import { IconProps } from './types'

const Icon = ({ size = 24, color = 'currentColor', name, viewBox = '0 0 24 24', ...rest }: IconProps) => (
  <svg viewBox={viewBox} width={size} height={size} fill={color} {...rest}>
    <path d={icon[name]} />
  </svg>
)

export default Icon
