const Account = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.25 12.133c0-5.384 4.365-9.75 9.75-9.75s9.75 4.366 9.75 9.75a9.712 9.712 0 0 1-2.399 6.406 7.765 7.765 0 0 0-5.598-5.09 3.75 3.75 0 1 0-3.506 0 7.765 7.765 0 0 0-5.598 5.09 9.712 9.712 0 0 1-2.399-6.406Zm3.63 7.591a9.71 9.71 0 0 0 6.12 2.16 9.71 9.71 0 0 0 6.12-2.16 6.252 6.252 0 0 0-12.24 0ZM12 7.884a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Account
