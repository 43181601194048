import { FC } from 'react'
import styles from './GasTipCard.module.scss'
import { GasTipCardProps as Props } from 'components/EnergyOverview/GasTipCard/types'

const GasTipCard: FC<Props> = ({ tip, icon, orderNumber }) => {
  return (
    <div className={styles.card}>
      <span className={styles.order}>{`${orderNumber}`.padStart(2, '0')}</span>
      <figure className={styles.icon}>{icon}</figure>
      <p className={styles.tip}>{tip}</p>
    </div>
  )
}

export default GasTipCard
