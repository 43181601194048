import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Button } from '@boltenergy-be/design-system'
import Icon from 'components/Icon/Icon'
import { Electricity, Gas } from 'assets/svg'
import { ButtonMeterReadingsProps } from './types'
import styles from './ButtonMeterReadings.module.scss'
import { routes } from 'types/routes'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import useIsComponentVisible from 'hooks/useIsComponentVisible'

const ButtonMeterReadings = ({
  loading,
  setModalOpen,
  electricityLastMeterReadingDate,
  gasLastMeterReadingDate
}: ButtonMeterReadingsProps) => {
  // REDUX
  const { selectedContracts } = useSelector((store: Store) => store.user)

  // Contracts
  const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)

  // i18n
  const { t } = useTranslation()

  // Router
  const navigate = useNavigate()

  // Custom hook
  const { ref, isComponentVisible, setIsComponentVisible } = useIsComponentVisible(false)

  // Return the normal button if no last meter reading date was given
  if (!electricityLastMeterReadingDate) {
    return (
      <Button className={styles.button} leadingIcon="plus" loading={loading} onClick={() => setModalOpen(true)}>
        {t('buttonMeterReadings.label')}
      </Button>
    )
  }

  // Return the dropdown button if last meter reading date was given
  return (
    <div className={styles.container}>
      <div className={styles['meter-readings-container']}>
        <div className={styles['meta-container']}>
          <div className={styles.label}>{t('buttonMeterReadings.lastMeterReadingLabel')}</div>
          <div className={styles['date-container']}>
            <span className={styles.date}>{dayjs(electricityLastMeterReadingDate).format('DD/MM/YYYY')}</span>
            <Electricity isFilled iconColor="currentColor" />
            {gasLastMeterReadingDate && <Gas isFilled iconColor="currentColor" />}
          </div>
        </div>
        <button className={styles['button-container']} onClick={() => setIsComponentVisible(true)}>
          <Icon name="menuDown" color="currentColor" />
        </button>
      </div>
      {isComponentVisible && (
        <>
          <div ref={ref} className={styles['dropdown-container']}>
            <ul>
              {/* VIEW METER READINGS */}
              {!electricityContract.monthlyBilling && (
                <li>
                  <button
                    onClick={() => {
                      navigate(routes.CONSUMPTION_METER_READINGS)
                      setIsComponentVisible(false)
                    }}
                  >
                    <span className={styles['icon-container']}>
                      <Icon name="share" color="currentColor" size={20} />
                    </span>
                    {t('buttonMeterReadings.dropdownMenu.view')}
                  </button>
                </li>
              )}

              {/* ADD METER READINGS */}
              <li>
                <button
                  onClick={() => {
                    setModalOpen(true)
                    setIsComponentVisible(false)
                  }}
                >
                  <span className={styles['icon-container']}>
                    <Icon name="add" color="currentColor" size={20} />
                  </span>
                  {t('buttonMeterReadings.dropdownMenu.add')}
                </button>
              </li>
            </ul>
          </div>
          <div className="overlay dropdown-overlay" />
        </>
      )}
    </div>
  )
}

export default ButtonMeterReadings
