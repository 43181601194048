import styles from './PageLoading.module.scss'
import LoadingDots from 'components/LoadingDots/LoadingDots.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { useTranslation } from 'react-i18next'
import { PageLoadingProps } from 'components/PageLoading/types.ts'

const PageLoading = ({ isLoading, showError }: PageLoadingProps) => {
  // Redux
  const { userError } = useStoreSelector((store) => store.user)

  // i18n
  const { t } = useTranslation()

  return (
    <div className={styles.loading}>
      {!showError && isLoading && (
        <div>
          <div className={styles['dots-container']}>
            <LoadingDots />
          </div>
          {t('loadingData')}
        </div>
      )}
      {(showError || userError) && <div>{t('error')}</div>}
    </div>
  )
}

export default PageLoading
