import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal/Modal'
import { Button } from '@boltenergy-be/design-system'

import { ConfirmationModalProps as Props } from './types'
import styles from './ConfirmationModal.module.scss'
import resources from 'translations/dutch'

const ConfirmationModal: FC<Props> = ({ isOpen, state, setClose, onSubmit }) => {
  // i18n
  const { t } = useTranslation()

  const handleSubmit = async () => {
    setClose()
    onSubmit()
  }

  return (
    <Modal isStacked isOpen={isOpen} setClose={setClose} className={styles.container}>
      <div className={styles.content}>
        <h3>{t('addMeterReadingsModal.confirmationModal.title')}</h3>
        <p className={styles.description}>
          {t(
            `addMeterReadingsModal.confirmationModal.description.${
              state.toLowerCase() as keyof typeof resources.common.addMeterReadingsModal.confirmationModal.description
            }`
          )}
        </p>
      </div>
      <div className={styles['actions-container']}>
        <Button variant="tertiary" size="small" onClick={() => setClose()}>
          {t('cancel')}
        </Button>
        <Button size="small" onClick={() => handleSubmit()}>
          {t('confirm')}
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
