import { Banner, Heading } from '@boltenergy-be/design-system'
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard'
import { useTranslation } from 'react-i18next'
import styles from './Details.module.scss'
import { AccountDetailsProps } from './types'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock'
import SubnavWithoutNavigation from 'components/Subnav/SubNavWithoutNavigation'
import EditingButton from 'components/EditingButton/EditingButton'
import { Account, AccountType } from 'types/sales'
import { useState } from 'react'
import EditableTextField from 'components/EditeableFormFields/EditableTextField'
import EditableSelectField from 'components/EditeableFormFields/EditableSelectField'
import { useUpdateAccountMutation } from 'store/queries/bolt-api'
import { FormProvider, useForm } from 'react-hook-form'
import Toggle from 'components/Toggle/Toggle'
import classNames from 'classnames'
import { LEGAL_FORM_TRANSLATIONS } from 'features/contracts/add/constants'
import { SalesforceLegalForm } from 'types/types'
import { useStoreSelector } from 'hooks/store'
import { popErrorToast, popSuccessToast } from 'utils/toast'
import { BELGIAN_ENTERPRISE_NUMBER } from 'constants/regex.ts'
import { removeSpacesAndDots } from 'utils/format.ts'
import { validateBelgianEnterpriseNumber } from 'utils/validation.ts'

const AccountDetails = ({
  account,
  changeActiveHousehold,
  tabs,
  activeEditing,
  setActiveEditing,
  contactId,
  identifier
}: AccountDetailsProps) => {
  // i18n
  const { t } = useTranslation(['sales', 'common', 'validation'])

  // Redux store
  const { language } = useStoreSelector((store) => store.app)

  // React Hook Form
  const hookForm = useForm<Partial<Account>>({
    defaultValues: { ...account }
  })
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields }
  } = hookForm

  // Local constants
  const isEditing = activeEditing === identifier

  // Local state
  const [nameWarning, setNameWarning] = useState(false)
  const [isCompany, setIsCompany] = useState(!!account.company)

  // Mutation
  const [updateAccount, { isLoading }] = useUpdateAccountMutation()

  /**
   * Updates the Account object in the API
   *
   * @param {Partial<Account>} data
   */
  const onSave = async (data: Partial<Account>) => {
    // Set the name warning if the first or last name have been updated
    setNameWarning(!!dirtyFields.firstName || !!dirtyFields.lastName)

    try {
      // Update the account
      const updatedAccount = await updateAccount({
        account: {
          ...data,
          ...(isCompany && data.company
            ? {
                company: {
                  ...data.company,
                  vatApplication: data.company.vatApplication === 'true'
                }
              }
            : {
                company: undefined
              })
        },
        accountId: account.salesforceId,
        contactId: contactId
      }).unwrap()

      // Check if the account was updated
      if (updatedAccount) {
        // Remove the editing state
        setActiveEditing(undefined)

        // Show success toast
        popSuccessToast(t('details.toast.success'))
      } else {
        popErrorToast(t('error', { ns: 'common' }))
      }
    } catch (error) {
      popErrorToast(t('error', { ns: 'common' }))
    }
  }

  return (
    <section>
      <header className={styles.heading}>
        <SubnavWithoutNavigation
          tabs={tabs}
          activeTabPath={account.salesforceId}
          setActiveTab={changeActiveHousehold}
          className={styles.subnav}
          noPushElement
        />
        <EditingButton
          isDisabled={account.type !== AccountType.PROSPECT || !!activeEditing}
          setIsEditing={setActiveEditing}
          onSubmit={handleSubmit(onSave)}
          {...{ identifier, isEditing, isLoading }}
        />
      </header>

      <CollapsibleCard
        titleSection={
          <Heading as="h4" className={styles.title}>
            {t('details.customer.title')}
          </Heading>
        }
        contentClassName={styles['details-block']}
      >
        {nameWarning && (
          <Banner
            type="warning"
            className={classNames(styles['warning-banner'], styles['full-width'])}
            title={t('details.customer.warning.title')}
          >
            {t('details.customer.warning.description')}
          </Banner>
        )}

        <FormProvider {...hookForm}>
          <form>
            <span className={styles['full-width']}>
              <DataBlock label={t('details.customer.customerType')} withMargin={false}>
                {isEditing ? (
                  <>
                    <Toggle
                      options={[
                        { label: t('details.customer.professional'), value: true },
                        { label: t('details.customer.residential'), value: false }
                      ]}
                      onClick={(value) => {
                        setIsCompany(value)
                      }}
                      active={isCompany}
                    />
                  </>
                ) : (
                  <small>{account.company ? t('details.customer.professional') : t('details.customer.residential')}</small>
                )}
              </DataBlock>
            </span>
            <DataBlock label={t('details.contactData.firstName')} withMargin={false}>
              <EditableTextField
                name="firstName"
                required
                value={account.firstName}
                {...{
                  isEditing,
                  register,
                  errors
                }}
              />
            </DataBlock>
            <DataBlock label={t('details.contactData.lastName')} withMargin={false}>
              <EditableTextField
                name="lastName"
                required
                value={account.lastName}
                {...{
                  isEditing,
                  register,
                  errors
                }}
              />
            </DataBlock>

            {isCompany && (
              <>
                <DataBlock label={t('details.customer.companyName')} withMargin={false}>
                  <EditableTextField
                    name="company.name"
                    required={isCompany}
                    value={account?.company?.name}
                    {...{ isEditing, register, errors }}
                  />
                </DataBlock>
                <DataBlock label={t('details.customer.form')} withMargin={false}>
                  <EditableSelectField
                    name="company.legalForm"
                    options={Object.keys(LEGAL_FORM_TRANSLATIONS)
                      .map((value) => ({
                        label: LEGAL_FORM_TRANSLATIONS[value as SalesforceLegalForm][language],
                        value
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    required={isCompany}
                    value={account?.company?.legalForm}
                    {...{ isEditing, register, errors }}
                  />
                </DataBlock>
                <DataBlock label={t('details.customer.enterpriseNumber')} withMargin={false}>
                  <EditableTextField
                    extraValidation={{
                      validate: (value: string | undefined) =>
                        value &&
                        ((BELGIAN_ENTERPRISE_NUMBER.test(removeSpacesAndDots(value)) && validateBelgianEnterpriseNumber(value)) ||
                          t('validation:invalid.enterpriseNumber'))
                    }}
                    masked
                    name="company.enterpriseNumber"
                    required={isCompany}
                    value={account?.company?.enterpriseNumber}
                    {...{ isEditing, register, errors }}
                  />
                </DataBlock>
                <DataBlock label={t('details.customer.requiresVat')} withMargin={false}>
                  <EditableSelectField
                    name="company.vatApplication"
                    options={[
                      { label: t('common:yes'), value: true },
                      { label: t('common:no'), value: false }
                    ]}
                    required={isCompany}
                    value={account?.company?.vatApplication}
                    {...{ isEditing, register, errors }}
                  />
                </DataBlock>
              </>
            )}
          </form>
        </FormProvider>
      </CollapsibleCard>
    </section>
  )
}

export default AccountDetails
