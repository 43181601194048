// Common
export enum CommonTrackingEvents {
  CLICK_MOVE_BANNER = 'click-move-banner'
}

export enum CommonTrackingParams {
  ROUTE = 'route'
}

// Consumption Events
export enum ConsumptionEvents {
  PLAN_CONSUMPTION_TOGGLED = 'consumption-plan_consumption_toggled',
  CONSUMPTION_NAVIGATION_ITEM_VIEWED = 'consumption-consumption_navigation_item_viewed',
  CONSUMPTION_FUEL_TYPE_TOGGLED = 'consumption-consumption_fuel_type_toggled'
}

export enum ConsumptionTrackingParams {
  VIEW = 'view',
  FUEL_TYPE = 'fuelType'
}

// Smart Meter Options
export enum SmartMeterOptionEvents {
  EDIT_OPTIONS_CONFIRMED = 'smart_meter-edit_options_confirmed',
  EDIT_OPTIONS_CONFIRMATION_OPENED = 'smart_meter-edit_options_confirmation_opened',
  EDIT_OPTIONS_CANCELED = 'smart_meter-edit_options_canceled',
  EDIT_OPTIONS_STARTED = 'smart_meter-edit_options_started',
  EDIT_OPTION_TOGGLED = 'smart_meter-edit_option_toggled'
}

export enum SmartMeterOptionTrackingParams {
  OPTION = 'option',
  NEW_VALUE = 'newValue',
  SAME_AS_ORIGINAL = 'sameAsOriginal'
}

// Contract flows
export enum ContractFlowTrackingTypes {
  TERMINATE = 'terminate',
  ADD = 'add'
}
