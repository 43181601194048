import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { deleteRedirect } from 'store/app/slice'
import { clearTokens } from 'utils/localStorageService'
import { routes } from 'types/routes'
import styles from './Tabbar.module.scss'
import TabbarItem from './TabbarItem'
import TabbarDropdownItem from './TabbarDropdownItem'
import { TabbarProps } from './types'
import { Account, Invoices, Overview, Producer, Support, Usage, Friends, Contract, Move } from 'assets/svg/sidebar'
import { getUserTypeLoginPage } from 'utils/user'
import { clearUser } from 'store/user/slice'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'

const Tabbar = ({ isSales }: TabbarProps) => {
  // Local state
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  // REDUX
  const { userType } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation()

  // Refs
  const dropdownRef = useRef<HTMLDivElement>(null)

  // React Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  /**
   * Hides dropdown on click outside
   */
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLDivElement)) {
        setDropdownIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    if (dropdownIsOpen) document.body.classList.add('no-scroll-mobile')

    return () => {
      document.removeEventListener('click', handleClickOutside, true)

      if (dropdownIsOpen) document.body.classList.remove('no-scroll-mobile')
    }
  }, [dropdownIsOpen])

  /**
   * Toggles no-scroll-mobile class on body
   */
  useEffect(() => {
    if (dropdownIsOpen) document.body.classList.add('no-scroll-mobile')

    return () => {
      if (dropdownIsOpen) document.body.classList.remove('no-scroll-mobile')
    }
  }, [dropdownIsOpen])

  /**
   * Handles the logout action
   */
  const logout = () => {
    setDropdownIsOpen(false)
    // remove redirect from AppStore if still set
    dispatch(deleteRedirect())
    clearTokens(userType)
    dispatch(clearUser())
    navigate(getUserTypeLoginPage(userType))
  }

  /**
   * Opens the 'more' dropdown
   */
  const handleDropdownOpen = () => {
    setDropdownIsOpen(true)
  }

  /**
   * Handles click on TabbarDropdownItem (navigation + dropdown and overlay closing)
   */
  const handleTabbarDropdownItemClick = (route: routes) => {
    navigate(route)
    setDropdownIsOpen(false)
  }

  return (
    <>
      <nav className={styles.tabbar}>
        {isSales ? (
          <>
            <TabbarItem
              icon="general"
              text={t('nav.overview')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.SALES_HOME)}
              onClick={() => navigate(routes.SALES_HOME)}
            />
            <TabbarItem
              icon="contract"
              text={t('nav.events')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.SALES_EVENTS)}
              onClick={() => navigate(routes.SALES_EVENTS)}
            />
            <TabbarItem
              icon="add"
              text={t('nav.address')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.SALES_CONTRACTS_ADD)}
              onClick={() => navigate(routes.SALES_CONTRACTS_ADD)}
            />
            <TabbarItem icon="logout" text={t('nav.logout')} onClick={() => logout()} />
          </>
        ) : (
          <>
            <TabbarItem
              icon={<Overview />}
              text={t('nav.overview')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.OVERVIEW)}
              onClick={() => navigate(routes.OVERVIEW_GENERAL)}
            />
            <TabbarItem
              icon={<Usage />}
              text={t('nav.usage')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.CONSUMPTION)}
              onClick={() => navigate(routes.CONSUMPTION_YOUR_CONSUMPTION)}
            />
            <TabbarItem
              icon={<Invoices />}
              text={t('nav.billing')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.BILLING)}
              onClick={() => navigate(routes.BILLING_INVOICES)}
            />
            <TabbarItem
              icon={<Producer />}
              text={t('nav.producer')}
              isSelected={!dropdownIsOpen && pathname.includes(routes.PRODUCER)}
              onClick={() => navigate(routes.PRODUCER)}
            />
            <TabbarItem icon="dots" text={t('nav.more')} isSelected={dropdownIsOpen} onClick={() => handleDropdownOpen()} />
          </>
        )}
      </nav>

      {/* DROPDOWN */}
      <CSSTransition in={dropdownIsOpen} timeout={300} classNames="fade-in-scale-up" unmountOnExit>
        <div ref={dropdownRef} className={styles['dropdown-container']}>
          <ul className={styles['dropdown-tabbar-top']}>
            <TabbarDropdownItem
              text={t('nav.friends')}
              icon={<Friends />}
              onClick={() => handleTabbarDropdownItemClick(routes.FRIENDS)}
              badge={t('action')}
            />
            <TabbarDropdownItem
              text={t('nav.contract')}
              icon={<Contract />}
              onClick={() => handleTabbarDropdownItemClick(routes.CONTRACT_MY_CONTRACT)}
            />
            <TabbarDropdownItem
              text={t('nav.move')}
              icon={<Move />}
              onClick={() => handleTabbarDropdownItemClick(routes.MOVE_INITIATION)}
            />
            <TabbarDropdownItem
              text={t('nav.support')}
              icon={<Support />}
              onClick={() => handleTabbarDropdownItemClick(routes.SUPPORT_FAQ)}
            />
            <TabbarDropdownItem icon={<Account />} text={t('nav.user')} onClick={() => navigate(routes.USER_PERSONAL)} />
          </ul>
          <ul>
            <TabbarDropdownItem text={t('nav.logout')} icon="logout" onClick={() => logout()} />
          </ul>
        </div>
      </CSSTransition>
      {/*
        Dropdown overlay is being used to disable hover state of all underlying elements when dropdown is open for UX purposes
      */}
      <CSSTransition in={dropdownIsOpen} timeout={300} classNames="fade-in" unmountOnExit>
        <div className="overlay" />
      </CSSTransition>
    </>
  )
}

export default Tabbar
