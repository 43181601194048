import { FC } from 'react'
import { SubnavProps as Props } from './types'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import styles from './Subnav.module.scss'
import parse from 'html-react-parser'

const Subnav: FC<Props> = ({ tabs, activeTab, className, noPushElement = false }) => {
  // Router
  const location = useLocation()

  /**
   * Handles the tab click event
   *
   * @param {number} index
   * @returns
   */
  const handleTabClick = (index: number) => {
    activeTab(index)
  }

  return (
    <>
      <div className={classNames(styles.subnav, className)}>
        {tabs.map((tab, index) => (
          <button
            key={`subnav-${index}`}
            className={classNames(styles['subnav-item'], {
              [styles.active]: location.pathname.includes(tabs[index].path)
            })}
            onClick={() => handleTabClick(index)}
          >
            {typeof tab.label === 'string' ? parse(tab.label) : tab.label}
          </button>
        ))}
      </div>
      {!noPushElement && <div className={styles.push} />}
    </>
  )
}

export default Subnav
