import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Email } from 'types/types'
import { Store } from 'store/types'
import request, { getAxiosRequestConfig } from 'utils/request'
import { Methods } from 'types/request'
import { useStoreSelector } from 'hooks/store'
import styles from './Contact.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import Card from 'components/Card/Card.tsx'
import { Banner } from '@boltenergy-be/design-system'

const Contact: FC = () => {
  // REDUX STORE
  const { info, selectedContracts } = useStoreSelector((store: Store) => store.user)

  // React Hooks
  const { t } = useTranslation('support')
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Email>()

  // Local state
  const [status, setStatus] = useState<'submitted' | 'failed' | 'sending' | null>(null)

  /**
   * Handles the on submit after valdiation
   *
   * @param data
   */
  const onSubmit = async (data: Email) => {
    setStatus('sending')
    const { subject, message } = data
    const from: string = info.email
    const { electricityContract } = getMemoizedSelectedContracts(selectedContracts)
    const deliveryAddress = electricityContract?.address

    try {
      const { success } = await request(
        getAxiosRequestConfig(Methods.POST, '/contact/support', { email: from, subject, message, deliveryAddress })
      )

      if (success) {
        setStatus('submitted')
      } else {
        setStatus('failed')
      }
    } catch (err) {
      setStatus('failed')
    }
  }

  return (
    <div className="container">
      <Card as="section">
        <Card.Title>{t('contact.mailUs')}</Card.Title>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className="form-group">
            <label htmlFor="subject">{t('contact.subject')}</label>
            <input className="form-control" maxLength={255} {...register('subject', { required: true })} />
            {errors.subject && errors.subject.type === 'required' && (
              <span className="help-block text-negative">{t('required', { ns: 'validation' })}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="message">{t('contact.message')}</label>
            <textarea id="message" cols={30} rows={10} className="form-control large" {...register('message', { required: true })} />
            {errors.subject && errors.subject.type === 'required' && (
              <span className="help-block text-negative">{t('required', { ns: 'validation' })}</span>
            )}
          </div>

          <Button disabled={!!status} loading={status === 'sending'}>
            {t('contact.send')}
          </Button>
        </form>

        {(status === 'submitted' || status === 'failed') && (
          <Banner type={status === 'failed' ? 'blocking' : 'positive'} className={styles.alert}>
            {status === 'submitted' && t('contact.messageSent')}
            {status === 'failed' && t('error', { ns: 'common' })}
          </Banner>
        )}
      </Card>
    </div>
  )
}

export default Contact
