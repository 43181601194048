import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons'
import { EMAIL, LETTERS_NUMBERS_SLASHES_DASHES } from 'constants/regex'
import { isValidPostalCode } from 'utils/addContract'
import { getTown } from 'api/addContract'
import { Towns } from 'api/types'
import { UserTypes } from 'store/auth/types'
import { AddContractStepProps, AddContractSteps, AddressFormInputs, SimulationType } from '../../types.ts'
import styles from '../../../Contracts.module.scss'
import { UrlSearchParamsKeys } from 'store/app/types'
import { getCustomersForSalesPartner } from 'store/user/thunks/sales'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { useGetSalesEventsQuery } from 'store/queries/bolt-api'
import Toggle from 'components/Toggle/Toggle'
import { ELProduct, NGProduct, Product, ProductConfigOption } from 'types/products'
import { getProductFromProductCode } from 'utils/products'
import { PRODUCTS_CONFIG } from 'constants/products'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import Card from 'components/Card/Card.tsx'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'

const AddressStep = ({
  setCurrentStep,
  setNextStep,
  addContractData,
  setAddContractData,
  handleEanLookup,
  isSales,
  isMove
}: AddContractStepProps) => {
  // Redux store
  const { userType } = useStoreSelector((store) => store.auth)
  const { urlSearchParams, language } = useStoreSelector((store) => store.app)
  const { sales } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Redux queries
  const {
    data: eventsData,
    isLoading,
    isError
  } = useGetSalesEventsQuery(
    { filterSignatures: true },
    {
      skip: userType !== UserTypes.SALES
    }
  )
  const { data: productsContent } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation('contracts')

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [towns, setTowns] = useState<Towns>([])

  // React Hook Form
  const hookForm = useForm<AddressFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      ...addContractData[AddContractSteps.ADDRESS],
      ...(urlSearchParams[UrlSearchParamsKeys.CUSTOMER_EMAIL] && {
        salesEmail: urlSearchParams[UrlSearchParamsKeys.CUSTOMER_EMAIL]
      })
    }
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger
  } = hookForm
  const watchTownName = watch('deliveryAddress.townName')
  const watchPostalCode = watch('deliveryAddress.postalCode')
  const watchNeedsGas = watch('needsGas')
  const watchEventId = watch('eventId')

  // Constants
  const event = watchEventId ? eventsData?.events?.find((event) => event._id === watchEventId) : undefined

  /**
   * update the instalmentData if the sales event has simulationType.BOLT_GO
   */
  useEffect(() => {
    if (
      isSales &&
      event?.parameters?.simulation_type === SimulationType.BOLT_GO &&
      addContractData[AddContractSteps.INSTALMENT_DATA].simulationType !== SimulationType.BOLT_GO
    ) {
      setAddContractData({
        ...addContractData,
        [AddContractSteps.INSTALMENT_DATA]: {
          ...addContractData[AddContractSteps.INSTALMENT_DATA],
          simulationType: SimulationType.BOLT_GO
        },
        [AddContractSteps.PRODUCT]: {
          electricity: ELProduct.Go,
          gas: addContractData[AddContractSteps.ADDRESS].needsGas ? NGProduct.Go : undefined
        }
      })
    }
  }, [event, addContractData, isSales, setAddContractData])

  /**
   * Triggered on first render
   */
  useEffect(() => {
    // Fetch partner customers if email is found in url search params store
    const customerEmail = urlSearchParams[UrlSearchParamsKeys.CUSTOMER_EMAIL]
    if (customerEmail) {
      getPartnerCustomers(customerEmail)
    }

    // Checks if town names should be fetched based on postalCode in store
    const postalCode = addContractData[AddContractSteps.ADDRESS].deliveryAddress.postalCode
    validatePostalCodeAndFetchTowns(typeof postalCode === 'string' ? parseInt(postalCode) : postalCode)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the submit after validation by React Hook Form
   *
   * @param {AddressFormInputs} data
   */
  const onSubmit = async (data: AddressFormInputs) => {
    // Perform EAN lookup
    if (handleEanLookup) handleEanLookup(data.deliveryAddress)

    // Fetch the event & add it to the data
    if (event) {
      data.event = event
    }

    setNextStep(data, isMove ? AddContractSteps.REFERRAL : AddContractSteps.PRODUCER)
  }

  /**
   * Fetches the town names based on the given postalCode
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns, region } = response
        setTowns(towns)

        if (!towns.some((town) => town.townName === watchTownName)) {
          setValue('deliveryAddress.townName', towns[0].townName)
          setValue('deliveryAddress.townCode', towns[0].townCode)
          setValue('region', region)
        }

        // Return error when Bolt Go is not available in the selected region
        if (
          isSales &&
          event?.parameters?.simulation_type === SimulationType.BOLT_GO &&
          !PRODUCTS_CONFIG[Product.GO][ProductConfigOption.AVAILABLE_IN_REGIONS].Push.includes(region)
        ) {
          return t('invalid.productNotAvailable', {
            ns: 'validation',
            product: productsContent?.[Product.GO] ? `Bolt ${productsContent[Product.GO].name}` : t('common:thisProduct', 'Dit product')
          })
        }

        return true
      }
    }

    return t('invalid.postalCode', { ns: 'validation' })
  }

  // Trigger postalCode validation on event change
  useEffect(() => {
    if (watchPostalCode && event) trigger('deliveryAddress.postalCode')
  }, [event, trigger])

  /**
   * Fetches customers for sales partners and adds email to CustomerData step
   * @param {string} email
   */
  const getPartnerCustomers = async (email: string) => {
    try {
      setLoading(true)
      if (sales && !errors.salesEmail) {
        await dispatch(getCustomersForSalesPartner({ email, partnerId: sales.partner.id }))
        setAddContractData({
          ...addContractData,
          [AddContractSteps.CUSTOMER_DATA]: {
            ...addContractData[AddContractSteps.CUSTOMER_DATA],
            email,
            emailConfirmation: email
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  /**
   * Handles the town select change & sets the town code
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = towns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('deliveryAddress.townCode', townCode)
    setValue('deliveryAddress.townName', townName)
  }

  return (
    <form className={styles['form-card']} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles['form-content']}>
        {isSales && !isLoading && !isError && (
          <>
            <div className="row">
              <div className="column form-group">
                <label htmlFor="email">{t('add.steps.customerData.fields.email')}</label>
                <input
                  id="email"
                  {...register('salesEmail', { required: isSales, pattern: EMAIL })}
                  onBlur={(e) => getPartnerCustomers(e.target.value)}
                  className={classNames('form-control', { error: errors?.salesEmail })}
                />
                {errors?.salesEmail && (
                  <span className="help-block text-negative">
                    {errors?.salesEmail?.type === 'required' && t('required', { ns: 'validation' })}
                    {errors?.salesEmail?.type === 'pattern' && t('invalid.email', { ns: 'validation' })}
                  </span>
                )}
              </div>

              <div className="column form-group">
                <label htmlFor="eventId">Sales event</label>
                <select
                  id="event"
                  className={classNames('form-control', { error: errors?.eventId })}
                  {...register('eventId', { required: true })}
                >
                  {eventsData?.events.map((event) => (
                    <option key={event._id} value={event._id}>
                      {event.eventId} | {event.eventName}
                    </option>
                  ))}
                </select>
                {errors?.eventId?.type === 'required' && (
                  <span className="help-block text-negative">{t('required', { ns: 'validation' })}</span>
                )}
              </div>
            </div>
          </>
        )}

        <fieldset>
          <Card.Title as="h1">{t('add.steps.address.title')}</Card.Title>

          <div className="row">
            {/* DELIVERY ADDRESS STREET */}
            <div className="column form-group">
              <label htmlFor="deliveryAddress.streetName">{t('add.steps.address.form.deliveryAddress.streetName')}</label>
              <input
                id="deliveryAddress.streetName"
                {...register('deliveryAddress.streetName', { required: true })}
                className={classNames('form-control', { error: errors?.deliveryAddress?.streetName })}
              />
              {errors?.deliveryAddress?.streetName && (
                <span className="help-block text-negative">
                  {errors.deliveryAddress.streetName.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>

            <div className="column">
              <div className="row">
                {/* DELIVERY ADDRESS STREET NUMBER */}
                <div className="column form-group">
                  <label htmlFor="deliveryAddress.streetNumber">{t('add.steps.address.form.deliveryAddress.streetNumber')}</label>
                  <input
                    id="deliveryAddress.streetNumber"
                    maxLength={5}
                    {...register('deliveryAddress.streetNumber', {
                      required: true,
                      maxLength: 5,
                      pattern: LETTERS_NUMBERS_SLASHES_DASHES
                    })}
                    className={classNames('form-control', { error: errors?.deliveryAddress?.streetNumber })}
                  />
                  {errors?.deliveryAddress?.streetNumber && (
                    <span className="help-block text-negative">
                      {errors.deliveryAddress?.streetNumber.type === 'required' && t('required', { ns: 'validation' })}
                      {(errors.deliveryAddress?.streetNumber.type === 'maxLength' ||
                        errors.deliveryAddress?.streetNumber.type === 'pattern') &&
                        t('invalid.streetNumber', { ns: 'validation' })}
                    </span>
                  )}
                </div>

                {/* DELIVERY ADDRESS STREET BOX */}
                <div className="column form-group">
                  <label htmlFor="deliveryAddress.streetBox">{t('add.steps.address.form.deliveryAddress.streetBox')}</label>
                  <input
                    maxLength={5}
                    id="deliveryAddress.streetBox"
                    {...register('deliveryAddress.streetBox')}
                    className={classNames('form-control', { error: errors?.deliveryAddress?.streetBox })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* DELIVERY ADDRESS POSTAL CODE */}
            <div className="column form-group">
              <label htmlFor="deliveryAddress.postalCode">{t('add.steps.address.form.deliveryAddress.postalCode')}</label>
              <input
                id="deliveryAddress.postalCode"
                {...register('deliveryAddress.postalCode', {
                  required: true,
                  validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
                })}
                onChange={(e) => {
                  const value = e.target.value
                  setValue('deliveryAddress.postalCode', value)
                  validatePostalCodeAndFetchTowns(parseInt(value))
                }}
                inputMode="numeric"
                className={classNames('form-control', { error: errors?.deliveryAddress?.postalCode })}
              />
              {errors?.deliveryAddress?.postalCode && (
                <span className="help-block text-negative">
                  {errors.deliveryAddress.postalCode.message ||
                    (errors.deliveryAddress?.postalCode.type === 'required' && t('required', { ns: 'validation' }))}
                </span>
              )}
            </div>

            {/* DELIVERY ADDRESS TOWN NAME */}
            <div className="column form-group">
              <label htmlFor="deliveryAddress.townName">{t('add.steps.address.form.deliveryAddress.townName')}</label>
              <select
                id="deliveryAddress.townName"
                className={classNames('form-control', { error: errors?.deliveryAddress?.townName })}
                {...register('deliveryAddress.townName', { required: true })}
                onChange={(e) => handleTownSelectChange(e.target.value)}
                value={watchTownName}
              >
                <option disabled>
                  {watch('deliveryAddress.postalCode') === ''
                    ? t('add.steps.address.form.deliveryAddress.townNamePlaceholder')
                    : t('defaultPlaceholders.select', { ns: 'common' })}
                </option>
                {towns.map((town) => {
                  return (
                    <option key={`town.geoCode-${town.townCode}`} value={town.townName}>
                      {town.townName}
                    </option>
                  )
                })}
              </select>
              {errors?.deliveryAddress?.townName && (
                <span className="help-block text-negative">
                  {errors.deliveryAddress?.townName.type === 'required' && t('required', { ns: 'validation' })}
                </span>
              )}
            </div>
          </div>
        </fieldset>

        <div className="toggle-container">
          <strong>{t('add.steps.address.form.fuelType.title')}</strong>
          <Toggle
            active={watchNeedsGas}
            options={[
              { label: t('add.steps.address.form.fuelType.labels.onlyElec'), value: false },
              { label: t('add.steps.address.form.fuelType.labels.elecAndGas'), value: true }
            ]}
            onClick={(needsGas) => {
              setValue('needsGas', needsGas)
              const product = getProductFromProductCode(addContractData[AddContractSteps.PRODUCT].electricity)
              setAddContractData({
                ...addContractData,
                [AddContractSteps.PRODUCT]: {
                  ...addContractData[AddContractSteps.PRODUCT],
                  gas: needsGas && product ? NGProduct[product] : undefined
                }
              })
            }}
          />
        </div>
      </div>

      <FormButtons
        loading={loading}
        currentStep={AddContractSteps.ADDRESS}
        setCurrentStep={(step) => setCurrentStep(step as AddContractSteps)}
        trackingId={ContractFlowTrackingTypes.ADD}
      />
    </form>
  )
}

export default AddressStep
