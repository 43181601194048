import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PhoneFormFieldProps } from 'components/FormFields/PhoneFormField/types.ts'
import { PhoneField } from '@boltenergy-be/design-system'
import styles from './PhoneFormField.module.scss'

const PhoneFormField = ({
  control,
  errors,
  hookFormSelector,
  isRequired,
  isDisabled,
  label,
  setValue,
  defaultValue
}: PhoneFormFieldProps) => {
  // i18n
  const { t } = useTranslation()

  // Constants
  const key = hookFormSelector ?? 'mobilePhone'

  return (
    <div className={styles['temp-ds-override']}>
      <Controller
        name={key}
        {...{ control, defaultValue }}
        rules={{ required: isRequired ? t('required', { ns: 'validation' }) : false }}
        render={({ field: { name, onChange, value } }) => (
          <PhoneField
            name={key}
            onChange={(value) => {
              setValue(name, value)
              onChange(value)
            }}
            error={errors?.[key]?.message as string | undefined}
            disabled={isDisabled}
            {...{ label, value }}
          />
        )}
      />
    </div>
  )
}

export default PhoneFormField
