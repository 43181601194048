import { ToggleButtonProps } from 'components/FormFields/ToggleSwitch/types'
import styles from './ToggleSwitch.module.scss'
import classNames from 'classnames'

export const ToggleSwitch = ({ checked, onChange, label, ...rest }: ToggleButtonProps) => (
  <label className={styles['toggle-switch']}>
    <input type="checkbox" checked={checked} onChange={onChange} {...rest} />
    <span className={classNames(styles.slider, { [styles.checked]: checked })} />
  </label>
)
