import { createContext, PropsWithChildren, useMemo, useState } from 'react'
import {
  EnergyTransitionDocumentContextValues,
  EnergyTransitionDocumentType
} from 'store/react-context/energy-transition-document/types.ts'

// --> Important note
// We normally do not use react-context, but in this case we have to use it as
// Redux does not support non-serializable state to be stored (such as FileList)
// We need to store the uploaded energy transition documents because appears during move & terminate flow (--> better UX)

export const EnergyTransitionDocumentContext = createContext<EnergyTransitionDocumentContextValues | undefined>(undefined)

const EnergyTransitionDocumentContextProvider = ({ children }: PropsWithChildren) => {
  const [energyTransitionDocument, setEnergyTransitionDocument] = useState<EnergyTransitionDocumentType>(null)

  const energyTransitionDocumentContextValue = useMemo(
    () => ({
      energyTransitionDocument,
      setEnergyTransitionDocument
    }),
    [energyTransitionDocument, setEnergyTransitionDocument]
  )

  return (
    <EnergyTransitionDocumentContext.Provider value={energyTransitionDocumentContextValue}>
      {children}
    </EnergyTransitionDocumentContext.Provider>
  )
}

export default EnergyTransitionDocumentContextProvider
