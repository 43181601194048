import { SvgProps } from 'assets/svg/types'

const Charts = ({ iconColor = 'currentColor' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2944 5.60785C13.9299 5.60785 12.8238 6.71397 12.8238 8.07844V23.6079C12.8238 23.7277 12.8323 23.8455 12.8488 23.9608H12.0929C12.1093 23.8455 12.1179 23.7277 12.1179 23.6079V16.5491C12.1179 15.1846 11.0118 14.0785 9.64728 14.0785H8.23552C6.87105 14.0785 5.76493 15.1846 5.76493 16.5491V23.6079C5.76493 23.7277 5.77346 23.8455 5.78995 23.9608H4.00023C3.41546 23.9608 2.94141 24.4348 2.94141 25.0196C2.94141 25.6044 3.41546 26.0784 4.00023 26.0784H8.22318C8.22729 26.0785 8.23141 26.0785 8.23552 26.0785H9.64729C9.6514 26.0785 9.6555 26.0785 9.65961 26.0784L15.2944 26.0784H16.7061L28.0002 26.0784C28.585 26.0784 29.0591 25.6044 29.0591 25.0196C29.0591 24.4348 28.585 23.9608 28.0002 23.9608H26.2105C26.227 23.8455 26.2355 23.7277 26.2355 23.6078V13.7255C26.2355 12.361 25.1294 11.2549 23.7649 11.2549H22.3532C20.9887 11.2549 19.8826 12.361 19.8826 13.7255V23.6078C19.8826 23.7277 19.8911 23.8455 19.9076 23.9608H19.1517C19.1682 23.8455 19.1767 23.7277 19.1767 23.6079V8.07844C19.1767 6.71397 18.0706 5.60785 16.7061 5.60785H15.2944Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default Charts
