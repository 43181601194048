import { FC, ReactElement, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale } from 'react-datepicker'
import classNames from 'classnames'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import nl from 'date-fns/locale/nl-BE'
import fr from 'date-fns/locale/fr'
import { Store } from 'store/types'
import { Language } from 'store/app/types'
import { Electricity, Gas, Injection, Night, Sun } from 'assets/svg'
import { DATE_FORMAT_REACT_DATEPICKER } from 'constants/constants'
import Modal from 'components/Modal/Modal'
import Icon from 'components/Icon/Icon'
import { Button } from '@boltenergy-be/design-system'
import {
  AddMeterReadingsModalContentProps,
  AddMeterReadingsModalEntryPoint,
  AddMeterReadingsModalFormInputs,
  AddMeterReadingsModalProps,
  MeterReadingInputLayoutContract
} from './types'
import styles from './AddMeterReadingsModal.module.scss'
import { getDigitalMeterRegisterName, getMemoizedSelectedContracts, isActiveContract } from 'utils/contracts'
import { formatAddress } from 'utils/user'
import { Direction, ProductType } from 'types/types'
import {
  determineElMeterReadingInputLayoutContract,
  exceedsCap,
  generateCreateMeterReadingRequest,
  getMultiFieldErrors,
  hasLowerValue
} from './utils'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { MeterIndexType, MeterReading, MeterReadingSource, MeterReadingsPayload } from 'types/contracts'
import { saveMeterReadings } from 'api/contracts'
import { ConfirmationModalProps } from '../ConfirmationModal/types'
import resources from 'translations/dutch'
import { updateMeterReadings } from 'store/contracts/slice'
import MoveBanner from 'components/MoveBanner/MoveBanner.tsx'

const AddMeterReadingsModalContent: FC<AddMeterReadingsModalContentProps> = ({
  entryPoint,
  setClose,
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  onSuccess
}) => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)
  const { selectedContracts } = useSelector((store: Store) => store.user)
  const {
    meterReadings: { data: meterReadingsData }
  } = useSelector((store: Store) => store.contracts)
  const dispatch = useDispatch()

  // Local state
  const [showInfo, setShowInfo] = useState<boolean>(entryPoint !== AddMeterReadingsModalEntryPoint.MeterReadingsPage)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [meterReadingsPayload, setMeterReadingsPayload] = useState<MeterReadingsPayload>()
  const [confirmationState, setConfirmationState] = useState<ConfirmationModalProps['state']>('LOW')

  // i18n
  const { t } = useTranslation()

  // DayJS
  const now = dayjs()

  // React Hook Form
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setValue
  } = useForm<AddMeterReadingsModalFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      date: now.toDate()
    }
  })
  const elekConsumptionTotal = watch('electricity.consumption.total')
  const elekConsumptionDay = watch('electricity.consumption.day')
  const elekConsumptionNight = watch('electricity.consumption.night')
  const elekConsumptionExclNight = watch('electricity.consumption.exclNight')

  // Contracts
  const { electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Memos
  const noElekInput = useMemo<boolean>(
    () => [elekConsumptionTotal, elekConsumptionDay, elekConsumptionNight, elekConsumptionExclNight].every((value) => !value),
    [elekConsumptionDay, elekConsumptionExclNight, elekConsumptionNight, elekConsumptionTotal]
  )
  const { allElOptional, electricity: electricityInputLayoutContract } = useMemo<MeterReadingInputLayoutContract>(
    () => determineElMeterReadingInputLayoutContract(electricityContract, meterReadingsData),
    [meterReadingsData, electricityContract]
  )

  // Constants
  const hasEffectiveGasContract = gasContract && isActiveContract(gasContract)

  // Locale for React Datepicker
  registerLocale(language, language === Language.NL ? nl.nlBE : fr.fr)

  /**
   * Handles the billshock call after confirmation
   *
   * @param {MeterReadingsPayload} meterReadingsPayload
   */
  const handleConfirm = async (meterReadingsPayload: MeterReadingsPayload) => {
    setLoading(true)

    const [electricitySuccess, gasSuccess]: boolean[] = await Promise.all([
      saveMeterReadings(generateCreateMeterReadingRequest(meterReadingsPayload, electricityContract)),
      ...(hasEffectiveGasContract ? [saveMeterReadings(generateCreateMeterReadingRequest(meterReadingsPayload, gasContract, true))] : [])
    ])
    if (electricitySuccess && (!hasEffectiveGasContract || gasSuccess)) {
      // Call success callback function
      if (onSuccess) onSuccess()

      // Close modal
      setClose()

      if (
        meterReadingsData?.electricity?.date &&
        dayjs(meterReadingsPayload?.date).isSameOrAfter(dayjs(meterReadingsData.electricity.date))
      ) {
        // Save new meter readings to store
        const newMeterReadings = {
          ...(meterReadingsPayload.electricity && {
            electricity: {
              ean: meterReadingsPayload.electricity.ean,
              date: meterReadingsPayload.date,
              source: MeterReadingSource.CLIENT_PROVIDED,
              consumption: meterReadingsPayload.electricity.consumption,
              injection: meterReadingsPayload.electricity.injection
            }
          }),
          ...(meterReadingsPayload.gas && {
            gas: {
              ean: meterReadingsPayload.gas.ean,
              date: meterReadingsPayload.date,
              source: MeterReadingSource.CLIENT_PROVIDED,
              consumption: meterReadingsPayload.gas?.consumption
            }
          })
        }

        dispatch(updateMeterReadings(newMeterReadings))
      }
    } else {
      setLoading(false)
      setError(true)
    }
  }

  /**
   * Handles the form submit after validation by React Hook Form
   *
   * @param {AddMeterReadingsModalFormInputs} data
   */
  const onSubmit = (data: AddMeterReadingsModalFormInputs) => {
    const meterReadingsPayload = {
      date: data.date.toISOString(),
      electricity: {
        ean: electricityContract.ean,
        consumption: {
          singleRate: data.electricity?.consumption?.total ?? undefined,
          exclNight: data.electricity?.consumption?.exclNight ?? undefined,
          ...((typeof data.electricity?.consumption?.day !== 'undefined' || typeof data.electricity.consumption?.night !== 'undefined') && {
            doubleRate: {
              day: data.electricity?.consumption?.day ?? undefined,
              night: data.electricity?.consumption?.night ?? undefined
            }
          })
        } as MeterReading,
        ...((typeof data.electricity.injection?.total !== 'undefined' ||
          typeof data.electricity.injection?.day !== 'undefined' ||
          typeof data.electricity.injection?.night !== 'undefined') && {
          injection: {
            singleRate: data.electricity.injection?.total ?? undefined,
            doubleRate: {
              day: data.electricity.injection?.day ?? undefined,
              night: data.electricity.injection?.night ?? undefined
            }
          }
        })
      },
      ...(hasEffectiveGasContract && {
        gas: {
          ean: gasContract.ean,
          consumption: {
            singleRate: data.gas?.total ?? undefined
          }
        }
      })
    }
    setMeterReadingsPayload(meterReadingsPayload)

    const hasLower = hasLowerValue(meterReadingsPayload, meterReadingsData)
    const hasHigher = exceedsCap(meterReadingsPayload, meterReadingsData)

    // Check if at least one value is lower than previous entry to show confirmation modal
    if (meterReadingsData && (hasLower || hasHigher)) {
      setIsConfirmationModalOpen(true)
      setConfirmationState(hasHigher && hasLower ? 'BOTH' : hasLower ? 'LOW' : 'HIGH')
    } else {
      // Proceed without confirmation
      handleConfirm(meterReadingsPayload)
    }
  }

  /**
   * Returns the correct meter reading entry based on the given product type & meter index type
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {MeterIndexType} meterIndexType
   * @returns {number|undefined}
   */
  const getMeterReadingEntry = (type: ProductType, direction: Direction, meterIndexType: MeterIndexType): number | undefined => {
    if (type === ProductType.GAS) return meterReadingsData?.gas?.consumption?.singleRate

    switch (meterIndexType) {
      case MeterIndexType.DAY:
        return meterReadingsData?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.day

      case MeterIndexType.NIGHT:
        return meterReadingsData?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.doubleRate?.night

      case MeterIndexType.EXCL_NIGHT:
        return meterReadingsData?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.exclNight

      default:
        return meterReadingsData?.electricity?.[direction === Direction.CONSUMPTION ? 'consumption' : 'injection']?.singleRate
    }
  }

  /**
   * Returns the register form row for the given type & meterIndexType
   *
   * @param {ProductType} type
   * @param {Direction} direction
   * @param {MeterIndexType} meterIndexType
   * @returns {ReactElement}
   */
  const getInputRow = (type: ProductType, direction: Direction, meterIndexType: MeterIndexType): ReactElement => {
    const isGas = type === ProductType.GAS
    const directionType = direction === Direction.CONSUMPTION ? 'consumption' : 'injection'
    const hasElectricityInputError = errors?.electricity?.[directionType]?.[meterIndexType]
    const meterReadingEntry = getMeterReadingEntry(type, direction, meterIndexType)
    const key = (isGas ? 'gas.total' : `electricity.${directionType}.${meterIndexType}`) as any

    return (
      <div className={classNames(styles.row, styles['table-row'])}>
        <div className={styles.column}>
          <div className={styles.meter}>
            <div
              className={classNames(
                styles['icon-container'],
                { [styles.gas]: isGas },
                { [styles.injection]: direction === Direction.PRODUCTION }
              )}
            >
              {isGas ? (
                <Gas isFilled iconColor="currentColor" />
              ) : direction === Direction.CONSUMPTION ? (
                <Electricity isFilled iconColor="currentColor" />
              ) : (
                <Injection isFilled iconColor="currentColor" />
              )}
            </div>
            <div className={styles['info-container']}>
              <div className={styles['meter-name']}>
                {isGas ? (
                  /* Gas */
                  t('addMeterReadingsModal.form.table.meters.gas.total')
                ) : (
                  /* Electricity */
                  <>
                    {t(`addMeterReadingsModal.form.table.meters.electricity.${directionType}.${meterIndexType}`, '')}
                    {electricityContract.digitalMeter && getDigitalMeterRegisterName(direction, meterIndexType)}
                    <span>
                      {meterIndexType === MeterIndexType.NIGHT || meterIndexType === MeterIndexType.EXCL_NIGHT ? (
                        <Night iconColor="currentColor" />
                      ) : (
                        <Sun iconColor="currentColor" />
                      )}
                    </span>
                  </>
                )}
              </div>
              <div className={styles.caption}>
                {t('ean')}: {isGas ? gasContract?.ean : electricityContract.ean}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.column}>
          {typeof meterReadingEntry !== 'undefined' ? (
            <>
              <div className={styles['meter-reading']}>
                {meterReadingEntry?.toFixed(0)} <small>{isGas ? parse('m<sup>3</sup>') : 'kWh'}</small>
              </div>
              <div className={classNames(styles.caption, styles['meter-reading-caption'])}>
                {parse(
                  t(
                    `addMeterReadingsModal.form.table.meterReadingCaption.${
                      meterReadingsData?.[type]?.source === MeterReadingSource.CLIENT_PROVIDED ? 'self' : 'networkOperator'
                    }`,
                    {
                      date: dayjs(meterReadingsData?.[type]?.date).format('DD/MM/YYYY')
                    }
                  )
                )}
              </div>
            </>
          ) : (
            <div className={styles['no-meter-reading']}>{t('addMeterReadingsModal.form.table.meterReadingCaption.noMeterReading')}</div>
          )}
        </div>
        <div className={classNames('form-group', styles.column)}>
          <div className={styles['meter-input']}>
            <input
              type="number"
              step="1"
              {...register(key, {
                valueAsNumber: true,
                required: !allElOptional || isGas ? true : noElekInput,
                min: 1,
                validate: {
                  isDecimal: (value: number) => value?.toString()?.indexOf('.') === -1 && value?.toString()?.indexOf(',') === -1
                }
              })}
              className={classNames('form-control', { error: isGas ? errors?.gas?.total : hasElectricityInputError })}
              onChange={(e) => {
                setValue(key, Number(e.target.value))
              }}
              onWheel={(e) => e.currentTarget.blur()}
            />
            <div className={classNames(styles.postfix, { [styles.error]: isGas ? errors?.gas?.total : hasElectricityInputError })}>
              ,000
            </div>
          </div>
          <div className={styles['meter-input-caption-mobile']}>
            {t('lastKnownMeterReading')}: <span>{meterReadingEntry}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles['modal-top']}>
        <header className={styles.header}>
          <div className={styles['title-container']}>
            <h3>{t('addMeterReadingsModal.title')}</h3>
            <div className={styles['title-caption']}>{formatAddress(electricityContract.address)}</div>
          </div>
          <button className={styles['button-close']} onClick={() => setClose()}>
            <Icon name="close" color="currentColor" />
          </button>
        </header>

        <section className={styles.body}>
          {!showInfo ? (
            <>
              <MoveBanner className="mb-500" />

              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.row}>
                  <div className={classNames(styles.column, styles['date-container'])}>
                    <label htmlFor="date">{t('addMeterReadingsModal.form.date.label')}</label>
                    <span className={styles.caption}>{t('addMeterReadingsModal.form.date.caption')}</span>
                  </div>

                  <div className={classNames('form-group', styles.column, styles['date-input-container'])}>
                    <div className="date-picker-container">
                      <div className="date-picker-element">
                        <Controller
                          name="date"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <DatePicker
                                locale={language}
                                maxDate={now.toDate()}
                                selected={dayjs(value).toDate()}
                                disabledKeyboardNavigation
                                dateFormat={DATE_FORMAT_REACT_DATEPICKER}
                                className="form-control"
                                calendarClassName="date-picker-calendar"
                                onChange={onChange}
                                calendarStartDay={1}
                              />
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.caption}>{t('addMeterReadingsModal.form.date.caption')}</div>
                  </div>
                </div>

                <div className={styles.table}>
                  <div className={classNames(styles.row, styles['heading-row'])}>
                    <div className={styles.column}>
                      <div className={styles.heading}>{t('addMeterReadingsModal.form.table.heading.meterReading')}</div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.heading}>{t('lastKnownMeterReading')}</div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.heading}>{t('addMeterReadingsModal.form.table.heading.addMeterReading')}</div>
                    </div>
                  </div>
                  <div className={styles['heading-row-mobile']}>{t('addMeterReadingsModal.form.table.heading.meterReadings')}</div>

                  {/* CONSUMPTION ELECTRICITY INPUTS */}
                  {electricityInputLayoutContract?.consumption?.total &&
                    getInputRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, MeterIndexType.TOTAL)}
                  {electricityInputLayoutContract?.consumption?.day &&
                    getInputRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, MeterIndexType.DAY)}
                  {electricityInputLayoutContract?.consumption?.night &&
                    getInputRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, MeterIndexType.NIGHT)}
                  {electricityInputLayoutContract?.consumption?.exclNight &&
                    getInputRow(ProductType.ELECTRICITY, Direction.CONSUMPTION, MeterIndexType.EXCL_NIGHT)}

                  {/* INJECTION ELECTRICITY INPUTS */}
                  {electricityInputLayoutContract.injection?.total &&
                    getInputRow(ProductType.ELECTRICITY, Direction.PRODUCTION, MeterIndexType.TOTAL)}
                  {electricityInputLayoutContract.injection?.day &&
                    getInputRow(ProductType.ELECTRICITY, Direction.PRODUCTION, MeterIndexType.DAY)}
                  {electricityInputLayoutContract.injection?.night &&
                    getInputRow(ProductType.ELECTRICITY, Direction.PRODUCTION, MeterIndexType.NIGHT)}

                  {/* GAS INPUTS */}
                  {hasEffectiveGasContract && getInputRow(ProductType.GAS, Direction.CONSUMPTION, MeterIndexType.TOTAL)}
                </div>

                {Object.keys(errors).length !== 0 && (
                  <div className={classNames('help-block text-negative', styles.errors)}>
                    {Object.keys(getMultiFieldErrors(errors)).map((errorType) => (
                      <span key={errorType}>
                        {allElOptional && errorType === 'required'
                          ? t(
                              `addMeterReadingsModal.form.validation.${gasContract?.id ? 'atleastOneRequiredAndGas' : 'atleastOneRequired'}`
                            )
                          : t(
                              `addMeterReadingsModal.form.validation.${
                                errorType as keyof typeof resources.common.addMeterReadingsModal.form.validation
                              }`,
                              t('addMeterReadingsModal.form.validation.fallback')
                            )}
                      </span>
                    ))}
                  </div>
                )}
                {error && (
                  <div className={classNames('help-block text-negative', styles.errors)}>
                    <span>{t('formFeedback.error')}</span>
                  </div>
                )}
              </form>
            </>
          ) : (
            <>
              <div className={styles['info-content']}>
                {parse(t(`addMeterReadingsModal.infoMessage.${entryPoint as 'billingCyclesPage' | 'consumptionPage'}.text`))}
              </div>

              <Button variant="secondary" className="mt-500" onClick={() => setShowInfo(false)}>
                {t('addMeterReadingsModal.infoMessage.button')}
              </Button>
            </>
          )}
        </section>
      </div>

      {!showInfo && (
        <footer className={styles.footer}>
          <div className={styles['footer-actions']}>
            <Button variant="tertiary" onClick={() => setClose()}>
              {t('cancel')}
            </Button>
            <Button loading={loading} onClick={handleSubmit(onSubmit)}>
              {t('confirm')}
            </Button>
          </div>
        </footer>
      )}

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        state={confirmationState}
        setClose={() => setIsConfirmationModalOpen(false)}
        onSubmit={() => meterReadingsPayload && handleConfirm(meterReadingsPayload)}
      />
    </>
  )
}

const AddMeterReadingsModal: FC<AddMeterReadingsModalProps> = ({ isOpen, setClose, ...props }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  return (
    <Modal isOpen={isOpen} setClose={setClose} withCloseButton={false} className={styles.container} stopEvents={isConfirmationModalOpen}>
      <AddMeterReadingsModalContent
        setClose={setClose}
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        {...props}
      />
    </Modal>
  )
}

export default AddMeterReadingsModal
