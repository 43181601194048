import { FC, useEffect } from 'react'
import styles from './OverviewModal.module.scss'
import { Close } from 'assets/svg'
import { OverviewModalProps as Props } from 'components/EnergyOverview/OverviewModal/types'

const OverviewModal: FC<Props> = ({ isOpen, closeFn, children }) => {
  /**
   * Stop scroll on body if modal is open
   */
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isOpen])

  return isOpen ? (
    <dialog className={styles.modal} open={isOpen}>
      <form method="dialog">
        <button aria-label="close" className={styles.close} onClick={closeFn}>
          <Close className={styles.icon} />
        </button>
      </form>

      <div className={styles.content}>{children}</div>
    </dialog>
  ) : (
    <></>
  )
}

export default OverviewModal
