import i18next from 'i18next'
import { HelpModalFAQ } from 'components/HelpModal/types'
import ImageCurrentBillingCycleNlDesktop from 'assets/images/billing-cycles/help/current-billing-cycle-nl-desktop.png'
import ImageCurrentBillingCycleFrDesktop from 'assets/images/billing-cycles/help/current-billing-cycle-fr-desktop.png'
import ImageDeterminationNlDesktop from 'assets/images/billing-cycles/help/determination-nl-desktop.png'
import ImageDeterminationFrDesktop from 'assets/images/billing-cycles/help/determination-fr-desktop.png'
import ImageConsumptionNlDesktop from 'assets/images/billing-cycles/help/consumption-nl-desktop.png'
import ImageConsumptionFrDesktop from 'assets/images/billing-cycles/help/consumption-fr-desktop.png'
import ImageCalculationSettlementNlDesktop from 'assets/images/billing-cycles/help/calculation-settlement-nl-desktop.png'
import ImageCalculationSettlementFrDesktop from 'assets/images/billing-cycles/help/calculation-settlement-fr-desktop.png'
import ImageCalculationInstalmentNlDesktop from 'assets/images/billing-cycles/help/calculation-instalment-nl-desktop.png'
import ImageCalculationInstalmentFrDesktop from 'assets/images/billing-cycles/help/calculation-instalment-fr-desktop.png'
import ImageMeterReadingsNlDesktop from 'assets/images/billing-cycles/help/meter-readings-nl-desktop.png'
import ImageMeterReadingsFrDesktop from 'assets/images/billing-cycles/help/meter-readings-fr-desktop.png'
import ImagePreviousBillingCyclesNlDesktop from 'assets/images/billing-cycles/help/previous/overview-collapsed-nl-desktop.png'
import ImagePreviousBillingCyclesNlMobile from 'assets/images/billing-cycles/help/previous/overview-collapsed-nl-mobile.png'
import ImagePreviousBillingCyclesFrDesktop from 'assets/images/billing-cycles/help/previous/overview-collapsed-fr-desktop.png'
import ImagePreviousBillingCyclesFrMobile from 'assets/images/billing-cycles/help/previous/overview-collapsed-fr-mobile.png'
import ImagePreviousPeriodNlDesktop from 'assets/images/billing-cycles/help/previous/cycle-period-nl-desktop.png'
import ImagePreviousPeriodNlMobile from 'assets/images/billing-cycles/help/previous/cycle-period-nl-mobile.png'
import ImagePreviousPeriodFrDesktop from 'assets/images/billing-cycles/help/previous/cycle-period-fr-desktop.png'
import ImagePreviousPeriodFrMobile from 'assets/images/billing-cycles/help/previous/cycle-period-fr-mobile.png'
import ImagePreviousUsageNlDesktop from 'assets/images/billing-cycles/help/previous/cycle-usage-nl-desktop.png'
import ImagePreviousUsageNlMobile from 'assets/images/billing-cycles/help/previous/cycle-usage-nl-mobile.png'
import ImagePreviousUsageFrDesktop from 'assets/images/billing-cycles/help/previous/cycle-usage-fr-desktop.png'
import ImagePreviousUsageFrMobile from 'assets/images/billing-cycles/help/previous/cycle-usage-fr-mobile.png'
import ImagePreviousChartNlDesktop from 'assets/images/billing-cycles/help/previous/chart-nl-desktop.png'
import ImagePreviousChartNlMobile from 'assets/images/billing-cycles/help/previous/chart-nl-mobile.png'
import ImagePreviousChartFrDesktop from 'assets/images/billing-cycles/help/previous/chart-fr-desktop.png'
import ImagePreviousChartFrMobile from 'assets/images/billing-cycles/help/previous/chart-fr-mobile.png'
import ImagePreviousCalculationsNlDesktop from 'assets/images/billing-cycles/help/previous/calculation-settlement-nl-desktop.png'
import ImagePreviousCalculationsNlMobile from 'assets/images/billing-cycles/help/previous/calculation-settlement-nl-mobile.png'
import ImagePreviousCalculationsFrDesktop from 'assets/images/billing-cycles/help/previous/calculation-settlement-fr-desktop.png'
import ImagePreviousCalculationsFrMobile from 'assets/images/billing-cycles/help/previous/calculation-settlement-fr-mobile.png'

export const EXTRA_CONSUMPTION_LIMIT_EL = 100_000
export const EXTRA_CONSUMPTION_LIMIT_GAS = 10_000

export const NEVER_SHOW_INSTALMENT_INFO_MODAL = 'neverShowInstalmentInfoModal'

export const GET_HELP_FAQ_CURRENT_BILLING_CYCLE = () => [
  {
    question: i18next.t('billingCycles.current.helpModal.faq.currentBillingCycle.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.current.helpModal.faq.currentBillingCycle.answer', { ns: 'billing' }),
    image: ImageCurrentBillingCycleNlDesktop,
    altImgFrDesktop: ImageCurrentBillingCycleFrDesktop
  },
  {
    question: i18next.t('billingCycles.current.helpModal.faq.determination.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.current.helpModal.faq.determination.answer', { ns: 'billing' }),
    image: ImageDeterminationNlDesktop,
    altImgFrDesktop: ImageDeterminationFrDesktop
  },
  {
    question: i18next.t('billingCycles.current.helpModal.faq.consumption.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.current.helpModal.faq.consumption.answer', { ns: 'billing' }),
    image: ImageConsumptionNlDesktop,
    altImgFrDesktop: ImageConsumptionFrDesktop
  },
  {
    question: i18next.t('billingCycles.current.helpModal.faq.calculationSettlement.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.current.helpModal.faq.calculationSettlement.answer', { ns: 'billing' }),
    answerFix: i18next.t('billingCycles.current.helpModal.faq.calculationSettlement.answerFix', { ns: 'billing' }),
    image: ImageCalculationSettlementNlDesktop,
    altImgFrDesktop: ImageCalculationSettlementFrDesktop
  },
  {
    question: i18next.t('billingCycles.current.helpModal.faq.calculationInstalment.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.current.helpModal.faq.calculationInstalment.answer', { ns: 'billing' }),
    image: ImageCalculationInstalmentNlDesktop,
    altImgFrDesktop: ImageCalculationInstalmentFrDesktop
  },
  {
    question: i18next.t('billingCycles.current.helpModal.faq.meterReadings.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.current.helpModal.faq.meterReadings.answer', { ns: 'billing' }),
    image: ImageMeterReadingsNlDesktop,
    altImgFrDesktop: ImageMeterReadingsFrDesktop
  }
]
export const GET_HELP_FAQ_PREVIOUS_BILLING_CYCLES = (): HelpModalFAQ[] => [
  {
    question: i18next.t('billingCycles.previous.helpModal.faq.previousBillingCycles.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.previous.helpModal.faq.previousBillingCycles.answer', { ns: 'billing' }),
    image: ImagePreviousBillingCyclesNlDesktop,
    altImgNlMobile: ImagePreviousBillingCyclesNlMobile,
    altImgFrDesktop: ImagePreviousBillingCyclesFrDesktop,
    altImgFrMobile: ImagePreviousBillingCyclesFrMobile
  },
  {
    question: i18next.t('billingCycles.previous.helpModal.faq.periods.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.previous.helpModal.faq.periods.answer', { ns: 'billing' }),
    image: ImagePreviousPeriodNlDesktop,
    altImgNlMobile: ImagePreviousPeriodNlMobile,
    altImgFrDesktop: ImagePreviousPeriodFrDesktop,
    altImgFrMobile: ImagePreviousPeriodFrMobile
  },
  {
    question: i18next.t('billingCycles.previous.helpModal.faq.consumption.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.previous.helpModal.faq.consumption.answer', { ns: 'billing' }),
    image: ImagePreviousUsageNlDesktop,
    altImgNlMobile: ImagePreviousUsageNlMobile,
    altImgFrDesktop: ImagePreviousUsageFrDesktop,
    altImgFrMobile: ImagePreviousUsageFrMobile
  },
  {
    question: i18next.t('billingCycles.previous.helpModal.faq.billedInvoices.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.previous.helpModal.faq.billedInvoices.answer', { ns: 'billing' }),
    image: ImagePreviousChartNlDesktop,
    altImgNlMobile: ImagePreviousChartNlMobile,
    altImgFrDesktop: ImagePreviousChartFrDesktop,
    altImgFrMobile: ImagePreviousChartFrMobile
  },
  {
    question: i18next.t('billingCycles.previous.helpModal.faq.totalCost.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.previous.helpModal.faq.totalCost.answer', { ns: 'billing' }),
    answerFix: i18next.t('billingCycles.previous.helpModal.faq.totalCost.answerFix', { ns: 'billing' }),
    image: ImagePreviousChartNlDesktop,
    altImgNlMobile: ImagePreviousChartNlMobile,
    altImgFrDesktop: ImagePreviousChartFrDesktop,
    altImgFrMobile: ImagePreviousChartFrMobile
  },
  {
    question: i18next.t('billingCycles.previous.helpModal.faq.settlement.question', { ns: 'billing' }),
    answer: i18next.t('billingCycles.previous.helpModal.faq.settlement.answer', { ns: 'billing' }),
    image: ImagePreviousCalculationsNlDesktop,
    altImgNlMobile: ImagePreviousCalculationsNlMobile,
    altImgFrDesktop: ImagePreviousCalculationsFrDesktop,
    altImgFrMobile: ImagePreviousCalculationsFrMobile
  }
]
