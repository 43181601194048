import { AuthErrorCodes } from 'types/errorCodes'

export type AuthStore = {
  loading: boolean
  error: AuthErrorCodes | string | null
  userType: UserTypes | null
  email: string | null
}

export enum UserTypes {
  CUSTOMER = 'customer',
  SALES = 'sales',
  SUPER_USER = 'superuser'
}
