import styles from 'pages/App/Contract/ContractTab/components/SmartMeterOption/SmartMeterOption.module.scss'
import { Heading } from '@boltenergy-be/design-system'
import Icon from 'components/Icon/Icon.tsx'
import Badge from 'components/Badge/Badge.tsx'
import { BadgeColors } from 'components/Badge/types.ts'
import { useTranslation } from 'react-i18next'
import { SmartMeterOptionProps } from 'pages/App/Contract/ContractTab/components/SmartMeterOption/types.ts'
import classNames from 'classnames'
import { ToggleSwitch } from 'components/FormFields/ToggleSwitch/ToggleSwitch.tsx'

const SmartMeterOption = ({ option, icon, isActive, readMoreUrl, editable, onChange, title }: SmartMeterOptionProps) => {
  // i18n
  const { t } = useTranslation('contract')

  return (
    <li className={classNames(styles.item, styles[option])}>
      {icon}

      <div className={styles.content}>
        <Heading as="h3" variant="h5">
          {title}
        </Heading>
        <p>{t(`myContract.smartMeterOptions.${option}.description`)}</p>
        {readMoreUrl && (
          <a href={readMoreUrl} target="_blank" rel="noreferrer noopener" className={styles['more-info']}>
            {t('myContract.smartMeterOptions.actions.readMore')} <Icon name="openInNew" />
          </a>
        )}
      </div>

      <div className={styles.status}>
        {editable ? (
          <div className={styles['switch-group']}>
            <span>{isActive ? t('myContract.smartMeterOptions.status.active') : t('myContract.smartMeterOptions.status.inactive')}</span>
            <ToggleSwitch checked={isActive} onChange={(event) => onChange(event.target.checked)} />
          </div>
        ) : (
          <Badge
            text={t(`myContract.smartMeterOptions.status.${isActive ? 'active' : 'inactive'}`)}
            color={isActive ? BadgeColors.GREEN : BadgeColors.GREY}
          />
        )}
      </div>
    </li>
  )
}

export default SmartMeterOption
