import { KnownDowntimePeriod } from 'types/downtime.ts'

export const KNOWN_DOWNTIME_PERIODS: KnownDowntimePeriod[] = [
  // Example period
  // {
  //   start: new Date('2024-04-03T00:00:00Z'),
  //   end: new Date('2024-04-04T00:00:00Z'),
  //   userTypes: [UserTypes.CUSTOMER],
  //   customMessage: {
  //     [Language.NL]: 'message',
  //     [Language.FR]: 'message'
  //   }
  // }
]
