import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods, Response } from 'types/request'
import type { GetCustomersForSalesPartnerPayload } from './types'
import { GetUserDataSuccessPayload } from './types'
import type { GetUserDataResponseData } from 'types/user'
import { GetSalesPartnerByIdResponse, GetSalesUserDataResponse } from 'types/sales'
import { createCookie } from 'utils/cookies'
import { Language } from 'store/app/types'
import { RootState } from 'store'
import { switchLanguage } from 'store/app/thunks'
import Bugsnag from '@bugsnag/js'

export const getCustomersForSalesPartner = createAsyncThunk<
  GetUserDataResponseData,
  GetCustomersForSalesPartnerPayload,
  { rejectValue: string }
>('users/sales/getCustomersForSalesPartner', async ({ email, partnerId }, thunkAPI) => {
  try {
    const { success, data, message }: Response<GetUserDataResponseData> = await newRequest(
      getAxiosRequestConfig(Methods.GET, `/partners/${partnerId}/customers?customerEmail=${encodeURIComponent(email)}`)
    )

    if (!success) {
      return thunkAPI.rejectWithValue(message ?? 'getCustomersForSalesPartnerError')
    }

    return data as GetUserDataResponseData
  } catch (err) {
    const { message } = err as Error
    return thunkAPI.rejectWithValue(message)
  }
})

export const getSalesUserData = createAsyncThunk<GetUserDataSuccessPayload, undefined, { rejectValue: string }>(
  'users/sales/getSalesUserData',
  async (_, thunkAPI) => {
    try {
      const {
        success: userSuccess,
        data: userData,
        message: userMessage
      } = await newRequest<GetSalesUserDataResponse>(getAxiosRequestConfig(Methods.GET, '/sales/users/me'))
      if (!userSuccess || !userData) {
        return thunkAPI.rejectWithValue(userMessage ?? 'error')
      }
      const { user }: GetSalesUserDataResponse = userData

      const {
        success: partnerSuccess,
        data: partnerData,
        message: partnerMessage
      }: Response<GetSalesPartnerByIdResponse> = await newRequest(getAxiosRequestConfig(Methods.GET, `/partners/${user.partnerId}`))
      if (!partnerSuccess || !partnerData?.partner) {
        return thunkAPI.rejectWithValue(partnerMessage ?? 'getPartnerByIdError')
      }
      const { partner } = partnerData

      // Set language cookie
      createCookie('language', user.language, 365)

      // Fetch current language and change it if it doesn't match the user language
      const lang: Language = (thunkAPI.getState() as RootState).app.language
      if (lang !== user.language) thunkAPI.dispatch(switchLanguage({ language: user.language }))

      // Add user data to Bugsnag
      Bugsnag.addMetadata('salesUser', {
        email: user.email
      })

      return {
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          language: user.language,
          phone: ''
        },
        customers: {},
        producers: [],
        sales: {
          _id: user._id,
          agentId: user.agentId,
          activated: user.activated,
          partner
        }
      } as GetUserDataSuccessPayload
    } catch (err) {
      const { message } = err as Error
      return thunkAPI.rejectWithValue(message)
    }
  }
)
