import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Night: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M10.023 3.766c.293.305.438.77.307 1.233a6.427 6.427 0 0 0-.078 3.167c.791 3.498 4.294 5.695 7.832 4.894a6.61 6.61 0 0 0 1.798-.693 1.282 1.282 0 0 1 1.269.003c.38.22.667.664.579 1.207a9.452 9.452 0 0 1-7.258 7.696C9.387 22.424 4.33 19.267 3.184 14.209 2.165 9.709 4.587 5.236 8.711 3.49a1.169 1.169 0 0 1 1.312.275ZM8.847 5.008c-3.241 1.6-5.087 5.238-4.262 8.883.968 4.275 5.249 6.96 9.57 5.98a8.02 8.02 0 0 0 6.068-6.06 8.065 8.065 0 0 1-1.822.65c-4.301.974-8.58-1.696-9.55-5.977a7.865 7.865 0 0 1-.004-3.476Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Night.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Night
