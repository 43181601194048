import { ContractsStore } from 'store/contracts/types'

export const initialContractsState: ContractsStore = {
  billShock: {
    initialLoaded: false,
    loading: false
  },
  previousBillingCycles: {
    loadingPeriods: false,
    loadingDataIds: [],
    data: {},
    erroredIds: []
  },
  meterReadings: {
    loading: false
  }
}
