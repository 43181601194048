import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './EditableInstalmentField.module.scss'
import instalmentStepStyles from '../../steps/Instalment/InstalmentStep.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { formatAmount } from 'utils/format'
import { EditableInstalmentFieldProps as Props } from './types'

const EditableInstalmentField: FC<Props> = ({
  activeStateOnly,
  isError,
  value,
  setValue,
  recalculateTotalInstalment,
  name,
  label,
  setEditingCheck,
  notEditable
}) => {
  // Local state
  const [isEditing, setIsEditing] = useState<boolean>(activeStateOnly)

  // i18n
  const { t } = useTranslation()

  // Updates the parents editing check when the editing state changes
  useEffect(() => {
    if (!activeStateOnly) {
      setEditingCheck(isEditing)
    }
  }, [activeStateOnly, isEditing, setEditingCheck])

  return (
    <div className={styles['fuel-container']}>
      <label
        htmlFor={name}
        className={classNames('mnone', {
          [styles.error]: isError
        })}
      >
        {label}
      </label>
      <div className={styles['value-container']}>
        {isEditing ? (
          <div className="form-group">
            <div className={classNames(instalmentStepStyles['input-wrapper'], 'prepend-input-wrapper')}>
              <span className="prepend">€</span>
              <input
                type="number"
                step="1"
                className="form-control"
                value={value}
                onChange={(e) => {
                  setValue(Number(e.target.value))
                }}
                onBlur={() => {
                  if (activeStateOnly) recalculateTotalInstalment()
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    recalculateTotalInstalment()
                    if (!activeStateOnly) setIsEditing(false)
                  }
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            {!activeStateOnly && (
              <Button
                className={styles['button-edit-confirm']}
                representation="link"
                onClick={() => {
                  recalculateTotalInstalment()
                  if (!activeStateOnly) setIsEditing(false)
                }}
              >
                {t('confirm')}
              </Button>
            )}
          </div>
        ) : (
          <>
            <div className={styles.value}>€ {formatAmount(value)}</div>
            {!notEditable && (
              <Button className={styles['button-edit-confirm']} representation="link" onClick={() => setIsEditing(true)}>
                {t('edit')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default EditableInstalmentField
