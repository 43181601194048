import { FC } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router'
import Subnav from 'components/Subnav/Subnav'
import { Tab } from 'components/Subnav/types'
import { routes } from 'types/routes'
import { useTranslation } from 'react-i18next'
import SeoTags from 'components/SeoTags/SeoTags'

const User: FC = () => {
  // React router
  const navigate = useNavigate()
  const location = useLocation()
  const handleTabClick = (idx: number) => navigate(tabs[idx].path)

  // i18n
  const { t } = useTranslation('user')

  const tabs: Tab[] = [
    { label: t('tabTitles.personal'), path: routes.USER_PERSONAL },
    { label: t('tabTitles.contact'), path: routes.USER_CONTACT },
    { label: t('tabTitles.payment'), path: routes.USER_PAYMENT }
  ]

  /**
   * get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (location.pathname) {
      case routes.USER_PERSONAL:
        return `${t('nav.user', { ns: 'common' })} - ${t('tabTitles.personal')}`
      case routes.USER_PAYMENT:
        return `${t('nav.user', { ns: 'common' })} - ${t('tabTitles.payment')}`
      default:
        return t('nav.user', { ns: 'common' })
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <Subnav tabs={tabs} activeTab={handleTabClick} />

      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default User
