import { FC } from 'react'
import parse from 'html-react-parser'
import TooltipOnClick from 'components/Tooltip/TooltipOnClick'
import { IconBadgeProps as Props } from './types'
import styles from './IconBadge.module.scss'

const IconBadge: FC<Props> = ({ label, icon, tooltipContent }) => {
  return (
    <TooltipOnClick
      tooltipContent={tooltipContent}
      classNameTrigger={styles['badge-container']}
      classNameTriggerTooltipActive={styles['tooltip-is-active']}
    >
      <div className={styles['icon-container']}>{icon}</div>
      <span className={styles.label}>{parse(label)}</span>
    </TooltipOnClick>
  )
}

export default IconBadge
