import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const VegetarianMeal: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M18.226 2.498a.75.75 0 0 1 .978.41l.235.575.369-.322a.75.75 0 0 1 .987 1.13l-1.13.986-.008.007a2.838 2.838 0 0 1 1.306 3.43l-.895 2.536h.666a.75.75 0 0 1 .747.816c-.475 5.364-4.533 9.684-9.614 9.684-5.082 0-9.14-4.32-9.614-9.684A.75.75 0 0 1 3 11.25h.302c-.088-.702.097-1.434.543-2.08.202-.29.444-.546.713-.764a1.987 1.987 0 0 1 .339-1.51c.256-.371.609-.648.997-.83.073-.312.21-.617.405-.9.852-1.232 2.61-1.73 3.983-1.13.352.155.656.373.897.638.404-.037.825.018 1.216.19.515.225.897.622 1.08 1.103.273.05.543.13.803.245.455.199.894.476 1.264.816l.072-.203a2.838 2.838 0 0 1 2.802-1.89.758.758 0 0 1-.033-.07l-.567-1.39a.75.75 0 0 1 .41-.977ZM7.532 6.02c.445-.642 1.432-.923 2.148-.61.258.114.42.277.508.443l.292.546.591-.182c.26-.08.522-.067.722.02.238.104.305.268.302.399l-.017.748.748.02c.302.007.59.068.85.182.565.247 1.01.628 1.229 1.006.018.032.035.064.05.096l-.905 2.562h-2.542l-.222-.555 2.05-1.024a.75.75 0 1 0-.671-1.342l-1.938.969-1.03-2.577a.75.75 0 1 0-1.393.557l.483 1.209-.532-.192a.75.75 0 1 0-.51 1.41l1.788.647.36.898H4.828v-.002c-.102-.355-.05-.79.25-1.224a2.31 2.31 0 0 1 .765-.685l.716-.406-.47-.676c-.079-.112-.11-.29.04-.508a1.06 1.06 0 0 1 .625-.402l.577-.139-.002-.593c0-.176.055-.382.202-.595Zm-3.544 6.73.032.034-.004-.034h15.866c-.73 4.33-4.098 7.5-8.015 7.5-3.918 0-7.286-3.17-8.015-7.5h.136Zm14.691-1.5 1.05-2.971a1.528 1.528 0 1 0-2.88-1.018l-1.41 3.989h3.24Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

VegetarianMeal.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default VegetarianMeal
