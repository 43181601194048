import { Heading } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import styles from './PlanConsumptionSection.module.scss'
import Card from 'components/Card/Card.tsx'
import PlanConsumptionContent from './PlanConsumptionContent.tsx'

const PlanConsumptionSection = () => {
  // i18n
  const { t } = useTranslation('consumption')

  return (
    <section className={styles['off-peak-section']}>
      <Heading as="h1" variant="h5">
        {t('planConsumption.title')}
      </Heading>

      <Card className={styles.card}>
        <PlanConsumptionContent />
      </Card>
    </section>
  )
}

export default PlanConsumptionSection
