import { TableProps } from './types'
import styles from './Table.module.scss'
import classNames from 'classnames'
import TableHead from 'components/Table/TableHead.tsx'

export const Table = ({ headers, rows, className, onSort, sorting }: TableProps) =>
  !!headers?.length && !!rows?.length ? (
    <table className={classNames(styles.table, className)}>
      <thead>
        <tr>
          {headers.map((header, index: number) => (
            <TableHead key={index} {...{ header, onSort, sorting }} />
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rowIndex: number) => (
          <tr key={rowIndex}>
            {row.cells.map((cell, cellIndex) => (
              <td key={cellIndex} className={cell.extraClasses}>
                {cell.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : null
