import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from 'store/user/slice'
import appReducer from 'store/app/slice'
import authReducer from 'store/auth/slice'
import contractsReducer from 'store/contracts/slice'
import { boltApi } from 'store/queries/bolt-api'
import { cmsApi } from 'store/queries/cms-api'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer({ key: 'auth', storage: storageSession }, authReducer),
  contracts: contractsReducer,
  user: userReducer,
  [boltApi.reducerPath]: boltApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the redux-persist actions: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([boltApi.middleware, cmsApi.middleware])
})

// Infer the `RootState` and `StoreDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type StoreDispatch = typeof store.dispatch
export type RootStore = typeof store
// Persistor for the PersistGate
export const persistor = persistStore(store)
