import { FC, useEffect, useRef } from 'react'
import styles from './SettlementPeriodIndicator.module.scss'
import { useTranslation } from 'react-i18next'
import { SettlementPeriodIndicatorProps as Props } from './types'

const SettlementPeriodIndicator: FC<Props> = ({ columnWidth, chartYAxisWidth, columns, negativeMarginLeftValue }) => {
  // i18n
  const { t } = useTranslation('billing')

  // reference
  const periodsWrapperRef = useRef<HTMLDivElement>(null)

  // Set the styling of the period indicator wrapper
  useEffect(() => {
    if (columnWidth && chartYAxisWidth && periodsWrapperRef?.current) {
      periodsWrapperRef.current.style.width = `${columnWidth * columns}px`
      periodsWrapperRef.current.style.marginLeft = `${chartYAxisWidth - (negativeMarginLeftValue || 0)}px`
    }
  }, [chartYAxisWidth, columnWidth, columns, negativeMarginLeftValue])

  return (
    <div className={styles['period-indicator']} ref={periodsWrapperRef}>
      <div className={styles.line} />
      <span>{t('billingCycles.chart.legend.settlementPeriod')}</span>
      <div className={styles.line} />
    </div>
  )
}

export default SettlementPeriodIndicator
