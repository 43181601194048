import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from './Home.module.scss'
import { Store } from 'store/types'
import { ButtonLoadingIndicator, Heading } from '@boltenergy-be/design-system'
import ReportingStatsCard from 'components/ReportingStatsCard/ReportingStatsCard'
import CasesCard from 'components/CasesCard/CasesCard'
import OpportunitiesCard from 'components/OpportunitiesCard/OpportunitiesCard'

const Home = () => {
  const user = useSelector((store: Store) => store.user)

  // i18n
  const { t } = useTranslation(['home', 'common'])

  return (
    <div className="content">
      {user.loading || user.userLoading ? (
        <ButtonLoadingIndicator />
      ) : (
        <div className={classNames('container', styles.wrapper)}>
          <Heading as="h1" variant="h3" className={styles['section-head']}>
            {t('app.welcome')}, {user.info.firstName}!
          </Heading>
          <OpportunitiesCard />
          <CasesCard />
          <ReportingStatsCard />
        </div>
      )}
    </div>
  )
}

export default Home
