import { useState } from 'react'
import styles from 'pages/App/Consumption/EnergyOverview/EnergyOverview.module.scss'
import classNames from 'classnames'
import OverviewModal from 'components/EnergyOverview/OverviewModal/OverviewModal'
import parse from 'html-react-parser'
import { MobileSectionProps } from 'components/EnergyOverview/MobileSection/types'
import Card from 'components/Card/Card.tsx'
import { Button } from '@boltenergy-be/design-system'

const MobileSection = ({ button, children, color, fact, title }: MobileSectionProps) => {
  // Local state
  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <>
      <Card as="section" className={styles['slider-card']}>
        <Card.Title variant="h6">{title}</Card.Title>
        <div className={classNames(styles['mobile-content'], styles[color || 'green'])}>
          <p>{parse(fact)}</p>
          <Button variant={button.color} onClick={() => setOpenModal(true)}>
            {button.text}
          </Button>
        </div>
      </Card>

      <OverviewModal isOpen={openModal} closeFn={() => setOpenModal(false)}>
        {children}
      </OverviewModal>
    </>
  )
}

export default MobileSection
