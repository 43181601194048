import { TextLinkProps } from 'components/Link/types.ts'
import Link from 'components/Link/Link.tsx'
import styles from 'pages/App/Contract/ContractTab/components/OutlinedLinkButton/OutlinedLinkButton.module.scss'
import classNames from 'classnames'

const OutlinedLinkButton = ({ children, className, ...props }: TextLinkProps) => {
  return (
    <Link representation="button" className={classNames(className, styles['outlined-link-button'])} {...props}>
      {children}
    </Link>
  )
}

export default OutlinedLinkButton
