import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from 'components/Charts/Charts.module.scss'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js'
import { ChartLabels } from 'components/Charts/types'
import { BillingCycleChartProps } from './types'
import { calculateChartDimensions } from 'utils/chartjs'
import { formatAmount } from 'utils/format'
import { getBillingCycleChartOptions } from './chartjs.config'
import { useTranslation } from 'react-i18next'
import ChartLegend from 'components/Charts/ChartLegend/ChartLegend'
import SettlementPeriodIndicator from 'components/Charts/SettlementPeriodIndicator/SettlementPeriodIndicator'
import { useStoreSelector } from 'hooks/store.ts'

const PreviousBillingCycleChartDesktop = ({
  data,
  chartData,
  minMaxData,
  labels,
  settlementColor,
  alternatingBackground
}: BillingCycleChartProps) => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation()

  // Local state
  const [columnWidth, setColumnWidth] = useState<number>(0)
  const [chartYAxisWidth, setChartYAxisWidth] = useState<number>(0)
  const [settlementOuterEdge, setSettlementOuterEdge] = useState<number>(0)

  // Reference
  const chartRef = useRef<never>(null)
  const activeTooltipRef = useRef<HTMLDivElement>(null)

  /**
   * sets the local state sizes for custom styling
   * @param {ChartJS} chart
   * @param {ChartLabels} labels
   */
  const handleResizeChart = useCallback(
    (chart: ChartJS, labels: ChartLabels) => {
      if (chart) {
        const { settlementOuterEdge: outerEdge, chartWidth, chartYAxisWidth: YAxisWidth } = calculateChartDimensions(chart, data.settlement)

        setSettlementOuterEdge(outerEdge)
        setColumnWidth(chartWidth / labels.length)
        setChartYAxisWidth(YAxisWidth)
      }
    },
    [data.settlement]
  )

  const options = useMemo<any>(
    () => getBillingCycleChartOptions(labels, alternatingBackground, handleResizeChart, language, minMaxData),
    [alternatingBackground, handleResizeChart, labels, minMaxData]
  )

  // Set the state dimensions
  useEffect(() => {
    if (chartRef?.current) {
      handleResizeChart(chartRef.current, labels)
    }
  }, [chartRef, handleResizeChart, labels])

  // Set the styling of the active tooltip
  useEffect(() => {
    if (columnWidth && settlementOuterEdge && activeTooltipRef?.current) {
      activeTooltipRef.current.style.bottom = `${settlementOuterEdge}px`
      activeTooltipRef.current.style.right = `${columnWidth / 2 + 22}px`
    }
  }, [columnWidth, settlementOuterEdge])

  return (
    <>
      <div className={styles['chart-wrapper']}>
        {/* LEGEND */}
        <ChartLegend {...{ chartData }} />

        <div className={styles['chart-area-wrapper']}>
          <Bar options={options} data={chartData} className={styles.chart} ref={chartRef} />

          {/* ACTIVE TOOLTIP */}
          <div className={styles['always-active-tooltip']} ref={activeTooltipRef}>
            <span>{t('billingCycles.chart.tooltips.settlement', { ns: 'billing' })}</span>
            <div className={styles['tooltip-description']}>
              <div className={styles['colored-box']} style={{ backgroundColor: settlementColor }} />
              <strong>€ {formatAmount(data.settlement)}</strong>
            </div>
          </div>
        </div>
      </div>

      {/* CURRENT PERIOD INDICATOR */}
      <SettlementPeriodIndicator columns={labels.length - 1} {...{ columnWidth, chartYAxisWidth }} />
    </>
  )
}

export default PreviousBillingCycleChartDesktop
