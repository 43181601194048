import { RawDataType, Row, SortingDirection } from 'components/Table/types.ts'
import { CellType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'

/**
 * Returns a paginated an array
 * @param {any[]} arr
 * @param {number }size
 */
export const paginate = (arr: any[], size: number) => {
  return arr.reduce((acc, val, i) => {
    const idx = Math.floor(i / size)
    const page = acc[idx] || (acc[idx] = [])
    page.push(val)
    return acc
  }, [])
}

/**
 * Returns the rawData from a given table cell.
 *
 * @param {RawDataType | undefined} rawData
 * @param {string} key
 */
const getCellRawData = (rawData: RawDataType | undefined, key: string) =>
  typeof rawData === 'string'
    ? rawData.toUpperCase()
    : typeof rawData === 'object' && Object.prototype.hasOwnProperty.call(rawData, key)
      ? rawData[key]
      : (rawData ?? '')

/**
 * Sorts a Rows[] by one given key, in a 'asc' or 'desc' order.
 * @param {string} key
 * @param {SortingDirection} order
 */
export const sortRowsByKey =
  (key: string, order: SortingDirection = SortingDirection.ASC) =>
  (rowA: Row, rowB: Row) => {
    const cellId = key === CellType.DESCRIPTION ? CellType.ADDRESS : key
    let comparison = 0
    const cellsA = rowA.cells.find((cell) => cell?.id === cellId)
    const cellsB = rowB.cells.find((cell) => cell?.id === cellId)

    if (!cellsA || !cellsB) return comparison

    const rawDataA = getCellRawData(cellsA.rawData, key)
    const rawDataB = getCellRawData(cellsB.rawData, key)

    if (rawDataA > rawDataB) {
      comparison = 1
    } else if (rawDataA < rawDataB) {
      comparison = -1
    }

    return order === SortingDirection.DESC ? comparison * -1 : comparison
  }
