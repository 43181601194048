import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Tab } from 'components/Subnav/types.ts'
import { routes } from 'types/routes.ts'
import Subnav from 'components/Subnav/Subnav.tsx'
import SeoTags from 'components/SeoTags/SeoTags.tsx'

const Move = () => {
  // i18n
  const { t } = useTranslation('move')

  // React Router
  const navigate = useNavigate()
  const location = useLocation()
  const handleTabClick = (idx: number) => navigate(tabs[idx].path)

  // The overview tabs
  const tabs: Tab[] = [
    { label: t('initiation.tab'), path: routes.MOVE_INITIATION },
    { label: t('yourAddresses.tab'), path: routes.MOVE_ADDRESSES }
  ]

  /**
   * get page title
   * @returns {string}
   */
  const getPageTitle = (): string => {
    switch (location.pathname) {
      case routes.MOVE_INITIATION:
        return `${t('nav.contract', { ns: 'common' })} - ${t('initiation.tab')}`
      case routes.MOVE_ADDRESSES:
        return `${t('nav.contract', { ns: 'common' })} - ${t('yourAddresses.tab')}`
      default:
        return t('nav.contract', { ns: 'common' })
    }
  }

  return (
    <>
      <SeoTags title={getPageTitle()} />

      <Subnav tabs={tabs} activeTab={handleTabClick} />

      <div className="content">
        <Outlet />
      </div>
    </>
  )
}

export default Move
