export type EnergyOverviewSlideProps = {
  className?: string
  color?: string
  data: string
  description: string
  label: string
  metric?: string
  centerData?: boolean
}

export enum BrandColors {
  GREEN = '#23D5AE',
  YELLOW = '#F6DF48',
  PINK = '#FF95F3',
  BLUE = '#69BBFF',
  PURPLE = '#746DEF',
  ORANGE = '#FF9100'
}
