const Usage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.47 4.206c-1.023 0-1.852.83-1.852 1.853v11.647c0 .09.006.178.018.265H9.07a1.87 1.87 0 0 0 .02-.265v-5.294c0-1.024-.83-1.853-1.854-1.853H6.176c-1.023 0-1.852.83-1.852 1.853v5.294c0 .09.006.178.018.265H3a.794.794 0 1 0 0 1.588h18a.794.794 0 1 0 0-1.588h-1.342a1.88 1.88 0 0 0 .018-.265v-7.412c0-1.023-.83-1.853-1.852-1.853h-1.06c-1.023 0-1.852.83-1.852 1.853v7.412c0 .09.006.178.018.265h-.566c.012-.087.018-.175.018-.265V6.059c0-1.024-.83-1.853-1.853-1.853h-1.058Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Usage
