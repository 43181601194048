import { ContractsIconProps } from 'assets/svg/contracts/types.ts'

const Add = ({ accent = 'currentColor' }: ContractsIconProps) => {
  // Constants
  const isCurrentColor = accent === 'currentColor'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6845 10.0962C29.5063 10.6441 30 11.5665 30 12.5542L30 23.8363C30 25.5498 28.6109 26.9389 26.8974 26.9389H11.1026C9.38907 26.9389 8 25.5498 8 23.8363V12.5542C8 11.5665 8.49365 10.6441 9.31552 10.0962L17.279 4.78719C18.3212 4.09242 19.6788 4.09242 20.721 4.78719L28.6845 10.0962Z"
        fill={isCurrentColor ? accent : `var(${accent})`}
        fillOpacity={isCurrentColor ? '0.1' : '1'}
      />
      <path
        d="M1 20H16M16 20L11.9444 16M16 20L11.9444 24"
        stroke={isCurrentColor ? accent : 'var(--text-default)'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Add
