import pattern from 'patternomaly'
import { Chart as ChartJS, ScriptableContext, ScriptableScaleContext } from 'chart.js'
import i18next from 'i18next'
import { CoreAnnotationOptions } from 'chartjs-plugin-annotation'
import { formatAmount } from 'utils/format'
import { createChartDataArray, formatChartDateLabel } from 'utils/chartjs'
import { CHART_COLORS } from 'components/Charts/constants'
import { ChartLabels, MinMaxData } from 'components/Charts/types'
import { externalTooltipHandler } from 'components/Charts/chartjs.config'
import { BillShock } from 'types/billShock'
import { CycleViews } from 'pages/App/Billing/BillingCycles/types'
import { getSettlementColor } from '../utils'
import { Granularity } from 'types/contracts.ts'
import { Language } from 'store/app/types.ts'

export const getBillshockChartOptions = (
  labels: ChartLabels,
  alternatingBackground: CoreAnnotationOptions,
  activeIndex: number,
  handleResize: (chart: ChartJS, labels: ChartLabels) => void,
  language: Language,
  minMax?: MinMaxData,
  isMobile?: boolean
) => ({
  responsive: true,
  aspectRatio: isMobile ? 1 : 2,
  onResize: (chart: ChartJS) => {
    if (chart?.boxes?.length) {
      handleResize(chart, labels)
    }
  },
  categoryPercentage: 0.75,
  barPercentage: isMobile ? 0.8 : 1,
  scales: {
    y: {
      suggestedMin: minMax?.minDataValue,
      suggestedMax: minMax?.maxDataValue,
      ticks: {
        font: {
          family: "'Commuters Sans', sans-serif",
          size: 10
        },
        color: CHART_COLORS.textSubdued,
        padding: 5,
        callback: (value: number) => '€ ' + formatAmount(value)
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
        color: (context: ScriptableScaleContext) => (context.tick.value === 0 ? CHART_COLORS.gridBaseline : CHART_COLORS.gridLine)
      }
    },
    x: {
      grid: {
        lineWidth: 0
      },
      ticks: {
        callback: (value: number) => {
          if (value === labels.length - 1) return labels[value]
          return formatChartDateLabel(labels[value], Granularity.MONTH, language)
        },
        color: (context: ScriptableScaleContext) => (context.tick.value === activeIndex ? CHART_COLORS.blueDark : CHART_COLORS.textSubdued),
        font: {
          family: "'Commuters Sans', sans-serif",
          size: 10
        }
      }
    }
  },
  plugins: {
    annotation: {
      common: {
        drawTime: 'beforeDraw'
      },
      annotations: {
        ...alternatingBackground,
        lastColumn: {
          type: 'box',
          backgroundColor: pattern.draw('diagonal-right-left', '#EAEAEB', '#E1E1E5', 20),
          borderWidth: 0,
          xMax: 0.5 + labels.length - 1,
          xMin: -0.5 + labels.length - 1
        }
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        padding: 20
      }
    },
    tooltip: {
      // Disable the on-canvas tooltip
      enabled: false,
      external: (context: any) =>
        externalTooltipHandler({
          context,
          totalColumns: labels.length,
          skipLast: true
        }),
      bodyAlign: 'center',
      titleAlign: 'center',
      filter: (context: ScriptableContext<'bar'>) => context.dataIndex !== labels.length - 1,
      callbacks: {
        label: (context: any) => {
          const value = `€ ${formatAmount(context.raw)}`
          const label = formatChartDateLabel(context.label, Granularity.MONTH, language, true)
          return context.dataIndex !== labels.length - 1 ? `${label}: ${value}` : value
        },
        title: (context: any) => context?.[0]?.dataset?.label
      }
    }
  }
})

export const getInstalmentChartDatasets = (
  labels: ChartLabels,
  data: BillShock,
  activePeriod: CycleViews,
  isMobile?: boolean,
  adjustedBillShockAmount?: number
) => {
  const settlementColor = getSettlementColor(activePeriod === CycleViews.CURRENT ? data.settlement.billShockAmount : 0)

  return !isMobile
    ? // DESKTOP
      [
        {
          label: i18next.t('currentBillingCycle.tooltips.billedInstalment', { ns: 'charts' }),
          backgroundColor: CHART_COLORS.lightblueLight,
          data: createChartDataArray(
            labels.length,
            data.past.map((instalment) => instalment.billedInstalment)
          ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 4
        },
        {
          label: i18next.t('currentBillingCycle.tooltips.plannedInstalment', { ns: 'charts' }),
          backgroundColor: activePeriod === CycleViews.CURRENT ? CHART_COLORS.blueDark : CHART_COLORS.gray,
          legendStateInactive: activePeriod !== CycleViews.CURRENT,
          data: createChartDataArray(
            labels.length,
            data.future.map((instalment) => instalment.plannedInstalment),
            data.past.length
          ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 4
        },
        {
          label: i18next.t('currentBillingCycle.tooltips.proposedInstalment', { ns: 'charts' }),
          backgroundColor: activePeriod === CycleViews.PROPOSED ? CHART_COLORS.greenDark : CHART_COLORS.gray,
          legendStateInactive: activePeriod !== CycleViews.PROPOSED,
          data: createChartDataArray(
            labels.length,
            data.future.map((instalment) => instalment.proposedInstalment),
            data.past.length
          ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 4
        },
        {
          label: i18next.t('currentBillingCycle.tooltips.billShockAmount', { ns: 'charts' }),
          backgroundColor: settlementColor,
          data: createChartDataArray(
            labels.length,
            [
              activePeriod === CycleViews.PROPOSED
                ? adjustedBillShockAmount
                  ? adjustedBillShockAmount
                  : -10
                : data.settlement.billShockAmount
            ],
            labels.length - 1
          ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 5
        }
      ]
    : // MOBILE
      [
        {
          label: i18next.t('currentBillingCycle.tooltips.billedInstalment', { ns: 'charts' }),
          backgroundColor: CHART_COLORS.blueLight,
          data: createChartDataArray(
            labels.length,
            data.past.map((instalment) => instalment.billedInstalment)
          ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 2
        },
        {
          label: i18next.t(
            `currentBillingCycle.tooltips.${activePeriod === CycleViews.PROPOSED ? 'proposedInstalment' : 'plannedInstalment'}`,
            { ns: 'charts' }
          ),
          backgroundColor: activePeriod === CycleViews.PROPOSED ? CHART_COLORS.greenDark : CHART_COLORS.blueDark,
          data:
            activePeriod === CycleViews.PROPOSED
              ? createChartDataArray(
                  labels.length,
                  data.future.map((instalment) => instalment.proposedInstalment),
                  data.past.length
                )
              : createChartDataArray(
                  labels.length,
                  data.future.map((instalment) => instalment.plannedInstalment),
                  data.past.length
                ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 2
        },
        {
          label: i18next.t('currentBillingCycle.tooltips.billShockAmount', { ns: 'charts' }),
          backgroundColor: settlementColor,
          data: createChartDataArray(
            labels.length,
            [
              activePeriod === CycleViews.PROPOSED
                ? adjustedBillShockAmount
                  ? adjustedBillShockAmount
                  : -10
                : data.settlement.billShockAmount
            ],
            labels.length - 1
          ),
          skipNull: true,
          maxBarThickness: 26,
          borderRadius: 2
        }
      ]
}
