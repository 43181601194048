import { ReferralIconType } from 'components/Icon/ReferralIcon/types'

export const iconVariants = {
  [ReferralIconType.SMILEY]: {
    rectFill: '#D3F7EF',
    pathFill: '#009E7B',
    d: 'M14.7507 13.25C13.8159 13.25 13.1257 14.0374 13.1257 14.9231C13.1257 15.8086 13.8159 16.5962 14.7507 16.5962C15.6855 16.5962 16.3756 15.8086 16.3756 14.9231C16.3756 14.0374 15.6854 13.25 14.7507 13.25ZM25.2498 13.25C24.3151 13.25 23.6248 14.0374 23.6248 14.9231C23.6248 15.8086 24.3151 16.5962 25.2498 16.5962C26.1845 16.5962 26.8747 15.8086 26.8747 14.9231C26.8747 14.0374 26.1844 13.25 25.2498 13.25ZM12 19.6617C11.7625 19.6617 11.539 19.7743 11.3975 19.965C11.2561 20.1558 11.2134 20.4024 11.2824 20.6296L12 20.4117C11.2824 20.6296 11.2825 20.63 11.2826 20.6303L11.2828 20.6311L11.2834 20.6332L11.2853 20.6391L11.2913 20.6581C11.2963 20.6739 11.3033 20.6957 11.3126 20.7233C11.331 20.7785 11.3579 20.8565 11.394 20.9543C11.466 21.1496 11.5745 21.4243 11.7237 21.752C12.0213 22.4054 12.4856 23.2803 13.1527 24.1593C14.4872 25.9176 16.6846 27.75 20 27.75C23.3154 27.75 25.5128 25.9176 26.8473 24.1593C27.5144 23.2803 27.9787 22.4054 28.2763 21.752C28.4255 21.4243 28.534 21.1496 28.606 20.9543C28.6421 20.8565 28.669 20.7785 28.6875 20.7233C28.6967 20.6957 28.7037 20.6739 28.7088 20.6581L28.7147 20.6391L28.7166 20.6332L28.7172 20.6311L28.7175 20.6303C28.7176 20.63 28.7177 20.6296 28 20.4117L28.7177 20.6296C28.7867 20.4024 28.7439 20.1558 28.6025 19.965C28.461 19.7743 28.2375 19.6617 28 19.6617H12ZM14.3475 23.2524C13.7751 22.4982 13.3686 21.7398 13.1032 21.1617H26.8968C26.6314 21.7398 26.2249 22.4982 25.6525 23.2524C24.4868 24.7883 22.6843 26.25 20 26.25C17.3157 26.25 15.5132 24.7883 14.3475 23.2524Z'
  },
  [ReferralIconType.OPEN_MOUNTH]: {
    rectFill: '#FFF9D1',
    pathFill: '#E0AF00',
    d: 'M14.7509 13.25C13.8161 13.25 13.126 14.0374 13.126 14.9231C13.126 15.8086 13.8161 16.5962 14.7509 16.5962C15.6857 16.5962 16.3758 15.8086 16.3758 14.9231C16.3758 14.0374 15.6857 13.25 14.7509 13.25ZM25.25 13.25C24.3153 13.25 23.6251 14.0374 23.6251 14.9231C23.6251 15.8086 24.3153 16.5962 25.25 16.5962C26.1847 16.5962 26.8749 15.8086 26.8749 14.9231C26.8749 14.0374 26.1847 13.25 25.25 13.25ZM17.8931 21.6843C17.8931 20.282 18.9053 19.2764 20.0002 19.2764C21.0951 19.2764 22.1074 20.282 22.1074 21.6843V24.8422C22.1074 26.2444 21.0951 27.2501 20.0002 27.2501C18.9053 27.2501 17.8931 26.2444 17.8931 24.8422V21.6843ZM20.0002 17.7764C17.9391 17.7764 16.3931 19.5983 16.3931 21.6843V24.8422C16.3931 26.9281 17.9391 28.7501 20.0002 28.7501C22.0613 28.7501 23.6074 26.9281 23.6074 24.8422V21.6843C23.6074 19.5983 22.0613 17.7764 20.0002 17.7764Z'
  }
}
