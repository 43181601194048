const Move = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.584 6.82125C21.3124 7.30689 21.75 8.12448 21.75 8.99999V19C21.75 20.5188 20.5188 21.75 19 21.75H18.75V15.5C18.75 13.7051 17.2949 12.25 15.5 12.25C13.7051 12.25 12.25 13.7051 12.25 15.5V21.75H5C3.48122 21.75 2.25 20.5188 2.25 19V8.99999C2.25 8.12448 2.68756 7.30689 3.41603 6.82125L10.4746 2.11555C11.3983 1.49973 12.6017 1.49973 13.5254 2.11555L20.584 6.82125ZM17.25 15.5V21.75H13.75V15.5C13.75 14.5335 14.5335 13.75 15.5 13.75C16.4665 13.75 17.25 14.5335 17.25 15.5ZM5.25 15C5.25 15.9665 6.0335 16.75 7 16.75H9C9.9665 16.75 10.75 15.9665 10.75 15V12C10.75 11.0335 9.9665 10.25 9 10.25H7C6.0335 10.25 5.25 11.0335 5.25 12L5.25 15ZM7 15.25C6.86193 15.25 6.75 15.1381 6.75 15V12C6.75 11.8619 6.86193 11.75 7 11.75H9C9.13807 11.75 9.25 11.8619 9.25 12V15C9.25 15.1381 9.13807 15.25 9 15.25H7Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Move
