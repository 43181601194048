import { FC } from 'react'
import styles from './NeedHelp.module.scss'
import { useTranslation } from 'react-i18next'

const NeedHelp: FC = () => {
  const { t } = useTranslation('auth')

  return (
    <p className={styles['need-help']}>
      <span>{t('authOptions.needHelp')}</span>
      <br />
      {t('authOptions.contact')} <a href="mailto:info@boltenergie.be">e-mail</a>
      {t('authOptions.orCall')} <a href="tel:028993300">02 899 33 00</a>
    </p>
  )
}

export default NeedHelp
