import { AppStore, Language } from 'store/app/types'
import { readCookie } from 'utils/cookies'

const langCookie = readCookie('language')

export const initialAppState: AppStore = {
  betaMode: false,
  language: !!langCookie && langCookie !== 'undefined' ? (langCookie as Language) : Language.NL,
  redirect: null,
  error: null,
  urlSearchParams: {}
}
