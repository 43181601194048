import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Injection: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor, isFilled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      {isFilled ? (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M15.25 6a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM7 10.25h2.349l-1.245 4h-3.37l1.58-3.555A.75.75 0 0 1 7 10.25Zm-2.932 5.5-1.753 3.945A.75.75 0 0 0 3 20.75h3.08l1.557-5H4.068Zm5.14 0-1.557 5h8.698l-1.557-5H9.208Zm7.155 0 1.557 5H21a.75.75 0 0 0 .666-1.095L19.64 15.75h-3.278Zm2.5-1.5h-2.967l-1.245-4h1.682a.75.75 0 0 1 .666.405l1.864 3.595Zm-4.538 0h-4.65l1.245-4h2.16l1.245 4Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M18 4.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM15.25 6a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM10.982 10.25h5.351a.75.75 0 0 1 .666.405l4.667 9A.75.75 0 0 1 21 20.75H3a.75.75 0 0 1-.685-1.055l4-9A.75.75 0 0 1 7 10.25h3.982Zm3.978 9H9.04l1.167-3.5h3.586l1.167 3.5Zm-1.667-5h-2.586l.833-2.5h.92l.833 2.5Zm2.08 1.5 1.168 3.5h3.225l-1.814-3.5h-2.578Zm1.8-1.5h-2.3l-.832-2.5h1.836l1.297 2.5Zm-8.047 0 .833-2.5H7.487l-1.11 2.5h2.75ZM5.732 15.7l-1.578 3.55H7.46l1.167-3.5H6a.747.747 0 0 1-.268-.05Z"
          clipRule="evenodd"
        />
      )}
    </svg>
  )
}

Injection.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Injection
