import { PropsWithChildren } from 'react'
import styles from './StepSummary.module.scss'
import { StepSummaryProps as Props } from './types'
import classNames from 'classnames'

const StepSummary = ({ badgeText, className, children }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles['step-summary-container'], className)}>
      <strong className={styles.badge}>{badgeText}</strong>
      {children}
    </div>
  )
}

export default StepSummary
