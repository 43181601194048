import styles from 'features/contracts/done/Done.module.scss'
import parse from 'html-react-parser'
import { formatAddress } from 'utils/user.ts'
import dayjs from 'dayjs'
import FlowDoneCard from 'features/contracts/done/components/flow-done-card/FlowDoneCard.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { useTranslation } from 'react-i18next'
import { Button, InlineMessage } from '@boltenergy-be/design-system'
import { StartStopCardProps } from '../types.ts'
import mixpanel from 'mixpanel-browser'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds.ts'
import { ContractFlowTrackingTypes } from 'types/tracking.ts'
import { routes } from 'types/routes.ts'
import { useNavigate } from 'react-router'

const StartCard = ({ handleBack }: StartStopCardProps) => {
  // Redux
  const { move } = useStoreSelector((store) => store.user)

  // i18n
  const { t } = useTranslation('contracts')

  // React router
  const navigate = useNavigate()

  /**
   * Handles start terminate flow button click
   */
  const handleStartNewAddressFlow = () => {
    mixpanel.track(MX_CONTRACT_FLOW.start, {
      flow: move.flow,
      type: ContractFlowTrackingTypes.ADD,
      from: MX_CONTRACT_FLOW.from.FINISH_MOVE
    })

    // Go to terminate flow
    navigate(routes.CONTRACTS_MOVE)
  }

  return (
    <FlowDoneCard type="started" title={t(`done.start.${move.started ? 'complete' : 'incomplete'}.title`)}>
      {move.started ? (
        <>
          <address className={styles.address}>{parse(formatAddress(move.started.address, true) || '')}</address>
          <span>{t('done.start.complete.startDate', { date: dayjs(move.started.date).format('DD MMMM YYYY') })}</span>
          <InlineMessage type="positive">{t('done.statuses.complete')}</InlineMessage>
        </>
      ) : (
        <>
          <p>{t('done.start.incomplete.description')}</p>
          <InlineMessage type="warning">{t('done.statuses.incomplete')}</InlineMessage>
          <div className={styles.actions}>
            <Button onClick={handleStartNewAddressFlow}>{t('done.start.incomplete.actions.startNewAddress')}</Button>
            <Button onClick={handleBack} representation="link">
              {t('done.start.incomplete.actions.postpone')}
            </Button>
          </div>
        </>
      )}
    </FlowDoneCard>
  )
}

export default StartCard
