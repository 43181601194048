import Card from 'components/Card/Card.tsx'
import { useTranslation } from 'react-i18next'
import { CardVariants } from 'components/Card/types.ts'
import classNames from 'classnames'
import styles from './Initiation.module.scss'
import { routes } from 'types/routes.ts'
import Video from 'components/Video/Video.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import parse from 'html-react-parser'
import { Language } from 'store/app/types.ts'
// import { Link } from 'react-router-dom'
import FooterFaq from 'components/Faq/FooterFaq/FooterFaq.tsx'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { FaqSectionSlugs } from 'types/faq'
import Link from 'components/Link/Link.tsx'

const Initiation = () => {
  // Redux
  const { language } = useStoreSelector((store) => store.app)

  // i18n
  const { t } = useTranslation(['move', 'referral'])

  // API
  const { data: faq, isLoading: faqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.MOVE_FR : FaqSectionSlugs.MOVE_NL
  })

  return (
    <>
      <Card variant={CardVariants.DEFAULT_MORE_PADDING} as="section" className={classNames('container', styles.info)}>
        <header>
          <Card.Title as="h1" variant="h3">
            {t('initiation.info.title')}
          </Card.Title>
          <p className={styles.subtitle}>
            {parse(
              t('initiation.info.description', {
                url: `https://files.boltenergie.be/energieovernamedocument/${
                  language === Language.FR ? 'document-de-reprise-des-energies' : 'energieovernamedocument'
                }.pdf`
              })
            )}
          </p>
        </header>

        <div className={styles.content}>
          <ol>
            <li>
              <span className={styles.bullet}>1</span>
              <strong>{t('initiation.info.steps.newAddress.title')}</strong>
              <p>{t('initiation.info.steps.newAddress.description')}</p>
            </li>
            <li>
              <span className={styles.bullet}>2</span>
              <strong>{t('initiation.info.steps.oldAddress.title')}</strong>
              <p>{t('initiation.info.steps.oldAddress.description')}</p>
            </li>
            <li>
              <span className={styles.bullet}>3</span>
              <strong>{t('initiation.info.steps.referral.title')}</strong>
              <p>{t('initiation.info.steps.referral.description')}</p>
            </li>
          </ol>

          <p>{t('initiation.info.steps.extra')}</p>
          <Link href={routes.CONTRACTS} representation="button">
            {t('initiation.info.steps.button')}
          </Link>
        </div>

        <figure className={styles['video-wrapper']}>
          <Video publicId={`website/videos/move-${language}`} thumbnailOffset="6" />
          <figcaption>{t('initiation.info.videoCaption')}</figcaption>
        </figure>
      </Card>

      <Card variant={CardVariants.DEFAULT_MORE_PADDING} as="section" className={classNames('container', styles.referral)}>
        <Card.Title as="h2" variant="h4">
          {t('initiation.referral.title')}
        </Card.Title>
        <p className={styles.subtitle}>{t('initiation.referral.description')}</p>
        <Link representation="button" href={routes.FRIENDS} variant="secondary-on-accent">
          {t('initiation.referral.button')}
        </Link>
      </Card>

      <FooterFaq isLoading={faqLoading} category={faq?.category} subtitle={t('faq.subtitle')} />
    </>
  )
}

export default Initiation
