import { store } from 'store'
import { AuthStore, UserTypes } from 'store/auth/types'
import { CustomerLibrary, UserStore } from 'store/user/types'
import { selectCustomer, setProducer } from 'store/user/slice'
import { selectContracts } from 'store/user/thunks/customer'
import { UserObjectForApiCalls } from 'types/user'
import { Address, Contract, ContractType, Customer } from 'types/types'
import { routes } from 'types/routes'
import { checkIsSales } from 'utils/sales'
import { USER_TYPE_CONFIG } from 'constants/userTypes'
import { resetContractsStore } from 'store/contracts/slice'
import { getProductFromProductCode } from 'utils/products'

/**
 * Returns an object with user data, needed for some API calls
 *
 * @returns {UserObjectForApiCalls}
 */
export const getUserObjectForApiCalls = (): UserObjectForApiCalls | null => {
  const { userType }: AuthStore = store.getState().auth
  const { info, selectedCustomer, customers }: UserStore = store.getState().user
  const isSuperUser = userType === UserTypes.SUPER_USER

  if (selectedCustomer) {
    const customer = customers[selectedCustomer]
    return {
      firstName: (isSuperUser ? customer.person : info).firstName,
      email: info.email,
      language: (isSuperUser ? customer : info).language,
      customerNumber: customer.id
    }
  }

  return null
}

/**
 * Sets selected user data in the store
 *
 * @param {Customer[]} customers
 * @param {number} selectedCustomerId
 * @param {number} selectedContractId
 */
export const setSelectedUserDataInStore = (customers: CustomerLibrary, selectedCustomerId: string, selectedContractId: string) => {
  const emptyAddress = {
    streetName: '',
    streetNumber: '',
    streetBox: '',
    townName: '',
    postalCode: ''
  }

  const selectedCustomer: Customer | undefined = customers[selectedCustomerId]
  const selectedContractsList: Contract[] | undefined =
    selectedCustomer?.contracts?.length === 1
      ? selectedCustomer?.contracts[0]
      : selectedCustomer?.contracts?.reduce((prev: Contract[], curr: Contract[]) => {
          if (curr.some((contract) => contract.id === selectedContractId)) {
            return curr
          }
          return prev
        }, [])

  const producerId = selectedContractsList?.find((c) => c.producer && c.producer !== '')?.producer
  const address = selectedContractsList?.find((c) => c.type === ContractType.ELECTRICITY)?.address

  store.dispatch(resetContractsStore())
  if (selectedCustomer)
    store.dispatch(
      selectCustomer({
        customerId: selectedCustomer.id,
        electricityProduct: getProductFromProductCode(
          selectedContractsList?.find((c) => c.type === ContractType.ELECTRICITY)?.detail?.productCode
        )
      })
    )
  store.dispatch(selectContracts({ contracts: selectedContractsList || [], address: address || emptyAddress }))
  store.dispatch(setProducer({ id: producerId || '' }))
}

/**
 * returns the correct route of the user types' homepage
 *
 * @param {UserTypes} type
 * @return {string}
 */
export const getUserTypeHomepage = (type: UserTypes | null): string => {
  switch (type) {
    case UserTypes.SALES:
      return routes.SALES_HOME
    case UserTypes.SUPER_USER:
      return routes.CUSTOMER_EMAIL
    case UserTypes.CUSTOMER:
      return routes.OVERVIEW_GENERAL
    default:
      return checkIsSales() ? routes.LOGIN_SALES : routes.LOGIN
  }
}

/**
 * returns the correct route of the user types' homepage
 *
 * @param {UserTypes} type
 * @return {string}
 */
export const getUserTypeLoginPage = (type: UserTypes | null): string => {
  if (type) return USER_TYPE_CONFIG[type].login
  return checkIsSales() ? USER_TYPE_CONFIG[UserTypes.SALES].login : USER_TYPE_CONFIG[UserTypes.CUSTOMER].login
}

/**
 * Verifies if the given coords are not null/undefined and not of value 0
 * @param {(number | null)[]} coords
 * @returns {boolean}
 */
export const verifyCoordinates = (coords: (number | null)[]): boolean =>
  coords.every((coordinate) => typeof coordinate === 'number' && coordinate !== 0)

/**
 * Formats the given address into a string
 *
 * @param {Address} address
 * @param {boolean} twoLines
 * @returns string|null
 */
export const formatAddress = (address?: Address, twoLines?: boolean): string | null => {
  if (!address) {
    return null
  }

  if (address.streetBox) {
    return twoLines
      ? `${address.streetName} ${address.streetNumber} ${address.streetBox}<br />${address.postalCode} ${address.townName}`
      : `${address.streetName} ${address.streetNumber} ${address.streetBox}, ${address.postalCode} ${address.townName}`
  }

  return twoLines
    ? `${address.streetName} ${address.streetNumber}<br />${address.postalCode} ${address.townName}`
    : `${address.streetName} ${address.streetNumber}, ${address.postalCode} ${address.townName}`
}
