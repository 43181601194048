import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Methods } from 'types/request'
import { GetCustomerBalancePayUrlResponseBody } from 'api/types.ts'

/**
 * Fetches the balance pay url for the given customer number
 *
 * @param {string} customerNumber
 * @returns {Promise<string | undefined>}
 */
export const getCustomerBalancePayUrl = async (customerNumber: string): Promise<string | undefined> => {
  const { success, data } = await newRequest<GetCustomerBalancePayUrlResponseBody>(
    getAxiosRequestConfig(Methods.GET, `/customers/${customerNumber}/balance-pay-url`)
  )

  return success && data ? data.balancePayUrl : undefined
}
