import TooltipOnClick from 'components/Tooltip/TooltipOnClick'
import styles from './ConfirmationTooltip.module.scss'
import { ConfirmationTooltipProps } from './types'
import { Button } from '@boltenergy-be/design-system'

const ConfrmationTooltip = ({ label, confirmation, children, onConfirm, width = 360, disabled, isLoading }: ConfirmationTooltipProps) => {
  return (
    <TooltipOnClick
      tooltipContent={
        <div className={styles['confirmation-tooltip']}>
          <small>{label}</small>
          <Button onClick={onConfirm} loading={isLoading} className={styles.confirmation}>
            {confirmation}
          </Button>
        </div>
      }
      classNameTrigger={styles['badge-container']}
      variant="tertiary"
      isOpen={isLoading}
      {...{ width, disabled }}
    >
      {children}
    </TooltipOnClick>
  )
}

export default ConfrmationTooltip
