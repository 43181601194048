import dayjs from 'dayjs'
import { Chart as ChartJS } from 'chart.js'
import { CoreAnnotationOptions } from 'chartjs-plugin-annotation'
import { ChartLabels, MinMaxData } from 'components/Charts/types'
import { BillShock } from 'types/billShock'
import { Language } from 'store/app/types'
import { Granularity } from 'types/contracts'
import { getArrayOfFormattedDatesBetweenDates } from './date'
import { VolumeEntry } from 'pages/App/Consumption/YourConsumption/ConsumptionSection/types.ts'
import { getCssVariable } from 'utils/app.ts'

/**
 * Generate annotation object for alternating background columns
 * @param {ChartLabels} labels
 */
export const getAlternatingBackground = (labels: ChartLabels) => {
  // Create array of the amount of labels and generate background shapes
  return Array.from(Array(labels.length).keys()).reduce((prev: { [key: string]: CoreAnnotationOptions }, curr) => {
    // ignore uneven entries (array starts at 0)
    if (curr % 2) {
      return prev
    }

    // create background shape object
    const backgroundShape = {
      type: 'box',
      backgroundColor: getCssVariable('--bg-layer'),
      borderWidth: 0,
      xMax: 0.5 + curr,
      xMin: -0.5 + curr
    }

    return { ...prev, [curr]: backgroundShape }
  }, {}) as CoreAnnotationOptions
}

/**
 * returns active index based on array of ISO strings
 * @param {string[]} months
 * @param {string} givenMonth
 * @returns number
 */
export const getActiveMonthIndex = (months: string[], givenMonth?: string): number => {
  const format = 'YY-MMM'
  return months.findIndex((month) => dayjs(month).format(format) === dayjs(givenMonth || new Date()).format(format))
}

/**
 * generates array of given length with data from an array starting from a certain index
 * @param {number} arraySize
 * @param {(number|null)[]} dataArray
 * @param {[number]} startIndex
 * @returns {(number|null)[]}
 */
export const createChartDataArray = (arraySize: number, dataArray: (number | null)[], startIndex?: number): (number | null)[] => {
  let idx = 0
  return Array.from(Array(arraySize).keys()).reduce((prev: (number | null)[], curr) => {
    if (curr >= (startIndex || 0) && curr <= (startIndex || 0) + dataArray.length - 1) {
      prev.push(dataArray[idx] ?? null)
      idx++
    } else {
      prev.push(null)
    }

    return prev
  }, [])
}

/**
 * calculate min and max values based on array of BillShock data
 * @param {BillShock} data
 * @returns {MinMaxData}
 */
export const getBillingCycleMinMaxRange = (data: BillShock): MinMaxData => {
  let max = 0
  let min = 0

  if (data.settlement.billShockAmount > max) max = data.settlement.billShockAmount
  if (data.settlement.billShockAmount < min) min = data.settlement.billShockAmount

  data.past.forEach((cycle) => {
    if (cycle.billedInstalment > max) max = cycle.billedInstalment
    if (cycle.billedInstalment < min) min = cycle.billedInstalment
  })

  data.future.forEach((cycle) => {
    if (cycle.plannedInstalment > max) max = cycle.plannedInstalment
    if (cycle.proposedInstalment > max) max = cycle.proposedInstalment

    if (cycle.plannedInstalment < min) min = cycle.plannedInstalment
    if (cycle.proposedInstalment < min) min = cycle.proposedInstalment
  })

  max = Math.ceil(max / 100) * 100
  min = Math.floor(min / 100) * 100

  if (min > -200) {
    min = -200
  }

  return { maxDataValue: max, minDataValue: min }
}

/**
 * Calculates the dimensions of the chart
 *
 * @param {ChartJS} chart
 * @param {number} settlementAmount
 * @returns {{chartWidth: any, chartYAxisWidth: any, chartXAxisHeight: number, settlementOuterEdge: number}}
 */
export const calculateChartDimensions = (chart: ChartJS, settlementAmount: number) => {
  const xLayoutItem = chart.boxes[chart.boxes.length - 1] as any
  const yLayoutItem = chart.boxes[0] as any

  const columnHeight = yLayoutItem.height
  const maxY = yLayoutItem.max
  const minY = yLayoutItem.min
  const rangeY = Math.abs(minY) + Math.abs(maxY)
  const isNegative = settlementAmount < 0

  const chartXAxisH = chart.height - yLayoutItem.bottom

  const settlementRatio = isNegative
    ? ((Math.abs(minY) - Math.abs(settlementAmount)) / rangeY) * 100
    : ((Math.abs(minY) + Math.abs(settlementAmount)) / rangeY) * 100

  return {
    settlementOuterEdge: (columnHeight / 100) * settlementRatio + chartXAxisH,
    chartWidth: xLayoutItem.width,
    chartYAxisWidth: xLayoutItem.left,
    chartXAxisHeight: chart.height - yLayoutItem.bottom
  }
}

/**
 * Generates an array for the chart labels
 *
 * @param {Granularity} granularity
 * @param {string[]} dates
 * @param {Language} language
 * @param {string[]} append
 * @returns {ChartLabels}
 */
export const getChartDateLabels = (granularity: Granularity, dates: string[], language: Language, append?: string[]): ChartLabels => {
  const dateLabels = dates.map((d) => {
    const date = dayjs(d).locale(language)
    return granularity === Granularity.MONTH
      ? date.format('MMM').charAt(0).toUpperCase()
      : granularity === Granularity.DAY
        ? date.format('DD')
        : date.format('HH')
  })

  return append ? [...dateLabels, ...append] : dateLabels
}

/**
 * Formats the chart date label
 *
 * @param {string} label
 * @param {Granularity} granularity
 * @param {Language} language
 * @param {boolean} full
 * @returns {string}
 */
export const formatChartDateLabel = (label: string, granularity: Granularity, language: Language, full?: boolean): string => {
  const date = dayjs(label).locale(language)

  if (full) {
    return granularity === Granularity.MONTH
      ? date.format('MMMM YYYY')
      : granularity === Granularity.DAY
        ? date.format('D MMMM YYYY')
        : date.format('HH:mm D MMMM YYYY')
  }

  return granularity === Granularity.MONTH
    ? date.format('MMM').charAt(0).toUpperCase()
    : granularity === Granularity.DAY
      ? date.format('D')
      : date.format('HH')
}

/**
 * Converts the given data to a full period (from - to) with the given granularity
 *
 * @param {VolumeEntry[]} data
 * @param {string} from
 * @param {string} to
 * @param {Granularity} granularity
 */
export const convertToFullPeriod = (data: VolumeEntry[], from: string, to: string, granularity: Granularity) => {
  // Define the unit to use
  const unit = granularity.toLowerCase() as dayjs.ManipulateType

  // Fetch all dates between the given period, with the given granularity
  const allDates = getArrayOfFormattedDatesBetweenDates(from, to, unit)

  // Fetch all existing dates from the data array
  const existingDates = data.map((d) => d.datetime)

  // Check which dates are missing (not in the data array) & add them to the data array
  const missingDates = allDates.filter((date) => !existingDates.some((existingDate) => dayjs(existingDate).isSame(date, unit)))
  missingDates.forEach((date) => {
    // Push the missing date into the data array with default values or any desired structure
    data.push({
      datetime: date,
      consumption: { billed: 0 },
      history: {}
    })
  })

  // Sort the data array based on the date property
  data.sort((a, b) => dayjs(a.datetime).diff(dayjs(b.datetime)))
}
