import { FC } from 'react'
import { EnergyIconProps as Props } from './types'
import { Bio, Sun, Water, Wind } from 'assets/svg'
import { ProducerEnergyType } from 'types/types'

const EnergyIcon: FC<Props> = ({ type }) => {
  switch (type) {
    case ProducerEnergyType.SOLAR:
      return <Sun isFilled />

    case ProducerEnergyType.WATER:
      return <Water />

    case ProducerEnergyType.WIND:
      return <Wind isFilled />

    case ProducerEnergyType.BIO:
      return <Bio isFilled />

    default:
      return <Wind isFilled />
  }
}

export default EnergyIcon
