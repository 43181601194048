import { FC } from 'react'
import { SvgProps } from 'assets/svg/types'

const Tv: FC<SvgProps> = ({ hasBackdrop, backdropColor, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {hasBackdrop && <circle cx="15" cy="9" r="9" fill={backdropColor} />}
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M9.5 2.44a.75.75 0 0 1 1.06.06L12 4.116l1.44-1.614a.75.75 0 1 1 1.12.998l-.886.993H19.5a2.25 2.25 0 0 1 2.25 2.25v10.453a2.25 2.25 0 0 1-2.25 2.25h-.75V21a.75.75 0 0 1-1.5 0v-1.555H6.75v1.673a.75.75 0 1 1-1.5 0v-1.672H4.5a2.25 2.25 0 0 1-2.25-2.25V6.741a2.25 2.25 0 0 1 2.25-2.25h5.826L9.44 3.5A.75.75 0 0 1 9.5 2.44Zm2.495 3.552H19.5a.75.75 0 0 1 .75.75v10.453a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75V6.742a.75.75 0 0 1 .75-.75h7.495Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

Tv.defaultProps = {
  hasBackdrop: false,
  backdropColor: '#23D5AE',
  iconColor: 'currentColor',
  isFilled: false
}

export default Tv
