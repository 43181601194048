import dayjs from 'dayjs'
import { MIN_AGE } from './constants'

/**
 * Returns if underaged based on the given day, month & year
 *
 * @param day
 * @param month
 * @param year
 * @returns boolean
 */
export const isUnderaged = (day: string, month: string, year: string): boolean => {
  const date = dayjs()
    .year(parseInt(year, 10))
    .month(parseInt(month, 10) - 1)
    .date(parseInt(day, 10))
  const age = dayjs().diff(date, 'days') * 0.002738

  return age < MIN_AGE
}

/**
 * Returns if the given day, month & year can form a valid date
 *
 * @param day
 * @param month
 * @param year
 * @returns boolean
 */
export const isValidDate = (day: string, month: string, year: string): boolean => {
  if (day === '' || month === '' || year === '') return false

  const date = dayjs()
    .year(parseInt(year, 10))
    .month(parseInt(month, 10) - 1)
  const daysInMonth = date.daysInMonth()

  return parseInt(day, 10) <= daysInMonth
}
