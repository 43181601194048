const Overview = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.25 15.75v6H4.5a2.25 2.25 0 0 1-2.25-2.25v-3.75h12Zm1.5 0v6h3.75a2.25 2.25 0 0 0 2.25-2.25v-3.75h-6Zm6-1.5h-6v-12h3.75a2.25 2.25 0 0 1 2.25 2.25v9.75Zm-7.5 0h-12V4.5A2.25 2.25 0 0 1 4.5 2.25h9.75v12Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Overview
