import { TableHeaderValueType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'

export const headers = [
  {
    value: 'Date & time'
  },
  {
    value: 'Type'
  },
  {
    value: 'Updater'
  },
  {
    value: 'Updater user type'
  },
  {
    value: 'User'
  },
  {
    value: 'Data'
  }
] as unknown as TableHeaderValueType[]
