export type InstalmentForm = {
  totalInstalment: number
  electricityInstalment: number
  gasInstalment?: number
}

// ENUMS

export enum FieldTypes {
  FUEL = 'fuel',
  TOTAL = 'total'
}
