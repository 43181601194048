import classNames from 'classnames'
import styles from 'pages/App/Consumption/YourConsumption/TotalsBlock/TotalsBlock.module.scss'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { PropsWithChildren } from 'react'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover.tsx'
import { TotalsBlockProps } from './types.ts'

const Totals = ({ children }: PropsWithChildren) => {
  return <div className={styles.totals}>{children}</div>
}

const Content = ({ isLoading, children, label, color, fullwidth }: PropsWithChildren<TotalsBlockProps>) => {
  return (
    <div
      className={classNames(styles.card, {
        [styles[color || 'default']]: !!color,
        [styles['fullwidth-mobile']]: fullwidth === 'mobile',
        [styles.fullwidth]: !!fullwidth && typeof fullwidth === 'boolean'
      })}
    >
      {isLoading ? (
        <LoadingSkeleton className="mb-100">
          <LoadingSkeleton.Rectangle height={20} width={140} />
        </LoadingSkeleton>
      ) : (
        children
      )}
      <small>{label}</small>
    </div>
  )
}

const TotalsBlock = ({ tooltip, children, ...props }: PropsWithChildren<TotalsBlockProps>) => {
  return tooltip ? (
    <TooltipOnHover tooltipContent={tooltip} tooltipClassname={styles.tooltip}>
      <Content {...props}>{children}</Content>
    </TooltipOnHover>
  ) : (
    <Content {...props}>{children}</Content>
  )
}

Totals.Block = TotalsBlock

export default Totals
