import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import confetti from 'canvas-confetti'
import styles from './ThanksSlide.module.scss'
import { ThanksSlideProps } from 'components/Slider/ThanksSlide/types'
import Logo from 'assets/logo.svg'

const ThanksSlide = ({ name, text, color = 'green', popConfetti }: ThanksSlideProps) => {
  // Ref
  const slide = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (popConfetti && slide?.current) {
      const canvas = Object.assign(document.createElement('canvas'), {
        style: 'position: absolute; top: 0; bottom: 0; left: 0; right; 0; width: 100%; height: 100%; z-index: 0;'
      })

      // Prepend the canvas element
      slide.current?.prepend(canvas)

      // Create and initiate the confetti
      const myConfetti = confetti.create(canvas, { resize: true })
      myConfetti({
        particleCount: 150,
        spread: 160,
        colors: color === 'blue' ? ['#2D449F'] : ['#23D5AE']
      })
    }
  }, [popConfetti])

  return (
    <div ref={slide} className={classNames(styles.slide, styles[color || 'green'])}>
      {!!name && <h3>{name},</h3>}
      <p className={classNames({ [styles.alone]: !name })}>{parse(text)}</p>
      <img src={Logo} className={styles.logo} alt="Bolt logo" />
    </div>
  )
}

export default ThanksSlide
