import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ContractTypeIcon from 'components/Icon/ContractTypeIcon/ContractTypeIcon'
import ClickableCard from 'components/ClickableCard/ClickableCard'
import { deleteRedirect } from 'store/app/slice'
import { setSelectedUserDataInStore } from 'utils/user'
import { getMemoizedAddressTiles, hasNoActiveContracts } from 'utils/contracts'
import { routes } from 'types/routes'
import styles from './Welcome.module.scss'
import SeoTags from 'components/SeoTags/SeoTags'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Heading } from '@boltenergy-be/design-system'
import Move from 'assets/svg/contracts/Move.tsx'
import Add from 'assets/svg/contracts/Add.tsx'
import { ProductType } from 'types/types.ts'

const Welcome = () => {
  // REDUX STORE
  const { redirect } = useStoreSelector((store) => store.app)
  const { customers } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // React Router
  const navigate = useNavigate()

  // i18n
  const { t } = useTranslation('home')

  // Constants
  const addressTiles = getMemoizedAddressTiles(customers)

  /**
   * Handles the tile click
   *
   * @param {number} selectedCustomerId
   * @param {number} selectedBillingId
   * @returns void
   */
  const handleTileClick = (selectedCustomerId: string, selectedBillingId: string) => {
    setSelectedUserDataInStore(customers, selectedCustomerId, selectedBillingId)

    // Define where to redirect: if redirect string in AppStore present, use that. Else redirect to overview
    if (redirect) {
      navigate(redirect)
      // Clear redirect from AppStore
      dispatch(deleteRedirect())
    } else {
      navigate(routes.OVERVIEW_GENERAL)
    }
  }

  /**
   * Select first eligible contract & navigate to designated contracts page
   * @param {boolean} isMove
   */
  const handleContractActionClick = (isMove: boolean) => {
    const contract = addressTiles.find((addressTile) => !hasNoActiveContracts(addressTile.contracts)) || addressTiles[0]
    setSelectedUserDataInStore(customers, contract.customerId, contract.billingNumber)
    navigate(isMove ? routes.MOVE_INITIATION : routes.CONTRACTS_ADD)
  }

  return (
    <>
      <SeoTags title={t('app.chooseAddress')} />

      <section className={styles.welcome}>
        <Heading as="h1" variant="h3">
          {t('app.chooseAddress')}
        </Heading>

        <ul className={styles['cards-container']}>
          {addressTiles.length === 0 ? (
            <li className={styles.empty}>{t('app.empty')}</li>
          ) : (
            addressTiles.map((addressTile) => {
              const { billingNumber, customerId, isProfessional, address, contracts } = addressTile

              return (
                <li key={billingNumber}>
                  <ClickableCard
                    onClick={() => handleTileClick(customerId, billingNumber)}
                    icons={
                      <>
                        {contracts.electricity !== null && (
                          <ContractTypeIcon type={ProductType.ELECTRICITY} active={contracts.electricity} />
                        )}
                        {contracts.gas !== null && <ContractTypeIcon type={ProductType.GAS} active={contracts.gas} />}
                      </>
                    }
                  >
                    <div
                      className={classNames(styles['card-content'], {
                        [styles['no-active-contracts']]: hasNoActiveContracts(contracts)
                      })}
                    >
                      <div className={styles['customer-id']}>
                        {t('clientId', { ns: 'common' })}: {customerId}
                        {isProfessional && ` (${t('company', { ns: 'common' })})`}
                      </div>
                      <Heading
                        as="h2"
                        variant="h6"
                        className={styles.street}
                      >{`${address.streetName} ${address.streetNumber} ${address.streetBox}`}</Heading>
                      <span className={styles['postal-code']}>{`${address.postalCode} ${address.townName}`}</span>
                    </div>
                  </ClickableCard>
                </li>
              )
            })
          )}
        </ul>

        <hr className={styles.separator} data-content={t('or')} />

        <div className={styles['contract-actions']}>
          <button onClick={() => handleContractActionClick(true)}>
            <Move />
            {t('actions.move')}
          </button>
          <button onClick={() => handleContractActionClick(false)}>
            <Add />
            {t('actions.add')}
          </button>
        </div>
      </section>
    </>
  )
}

export default Welcome
