import { SvgProps } from 'assets/svg/types'

const ChevronDown = ({ iconColor }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 9L12 15L18 9" stroke={iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

ChevronDown.defaultProps = {
  iconColor: 'currentColor'
}

export default ChevronDown
