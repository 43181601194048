import { RawStrapiTypeInsideData, UnknownObject } from 'types/cms'

/**
 * Helper function that flattens the structure of the strapi cms api response
 * @param {RawStrapiTypeInsideData<unknown>} res
 * @returns {{[p: string]: any, id: number} | null}
 */
export const flattenCmsResponse = (res?: RawStrapiTypeInsideData<UnknownObject>): { [p: string]: unknown; id: number } | null => {
  if (!res?.data) return null

  return {
    id: res.data.id,
    ...res.data.attributes
  }
}
